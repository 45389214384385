import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API } from "../../contants/ApiContants";
import { User } from "../models/auth.models";
import { ConfigService } from "./config.service";
import { DEFAULT_LAYERS_OPACITY } from "src/app/contants";
const apiKey = "AIzaSyCX4BvUM_iLMa1RW6opc0c2Rl79A-lzfAQ";

@Injectable({ providedIn: "root" })
export class MapService {
  private apiUrl = "https://maps.googleapis.com/maps/api/directions/json";

  private layersOpacity$ = new BehaviorSubject(DEFAULT_LAYERS_OPACITY);
  private baseLayerId$ = new BehaviorSubject("streets-v12");
  constructor(private httpRequest: ConfigService, private http: HttpClient) { }

  setLayersOpacity$(data) {
    this.layersOpacity$.next(data);
  }
  getLayersOpacity$() {
    return this.layersOpacity$.asObservable();
  }

  setBaseLayerId$(data) {
    this.baseLayerId$.next(data);
  }
  getBaseLayerId$() {
    return this.baseLayerId$.asObservable();
  }

  setMapDefault(bodyRQ) {
    return this.httpRequest.post(
      `${API.BASE_URL}/private/gis-db/resource/map/detail/update-default`,
      bodyRQ
    );
  }

  getMapDetail(id, layer = null) {
    return this.httpRequest.get(
      `${API.BASE_URL}/gis-portal/map-detail?linkmap=${id}${layer != "0" ? `&layer=${layer}` : ""
      }`
    );
  }

  getHuyenXa() {
    return this.httpRequest.get(`${API.BASE_MAP_MOBILE_URL}/gis/category`);
  }

  searchTD(body) {
    let bodyRQ = {
      ma_huyen: body.maHuyen,
      ma_xa: body.maXa,
      so_thua: body.soThua,
      so_to: body.soTo,
    };
    return this.httpRequest.post(
      `${API.BASE_URL}/gis-map/find/sheets-plots`,
      bodyRQ
    );
  }

  searchLocation(body) {
    return this.httpRequest.post(
      `${API.BASE_URL}/gis-map/find/coordinates`,
      body
    );
  }

  searchAround(body) {
    return this.httpRequest.post(`${API.BASE_URL}/gis-map/find/by-space`, body);
  }

  getTableData(id, page, size) {
    return this.httpRequest.get(
      `${API.BASE_URL}/gis-portal/map-detail/layer-data/view-detail?page=${page}&size=${size}&tbkey=${id}`
    );
  }

  getMetaData(type, id) {
    return this.httpRequest.get(
      `${API.BASE_URL}/gis-portal/map-detail/metadata?maloai=${type}&id=${id}`
    );
  }

  filterData(tbkey, body, page, size) {
    return this.httpRequest.post(
      `${API.BASE_URL}/gis-portal/map-detail/layer-data/filter?page=${page}&size=${size}&tbkey=${tbkey}`,
      body
    );
  }

  getDataPopup(body) {
    return this.httpRequest.post(
      `${API.BASE_URL}/gis-portal/filter-data-popup`,
      body
    );
  }

  getListMap() {
    return this.httpRequest.get(
      `${API.BASE_URL}/private/gis-db/resource/map/ddl`
    );
  }

  getAllLayer() {
    return this.httpRequest.get(`${API.BASE_URL}/private/layer/find-all`);
  }

  getDirections(
    origin: string,
    destination: string,
    mode: string
  ): Observable<any> {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/directions/json?origin=place_id:${origin}&destination=place_id:${destination}&mode=${mode}&language=vi&key=${apiKey}`
    );
  }

  searchPlace(query: string): Observable<any> {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${query}&key=${apiKey}`
    );
  }
  analystParcel(layerName, fid, analystParams) {
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getAnalystParcelData?layerName=` +
      layerName +
      "&includegeom=true&fid=" +
      fid,
      analystParams
    );
  }
  analystParcels(layerName, analystParams) {
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getAnalystTableData?layerName=` +
      layerName +
      "&includegeom=true",
      analystParams
    );
  }

  getOjbectBound(table, pkey, pvalue) {
    const searchParams = [
      {
        field: pkey,
        operato: "=",
        join: "and",
        value: "'" + pvalue + "'",
      },
    ];
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getTableData?layerName=` +
      table +
      "&includegeom=true",
      searchParams
    );
  }
  getBound(layerId: string): Observable<any> {
    return this.httpRequest.get(
      `${API.API_GEODATA}/map/getbound?layerName=` + layerId
    );
  }
  getThongTinQuyHoach(
    layerName: string,
    primaryField: string,
    id: string,
    tthua: boolean
  ): Observable<any> {
    return this.httpRequest.get(
      `${API.API_GEODATA}/data/getthongtinquyhoach?layerName=` +
      layerName +
      `&primaryfield=` +
      primaryField +
      "&id=" +
      id +
      "&ttthua=" +
      tthua
    );
  }

  searchParcel(layerName, searchParams) {
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getTableData?layerName=` +
      layerName +
      "&includegeom=true",
      searchParams
    );
  }
  searchPOI(layerName, searchParams) {
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getTableData?layerName=` +
      layerName +
      "&includegeom=true",
      searchParams
    );
  }
  searchTable(layerName, searchParams, includeGeom) {
    return this.httpRequest.post(
      `${API.API_GEODATA}/data/getTableData?layerName=` +
      layerName +
      "&includegeom=" +
      includeGeom,
      searchParams
    );
  }
  reverseGeoCode(lng, lat) {
    // return this.httpRequest.get(`${API.API_GEONAME}/${lngLat}.json`);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Access-Control-Allow-Origin", "*"); // Thiết lập CORS cho yêu cầu
    // const url = `${API.API_GEONAME}/${lngLat}.json`;
    // return this.http.get(`${API.API_GEONAME}/${lngLat}.json`, {
    //   headers: headers,
    // });
    // return this.http.jsonp(url, "callback");
    // return this.http.get(url, { headers: headers });
    // return fetch(url);
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
    return this.http.get(url, { headers: headers });
  }
  reverseGeoCodeGoogle(latLng) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${apiKey}`
    );
  }
  getRasterBound(fileName) {
    // API_GEODATA + "/map/rasterinfo/" + fileName + "/raster/bbox"
    return this.httpRequest.get(
      `${API.API_GEODATA}/map/rasterinfo/${fileName}/raster/bbox`
    );
  }
}
