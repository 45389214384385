<div class="c-filter">
  <mat-checkbox class="checklist-leaf-node align-self-center" [(ngModel)]="isChoiceSpace">Theo không
    gian</mat-checkbox>
  <div *ngIf="isChoiceSpace" class="d-flex justify-content-evenly">
    <i class="align-middle fa-regular fa-circle font-size-20 icon-control cursor-pointer"
      (click)="drawShape('Geodesic')"></i>
    <i class="align-middle fa-solid fa-draw-polygon font-size-20 icon-control cursor-pointer"
      (click)="drawShape('Polygon')"></i>
    <i class="align-middle fa-solid fa-vector-square font-size-20 icon-control cursor-pointer"
      (click)="drawShape('Box')"></i>
    <i class="align-middle fa-solid fa-arrow-trend-up font-size-20 icon-control cursor-pointer"
      (click)="drawShape('LineString')"></i>
    <i class="align-middle fa-solid fa-trash-can font-size-20 icon-control cursor-pointer"
      (click)="drawShape('clear')"></i>
  </div>
  <div class="deliver-horizontal my-2"></div>
  <div class="">
    <form (ngSubmit)="onSubmit($event)" [formGroup]="filterForm">
      <div class="row">
        <div class="mb-3" *ngFor="let config of configFilter; let idx = index">
          <div *ngIf="config.domain">
            <label class="" for="autoSizingSelect-{{idx}}">{{config.alias}}</label>
            <ng-select [items]="config.domain.codedValues" [multiple]="false" [notFoundText]="'Không có dữ liệu'"
              bindLabel="name" bindValue="code" formControlName="{{config.col || config.name}}" name="ddl">
            </ng-select>
          </div>
          <div *ngIf="config.kieu === 'textbox' || config.type === 'esriFieldTypeString'">
            <label for="formrow-firstname-input-{{idx}}">{{config.alias}}</label>
            <input type="search" class="form-control" name="config.col" formControlName="{{config.col || config.name}}"
              id="formrow-firstname-input-{{idx}}">
          </div>
          <div *ngIf="config.kieu === 'ddl'">
            <label class="" for="autoSizingSelect-{{idx}}">{{config.alias}}</label>
            <ng-select [items]="listDDL[config.col]" [multiple]="false" [notFoundText]="'Không có dữ liệu'"
              bindLabel="value" bindValue="key" formControlName="{{config.col}}" name="ddl">
            </ng-select>
          </div>
          <div
            *ngIf="config.kieu ==='number' || (!config.domain && (config.type === 'esriFieldTypeInteger' || config.type === 'esriFieldTypeDouble'))">
            <label>{{config.alias}}</label>
            <div id="component-colorpicker-{{idx}}" class="input-group" title="Chọn phép toán">
              <input type="text" class="form-control input-lg" placeholder="{{renderPlaceHolder(symbol[idx])}}"
                name="config.col" formControlName="{{config.col || config.name}}">
              <span class="input-group-text p-0">
                <div class="btn-group" ngbDropdown>
                  <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>{{symbol[idx] ||
                    '='}}</button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="symbol[idx] = '='">=
                      Bằng</a>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="symbol[idx] = '<'">
                      < Nhỏ hơn</a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="symbol[idx] = '>'">>
                          Lớn
                          hơn</a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="symbol[idx] = '[]'">[]
                          Trong
                          khoảng</a>
                  </div>
                </div>
              </span>
            </div>
          </div>

        </div>
      </div>
      <div class="text-end px-2 mt-2">
        <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa-solid fa-magnifying-glass align-middle me-2"></i> Tìm kiếm
        </button>
      </div>
    </form>

  </div>
  <hr />
</div>