import { v4 as uuidv4 } from "uuid";
import { ROOT, TYPE_LAYER } from "src/app/contants";

export const initFolder = () => {
  let id = uuidv4();
  return {
    checked: false,
    id,
    layerid: id,
    level: 2,
    pid: ROOT,
    text: "",
    type: TYPE_LAYER.folder,
  };
};

export const initCell = () => {
  return {
    content: null,
    style: {},
    colspan: 1,
    rowspan: 1,
    hidden: false,
    type: null,
  };
};

export const legendImage = {
  uri: "",
  bucket: "resource",
  content_type: "",
};

export const initField = () => {
  return {
    alias: "",
    colname: "",
    control: "textbox",
    datalength: null,
    datatype: "text",
    formula: "string",
    hidden: false,
    key: "",
    length: null,
    noImEx: false,
    pattern: {
      default: null,
      max: null,
      min: null,
      msg: null,
      require: false,
    },
    readonly: false,
    source: null,
    sourceType: null,
    useCheckList: false,
  };
};

export const initLocation = [
  {
    alias: "Tọa độ X",
    colname: "x",
    control: "textbox",
    datalength: null,
    datatype: "double",
    formula: "string",
    hidden: false,
    key: "",
    length: null,
    noImEx: true,
    isDisable: true,
    pattern: {
      default: "string",
      max: null,
      min: null,
      msg: "",
      require: false,
    },
    readonly: false,
    source: null,
    sourceType: null,
    useCheckList: true,
    status: "add",
  },
  {
    alias: "Tọa độ Y",
    colname: "y",
    control: "textbox",
    datalength: null,
    isDisable: true,
    datatype: "double",
    formula: "string",
    hidden: false,
    key: "",
    length: null,
    noImEx: true,
    pattern: {
      default: "string",
      max: null,
      min: null,
      msg: "",
      require: false,
    },
    readonly: false,
    source: null,
    sourceType: null,
    useCheckList: true,
    status: "add",
  },
];

export const initLayer = () => {
  let id = uuidv4();
  return {
    checked: false,
    display: {
      cols: [],
      popup: {
        cols: [],
        use: true,
      },
      tooltip: {
        col: "",
        use: false,
      },
      viewdetail: {
        cols: [],
        detailExtend: false,
        use: true,
      },
    },
    filter: {
      in: [],
      out: [],
      order: "",
    },
    geotype: "point",
    id,
    layerid: id,
    layertype: "wms",
    level: 2,
    maxzoom: "",
    minzoom: "",
    pid: "root",
    table: "",
    tablefilter: null,
    tbkey: "",
    text: "",
    type: TYPE_LAYER.layer,
    viewparams: null,
    legend: {
      use: true,
      url: "",
    },
    wms: "",
    wmsExternal: false,
    zindex: "",
  };
};

export const route_google = {
  geocoded_waypoints: [
    {
      geocoder_status: "OK",
      place_id: "ChIJqXcvA7NUNDERNkEuEXEa4Lo",
      types: ["establishment", "point_of_interest", "transit_station"],
    },
    {
      geocoder_status: "OK",
      place_id: "ChIJJ5lf7EyrNTERMgJkYOl1ao0",
      types: ["route"],
    },
  ],
  routes: [
    {
      bounds: {
        northeast: {
          lat: 21.0315184,
          lng: 105.78399,
        },
        southwest: {
          lat: 21.028629,
          lng: 105.7794414,
        },
      },
      copyrights: "Map data ©2024",
      legs: [
        {
          distance: {
            text: "1.0 km",
            value: 1007,
          },
          duration: {
            text: "4 mins",
            value: 226,
          },
          end_address:
            "Ng. 11 P. Duy Tân, Dịch Vọng Hậu, Cầu Giấy, Hà Nội, Vietnam",
          end_location: {
            lat: 21.0297271,
            lng: 105.78399,
          },
          start_address: "Bến xe Mỹ Đình, Mỹ Đình, Cầu Giấy, Hà Nội, Vietnam",
          start_location: {
            lat: 21.028629,
            lng: 105.7808572,
          },
          steps: [
            {
              distance: {
                text: "0.1 km",
                value: 149,
              },
              duration: {
                text: "1 min",
                value: 44,
              },
              end_location: {
                lat: 21.0288404,
                lng: 105.7794414,
              },
              html_instructions:
                'Head \u003cb\u003ewest\u003c/b\u003e on \u003cb\u003eTôn Thất Thuyết\u003c/b\u003e toward \u003cb\u003ePhạm Hùng\u003c/b\u003e\u003cdiv style="font-size:0.9em"\u003ePass by Tòa nhà DIMC (on the right)\u003c/div\u003e',
              polyline: {
                points: "}cj_CkisdSGn@I~@WjD",
              },
              start_location: {
                lat: 21.028629,
                lng: 105.7808572,
              },
              travel_mode: "DRIVING",
            },
            {
              distance: {
                text: "0.3 km",
                value: 302,
              },
              duration: {
                text: "1 min",
                value: 57,
              },
              end_location: {
                lat: 21.0315184,
                lng: 105.7799115,
              },
              html_instructions:
                'Turn \u003cb\u003eright\u003c/b\u003e at DOANH NHÂN VIỆT ONLINE onto \u003cb\u003ePhạm Hùng\u003c/b\u003e\u003cdiv style="font-size:0.9em"\u003ePass by Công Ty Tnhh Namyang Global (on the right)\u003c/div\u003e',
              maneuver: "turn-right",
              polyline: {
                points: "gej_Co`sdSoBSA?UC}AQYEYCwBS{AOi@E",
              },
              start_location: {
                lat: 21.0288404,
                lng: 105.7794414,
              },
              travel_mode: "DRIVING",
            },
            {
              distance: {
                text: "0.4 km",
                value: 425,
              },
              duration: {
                text: "2 mins",
                value: 90,
              },
              end_location: {
                lat: 21.0309047,
                lng: 105.7839531,
              },
              html_instructions:
                'Turn \u003cb\u003eright\u003c/b\u003e at Ngã ba Duy Tân - Phạm Hùng onto \u003cb\u003eP. Duy Tân\u003c/b\u003e\u003cdiv style="font-size:0.9em"\u003ePass by LEO - Learn English Online (on the right)\u003c/div\u003e',
              maneuver: "turn-right",
              polyline: {
                points: "_vj_CmcsdSB_@B]LyABYF}@D]Dg@F}@BMRwBHeARgCN_B",
              },
              start_location: {
                lat: 21.0315184,
                lng: 105.7799115,
              },
              travel_mode: "DRIVING",
            },
            {
              distance: {
                text: "0.1 km",
                value: 131,
              },
              duration: {
                text: "1 min",
                value: 35,
              },
              end_location: {
                lat: 21.0297271,
                lng: 105.78399,
              },
              html_instructions:
                'Turn \u003cb\u003eright\u003c/b\u003e at NPP Bia Thai Hong onto \u003cb\u003eNg. 11 P. Duy Tân\u003c/b\u003e\u003cdiv style="font-size:0.9em"\u003ePass by Lam Phương Media (on the right)\u003c/div\u003e',
              maneuver: "turn-right",
              polyline: {
                points: "crj_Cu|sdSdCAbBE",
              },
              start_location: {
                lat: 21.0309047,
                lng: 105.7839531,
              },
              travel_mode: "DRIVING",
            },
          ],
          traffic_speed_entry: [],
          via_waypoint: [],
        },
      ],
      overview_polyline: {
        points: "}cj_CkisdSQnBWjDoBSWCwBWwGm@XqDZaEt@sIN_BdCAbBE",
      },
      summary: "Phạm Hùng and P. Duy Tân",
      warnings: [],
      waypoint_order: [],
    },
  ],
  status: "OK",
};

export const data_google = [
  {
    business_status: "OPERATIONAL",
    formatted_address: "thị xã Sapa,Lào Cai,Vietnam",
    geometry: {
      location: {
        //   lat: 22.418683842829235,
        //   lng: 103.81467097787468,
        lat: 22.35198,
        lng: 103.84465,
      },
      viewport: {
        northeast: {
          lat: 10.03226342989272,
          lng: 105.7841071798927,
        },
        southwest: {
          lat: 10.02956377010728,
          lng: 105.7814075201073,
        },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/hospital-71.png",
    icon_background_color: "#F88181",
    icon_mask_base_uri:
      "https://maps.gstatic.com/mapfiles/place_api/icons/v2/hospital-H_pinlet",
    name: "thị xã Sa Pa,Lào Cai,Vietnam",
    opening_hours: {},
    photos: [
      {
        height: 1153,
        html_attributions: [
          '\u003ca href="https://maps.google.com/maps/contrib/109086667696633960580"\u003eA Google User\u003c/a\u003e',
        ],
        photo_reference:
          "AWU5eFi3Ae1SFzR9-pVAOfqXT7HcNfWl5ih1AB0S3YZZgZoqvcwaxiteR8ZlDoQhVbpxYCVisXcSBXaDAFLFg4aDJZlzyDF9G4Dx-_bCtsQyXwQX5LGClKn_Y-5h3d2WVeRvPt7qT27qNbweqo3OsoXwitcpAi02UlflRAIW8d_EZGrq9YK-",
        width: 2048,
      },
    ],
    plus_code: {
      compound_code: "2QJM+94 Ninh Kiều, Cần Thơ",
      global_code: "7P272QJM+94",
    },
    rating: 3.2,
    reference: "ChIJ79Em2Z9ioDERuEHdwUu2n14",
    types: ["hospital", "point_of_interest", "health", "establishment"],
    user_ratings_total: 307,
  },
];
