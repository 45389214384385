import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/auth.models";
import { API } from "src/app/contants/ApiContants";

@Injectable({ providedIn: "root" })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    var para = {
      UserNameOrEmailAddress: email,
      Password: password,
      RememberClient: true,
    };
    return this.http
      .post(API.API_MANAGER_AUTH + `/TokenAuth/Authenticate`, para)
      .pipe(
        map((rs: any) => {
          // login successful if there's a jwt token in the response
          if (rs && rs.status) {
            const user = rs.data;
            console.log("login ssssssssssssssssss", user);
            if (user && user.accessToken) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem("currentUser", JSON.stringify(user));
              this.currentUserSubject.next(user);
            }
            return user;
          } else return null;
        })
      );
  }
  register(param: any) {
    return this.http.post(API.API_ACCOUNT + `/user/create`, param);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
  getRoles() {
    return this.http.get(API.API_MANAGER_AUTH + `/user/getRoles`).pipe(
      map((rs: any) => {
        // login successful if there's a jwt token in the response
        if (rs && rs.status) {
          const user = rs.data;
          console.log("login ssssssssssssssssss", user);
          if (user && user.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        } else return null;
      })
    );
  }
}
