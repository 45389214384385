<div class="layout-wrapper">
  <app-header class="header" *ngIf="!isMobileScreen"></app-header>
  <div class="body_container">
    <app-sidebar [isCondensed]="isCondensed" [menuSidebar]="menu"></app-sidebar>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
    <!-- <router-outlet></router-outlet> -->
    <!-- <div class="main-content">
            </div> -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>
