<div class="tree-dvhc-container">
    <div class="header">
        <h5>Đơn vị hành chính</h5>
        <button type="button" (click)="closeWidget()" class="btn" style="padding:8px;">
            <i class="fa fa-close"></i>
        </button>
    </div>
    <hr style="margin:3px;height:1px;background: #ccc;" />
    <div class="body">
        <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <div style="display:flex;gap:12px;align-items: center;">
                    <button type="button" class="btn"  (click)="locatOnMap(node.item)">
                        <span><i class="fa fa-folder" style="color:#179568;font-size: 18px;"
                                aria-hidden="true"></i></span>
                        {{node.item?.name}}
                    </button>
                </div>

            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <div style="display:flex;align-items: center;">
                    <button mat-icon-button [attr.aria-label]="'toggle ' + node.filename" matTreeNodeToggle
                        (click)="loadChildren(node)">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>

                    <div style="display:flex;gap:12px;align-items: center;">
                        <button type="button" class="btn" (click)="locatOnMap(node.item)">
                            <span><i class="fa fa-folder" style="color:#179568;font-size: 18px;"
                                    aria-hidden="true"></i></span>
                            {{node.item?.name}}
                        </button>
                    </div>
                </div>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore">
                <button mat-button (click)="loadMore(node.loadMoreParentItem)">
                    Load more...
                </button>
            </mat-tree-node>
        </mat-tree>
    </div>
</div>