import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class MetadataService {

  constructor(private httpRequest: ConfigService) {

  }

  getInfoMetadata(id) {
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/meta-data/get-info?directoryId=${id}`);
  }
 
  updateMetaData(id, body){
    return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/meta-data/value/update?directoryId=${id}`, body);
  }

  createStruct(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/meta-data/struct/create`, body);
  }

  editStruct(body){
    return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/meta-data/struct/update`, body);
  }

  addCatalog(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/master-data/dm-chuyende/create`, body);
  }

  addResource(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/master-data/dm-tainguyen/create`, body);
  }

  updateResource(body){
    return this.httpRequest.put(`${API.BASE_URL}/private/master-data/dm-tainguyen/update`, body);
  }

  updateCatalog(body){
    return this.httpRequest.put(`${API.BASE_URL}/private/master-data/dm-chuyende/update`, body);
  }

  deleteCatalog(id){
    return this.httpRequest.delete(`${API.BASE_URL}/private/master-data/dm-chuyende/remove?id=${id}`)
  }

  deleteResource(id){
    return this.httpRequest.delete(`${API.BASE_URL}/private/master-data/dm-tainguyen/remove?id=${id}`)
  }

  deleteStruct(id){
    return this.httpRequest.delete(`${API.BASE_URL}/private/gis-db/resource/meta-data/struct/remove?id=${id}`)
  }

  getAllStruct(){
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/meta-data/struct/find-all`);
  }

}
