import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { DmChungService } from '../quan-tri/@core/data/service/dm-chung.service';
import { MapService } from '../core/services/map.service';
import wellknown from 'wellknown';
import * as turf from "@turf/turf";
const LOAD_MORE = 'LOAD_MORE';

interface TreeNode {
  name: string;
  id: string;
  level: number;
  children?: TreeNode[];
}

// interface FlatNode {
//   expandable: boolean;
//   name: string;
//   level: number;
// }

/** Nested node */
export class LoadmoreNode {
  childrenChange = new BehaviorSubject<LoadmoreNode[]>([]);

  get children(): LoadmoreNode[] {
    return this.childrenChange.value;
  }

  constructor(public item: TreeNode,
    public hasChildren = false,
    public loadMoreParentItem: TreeNode | null = null) { }
}

/** Flat node with expandable and level information */
export class LoadmoreFlatNode {
  constructor(public item: TreeNode,
    public level = 1,
    public expandable = false,
    public loadMoreParentItem: TreeNode | null = null) {
    // if (this.item.children && this.item.children.length > 0) {
    //   this.expandable = true;
    // }
  }
}

/**
 * A database that only load part of the data initially. After user clicks on the `Load more`
 * button, more data will be loaded.
 */
@Injectable()
export class LoadmoreDatabase {
  batchNumber = 5;
  dataChange = new BehaviorSubject<LoadmoreNode[]>([]);
  nodeMap = new Map<TreeNode, LoadmoreNode>();

  /** The data */
  rootLevelNodes: TreeNode[] = [];
  dataMap = new Map<TreeNode, TreeNode[]>([]);

  initialize() {
    const data = this.rootLevelNodes.map(node => this._generateNode(node));
    data.forEach((node: any) => {
      node.hasChildren = true;
      node.expandable = true;
    });
    this.dataChange.next(data);
  }

  loadMore(item: TreeNode, onlyFirstTime = false) {
    console.log('load more', item)
    // if (!this.nodeMap.has(item) || !this.dataMap.has(item)) {
    if (item.children && item.children.length) {
      console.log('load more return ', !this.nodeMap.has(item), !this.dataMap.has(item))
      return;
    }
    const parent = this.nodeMap.get(item)!;
    console.log('parent', parent)
    // const children = this.dataMap.get(item)!;
    // if (onlyFirstTime && parent.children!.length > 0) {
    //   return;
    // }
    // const newChildrenNumber = parent.children!.length + this.batchNumber;
    // const nodes = children.slice(0, newChildrenNumber)
    //   .map(name => this._generateNode(name));
    // if (newChildrenNumber < children.length) {
    //   // Need a new load more node
    //   const loadMoreNode: TreeNode = {
    //     id: 'abccc',
    //     name: 'Load more'
    //   }
    //   nodes.push(new LoadmoreNode(loadMoreNode, false, item));
    // }
    // parent.childrenChange.next(nodes);
    // this.dataChange.next(this.dataChange.value);
  }

  _generateNode(item: TreeNode): LoadmoreNode {
    if (this.nodeMap.has(item)) {
      return this.nodeMap.get(item)!;
    }
    const result = new LoadmoreNode(item, this.dataMap.has(item));
    this.nodeMap.set(item, result);
    return result;
  }
}

@Component({
  selector: 'app-tree-dvhc',
  templateUrl: './tree-dvhc.component.html',
  styleUrls: ['./tree-dvhc.component.scss'],
  providers: [LoadmoreDatabase]

})
export class TreeDVHCComponent implements OnInit {
  @Output() close = new EventEmitter<any>;
  @Output() handleViewPortChange = new EventEmitter<any>;
  nodeMap = new Map<TreeNode, LoadmoreFlatNode>();
  treeControl: FlatTreeControl<LoadmoreFlatNode>;
  treeFlattener: MatTreeFlattener<LoadmoreNode, LoadmoreFlatNode>;
  dataSource: MatTreeFlatDataSource<LoadmoreNode, LoadmoreFlatNode>;
  dmTinh: any[] = [];
  dmHuyen: any[] = [];
  dmXa: any[] = [];
  initCode: any = {
    level: 2,
    code: '088'
  }
  isLoadingBlock: boolean = true;
  constructor(
    private database: LoadmoreDatabase,
    private dmService: DmChungService,
    private mapService: MapService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);

    this.treeControl = new FlatTreeControl<LoadmoreFlatNode>(this.getLevel, this.isExpandable);

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    database.initialize();
  }

  ngOnInit(): void {
    if (this.initCode) {
      if (this.initCode.level === 1) {
        this.getDmTinhById(this.initCode.code)
      } else if (this.initCode.level === 2) {
        this.getDMHuyenById(this.initCode.code)
      }
    } else
      this.getDMTinh();
  }
  getDmTinhById(code) {
    this.dmService.getDmTinhById(code).subscribe((rs: any) => {
      this.dmTinh = rs;
      const rootNodes = rs.map(item => {
        return {
          name: item.tentinh,
          id: item.code,
          level: 1,
          children: []
        }
      })
      this.database.rootLevelNodes = rootNodes;
      this.database.initialize();
      this.isLoadingBlock = false;
    })
  }
  getDMTinh() {
    this.dmService.getDmTinh().subscribe((rs: any) => {
      this.dmTinh = rs;
      // const loadMoreNode: TreeNode = {
      //   id: LOAD_MORE,
      //   name: 'Load more'
      // }
      const rootNodes = rs.map(item => {
        return {
          name: item.tentinh,
          id: item.code,
          level: 1,
          children: []
        }
      })
      this.database.rootLevelNodes = rootNodes;
      this.database.initialize();
    })
  }
  getDMHuyenById(code) {
    this.dmService.getDmHuyenById(code).subscribe((rs: any) => {
      this.dmHuyen = rs;
      const rootNodes = rs.map(item => {
        return {
          name: item.name,
          id: item.code,
          level: 2,
          children: []
        }
      })
      this.database.rootLevelNodes = rootNodes;
      this.database.initialize();
      this.isLoadingBlock = false;
    })
  }
  getDMHuyen(maTinh) {
    return this.dmService.getDmHuyen(maTinh)
  }
  getDMXa(maHuyen) {
    // this.dmService.getDmHuyen(maTinh).subscribe((rs: any) => {
    //   this.dmHuyen = rs;
    //   const childNodes = rs.map(item => {
    //     return {
    //       name: item.tentinh,
    //       id: item.madoituong,
    //       children: []
    //     }
    //   })
    // })
    return this.dmService.getDmXa(maHuyen)
  }

  getChildren = (node: LoadmoreNode): Observable<LoadmoreNode[]> => node.childrenChange;

  transformer = (node: LoadmoreNode, level: number) => {
    const existingNode = this.nodeMap.get(node.item);

    if (existingNode) {
      return existingNode;
    }

    const newNode =
      new LoadmoreFlatNode(node.item, level, node.hasChildren, node.loadMoreParentItem);
    this.nodeMap.set(node.item, newNode);
    return newNode;
  }

  getLevel = (node: LoadmoreFlatNode) => node.level;

  isExpandable = (node: LoadmoreFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: LoadmoreFlatNode) => _nodeData.expandable;


  isLoadMore = (_: number, _nodeData: LoadmoreFlatNode) => {
    if (_nodeData && _nodeData.item)
      return _nodeData.item.id === LOAD_MORE;
    else return false;
  }

  /** Load more nodes from data source */
  loadMore(item: TreeNode) {
    console.log('load more node dddd', item)
    // this.database.loadMore(item);
    if (item.level === 1) {
      // await this.getDMHuyen(item.id).
      // const dmHuyen: any = await firstValueFrom(this.getDMHuyen(item.id));
      // console.log('dmhuyen', dmHuyen)
      // const nodesHuyen = dmHuyen.map(item => {
      //   return {
      //     name: item.tenhuyen,
      //     id: item.code,
      //     level: 2,
      //     children: []
      //   }
      // })
      // this.dmHuyen = dmHuyen
    } else if (item.level === 2) {

      // const dmxa = await firstValueFrom(this.getDMXa(item.id));
      // console.log('dmxa', dmxa)
    }
  }

  async loadChildren(node: LoadmoreFlatNode) {
    // this.database.loadMore(node.item, true);
    const parent = this.database.nodeMap.get(node.item)!;
    let nodeItems = []
    if (node.item.level === 1) {
      const dmHuyen: any = await firstValueFrom(this.getDMHuyen(node.item.id));
      nodeItems = dmHuyen.map(item => {
        return {
          name: item.name,
          id: item.code,
          level: 2,
          children: []
        }
      })
      this.dmHuyen = dmHuyen

    } else if (node.item.level === 2) {
      const dmxa: any = await firstValueFrom(this.getDMXa(node.item.id));
      nodeItems = dmxa.map(item => {
        return {
          name: item.name,
          id: item.code,
          level: 3,
          children: []
        }
      })
    }
    const nodes = nodeItems.map(name => this.database._generateNode(name));
    if (node.item.level < 2) {
      nodes.forEach((node: any) => {
        node.hasChildren = true;
        node.expandable = true;
      });
    }
    parent.childrenChange.next(nodes);
    this.database.dataChange.next(this.database.dataChange.value);
  }
  locatOnMap(nodeItem) {
    console.log('locate on map', nodeItem)
    let layerName = ''
    if (nodeItem.level === 2) {
      layerName = 'DiaPhanHuyen'
    } else if (nodeItem.level === 3) {
      layerName = 'DiaPhanXa'
    }
    this.mapService.getOjbectBound(layerName, 'code', nodeItem.id).subscribe((rs: any) => {
      if (rs && rs.length) {
        const objectInfo = rs[0]
        if (objectInfo["geom"]) {
          const geostr = objectInfo.geom;
          if (geostr) {
            const geom: any = wellknown.parse(geostr);
            if (geom.type === 'MultiPolygon') {
              var mpolygon = turf.multiPolygon(geom.coordinates)
              this.handleViewPortChange.emit(mpolygon);
            } else {
              var polygon = turf.polygon(geom.coordinates)
              this.handleViewPortChange.emit(polygon);
            }
          }
        }
      }
    })

  }

  closeWidget() {
    this.close.emit(true);
  }

}


