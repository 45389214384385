import { type_file } from "../contants";

export const detectFileByContentType = (val) => {
  if (val && val.content_type && val.content_type.includes("image/")) {
    return "image";
  } else {
    return "other";
  }
}

export const detectFileByFileName = (name) => {
  let parseName = name.split(".");
  let extension = "";
  if(parseName.length){
    extension = parseName.pop();
  }
  if(!extension) return "other";
  if (type_file.image.includes(extension)) {
    return "image";
  } else if(type_file.excel.includes(extension)){
    return "excel"
  } else if(type_file.video.includes(extension)){
    return "video"
  } else if(extension === 'shp') {
    return "shp"
  } else if(extension === 'txt') {
    return "txt"
  }
  else {
    return "other";
  }
}

export const genderStatus = (status) =>{
  switch(status){
      case "active":
          return "Phê duyệt";
      case "pending":
          return "Chờ duyệt";
      case "inactive":
          return "Đã khóa";
      case "deleted":
          return "Đã Xóa"
      default:
          return "Không xác định";
  }
}

export const _normalizeValue = (value) => {
  return value.toLowerCase().replace(/\s/g, '');
}