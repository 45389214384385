import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogService } from 'src/app/core/services/catalog.service';

interface Selects {
  value: any;
  label: string;
  options: any;
}

@Component({
  selector: 'app-element-select',
  templateUrl: './element-select.component.html',
  styleUrls: ['./element-select.component.scss']
})

export class ElementSelectComponent implements OnInit {
  @Input() key: any;
  @Input() options: any = [];
  @Output() onAction = new EventEmitter<any>();

  districts: any = [];
  selects: Selects[] = [];
  objSelect: any = [];

  constructor(private catalogService: CatalogService) {

  }

  ngOnInit(): void {
    console.log("----------optionsoptions---------", this.options)
  }

  getHuyenXa() {
    this.catalogService.getHuyenXa().subscribe(res => {
      console.log("-----res----", res)
      if (res["status"] === 200) {
        this.districts = res["data"];
      }
    })
  }

  ngOnChanges(event) {
    // console.log("------ngOnChanges--------", event)
    if (event.key && event.key.currentValue !== event.key.previousValue) {
      this.key = event.key.currentValue;
      switch (this.key) {
        case "mahuyen":
          this.catalogService.getHuyenXa().subscribe(res => {
            if (res["status"] === 200) {
              this.districts = res["data"];
              this.selects = (res["data"] || []).map(item => {
                return {
                  value: item.id,
                  label: item.data,
                  options: item
                }
              })
            }
          })
        case "phanloai":
          break;
        default: break;
      }
    }

    if(event.options && event.options.currentValue.length){
      this.selects = event.options.currentValue;
    }
  }

  onChange(event){
    let value = this.selects.find(item => item.value === event.target.value);
    let obj = {
      key: "element_select",
      value: {
        code: this.key,
        option: value
      }
    }
    this.onAction.emit(obj);
  }
}
