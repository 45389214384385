import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingScreenComponent } from "./loading-screen/loading-screen.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from "@angular/router";
import { TreeViewComponent } from "./tree-view/tree-view.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { CatalogComponent } from "./catalog/catalog.component";
import { MaterialModule } from "../material/material.module";
import { PaginatorComponent } from "./paginator/paginator.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { FilterMapComponent } from "./filter-map/filter-map.component";
import { ElementSelectComponent } from "./element-select/element-select.component";
import { TreeViewEditMapComponent } from "./tree-view-edit-map/tree-view-edit-map.component";
import { TreeViewFolderComponent } from "./tree-view-folder/tree-view-folder.component";
import { TreeChoiceFolderComponent } from "./tree-choice-folder/tree-choice-folder.component";
import { CkeditorComponent } from "./ckeditor/ckeditor.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SelectLocationComponent } from "./select-location/select-location.component";
import { BreadCrumbComponent } from "./bread-crumb/bread-crumb.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { SelectImageComponent } from "./select-image/select-image.component";
import { LightboxModule } from "ngx-lightbox";
import { FormFilterComponent } from "./form-filter/form-filter.component";
import { DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";
import { YearRangePickerComponent } from "./year-range-picker/year-range-picker.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MapColumnComponent } from "./map-column/map-column.component";
import { DateRangeComponent } from "./date-range/date-range.component";
import { LoadingBlockComponent } from "./loading-block/loading-block.component";
import { ResizableColumnDirective } from "./directives/resizable-column.directive";
import { ConfigStyleVectorComponent } from "./config-style-vector/config-style-vector.component";
import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { RatingComponent } from "./rating/rating.component";
import { InputTagComponent } from "./input-tag/input-tag.component";
import { MapboxComponent } from "./mapbox/mapbox.component";
import { SetupDatabaseComponent } from "./setup-database/setup-database.component";
import { TreeLayersComponent } from "./tree-layers/tree-layers.component";
import { SearchComponent } from "./mapbox/components/search/search.component";
import { ThongTinQuyHoachComponent } from "./mapbox/components/thong-tin-quy-hoach/thong-tin-quy-hoach.component";
import { RightMapControlComponent } from "./mapbox/components/right-map-control/right-map-control.component";
import { LeftMapControlComponent } from "./mapbox/components/left-map-control/left-map-control.component";
import { TableThuaDatComponent } from "./mapbox/components/thong-tin-quy-hoach/components/table-thua-dat/table-thua-dat.component";
import { TreeLayerContextMenuComponent } from "./tree-layers/components/tree-layer-context-menu/tree-layer-context-menu.component";
import { BaseLayerPickerComponent } from "./mapbox/components/base-layer-picker/base-layer-picker.component";
import { ChuGiaiQHSDDComponent } from "./mapbox/components/chu-giai-qhsdd/chu-giai-qhsdd.component";
import { TreeDVHCComponent } from "../tree-dvhc/tree-dvhc.component";
import { ThuaDatComponent } from "./mapbox/components/thong-tin-quy-hoach/components/thua-dat/thua-dat.component";
import { QuyHoachComponent } from "./mapbox/components/thong-tin-quy-hoach/components/quy-hoach/quy-hoach.component";
import { OQuyHoachComponent } from "./mapbox/components/thong-tin-quy-hoach/components/oquy-hoach/oquy-hoach.component";
import { TopControlComponent } from "./mapbox/components/top-control/top-control.component";
import { SearchPOIComponent } from "./mapbox/components/search-poi/search-poi.component";
import { FeatureInfoComponent } from "./mapbox/components/feature-info/feature-info.component";
import { TableQuyHoachComponent } from "./mapbox/components/thong-tin-quy-hoach/components/table-quy-hoach/table-quy-hoach.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { ImageViewerModule } from "ngx-image-viewer";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { PlaceSearchControlComponent } from "./mapbox/components/place-search-control/place-search-control.component";
import { LoQuyHoachComponent } from "./mapbox/components/thong-tin-quy-hoach/components/lo-quy-hoach/lo-quy-hoach.component";
import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { GroupLayerComponent } from './tree-layers/group-layer/group-layer.component';
import { QHTreeLayerComponent } from './tree-layers/qhtree-layer/qhtree-layer.component';
import { QRCodeComponent } from './qrcode/qrcode.component';

// import { NgJsonEditorModule } from 'ang-jsoneditor';
@NgModule({
  declarations: [
    LoadingScreenComponent,
    HeaderComponent,
    TreeViewComponent,
    TreeLayersComponent,
    VideoPlayerComponent,
    TreeDVHCComponent,
    CatalogComponent,
    PaginatorComponent,
    FilterMapComponent,
    ElementSelectComponent,
    TreeViewEditMapComponent,
    TreeViewFolderComponent,
    TreeChoiceFolderComponent,
    CkeditorComponent,
    SelectLocationComponent,
    BreadCrumbComponent,
    SelectImageComponent,
    FormFilterComponent,
    DateRangePickerComponent,
    YearRangePickerComponent,
    MapColumnComponent,
    DateRangeComponent,
    LoadingBlockComponent,
    ResizableColumnDirective,
    ConfigStyleVectorComponent,
    PagetitleComponent,
    RatingComponent,
    InputTagComponent,
    MapboxComponent,
    SetupDatabaseComponent,
    SearchComponent,
    ThongTinQuyHoachComponent,
    RightMapControlComponent,
    LeftMapControlComponent,
    TableThuaDatComponent,
    TreeLayerContextMenuComponent,
    BaseLayerPickerComponent,
    ChuGiaiQHSDDComponent,
    ThuaDatComponent,
    QuyHoachComponent,
    OQuyHoachComponent,
    LoQuyHoachComponent,
    TopControlComponent,
    RightMapControlComponent,
    SearchPOIComponent,
    FeatureInfoComponent,
    TableQuyHoachComponent,
    ImageViewerComponent,
    VideoPlayerComponent,
    PlaceSearchControlComponent,
    LoQuyHoachComponent,
    DialogUploadComponent,
    GroupLayerComponent,
    QHTreeLayerComponent,
    QRCodeComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatTreeModule,
    CdkTreeModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    NgbDropdownModule,
    MaterialModule,
    CKEditorModule,
    NgxDropzoneModule,
    LightboxModule,
    NgbModule,
    CarouselModule,
    ImageViewerModule.forRoot(),
  ],
  exports: [
    LoadingScreenComponent,
    HeaderComponent,
    TreeViewComponent,
    TreeLayersComponent,
    VideoPlayerComponent,
    TreeDVHCComponent,
    CatalogComponent,
    PaginatorComponent,
    FilterMapComponent,
    ElementSelectComponent,
    TreeViewEditMapComponent,
    TreeViewFolderComponent,
    TreeChoiceFolderComponent,
    CkeditorComponent,
    BreadCrumbComponent,
    SelectImageComponent,
    FormFilterComponent,
    DateRangePickerComponent,
    MapColumnComponent,
    DateRangeComponent,
    LoadingBlockComponent,
    ConfigStyleVectorComponent,
    PagetitleComponent,
    RatingComponent,
    MapboxComponent,
    TopControlComponent,
    FeatureInfoComponent,
    PlaceSearchControlComponent,
    LeftMapControlComponent,
    SetupDatabaseComponent,
    RightMapControlComponent,
    BaseLayerPickerComponent,
    SearchPOIComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorComponent,
    },
  ],
  bootstrap: [TreeViewComponent, TreeViewEditMapComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
