import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder } from '@angular/forms';
import { DM_CNXD, DM_MDSDD, MAP_LAYERS_ID } from 'src/app/contants';
import { MapService } from 'src/app/core/services/map.service';
import { DmChungService } from 'src/app/quan-tri/@core/data/service/dm-chung.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnChanges {
  // @Input() searchQHCCount: any;
  // @Input() searchQHPKCount: any;
  // @Input() searchQHCTCount: any;
  @Input() showResult: boolean;
  @Output() onClose = new EventEmitter<any>();
  @Output() clearSearch = new EventEmitter<any>();
  @Output() onSearchParamsChange = new EventEmitter<any>();
  @Output() onActiveSpatialSearch = new EventEmitter<any>();
  @Output() raidusOnChange = new EventEmitter<any>();
  @Output() hilightPolygon = new EventEmitter<any>();
  formSearch: UntypedFormGroup;
  dmMDSD: any[] = DM_MDSDD;
  dmXa: any[] = [];
  dmCN: any[] = DM_CNXD;
  radius: number = 10;
  showRadiusError: boolean = false;
  isShowTable: boolean = false;
  initCode: any = {
    level: 2,
    code: '088'
  }
  searchResult: any = {};
  listSearch: any[] = [
    {
      value: 'qhxd',
      label: 'Quy hoạch XD'
    },
    {
      value: 'td',
      label: 'Thửa đất'
    }
  ];
  listProjects: any[] = [
    {
      value: 'WGS84',
      label: 'WGS84'
    },
    {
      value: 'VN2000',
      label: 'VN2000'
    }
  ];
  selectedSearch: string = 'qhxd';
  selectedProj: string = 'WGS84';
  loaiQH: string = '';
  chucNang: string = '';
  isLoadingBlock: boolean = false;
  groupResult: any = {};
  tableData: any[] = [];
  addressKeyWord: string = '';
  addressTable: any[] = [];
  constructor(
    private dmService: DmChungService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private mapService: MapService
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
    this.dmService.getDmXa(this.initCode.code).subscribe((rs: any[]) => {
      if (rs && rs.length) {
        this.dmXa = rs.map(item => {
          return {
            label: item.name,
            value: "0" + item.code
          }
        })
      }
    })
    // this.changeDetectorRef.detectChanges()
  }

  ngOnInit(): void {
    this.formSearch = this.formBuilder.group({
      // mdsd: [''],
      ccode: [''],
      cnxd: [''],
      soto: [''],
      sothua: [''],
      radius: [''],
      address: [''],
      proj: ['105303'],
      lng: [null],
      lat: [null],
      lng2000: [null],
      lat2000: [null],
      linkGG: [null],
      latLng: [null],
      // name: [''],
      // alias: [""],
      // sort: [0],
      // control: [""],
      // value: [[]],
      // leaf: [""],
      // standard: [""],
    });
  }

  close() {
    this.onClose.emit();
  }

  get formValues() {

    return this.formSearch.controls;
  }

  clearInput(cname) {
    // this.showResult = false;
    this.formSearch.get(cname).setValue(null);
    this.showResult = false;
    this.clearSearch.emit();
  }
  onChangeAddressSearch(evt) {
    // const searchText = evt.target.value;
    const formValues = this.formSearch.getRawValue();
    const searchText = formValues.address;
    if (searchText.length >= 2) {
      this.isLoadingBlock = true;
      this.mapService.searchGeoName(searchText).subscribe((rs: any) => {
        this.isLoadingBlock = false;
        console.log('addreess table', rs)
        this.addressTable = rs;
      });
    }
  }
  handleSelectAddress(evt) {
    console.log('handle select address event', evt)
  }

  handleSearch() {
    if (this.formSearch.invalid) {
      return
    }
    const formValues = this.formSearch.getRawValue();
    const chucNangInfo = this.dmCN.find(item => item.label === formValues.cnxd);
    const addressInfo = this.addressTable.find(item => item.display_name == formValues.address);
    if (formValues.cnxd) formValues.cnxd = chucNangInfo.label;
    if (chucNangInfo) {
      this.chucNang = formValues.cnxd;
      const searchParams = {
        ...formValues
      }
      // this.showCount = false;
      this.isLoadingBlock = true;
      const searchChucNangParams = [
        {
          "field": 'oqh_chucnangsdd',
          "operato": "=",
          "join": "and",
          "value": "\'" + this.chucNang + "\'"
        }
      ]
      this.mapService.searchTable(MAP_LAYERS_ID.OQH_NAME, searchChucNangParams, true).subscribe((rs: any) => {
        this.isLoadingBlock = false;
        this.searchResult = {
          QHC: 0,
          QHPK: 0,
          QHCT: 0
        }
        if (rs && rs.length) {
          const groupResult = _.groupBy(rs, 'oqh_loaiqh');
          this.groupResult = groupResult;
          if (groupResult.QHC) {
            this.searchResult.QHC = groupResult.QHC.length;
          }
          if (groupResult.QHPK) {
            this.searchResult.QHPK = groupResult.QHPK.length;
          }
          if (groupResult.QHCT) {
            this.searchResult.QHCT = groupResult.QHCT.length;
          }
        }
      })
      this.onSearchParamsChange.emit(searchParams);
    }
    //tim kiem theo dia chi
    else if (addressInfo) {
      const searchParams = {
        ...formValues,
        address: addressInfo
      }
      this.onSearchParamsChange.emit(searchParams);
    }
    // tim kiem theo toa do
    //link google
    else if (formValues.linkGG) {
      const coords = this.extractCoordinates(formValues.linkGG);
      const { longitude, latitude } = coords;
      if (coords) {
        const searchParams = {
          ...formValues,
          coordinates: {
            lng: longitude,
            lat: latitude
          }
        }
        this.onSearchParamsChange.emit(searchParams);
      }
    }
    else if (formValues.latLng) {
      const coordstr = formValues.latLng;
      const coords = coordstr.split(',')
      if (coords && coords.length && coords.length >= 2) {
        const searchParams = {
          ...formValues,
          coordinates: {
            lng: coords[1],
            lat: coords[0],
          }
        }
        this.onSearchParamsChange.emit(searchParams);
      }
    }
    else if (formValues.lng && formValues.lat) {
      const searchParams = {
        ...formValues,
        coordinates: {
          lng: formValues.lng,
          lat: formValues.lat,
        }
      }
      this.onSearchParamsChange.emit(searchParams);
    } else
      this.onSearchParamsChange.emit(formValues);
  }

  setResults(results) {
    // this.searchResult = results;
    // this.isLoadingBlock = false;
  }
  searchLine() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'line',
      radius: formValues.radius
    }
    this.onActiveSpatialSearch.emit(searchParams);
  }
  searchPolygon() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'polygon',
      radius: formValues.radius
    }
    this.onActiveSpatialSearch.emit(searchParams);
  }
  searchPoint() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'point',
      radius: formValues.radius
    }
    if (!formValues.radius) {
      this.showRadiusError = true;
      return;
    } else this.showRadiusError = false;
    this.onActiveSpatialSearch.emit(searchParams);
  }
  handleRadiusChange(evt) {
    const { value } = evt.target;
    this.radius = value;
    this.raidusOnChange.emit(value)
  }
  clearDraw() {
    this.onSearchParamsChange.emit(null);
  }
  clickSearchType(evt, search) {

  }
  chooseProject(evt) {
    console.log('proj', evt)
    this.selectedProj = evt;
  }
  showTable(type) {
    // this.loaiQH = type;
    this.tableData = [];
    console.log('show table', type)
    console.log('show this.groupResult', this.groupResult)
    console.log('show aaaaa', this.groupResult[type])

    if (this.groupResult[type]) {
      this.tableData = this.groupResult[type]
    }
    this.isShowTable = true;
  }
  locatePolygon(feature) {
    this.hilightPolygon.emit(feature)
  }
  closeTable() {

    this.isShowTable = false;
  }

  extractCoordinates(url) {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    } else {
      return null;
    }
  }


}
