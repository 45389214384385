import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { detectFileByFileName } from 'src/app/commons/common';
import { ROOT } from 'src/app/contants';
import { MinioService } from 'src/app/core/services/minio.service';
import { ResourceService } from 'src/app/core/services/resource.service';
import Swal from 'sweetalert2';
import * as Constants from '../../quan-tri/Constants/Constants';

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss']
})
export class SelectImageComponent implements OnInit {
  buttons: any = Constants.BUTTONS;
  @Input() typeSelect: string = "file"; // file or folder
  @Input() filterFile: string = null;         // image, video, shp, ...
  @Input() hideUpload: boolean = false;
  @Input() security: string = "public";
  @Output() onAction = new EventEmitter<any>();

  formUpload: UntypedFormGroup;
  submitted: boolean = false;
  files: File[] = [];
  dataTree: any = [];
  listView: any = [];
  queues: any = [];
  showInputAddNew: boolean = false;
  isLoading: boolean = false;
  formFolder: UntypedFormGroup;
  idFileSelected: string = "";
  fileSelected: any;
  idRename: string = "";
  statusAction: string = "";
  breadCrumbItems: Array<{}>;
  @ViewChild('cModal', { static: true }) cModal: any;
  @ViewChild('modalupload', { static: true }) modalupload: any;

  get valFormUpload() { return this.formUpload.controls; }
  get valFormFolder() { return this.formFolder.controls; }

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private minioService: MinioService,
    private resourceService: ResourceService
  ) {
    this.formUpload = this.formBuilder.group({
      bucketName: ['', [Validators.required]],
      fileName: [''],
    });
    this.formFolder = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  submitFile() {
    this.submitted = true;
    if (this.formFolder.invalid) {
      return
    }
    this.isLoading = true;
    if(this.statusAction === "create"){
      let body = {
        parentID: this.listView?.dataRoot?.id || ROOT,
        name: this.valFormFolder.name.value || ""
      }
      this.resourceService.createFolder(body).subscribe(res => {
        this.isLoading = false;
        this.showInputAddNew = false;
        if (res["status"] === 200) {
          this.listView?.dataView.push({
            id: res["data"].id,
            data: res["data"]
          })
        }
      })
    }else{
      let body = {
        id: this.fileSelected?.data?.id,
        name: this.valFormFolder.name.value || ""
      }
      this.resourceService.rename(body).subscribe(res => {
        this.isLoading = false;
        this.showInputAddNew = false;
        if (res["status"] === 200) {
          this.idRename = "";
          this.listView.dataView[this.fileSelected.idx] = {
            ...this.listView.dataView[this.fileSelected.idx],
            data: res["data"]
          } 
        }
      })
    }
  }

  deleteResource(node, i) {
    Swal.fire({
      title: `Bạn có muốn xóa <strong>${node?.data?.name}</strong>?`,
      text: '',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Xóa'
    }).then(result => {
      if (result.value) {
        this.resourceService.deleteResource(node?.data?.id).subscribe(res => {
          if (res["status"] === 200) {
            this.listView.dataView.splice(i, 1);
            this.isLoading = false;
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Xóa thành công',
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Thư mục không tồn tại!!',
              showConfirmButton: false,
              timer: 3000
            });
          }
        },
          () => {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Thư mục không tồn tại!!',
              showConfirmButton: false,
              timer: 3000
            });
          })
      }
    });
  }

  handleBack() {
    if (this.queues.length) {
      this.listView = this.queues.pop();
    }
  }

  dblclickFile(event, file, modal) {
    this.fileSelected = file;
    if(file.data.info){
      modal.dismiss();
      let obj = {
        key: "selected_file",
        value: this.fileSelected
      }
      this.onAction.emit(obj);
    }else{
      event.preventDefault();
      this.breadCrumbItems = this.breadCrumbItems.map(item => {
        return {
          ...item,
          active: false
        }
      })
      this.breadCrumbItems.push({
        ...file,
        label: file?.data?.name,
        active: true
      });
      this.idRename = "";
      this.showInputAddNew = false;
      this.queues.push(this.listView);
      this.listView = {
        dataRoot: file.data,
        dataView: this.handleFilterFile(file.children) 
      }
    }
  }

  handleSelectFile(node){
    this.idFileSelected = node.data.id;
    this.fileSelected = node;
  }

  save(modal) {
    console.log("------fileSelected---------", this.fileSelected)
    if ((this.fileSelected?.data?.info && this.typeSelect === 'file') || (this.typeSelect === 'folder')) {
      modal.dismiss();
      let obj = {
        key: "selected_file",
        value: this.fileSelected
      }
      this.onAction.emit(obj);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Bạn chưa chọn file!!',
        showConfirmButton: false,
        timer: 3000
      });
    }
  }

  addNewItem() {
    this.statusAction = "create";
    this.idRename = "";
    this.showInputAddNew = true;
    this.formFolder.reset();
  }

  clickFolder(event, folder) {
    event.preventDefault();
    this.queues.push(this.listView);
    this.listView = {
      dataRoot: folder.data,
      dataView: folder.children
    }
  }

  onHandle(event){
    switch(event.key){
      case "click_item_breadCrum":
        if (!event?.value?.id) {
          this.breadCrumbItems = this.initBreadCrumb();
          this.listView.dataView = this.handleFilterFile(this.dataTree);
        } else {
          this.breadCrumbItems = this.breadCrumbItems.slice(0, event?.idx + 1);
          let lastIdx = this.breadCrumbItems.length - 1
          this.breadCrumbItems[lastIdx] = {
            ...this.breadCrumbItems[lastIdx],
            active: true
          }
          this.listView.dataView = this.handleFilterFile(event?.value?.children || []);
        }
        break;

      default: break;
    }
  }

  initBreadCrumb(){
    return [{ label: 'ROOT', active: true }];
  }

  handleFilterFile(data){
    if(this.filterFile === null) return data;
    return data.filter(item => !item?.data?.info || detectFileByFileName(item?.data?.name) === this.filterFile);
  }

  getAllFolder(){
    this.breadCrumbItems = this.initBreadCrumb();
    this.resourceService.getAllResource(false, this.security === 'public').subscribe(res => {
      if (res["status"] === 200) {
        this.dataTree = res["data"];
        this.listView = {
          dataRoot: null,
          dataView: this.handleFilterFile(this.dataTree)
        }
        console.log("=======listView========", this.listView)
      }
    })
  }

  detectFileByFileName(name){
    return detectFileByFileName(name);
  }

  ngOnInit(): void {
    this.breadCrumbItems = this.initBreadCrumb();
  }

  onSelect(event) {
    console.log(event);
    this.files = event.addedFiles;
  }

  onRemove(f, i) {
    this.files.splice(i, 1);
  }

  openModalChooseFile() {
    this.modalService.open(this.modalupload, { size: 'lg', centered: true });
  }

  closeFormFolder(){
    if(this.statusAction === "edit"){
      this.idRename = "";
    }else{
      this.showInputAddNew = false;
    }
  }

  rename(node, i){
    this.statusAction = "edit";
    this.formFolder.controls.name.patchValue(node.data.name)
    this.idRename = node.data.id;
    this.fileSelected = {
      ...node,
      idx: i
    };
  }

  openNewUpload() {
    this.modalService.open(this.cModal, { size: 'lg' });
  }

  uploadFile() {
    let elm = document.getElementById("input-upload");
    if (elm) elm.click();
  }

  upFile(event) {
    console.log("-------event------", event.target.files, this.listView.dataRoot);
    this.files = event.target.files;
    let formdata = new FormData();
    formdata.append("file", this.files[0]);
    this.resourceService.uploadFile(this.listView?.dataRoot?.id || ROOT, formdata).subscribe(res => {
      if (res["status"] === 200) {
        this.listView?.dataView.push({
          id: res["data"][0].id,
          data: res["data"][0]
        })
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Tải lên thành công !!',
          showConfirmButton: false,
          timer: 3000
        });
      }else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res["message"],
          showConfirmButton: false,
          timer: 3000
        });
      }
    })
  }

  submitUpload(modal) {
    this.submitted = true;

    if (this.formUpload.invalid) {
      return;
    }



  }
}
