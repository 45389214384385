import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, forkJoin, map } from "rxjs";
import { MapService } from "src/app/core/services/map.service";
import * as _ from "lodash";
import { QuyHoachFetureIDS } from "../../types";
import { MAP_LAYERS_ID } from "src/app/contants";
@Component({
  selector: "app-thong-tin-quy-hoach",
  templateUrl: "./thong-tin-quy-hoach.component.html",
  styleUrls: ["./thong-tin-quy-hoach.component.scss"],
})
export class ThongTinQuyHoachComponent implements OnInit, OnChanges {
  @Input() quyHoachIDXD: QuyHoachFetureIDS;
  @Output() onClose = new EventEmitter<any>();
  @Output() fitParcelBound = new EventEmitter<any>();
  @Output() hilightSplitParcel = new EventEmitter<any>();
  featureProps: any[] = [];
  soLuongThua: number = 0;
  dsThua: any[] = [];
  showThuaDat: boolean = false;
  isLoadingBlock: boolean = false;
  isSDD: boolean = false;
  thuaDatInfo: any = null;
  loDatInfo: any = null;
  oQHInfo: any = null;
  doAnQHInfo: any = null;
  constructor(private mapService: MapService, private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("quyHoachIDXD" in changes) {
      if (this.quyHoachIDXD) this.getThongTinQHXD();
    }
  }

  ngOnInit() {}

  close() {
    this.onClose.emit();
  }

  trackFunc(index: any, item: any): string {
    return index; // item.id;
  }
  chuanHoaDuLieuThuocTinh(props: any[]) {
    const featureProps = [];
    props.forEach((prop) => {
      let key = prop.Key;
      const value = prop.Value;
      if (
        key &&
        key !== "geom" &&
        key !== "s_geom" &&
        key !== "object_id" &&
        key !== "maid" &&
        key !== "s_xdaidien" &&
        key !== "s_ydaidien" &&
        key !== "madoituong" &&
        key !== "malienket" &&
        key !== "xdaidien" &&
        key !== "ydaidien" &&
        key !== "guid" &&
        key !== "parentguid" &&
        key !== "shape_length" &&
        key !== "shape_leng" &&
        key !== "mucdichsud" &&
        key !== "shape_area"
      ) {
        if (key === "kehoachsdd") key = "Kế hoạch sử dụng đất";
        else if (key === "matinh") key = "Mã tỉnh";
        else if (key === "mahuyen") key = "Mã huyện";
        else if (key === "tenhuyen") key = "Tên huyện";
        else if (key === "tenvungquy") key = "Tên vùng quy hoạch";
        else if (key === "mucdichsud") key = "Mục đích sử dụng";
        else if (key === "nhan_mdsd") key = "Mục đích sử dụng";
        else if (key === "namquyhoac") key = "Năm quy hoạch";
        else if (key === "mahoso") key = "Mã hồ sơ";
        else if (key === "chucnang") key = "Chức năng";
        else if (key === "ttqh") key = "Thông tin quy hoạch";
        else if (key === "ghichu") key = "Ghi chú";
        else if (key === "maxa") key = "Mã xã";
        else if (key === "tenxa") key = "Tên xã";
        featureProps.push({
          key: key,
          value: value.toString() == "[object Object]" ? "" : value,
        });
        // c:\Users\user\Downloads\QĐ_TP_5149_7_12_2021_phê_duyệt_Quy_hoạch_sử_dụng_đất_huyện_Đan_Phượng (1).pdf
      }
    });
    // featureProps.push({
    //   key: 'Quyết định',
    //   value: 'http://45.118.146.215:99/map/Q%C4%90_TP_5149_7_12_2021_ph%C3%AA_duy%E1%BB%87t_Quy_ho%E1%BA%A1ch_s%E1%BB%AD_d%E1%BB%A5ng_%C4%91%E1%BA%A5t_huy%E1%BB%87n_%C4%90an_Ph%C6%B0%E1%BB%A3ng.pdf'
    // })
    return featureProps;
  }
  openQuyetDinh() {
    var url =
      "http://45.118.146.215:99/map/Q%C4%90_TP_5149_7_12_2021_ph%C3%AA_duy%E1%BB%87t_Quy_ho%E1%BA%A1ch_s%E1%BB%AD_d%E1%BB%A5ng_%C4%91%E1%BA%A5t_huy%E1%BB%87n_%C4%90an_Ph%C6%B0%E1%BB%A3ng.pdf";
    window.open(url, "_blank");
  }
  getThongTinQHXD() {
    this.isLoadingBlock = true;
    this.isSDD = false;
    if (
      this.quyHoachIDXD.thuaDatId == null &&
      this.quyHoachIDXD.loDatId == null &&
      this.quyHoachIDXD.oQuyHoachId == null &&
      this.quyHoachIDXD.kQuyHoachId == null
    ) {
      let oInfo = {};
      this.mapService
        .getThongTinQuyHoach(
          "QHXD_LaoCai",
          "objectid",
          this.quyHoachIDXD.qhcId.toString(),
          false
        )
        .subscribe((rs) => {
          this.isLoadingBlock = false;
          if (rs && rs.dsThuocTinh && rs.dsThuocTinh.length) {
            rs.dsThuocTinh.forEach((kvp) => {
              oInfo = {
                ...oInfo,
                [kvp.Key]: this.isEmpty(kvp.Value) ? "" : kvp.Value.toString(),
              };
            });
            this.oQHInfo = _.isEmpty(oInfo) ? null : oInfo;
            const maHSQH = this.oQHInfo["oqh_mahoso"];
            if (!maHSQH) return;
            this.getThongTinDoAn(maHSQH);
          }
        });
    } else if (
      this.quyHoachIDXD.thuaDatId == null &&
      this.quyHoachIDXD.loDatId == null &&
      this.quyHoachIDXD.oQuyHoachId == null &&
      this.quyHoachIDXD.kQuyHoachId != null
    ) {
      let oInfo = {};
      this.mapService
        .getThongTinQuyHoach(
          "QHXD_LaoCai",
          "objectid",
          this.quyHoachIDXD.kQuyHoachId.toString(),
          false
        )
        .subscribe((rs) => {
          this.isLoadingBlock = false;
          if (rs && rs.dsThuocTinh && rs.dsThuocTinh.length) {
            rs.dsThuocTinh.forEach((kvp) => {
              oInfo = {
                ...oInfo,
                [kvp.Key]: this.isEmpty(kvp.Value) ? "" : kvp.Value.toString(),
              };
            });
            this.oQHInfo = _.isEmpty(oInfo) ? null : oInfo;
            const maHSQH = this.oQHInfo["oqh_mahoso"];
            if (!maHSQH) return;
            this.getThongTinDoAn(maHSQH);
          }
        });
    } else {
      this.getThongTinQuyHoachAsync().subscribe(
        (combinedResponse) => {
          this.isLoadingBlock = false;
          console.log("combinedResponse", combinedResponse);
          if (combinedResponse.length) {
            let tInfo = {};
            let ldInfo = {};
            let oInfo = {};
            let kInfo = {};
            if (this.quyHoachIDXD.thuaDatId) {
              tInfo = {};
              combinedResponse[0].forEach((kvp) => {
                tInfo = {
                  ...tInfo,
                  [kvp.Key]:
                    typeof kvp.Value === "object" ? "" : kvp.Value.toString(),
                };
              });
            }
            if (this.quyHoachIDXD.loDatId) {
              let oIndex = 0;
              if (this.quyHoachIDXD.thuaDatId) oIndex++;
              if (combinedResponse.length >= oIndex) {
                combinedResponse[oIndex].forEach((kvp) => {
                  ldInfo = {
                    ...ldInfo,
                    [kvp.Key]: this.isEmpty(kvp.Value)
                      ? ""
                      : kvp.Value.toString(),
                  };
                });
              }
            }
            if (this.quyHoachIDXD.oQuyHoachId) {
              let oIndex = 0;
              if (this.quyHoachIDXD.thuaDatId) oIndex++;
              if (this.quyHoachIDXD.loDatId) oIndex++;
              if (combinedResponse.length >= oIndex) {
                combinedResponse[oIndex].forEach((kvp) => {
                  oInfo = {
                    ...oInfo,
                    [kvp.Key]: this.isEmpty(kvp.Value)
                      ? ""
                      : kvp.Value.toString(),
                    // [kvp.Key]: kvp.Value.toString()
                  };
                });
              }
            }
            // if (this.quyHoachIDXD.kQuyHoachId) {
            //   let kIndex = 0;
            //   if (this.quyHoachIDXD.thuaDatId) kIndex++;
            //   if (this.quyHoachIDXD.loDatId) kIndex++;
            //   if (this.quyHoachIDXD.oQuyHoachId) kIndex++;
            //   if (combinedResponse.length >= kIndex) {
            //     combinedResponse[kIndex].forEach((kvp) => {
            //       kInfo = {
            //         ...kInfo,
            //         [kvp.Key]: this.isEmpty(kvp.Value) ? "" : kvp.Value,
            //       };
            //     });
            //   }
            // }
            if (!_.isEmpty(oInfo)) {
              const maHSQH = oInfo["oqh_mahoso"];
              if (!maHSQH) return;
              this.getThongTinDoAn(maHSQH);
            } else {
              if (!_.isEmpty(tInfo)) {
                const searchChucNangParams = [
                  {
                    field: "geom",
                    operato: "ST_Contains",
                    join: "and",
                    value: tInfo["geom"],
                  },
                ];
                this.mapService
                  .searchTable("QHXD_LaoCai", searchChucNangParams, true)
                  .subscribe((rs: any) => {
                    if (rs && rs.length) {
                      const kvp = rs[0];
                      Object.keys(kvp).forEach((key) => {
                        oInfo = {
                          ...oInfo,
                          [key]: this.isEmpty(kvp[key])
                            ? ""
                            : kvp[key].toString(),
                          // [kvp.Key]: kvp.Value.toString()
                        };
                      });
                      this.oQHInfo = oInfo;
                      const maHSQH = this.oQHInfo["oqh_mahoso"];
                      if (!maHSQH) return;
                      this.getThongTinDoAn(maHSQH);
                    }
                  });
              } else {
                console.log("eeess", this.quyHoachIDXD);
                if (this.quyHoachIDXD.kQuyHoachId) {
                  this.mapService
                    .getThongTinQuyHoach(
                      "QHXD_LaoCai",
                      "objectid",
                      this.quyHoachIDXD.kQuyHoachId.toString(),
                      false
                    )
                    .subscribe((rs) => {
                      console.log("rsssssss", rs);
                      rs.forEach((kvp) => {
                        oInfo = {
                          ...oInfo,
                          [kvp.Key]: this.isEmpty(kvp.Value)
                            ? ""
                            : kvp.Value.toString(),
                          // [kvp.Key]: kvp.Value.toString()
                        };
                      });
                      this.oQHInfo = oInfo;
                      const maHSQH = this.oQHInfo["oqh_mahoso"];
                      if (!maHSQH) return;
                      this.getThongTinDoAn(maHSQH);
                    });
                }
              }
            }
            this.thuaDatInfo = _.isEmpty(tInfo) ? null : tInfo;
            this.loDatInfo = _.isEmpty(ldInfo) ? null : ldInfo;
            this.oQHInfo = _.isEmpty(oInfo) ? null : oInfo;
          } else {
            if (this.quyHoachIDXD.kQuyHoachId) {
              let oInfo = {};
              this.mapService
                .getThongTinQuyHoach(
                  "QHXD_LaoCai",
                  "objectid",
                  this.quyHoachIDXD.kQuyHoachId.toString(),
                  false
                )
                .subscribe((rs) => {
                  console.log("rsssssss", rs);
                  rs.forEach((kvp) => {
                    oInfo = {
                      ...oInfo,
                      [kvp.Key]: this.isEmpty(kvp.Value)
                        ? ""
                        : kvp.Value.toString(),
                      // [kvp.Key]: kvp.Value.toString()
                    };
                  });
                  this.oQHInfo = _.isEmpty(oInfo) ? null : oInfo;
                  const maHSQH = this.oQHInfo["oqh_mahoso"];
                  if (!maHSQH) return;
                  this.getThongTinDoAn(maHSQH);
                });
            }
          }
        },
        (error) => {
          console.error("Lỗi khi lấy thông tin quy hoạch:", error);
        }
      );
    }
  }
  getThongTinDoAn(maHSQH) {
    this.mapService
      .getThongTinQuyHoach(
        MAP_LAYERS_ID.OQH,
        "daqh_mahoso",
        maHSQH.toString(),
        false
      )
      .subscribe((rs) => {
        let kInfo = {};
        if (rs && rs.dsThuocTinh && rs.dsThuocTinh.length) {
          rs.dsThuocTinh.forEach((kvp) => {
            kInfo = {
              ...kInfo,
              // [kvp.Key]: kvp.Value.toString()
              [kvp.Key]: this.isEmpty(kvp.Value) ? "" : kvp.Value.toString(),
            };
          });
          console.log("kinfo", kInfo);
          this.doAnQHInfo = _.isEmpty(kInfo) ? null : kInfo;
        }
      });
  }
  getThongTinQuyHoachAsync = (): Observable<any> => {
    const requests = [];
    if (this.quyHoachIDXD.thuaDatId)
      requests.push(
        this.mapService.getThongTinQuyHoach(
          "QHXD_LaoCai_ThuaDat",
          "objectid",
          this.quyHoachIDXD.thuaDatId.toString(),
          false
        )
      );
    if (this.quyHoachIDXD.loDatId)
      requests.push(
        this.mapService.getThongTinQuyHoach(
          "QHXD_LaoCai_CoCauPhanLo",
          "objectid",
          this.quyHoachIDXD.loDatId.toString(),
          false
        )
      );
    if (this.quyHoachIDXD.oQuyHoachId)
      requests.push(
        this.mapService.getThongTinQuyHoach(
          "QHXD_LaoCai",
          "objectid",
          this.quyHoachIDXD.oQuyHoachId.toString(),
          false
        )
      );

    return forkJoin(requests).pipe(
      map((responses) => {
        const combinedResponse = responses.reduce((acc, response) => {
          if (response && response.dsThuocTinh && response.dsThuocTinh.length) {
            acc.push(response.dsThuocTinh);
          }
          return acc;
        }, []);
        console.log("combinedResponse", combinedResponse);
        return combinedResponse;
      })
    );
  };
  isEmpty(obj) {
    if (typeof obj === "object") {
      return Object.keys(obj).length === 0;
    } else return false;
  }
  showDSThua() {
    this.showThuaDat = !this.showThuaDat;
  }
  goToParcel(bbox) {
    this.fitParcelBound.emit(bbox);
  }
  fitPolygonBounds(evt) {
    this.fitParcelBound.emit(evt);
  }

  hilightPolygon(parcel) {
    this.hilightSplitParcel.emit(parcel);
  }
}
