import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-top-control',
  templateUrl: './top-control.component.html',
  styleUrls: ['./top-control.component.scss']
})
export class TopControlComponent implements OnInit {
  @Output() showSearchPOI = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  showSearch() {
    console.log('ss')
    this.showSearchPOI.emit();
  }
}
