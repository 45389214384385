import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { VgApiService } from "@videogular/ngx-videogular/core";
import JSMpeg from "@cycjimmy/jsmpeg-player";
@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements AfterViewInit {
  // videoUrl = "rtsp://admin:camera234@10.19.60.7:554";
  @ViewChild("streaming", { static: false }) streamingcanvas: ElementRef;
  videoUrl = "ws://gtsvietnam.net:9999";
  // @Input()
  // set src(src: string) {
  //   this.videoUrl = src;
  //   console.log("changing src: " + src);
  //   if (this.api != null) {
  //     this.playVideo();
  //   }
  // }
  height: 600;
  width: 800;
  @Output() onFinish = new EventEmitter<any>();
  isPlaying: boolean;
  constructor() {}
  ngAfterViewInit(): void {
    console.log("canvas", this.streamingcanvas);
    let player = new JSMpeg.Player(this.videoUrl, {
      canvas: this.streamingcanvas.nativeElement,
      autoplay: true,
      audio: false,
      loop: true,
    });
    console.log("player", player);
  }

  ngOnInit(): void {}
}
