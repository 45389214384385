<form [formGroup]="formStyle" (ngSubmit)="onSubmit()">
  <div class="modal-body pt-0">
    <div class="mt-2 d-flex justify-content-between">
      <div class="d-flex">
        <label class="font-size-18 mb-0 text-nowrap me-2">Tên vector: </label>
        <input class="form-control" type="search" id="vectorName" name="vectorName" formControlName="vectorName">
      </div>
      <button type="submit" class="btn btn-success" [role]="buttons.SAVE.VALUE">
        <i class="fa-solid fa-floppy-disk align-middle me-1"></i> Lưu
      </button>
    </div>
    <div class="">
      <div class="d-flex justify-content-center">
        <div class="form-check font-size-14">
          <input class="form-check-input" type="radio" value="pointImage" id="pointImage" name="vectorType"
            formControlName="vectorType">
          <label class="form-check-label" for="pointImage">
            Point Image
          </label>
        </div>
        <div class="form-check mx-5 font-size-14">
          <input class="form-check-input" type="radio" value="point" id="point" name="vectorType"
            formControlName="vectorType">
          <label class="form-check-label" for="point">
            Point Vector
          </label>
        </div>
        <div class="form-check me-5 font-size-14">
          <input class="form-check-input" type="radio" value="line" id="line" name="vectorType"
            formControlName="vectorType">
          <label class="form-check-label" for="line">
            Line Vector
          </label>
        </div>
        <div class="form-check font-size-14">
          <input class="form-check-input" type="radio" value="polygon" id="polygon" name="vectorType"
            formControlName="vectorType">
          <label class="form-check-label" for="polygon">
            Polygon Vector
          </label>
        </div>
      </div>
    </div>
    <div class="form-check mt-3 d-flex justify-content-center">
      <div>
        <input class="form-check-input" type="checkbox" id="useGroup" formControlName="useGroup" name="useGroup">
        <label class="form-check-label" for="useGroup">
          Phân nhóm hiển thị
        </label>
      </div>
      <div class="ms-5">
        <input class="form-check-input" type="checkbox" id="useLabel" formControlName="useLabel" name="useLabel">
        <label class="form-check-label" for="useLabel">
          Sử dụng nhãn
        </label>
      </div>
    </div>

    <div *ngIf="!valFormStyle.useGroup.value" class="my-fieldset mt-4">
      <div class="my-legend-title">
        Vector
      </div>
      <div class="row w-100">
        <div *ngIf="valFormStyle.vectorType.value === 'point'" class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Radius:</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="number" placeholder="radius" formControlName="vector_radius"
                name="vector_radius">
            </div>
          </div>
        </div>
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center me-2">Stroke Width:</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="number" placeholder="Stroke Width" formControlName="vector_stroke_width"
                name="vector_stroke_width">
            </div>
          </div>
        </div>
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Stroke Color:</label>
            </div>
            <div class="col-8 d-flex g-input-color">
              <input class="form-control c-color" type="color" placeholder="Stroke Color"
                formControlName="vector_stroke_color" name="vector_stroke_color">
              <input class="align-self-center ms-2 form-control c-input"
                (input)="changeColorRecord($event, 'vector_stroke_color')"
                [value]="valFormStyleRecord.vector_stroke_color.value">
            </div>
          </div>
        </div>
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Fill Color:</label>
            </div>
            <div class="col-8 d-flex g-input-color">
              <input class="form-control c-color" type="color" placeholder="Stroke Color"
                formControlName="vector_fill_color" name="vector_fill_color">
              <input class="align-self-center form-control c-input"
                (input)="changeColorRecord($event, 'vector_fill_color')"
                [value]="valFormStyleRecord.vector_fill_color.value">
            </div>
          </div>
        </div>
        <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Line dash:</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="[1,3]" formControlName="vector_line_dash"
                name="vector_line_dash">
            </div>
          </div>
        </div>
        <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Line cap:</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="butt | round | square"
                formControlName="vector_line_cap" name="vector_line_cap">
            </div>
          </div>
        </div>
        <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Line join:</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="bevel | round | miter"
                formControlName="vector_line_join" name="vector_line_join">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="valFormStyle.useGroup.value" class="my-fieldset mt-4">
      <div class="my-legend-title">
        Phân nhóm
      </div>
      <div class="d-flex w-100">
        <div class="w-60 d-flex">
          <div class="d-flex me-3 w-40">
            <label class="form-label text-nowrap align-self-center me-2 mb-0">Chọn bảng</label>
            <ng-select class="w-100" [notFoundText]="'Không có dữ liệu'" (change)="onSelectSource($event)"
              [items]="tableSources" [multiple]="false" bindLabel="name" bindValue="id" formControlName="tbkey"
              name="tbkey" placeholder="Chọn bảng">
            </ng-select>
          </div>
          <div class="d-flex w-60">
            <label class="form-label text-nowrap align-self-center me-2 mb-0">Chọn trường phân loại</label>
            <ng-select class="w-100" [notFoundText]="'Không có dữ liệu'" [items]="cols.model || []" [multiple]="false"
              bindLabel="alias" bindValue="colname" formControlName="field" name="field"
              placeholder="Chọn trường phân loại">
              <ng-template ng-option-tmp let-item="item"> <!-- Template for option display -->
                {{ item.alias }} - ({{ item.colname }})
              </ng-template>
            </ng-select>
            <button type="button" class="btn btn-success ms-2 text-nowrap" (click)="getValueLegend()">Lấy giá
              trị</button>
          </div>
        </div>
        <div class="w-40 d-flex">
          <div class="d-flex me-3 justify-content-around w-100">
            <label class="form-label text-nowrap align-self-center me-2 mb-0">Phương thức:</label>
            <div class="form-check font-size-14 align-self-center">
              <input class="form-check-input" type="radio" value="equal" id="equal" name="method"
                formControlName="method">
              <label class="form-check-label" for="equal">
                Equal
              </label>
            </div>
            <div class="form-check mx-5 font-size-14 align-self-center">
              <input class="form-check-input" type="radio" value="inrange" id="inrange" name="method"
                formControlName="method">
              <label class="form-check-label" for="inrange">
                In Range
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="valFormStyle.useLabel.value" class="my-fieldset mt-4">
      <div class="my-legend-title">
        Thiết lập nhãn
      </div>
      <div class="row w-100">
        <div class="col-4">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Font family</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="Font family" formControlName="label_font_family"
                name="label_font_family">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center me-2 mb-0">Font color</label>
            </div>
            <div class="col-8 d-flex g-input-color">
              <input class="form-control c-color" type="color" placeholder="Stroke Color"
                formControlName="label_font_color" name="label_font_color">
              <input class="align-self-center form-control c-input" (input)="changeColor($event, 'label_font_color')"
                [value]="valFormStyle.label_font_color.value">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Zoom Start</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="Zoom Start" formControlName="label_zoom_start"
                name="label_zoom_start">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 w-100">
        <div class="col-4">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Font size</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="number" placeholder="Font size" formControlName="label_font_size"
                name="label_font_size">
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-4 align-self-center">
              <label class="form-label text-nowrap align-self-center mb-0">Cột hiển thị nhãn</label>
            </div>
            <div class="col-8">
              <ng-select class="w-100" [notFoundText]="'Không có dữ liệu'" [items]="cols.model" [multiple]="false"
                bindLabel="alias" bindValue="colname" formControlName="label_column" name="label_column"
                placeholder="Chọn trường phân loại">
                <ng-template ng-option-tmp let-item="item"> <!-- Template for option display -->
                  {{ item.alias }} - ({{ item.colname }})
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="valFormStyle.vectorType.value === 'pointImage' && !valFormStyle.useGroup.value"
      class="my-fieldset mt-4">
      <div class="my-legend-title">
        Image
      </div>
      <div class="d-flex w-100">
        <div class="w-25 d-flex">
          <button type="button" class="btn btn-light" (click)="openSelectFile()" data-bs-target=".bs-example-modal-lg"
            data-bs-toggle="modal" data-bs-target="#myModal">
            <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Chọn ảnh
          </button>
          <img *ngIf="valFormStyle.image.value" src="{{HOST_RESOURCE}}/{{valFormStyle.image.value}}" width="30px"
            height="30px" class="ms-2 image-crop">
        </div>
        <div class="w-75 d-flex">
          <div class="d-flex me-3">
            <label class="form-label text-nowrap align-self-center me-2">Offset X</label>
            <input class="form-control" type="text" placeholder="Offset X" formControlName="offsetX" name="offsetX">
          </div>
          <div class="d-flex">
            <label class="form-label text-nowrap align-self-center me-2">Offset Y</label>
            <input class="form-control" type="text" placeholder="Offset Y" formControlName="offsetY" name="offsetY">
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="valFormStyle.useGroup.value" class="my-fieldset mt-4">
      <div class="my-legend-title">
        {{valFormStyle.vectorType.value}} Vector
      </div>
      <div class="table-responsive mh-70-vh content-scroll w-100">
        <table class="table mb-0 table-bordered">
          <thead>
            <tr class="sticky-head">
              <th>Chú giải</th>
              <th *ngIf="valFormStyle.method.value === 'equal'">Giá trị phân loại</th>
              <th *ngIf="valFormStyle.method.value === 'inrange'">Min</th>
              <th *ngIf="valFormStyle.method.value === 'inrange'">Max</th>
              <th *ngIf="valFormStyle.vectorType.value === 'pointImage'">Images</th>
              <th *ngIf="valFormStyle.vectorType.value !== 'pointImage'">Vector</th>
              <th class="text-center w-5"><button type="button" class="btn btn-light btn-sm" (click)="addRowLegend()">
                  <i class="fa-solid fa-plus"></i>
                </button></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of listLegend; let i = index">
              <td name="note">
                <input class="form-control form-control-sm w-100" type="text" name="note" placeholder="Chú giải"
                  (change)="changeValueLegend($event, i, 'note')" [defaultValue]="row.note">
              </td>
              <td *ngIf="valFormStyle.method.value === 'equal'" name="value">
                <input class="form-control form-control-sm w-100" type="text" name="value"
                  placeholder="Giá trị phân loại" (change)="changeValueLegend($event, i, 'value')"
                  [defaultValue]="row.value">
              </td>
              <td *ngIf="valFormStyle.method.value === 'inrange'" name="min">
                <input class="form-control form-control-sm w-100" type="number" name="min"
                  placeholder="Giá trị nhỏ nhất" (change)="changeValueLegend($event, i, 'min')"
                  [defaultValue]="row.min">
              </td>
              <td *ngIf="valFormStyle.method.value === 'inrange'" name="max">
                <input class="form-control form-control-sm w-100" type="number" name="max"
                  placeholder="Giá trị lớn nhất" (change)="changeValueLegend($event, i, 'max')"
                  [defaultValue]="row.max">
              </td>
              <td *ngIf="valFormStyle.vectorType.value === 'pointImage'" name="image">
                <button type="button" class="btn btn-light btn-sm" (click)="triggerChooseFile(row, i)"
                  data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
                  <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Chọn ảnh
                </button>
                <img *ngIf="row.image" src="{{HOST_RESOURCE}}/{{row.image}}" width="15px" height="15px"
                  class="ms-2 image-crop">
              </td>
              <td *ngIf="valFormStyle.vectorType.value !== 'pointImage'" name="vector" class="">
                <div class="d-flex cursor-pointer" (click)="openModalConfig(row, i)">
                  <i class="fa-solid fa-paintbrush align-self-center me-2"></i>
                  <div class="cursor-pointer w-25 rounded"
                    [style.background]="row?.vector?.vector_fill_color || '#ffffff'">
                    &nbsp;
                  </div>
                </div>
              </td>
              <td name="action" class="w-5 text-center">
                <i class="fa-solid fa-trash-can text-danger" (click)="deleteLegend(row, i)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success me-2">
      <i class="fa-solid fa-floppy-disk align-middle me-1"></i> Lưu
    </button>
    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" [role]="buttons.CLOSE.VALUE">Đóng</button>
  </div>
</form>
<app-select-image #selectImage (onAction)="onHandle($event)" [hideUpload]="false" [typeSelect]="typeSelectImage"
  [filterFile]="'image'"></app-select-image>

<ng-template #modalConfigStyle let-modal>
  <div class="c-modal">
    <div class="modal-header bg-eee">
      <div class="w-100">
        <div class="d-flex justify-content-between w-100">
          <h5 class="modal-title" id="exampleModalFullscreenLabel">THIẾT LẬP STYLES</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
        </div>
      </div>
    </div>
    <form [formGroup]="formStyleRecord" (ngSubmit)="saveStyleRecord(modal)">
      <div class="modal-body pt-0">
        <div class="row w-100">
          <div *ngIf="valFormStyle.vectorType.value === 'point'" class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Radius:</label>
              </div>
              <div class="col-8">
                <input class="form-control" type="number" placeholder="radius" formControlName="vector_radius"
                  name="vector_radius">
              </div>
            </div>
          </div>
          <div class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center me-2">Stroke Width:</label>
              </div>
              <div class="col-8">
                <input class="form-control" type="number" placeholder="Stroke Width"
                  formControlName="vector_stroke_width" name="vector_stroke_width">
              </div>
            </div>
          </div>
          <div class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Stroke Color:</label>
              </div>
              <div class="col-8 d-flex g-input-color">
                <input class="form-control c-color" type="color" placeholder="Stroke Color"
                  formControlName="vector_stroke_color" name="vector_stroke_color">
                <input class="align-self-center ms-2 form-control c-input"
                  (input)="changeColorRecord($event, 'vector_stroke_color')"
                  [value]="valFormStyleRecord.vector_stroke_color.value">
              </div>
            </div>
          </div>
          <div class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Fill Color:</label>
              </div>
              <div class="col-8 d-flex g-input-color">
                <input class="form-control c-color" type="color" placeholder="Stroke Color"
                  formControlName="vector_fill_color" name="vector_fill_color">
                <input class="align-self-center form-control c-input"
                  (input)="changeColorRecord($event, 'vector_fill_color')"
                  [value]="valFormStyleRecord.vector_fill_color.value">
              </div>
            </div>
          </div>
          <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Line dash:</label>
              </div>
              <div class="col-8">
                <input class="form-control" type="text" placeholder="[1,3]" formControlName="vector_line_dash"
                  name="vector_line_dash">
              </div>
            </div>
          </div>
          <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Line cap:</label>
              </div>
              <div class="col-8">
                <input class="form-control" type="text" placeholder="butt | round | square"
                  formControlName="vector_line_cap" name="vector_line_cap">
              </div>
            </div>
          </div>
          <div *ngIf="valFormStyle.vectorType.value === 'line'" class="col-4 mt-3">
            <div class="row">
              <div class="col-4 align-self-center">
                <label class="form-label text-nowrap align-self-center mb-0">Line join:</label>
              </div>
              <div class="col-8">
                <input class="form-control" type="text" placeholder="bevel | round | miter"
                  formControlName="vector_line_join" name="vector_line_join">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-end">
            <div class="deliver-horizontal my-3"></div>
            <button type="submit" class="btn btn-success" [role]="buttons.SAVE.VALUE">
              <i class="fa-solid fa-floppy-disk align-middle me-1"></i> Lưu
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>