import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import { MapService } from "src/app/core/services/map.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QRCodeComponent } from "src/app/components/qrcode/qrcode.component";
@Component({
  selector: "app-thua-dat",
  templateUrl: "./thua-dat.component.html",
  styleUrls: ["./thua-dat.component.scss"],
})
export class ThuaDatComponent implements OnInit, OnChanges {
  @Input() thuaDatInfo: any;
  @Output() fitPolygon = new EventEmitter<any>();
  @Output() hilightPolygon = new EventEmitter<any>();
  showAnalyst: boolean = false;
  listCNXD: any[] = [];
  listCNSDD: any[] = [];
  isLoadingBlock: boolean = false;
  constructor(private mapsService: MapService, private ngbModal: NgbModal) {}

  ngOnInit(): void {
    // console.log('tdinfo', this.thuaDatInfo)
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ("thuaDatInfo" in changes) {
      this.showAnalyst = false;
    }
  }

  hilightArea() {
    if (this.thuaDatInfo && this.thuaDatInfo.geom) {
      const geostr = this.thuaDatInfo.geom;
      if (geostr) {
        const geom: any = wellknown.parse(geostr);
        if (geom.type === "MultiPolygon") {
          var mpolygon = turf.multiPolygon(geom.coordinates);
          this.fitPolygon.emit(mpolygon);
        } else {
          var polygon = turf.polygon(geom.coordinates);
          this.fitPolygon.emit(polygon);
        }
      }
    }
  }
  analyst() {
    const modalRef = this.ngbModal.open(QRCodeComponent, {
      backdrop: "static",
      // modalDialogClass: 'my-custom-modal'
    });
    modalRef.result
      .then((result) => {
        console.log("Modal closed with data:", result);
        if (result) {
          this.showAnalyst = true;
          const analystLayers = [
            {
              layerName: "QHXD_LaoCai",
              operato: "",
              outFields: ["oqh_chucnangsdd"],
            },
          ];
          const analysParams = {
            analystLayers: analystLayers,
          };
          this.isLoadingBlock = true;
          this.mapsService
            .analystParcel(
              "QHXD_LaoCai_ThuaDat",
              this.thuaDatInfo.objectid,
              analysParams
            )
            .subscribe((rs: any) => {
              this.isLoadingBlock = false;
              this.listCNXD = rs;
            });
        }
      })
      .catch((reason) => {
        console.log("Modal dismissed with reason:", reason);
        // Handle the case where the modal was dismissed (closed without using close button)
      });
  }
  showGeometry(geostr) {
    if (geostr) {
      const geom: any = wellknown.parse(geostr);
      if (geom.type === "MultiPolygon") {
        var mpolygon = turf.multiPolygon(geom.coordinates);
        this.hilightPolygon.emit(mpolygon);
      } else {
        var polygon = turf.polygon(geom.coordinates);
        this.hilightPolygon.emit(polygon);
      }
    }
  }
}
