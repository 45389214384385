<div class="feature-info" cdkDrag>
  <div class="header">
    <h4 style="margin-bottom: 0px">Thông tin quy hoạch xây dựng</h4>
    <button type="button" (click)="close()" class="btn closebtn">
      <i class="fa fa-close"></i>
    </button>
  </div>
  <hr style="margin: 10px; height: 1px; background: #ccc" />
  <div class="feature-info-body">
    <app-thua-dat
      *ngIf="thuaDatInfo"
      [(thuaDatInfo)]="thuaDatInfo"
      (fitPolygon)="fitPolygonBounds($event)"
      (hilightPolygon)="hilightPolygon($event)"
    ></app-thua-dat>
    <app-lo-quy-hoach
      *ngIf="loDatInfo"
      [(loQHInfo)]="loDatInfo"
      (fitPolygon)="fitPolygonBounds($event)"
    ></app-lo-quy-hoach>
    <app-oquy-hoach
      [(oQHInfo)]="oQHInfo"
      (fitPolygon)="fitPolygonBounds($event)"
    ></app-oquy-hoach>
    <app-quy-hoach
      [(doAnQHInfo)]="doAnQHInfo"
      (fitPolygon)="fitPolygonBounds($event)"
    ></app-quy-hoach>
  </div>
  <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
</div>
