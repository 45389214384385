import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chu-giai-qhsdd',
  templateUrl: './chu-giai-qhsdd.component.html',
  styleUrls: ['./chu-giai-qhsdd.component.scss']
})
export class ChuGiaiQHSDDComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>();
  constructor(
  ) { }

  

  ngOnInit() {
  
  }

  close() {
    this.onClose.emit();
  }

  trackFunc(index: any, item: any): string {
    return index;// item.id;
  }

}
