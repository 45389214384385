<div class="info-container" style="position: relative; background: rgb(245, 245, 245);">
        <div style="display:flex;align-items: center;justify-content:space-between;width:100%;">
                <div style="display:flex;align-items: center;gap:16px;">
                        <a href="javascript:;" (click)="hilightArea()"
                                class="btn btn-icon btn-outline-success btn-circle active"
                                style="display:flex;justify-content: center;align-items: center;width:32px;height:32px;">
                                <i class="fas fa-map-marker-alt" style="font-size: 1rem;"></i></a>
                        <h4 class="text-dark font-weight-bold" style="margin-bottom: 0px;">THỬA ĐẤT:</h4>

                </div>
                <a href="javascript:;" (click)="analyst()" class="btn btn-icon btn-outline-success btn-circle active"
                        style="display:flex;justify-content: center;align-items: center;width:32px;height:32px;"
                        title="Phân tích thửa đất">
                        <i class="fa fa-pie-chart" style="font-size: 1rem;"></i></a>
        </div>
        <!-- <h4 class="text-dark font-weight-bold mb-5">THỬA ĐẤT:</h4> -->
        <!-- <a href="javascript:;" title="Phân tích thửa đất"
                class="btn btn-icon btn-outline-success btn-circle active ng-star-inserted"
                style="transform: scale(1); position: absolute; top: 1rem; right: 4rem; margin: 0; padding: .8rem; width: 0; height: 0;"><i
                        class="fas fa-chart-pie" style="font-size: 1rem;"></i></a><a href="javascript:;"
                class="btn btn-icon btn-outline-success btn-circle active"
                style="transform: scale(1); position: absolute; top: 1rem; right: 1rem; margin: 0; padding: .8rem; width: 0; height: 0;"><i
                        class="fas fa-map-marker-alt" style="font-size: 1rem;"></i></a> -->
        <div *ngIf="thuaDatInfo">
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Phường/Xã:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.tenxa}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Địa chỉ:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.diachi}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Số tờ:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.soto}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Số thửa:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.sothua}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Loại đất:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.loaidat}}</label>
                </div>

                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Diện tích:</label>
                        <label class="col-8 pl-1">{{thuaDatInfo.dientich}}m<sup>2</sup></label>
                </div>
        </div>
        <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
        <div *ngIf="showAnalyst">
                <div style="display:flex;gap:12px;">
                        <h5 style="color:black;font-weight:bold"> Phân tích quy hoạch xây dựng</h5>
                        <span style="font-size:15px;color:red;font-weight:bold">(tham khảo)</span>
                </div>
                <div *ngFor="let manh of listCNXD;let i=index;" class="list-chucnang"
                        style="background-color: #9C6134; color: rgb(228, 228, 228);" (click)="showGeometry(manh.t0_geom)">
                        <div class="container" style="padding: 12.5px 8px">
                                <div class="row" style="cursor: pointer;">
                                        <div class="col-2">
                                                <div class="number-logo">{{i+1}}</div>
                                        </div>
                                        <div class="col-10">
                                                <div class="row"></div>
                                                <div class="row">
                                                        <div class="col-6"><strong>Chức năng đất</strong>
                                                        </div>
                                                        <div class="col-6">{{manh.t0_oqh_chucnangsdd}}</div>
                                                </div>
                                                <div class="row">
                                                        <div class="col-6"><strong>Diện tích</strong></div>
                                                        <div class="col-6">{{manh.dientichtrong0.toFixed(1)}}
                                                                (m<sup>2</sup>)</div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
                <!-- <hr> -->
                <!-- <h5 style="color:black;font-weight:bold">  Phân tích quy hoạch sử dụng đất</h5> -->
        </div>
</div>