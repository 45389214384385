import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-qrcode",
  templateUrl: "./qrcode.component.html",
  styleUrls: ["./qrcode.component.scss"],
})
export class QRCodeComponent implements OnInit {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {}

  save() {
    this.ngbActiveModal.close(true);
  }
  close() {
    this.ngbActiveModal.close();
  }
}
