import { MenuItem } from './menu.model';


export const MENU: MenuItem[] = [
    {
        id: "1",
        label: 'Quản trị người dùng',
        isTitle: true
    },
    {
        id: "1.1",
        label: 'Người dùng',
        icon: 'bxs-user',
        link: '/quan-tri/danh-sach-nd',

    },
    {
        id: "1.2",
        label: 'Nhóm người sử dụng',
        icon: 'bxs-user',
        link: '/quan-tri/nhom-nd',

    },
    {
        id: "1.3",
        label: 'Danh sách nhóm quyền',
        icon: 'bxs-user',
        link: '/quan-tri/nhom-quyen',

    },
    {
        id: "1.4",
        // label: 'Gán nhóm người sử dụng vào nhóm quyền',
        label: 'Phân quyền theo nhóm',
        icon: 'bxs-user',
        link: '/quan-tri/gan-nhom-nd-vao-nhom-quyen',

    },
    // {
    //     id: "1.5",
    //     label: 'Danh sách menu',
    //     icon: 'bxs-user',
    //     link: '/quan-tri/danh-sach-quyen',

    // },
    // {
    //     id: "1.6",
    //     label: 'Phần quyền nút chức năng',
    //     icon: 'bxs-user',
    //     link: '/quan-tri/gan-quyen-cho-menu',

    // },
    {
        id: "1.7",
        label: 'Nhật ký tài khoản',
        icon: 'bx-history',
        link: '/quan-tri/log-nguoi-dung',

    },

    {
        id: "2",
        label: 'Quản trị nội dung',
        isTitle: true
    },
    {
        id: "2.1",
        label: 'Công bố quy hoạch',
        icon: 'bx bx-notepad',
        link: '/quan-tri/cong-bo-quy-hoach',
    },
    {
        id: "2.2",
        label: 'Xin ý kiến người dân',
        icon: 'bx bxs-news',
        link: '/quan-tri/xin-y-kien',
    },
    {
        id: "2.2",
        label: 'Phản ánh hiện trường',
        icon: 'bx bxs-news',
        link: '/quan-tri/phan-anh-hien-truong',
    },

    // {
    //     id: "2",
    //     label: 'Giám sát hệ thống',
    //     isTitle: true
    // },
    // {
    //     id: "2.1",
    //     label: 'Thống kê',
    //     icon: 'bxs-pie-chart-alt-2',
    //     link: '/administration/quan-tri/thong-ke',

    // },
    // {
    //     id: "2.2",
    //     label: 'Nhật ký hệ thống',
    //     icon: 'bxs-food-menu',
    //     link: '/administration/quan-tri/nhat-ky-he-thong',

    // },
    // {
    //     id: "3",
    //     label: 'Quản lý danh mục',
    //     isTitle: true
    // },
    // {
    //     id: "3.1",
    //     label: 'Danh mục hành chính',
    //     icon: 'bxs-bank',
    //     link: '/administration/quan-tri/danh-muc-hanh-chinh',

    // },
    // {
    //     id: "3.2",
    //     label: 'Danh mục tham chiếu',
    //     icon: 'bxs-book-content',
    //     link: '/administration/quan-tri/danh-muc-tham-chieu',

    // },
    // {
    //     id: "4",
    //     label: 'Dữ liệu',
    //     isTitle: true,

    // },
    // {
    //     id: "4.1",
    //     label: 'Dịch vụ chia sẻ dữ liệu',
    //     icon: 'bxs-book-content',
    //     link: '/quan-tri/dich-vu-chia-se-du-lieu',

    // },
    // {
    //     id:'4.2',
    //     label: "Lịch sử chia sẻ dữ liệu",
    //     icon:'bxs-book-content',
    //     link:'/quan-tri/lich-su-chia-se-du-lieu'
    // },
    // {
    //     id:'4.3',
    //     label: "Đăng ký khai thác dữ liệu",
    //     icon:'bxs-book-content',
    //     link:'/quan-tri/dang-ky-khai-thac-du-lieu'
    // }
];

export const MENU_HOSO: MenuItem[] = [
    // {
    //     id: "1",
    //     label: 'Khai thác hồ sơ',
    //     isTitle: true
    // },
    {
        id: "1.1",
        label: 'Khai thác hồ sơ',
        icon: 'bx bx-folder-open',
        link: '/ho-so/khai-thac-ho-so',

    },
    {
        id: "1.2",
        label: 'Quản lý thư mục đóng gói',
        icon: 'bx-folder',
        link: '/ho-so/quan-ly-thu-muc-dong-goi',

    },
    {
        id: "1.3",
        label: 'Quản lý hồ sơ',
        icon: 'bxs-archive',
        link: '/ho-so/quan-ly-ho-so',
    },
    {
        id: "1.4",
        label: 'Quản lý danh mục',
        icon: 'bxs-archive',
        link: '/ho-so/quan-ly-danh-muc',
    }
]

export const MENU_PHANANH: MenuItem[] = [
    // {
    //     id: "1",
    //     label: 'Khai thác hồ sơ',
    //     isTitle: true
    // },
    {
        id: "1.1",
        label: 'Thông tin cá nhân',
        icon: 'bx bxs-user',
        link: '/phan-anh/thong-tin-ca-nhan',

    },
    {
        id: "1.2",
        label: 'Phản ánh kiến nghị',
        icon: 'bx bxs-error',
        link: '/phan-anh/phan-anh-kien-nghi',

    },
    {
        id: "1.3",
        label: 'Phản ánh của bạn',
        icon: 'bx bxs-contact',
        link: '/phan-anh/phan-anh-cua-ban',
    },
    {
        id: "1.4",
        label: 'Đổi mật khẩu',
        icon: 'bx bxs-lock-open-alt',
        link: '/phan-anh/doi-mat-khau',
    }
]

