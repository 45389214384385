import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-year-range-picker',
  templateUrl: './year-range-picker.component.html',
  styleUrls: ['./year-range-picker.component.scss']
})
export class YearRangePickerComponent {
  @Input() startYear: number;
  @Input() endYear: number;
  @Output() yearSelected = new EventEmitter<number>();

  years: number[] = [];

  constructor() { }

  ngOnInit() {
    this.generateYearRange();
  }

  generateYearRange() {
    for (let year = this.startYear; year <= this.endYear; year++) {
      this.years.push(year);
    }
  }

  selectYear(year: number) {
    this.yearSelected.emit(year);
  }
}
