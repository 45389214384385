import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class ResourceService {

  constructor(private httpRequest: ConfigService) {

  }

  getAllResource(isFolder, onlyResource = true) {
    return this.httpRequest.get(`${API.BASE_URL}/private/resource/all?getFolder=${isFolder}&onlyResource=${onlyResource}`);
  }

  createFolder(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/resource/create-path?parentID=${body.parentID}&name=${body.name}`, body, [{"Content-Type": "multipart/form-data"}]);
  }

  deleteResource(id){
    return this.httpRequest.delete(`${API.BASE_URL}/private/resource/remove?id=${id}`);
  }

  uploadFile(id, body){
    return this.httpRequest.post(`${API.BASE_URL}/private/resource/upload?parentID=${id}`, body);
  }

  uploadFileCSDL(id, body){
    return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/directory/upload-file?parentID=${id}`, body);
  }

  uploadFileBlob(fileName, idfolder, body){
    return this.httpRequest.post(`${API.BASE_URL}/private/resource/upload-png?fileName=${fileName}&parentID=${idfolder}`, body);
  }

  rename(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/resource/rename?id=${body.id}&name=${body.name}`, {});
  }
}
