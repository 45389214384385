export const LAYOUTS = {
  EMPTY: "Empty",
  DEFAULT: "Default",
};
export const FORMS = {
  LOGIN: "/account/login",
};

export const TIME_CACHE_MAX_MINUTE = 1440; // phut
export const TIME_CACHE_NOMALL_MINUTE = 5; // phut
export const TIME_CACHE_MIN_MINUTE = 1; // phut

export const AUTH_TOKEN = "token";
export const CACHE_MENU = "CACHE_MENU";
export const CACHE_IS_ADMIN = "is_admin";
export const CACHE_ROLES = "roles";
export const CACHE_USER = "user";
export const CACHE_ID_USER = "user_id";
export const CACHE_FUNCTION_ID = "CACHE_FUNCTION_ID";
export const UN_AUTHORIZED = "Unauthorized";
export const CACHE_PREFIX_TABLE = "CACHE_PREFIX_TABLE";

export const Messages = {
  LOGIN_FAILURE:
    "Tên đăng nhập hoặc mật khẩu không đúng, vui lòng kiểm tra lại",
  WARNING_RECOVERY: "Bạn Cần chọn bản ghi đã xóa để phục hồi",
  ADD_SUCCESS: "Lưu thành công",
  DELETE_SUCCESS: "Xóa thành công",
  DELETE_NOT_SUCCESS: "Xóa không thành công",
  REJECT_SUCCESS: "Từ chối thành công",
  APPROVE_SUCCESS: "Phê duyệt thành công",
  RESTORE_SUCCESS: "Khôi phục thành công",
  ADD_ERROR: "Có lỗi xảy ra không thể lưu",
  CONFIRM_DELETE: "Bạn có chắc chắn muốn xóa?",
  CONFIRM_RECOVERY: "Bạn có chắc chắn muốn khôi phục?",
  EMPTY_RESULTS: "Chưa có thông tin",
  DEFAULT_MESSAGE_REQUIRE: "Trường bắt buộc nhập",
  DEFAULT_MESSAGE_PHONE_NUMBER: "Số điện thoại phải có 10 - 11 chữ số",
  DEFAULT_MESSAGE_EMAIL: "Sai định dạng email",
  DEFAULT_MESSAGE_CURRENCY: "Sai định dạng tiền",
  DEFAULT_MESSAGE_REQUIRE_SAVE: "Cần nhập các trường bắt buộc nhập",
  DEFAULT_MESSAGE_PASSWORD:
    "Mật khẩu cần chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ số, một chữ thường, một chữ in hoa và ký tự đặc biệt",
  DEFAULT_MESSAGE_DATE: "Sai định dạng ngày tháng",
  DEFAULT_WARNING_UPDATE: "Chưa có bản ghi nào được chọn",
  DEFAULT_WARNING_UPDATE2: "Chỉ được chọn 1 bản ghi",
  DEFAULT_WARNING_NGAY_HL: "Ngày hiệu lực phải nhỏ hơn ngày hết hiệu lực",
  DEFAULT_WARNING_NGAY_BH: "Ngày ban hành phải nhỏ hơn ngày áp dụng",
  DEFAULT_WARNING_SO_DT: "Số điện thoại nhập từ 10 đến 15 ký tự",
  SEND_SUCCESS: "Gửi thành công",
  SEND_ERROR: "Có lỗi xảy ra không thể gửi",
  EMPTY_LOAI_BC: "Loại báo cáo bắt buộc chọn",
  APPROVE_ERROR: "Phê duyệt lỗi",
  INCORRECT_EXCEL_TEMPLATE: "Mẫu excel không đúng, kiểm tra lại",

  RECOVERY_SUCCESS: "Phục hồi dữ liệu thành công",
  RECOVERY_ERROR: "Phục hồi dữ liệu không thành công",
  SEND_EMAIL_SUCCESS: "Gửi email thành công",
  SEND_EMAIL_ERROR: "Gửi email không thành công",
  EMAIL_NOT_VALID: "Email không hợp lệ",
  KHONG_TIM_THAY_MA_CHI_TIEU_BC: "Không tìm thấy mã chỉ tiêu",
  MA_CHI_TIEU_KHONG_DUNG: "Mã chỉ tiêu không đúng",
  KHONG_TIM_THAY_THONG_TIN_COT: "Không tìm thấy thông tin cột",
  ROW: "hàng",
  IMPORT_EXCEL_SUCCESS: "Nhập excel thành công",
  CHI_XOA_BAN_NHAP: "Chỉ được xóa bản nháp",
  CHI_CHON_BAN_GHI_DA_XOA: "Chỉ phục hồi bản ghi đã xóa",
  PHUC_HOI_LAI_DU_LIEU_GAN_NHAT: "Phục hồi lại dữ liệu gần nhất?",
  DUYET_NHUNG_BAN_GHI_DANG_CHO_DUYET:
    "Chỉ phê duyệt những bản ghi trạng thái chờ duyệt",
  TU_CHOI_NHUNG_BAN_GHI_DANG_CHO_DUYET:
    "Chỉ từ chối những bản ghi trạng thái chờ duyệt",
  REFUSE_APPROVAL_SUCCESS: "Từ chối duyệt thành công",
  REFUSE_APPROVAL_ERROR: "Từ chối duyệt lỗi",
  UPDATE_SUCCESS: "Cập nhật thành công",
  UPDATE_ERROR: "Cập nhật lỗi",
  RE_ENTER_ERROR: "Nhập lại lỗi",
  KHONG_TIM_THAY_DON_VI_NHAN: "Chưa có đơn vị nhận",
  CHI_CHO_NHAP_SO_LON_HON_0: "Chỉ cho nhập số lớn hơn 0",
  CHI_CHO_NHAP_SO: "Chỉ cho nhập số",
  SO_TOI_DA_15_KY_TU: "Số tối đa 15 ký tự",

  HIEU_LUC_THAY_DOI: "Hiệu lực thay đổi, dữ liệu phải nhập lại",
  DON_VI_BAT_NHAP: "Chưa chọn đơn vị",

  CHUA_CHON_NGUOI_DUNG_DE_THEM: "Chưa chọn người dùng để thêm",
  CHUA_CHON_NHOM_NGUOI_DUNG_DE_GO_BO: "Chưa chọn người dùng để gỡ bỏ",
  CHUA_CHON_NHOM_QUYEN: "Chưa chọn nhóm quyền",
  CHUA_CHON_NHOM_CHUC_NANG_DE_THEM: "Cần chọn chức năng để thêm",
  CHUA_CHON_NHOM_CHUC_NANG_DE_GO_BO: "Cần chọn chức năng để gỡ bỏ",
  TEN_NHOM_QUYEN_TRONG: "Chưa chọn tên nhóm quyền",
  RESTORE_ERROR: "Khôi phục không thành công",
  CHUA_CHON_NHOM_NGUOI_DUNG: "Chưa chọn người dùng",
  CHUA_CHON_NHOM_NGUOI_DUNG_DE_THEM: "Chưa chọn nhóm người dùng để thêm",

  TAT_CA: "Tất cả",
  TONG_DON_VI_DA_CHON: "Tổng đơn vị đã chọn",
  BAN_CO_MUON_LUU: "Bạn có muốn lưu?",
  BAN_CO_MUON_GUI: "Bạn có muốn gửi duyệt?",
  BAN_CO_MUON_DANG_XUAT: "Bạn có muốn đăng xuất khỏi hệ thống?",
  TEN_NHOM_KHONG_DUOC_TRUNG: "Tên nhóm không được trùng",

  BAN_CAN_CHON_CAP: "Bạn cần phải chọn cấp",
  VUI_LONG_CHON_FILE_EXCEL: "Vui lòng chọn file Excel (xlsx)",
  TRANG_THAI_BC_NAY_DA_XOA_KHONG_XA_TIEP:
    "Trạng thái báo cáo này đã xóa, không xóa tiếp. Mời bạn kiểm tra lại",
  ENTER_SEARCH: "Enter tới tìm kiếm",
  SEARCH: "Tìm kiếm",
  FROM_DATE: "Từ ngày",
  TO_DATE: "Đến ngày",
  BAN_MUON_KHOA_TAI_KHOAN: "Bạn muốn khóa tài khoản không?",
  BAN_MUON_XOA_TAI_KHOAN: "Bạn muốn xóa tài khoản không?",
  XAC_NHAN: "Xác nhận",
  MA_KHONG_DUOC_TRUNG: "Mã không được trùng",
  DONG_HO_EMPTY: "Chưa thêm đồng hồ",
  NAM_SAN_XUAT_KHONG_DUNG: "Năm sản xuất không đúng",
  DU_LIEU_FILE_EXCEL_KHONG_DUNG:
    "Các dòng sau trong file excel không đúng, vui lòng kiểm tra lại",
  SHARE_TO_EMPTY: "Chọn người dùng, hoặc nhóm người dùng",
  ROLE_EMPTY: "Quyền chưa chọn",
};

export const MimeType = {
  image:
    "image/jpeg,image/jpg,image/gif,image/tiff,image/svg+xml,image/x-icon,image/png",
  media: "audio/mpeg,audio/mp4,video/mp4,video/webm",
};

export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_DATE_TIME_2 = "DD/MM/YYYY HH:mm:ss";

export const BUTTONS = {
  ADD: { LABEL: "Thêm mới", VALUE: "01" },
  UPDATE: { LABEL: "Cập nhât", VALUE: "02" },
  SAVE: { LABEL: "Lưu", VALUE: "03" },
  DETAIL: { LABEL: "Chi tiết", VALUE: "04" },
  DELETE: { LABEL: "Xóa", VALUE: "05" },
  RECOVER_DELETION: { LABEL: "Phục hồi xóa", VALUE: "06" },
  EXPORT: { LABEL: "Xuất", VALUE: "07" },
  PRINT: { LABEL: "In", VALUE: "08" },
  RE_ENTER: { LABEL: "Nhập lại", VALUE: "09" },
  SEND_APPROVAL: { LABEL: "Gửi duyệt", VALUE: "10" },
  APPROVAL: { LABEL: "Duyệt", VALUE: "11" },
  REFUSE_APPROVE: { LABEL: "Từ chối duyệt", VALUE: "12" },
  IMPORT_EXCEL: { LABEL: "Nhập excel", VALUE: "13" },
  RECOVER: { LABEL: "Phục hồi", VALUE: "14" },
  CLOSE: { LABEL: "Đóng", VALUE: "15" },
  ADD_ROW: { LABEL: "Thêm dòng", VALUE: "16" },
  ADD_1_ROW: { LABEL: "Thêm 1 dòng", VALUE: "17" },
  DELETE_ROW: { LABEL: "Xóa dòng", VALUE: "18" },
  ADD_10_ROWS: { LABEL: "Thêm 10 dòng", VALUE: "19" },
  LOGIN: { LABEL: "Đăng nhập", VALUE: "22" },
  LOGOUT: { LABEL: "Đăng xuất", VALUE: "23" },
  EDIT_INFO: { LABEL: "Sửa thông tin", VALUE: "24" },
  RE_ISSUE_PASSWORD: { LABEL: "Cấp lại mật khẩu", VALUE: "25" },
  LOCK: { LABEL: "Khóa", VALUE: "26" },
  SEARCH: { LABEL: "Tìm kiếm", VALUE: "27" },
  SHARE: { LABEL: "Chia sẻ", VALUE: "28" },
  VIEW: { LABEL: "Xem", VALUE: "29" },
};

export const SIZE_PER_PAGE = 10;

export const SIZE_PER_PAGE_REPORT = 300;

export const COL_EXCEL = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const ALIGNMENT = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

export const TITLE = {
  NEW: "Thêm mới",
  EDIT: "Chỉnh sửa",
  DETAIL: "Chi tiết",
  BOLD: "Chữ đậm",
  NONE_BOLD: "Chữ thường",
  FONT: "Font chữ",
  SELECT_FONT: "Chọn font",
  ORDER: "Sắp xếp",
  SELECT_STATUS: "Chọn trạng thái",
  STATUS: "Trạng thái",
  HOAN_THANH: "Hoàn thành",
  CHUA_HOAN_THANH: "Chưa hoàn thành",
};

export const DATA_TYPE_ARRAY = [
  "text",
  "checkbox",
  "number",
  "email",
  "radio",
  "file",
  "textarea",
  "combobox",
  "combobox_multi",
  "date",
  "sub_menu",
  "btn-group",
  "search",
  "password",
  "label",
  "icon",
  "autocomplete",
  "ckeditor",
];
export const DATA_TYPE_OBJECT = {
  TEXT: "text",
  DATE: "date",
  CHECKBOX: "checkbox",
  COMBOBOX: "combobox",
  COMBOBOX_MULTI: "combobox_multi",
  NUMBER: "number",
  AUTOCOMPLETE: "autocomplete",
  EMAIL: "email",
  FILE: "file",
  TEXTAREA: "textarea",
  RADIO: "radio",
  SUB_MENU: "sub_menu",
  BTN_GROUP: "btn-group",
  SEARCH: "search",
  PASSWORD: "password",
  LABEL: "label",
  ICON: "icon",
  CKEDITOR: "ckeditor",
};

export const STATUS_USER = {
  TYPE: "STATUS_USER",
  ACTIVE: { VALUE: "active", LABEL: "Hoạt động" },
  IN_ACTIVE: { VALUE: "inactive", LABEL: "Đã khóa" },
  DELETED: { VALUE: "deleted", LABEL: "Đã xóa" },
};

export const RESPONSE = {
  NOT_FOUND_ID: "not_found_id",
};

export const STATUS = {
  ACTIVE: { VALUE: "active", LABEL: "Hoạt động" },
  DELETED: { VALUE: "deleted", LABEL: "Đã xóa" },
};

export const DM_CHUNG = {
  CONG_VIEC: "CONG_VIEC",
  TRANG_THAI_NGUOI_DUNG: "STATUS_USER",
};

export const CHON_ALL = { label: "Chọn tất cả", value: "" };

export const POI_CATEGORIES = {
  fire_station: "Trạm cứu hỏa",
  car_station: "Trạm xe",
  animal_shelter: "Trại nuôi động vật",
  attraction: "Điểm thu hút",
  monument: "Tượng đài",
  farmyard: "Sân trại",
  theatre: "Rạp hát",
  church: "Nhà thờ",
  prison: "Nhà tù",
  warehouse: "Kho",
  police: "Cảnh sát",
  park: "Công viên",
  kindergarten: "Trường mẫu giáo",
  sports_centre: "Trung tâm thể thao",
  manor: "Lâu đài",
  greenhouse_horticulture: "Nhà kính nông nghiệp",
  cemetery: "Nghĩa trang",
  museum: "Bảo tàng",
  commune_hall: "Nhà hội đồng",
  industrial: "Khu công nghiệp",
  hotel: "Khách sạn",
  ranger_station: "Trạm kiểm lâm",
  supermarket: "Siêu thị",
  stadium: "Sân vận động",
  clinic: "Phòng khám",
  community_centre: "Trung tâm cộng đồng",
  quarry: "Mỏ đá",
  district_hall: "Nhà hội quận",
  hospital: "Bệnh viện",
  railway_station: "Nhà ga đường sắt",
  marketplace: "Chợ",
  station: "Trạm",
  fuel: "Nhiên liệu",
  college: "Trường đại học",
  townhall: "Thị trấn",
  school: "Trường học",
  military: "Quân đội",
  government: "Chính phủ",
  place_of_worship: "Nơi tôn giáo",
  landfill: "Bãi chôn lấp",
  bank: "Ngân hàng",
  cinema: "Rạp chiếu phim",
  courthouse: "Tòa án",
  post_office: "Bưu điện",
};
