import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { BehaviorSubject, Subscription, firstValueFrom, fromEvent } from "rxjs";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MAP_TREE_LAYERS_DATA } from "src/app/contants/map_layers";
import { MapService } from "src/app/core/services/map.service";
import mapboxgl from "mapbox-gl";

@Component({
  selector: "app-qhtree-layer",
  templateUrl: "./qhtree-layer.component.html",
  styleUrls: ["./qhtree-layer.component.scss"],
})
export class QHTreeLayerComponent implements OnInit {
  @Input() isQH: boolean = false;
  @Input() inputDataList: any[] = [];
  @Input() inputDataList1: any[] = [];
  @Input() layerVisibleChanges: any[];
  @Output() selectEvent = new EventEmitter<any>();
  @Output() selectGroupType = new EventEmitter<any>();
  @Output() selectGroupEvent = new EventEmitter<any>();
  @Output() selectLayerChange = new EventEmitter<any>();
  @Output() changeLayerOpacity = new EventEmitter<any>();
  @Output() fitLayer = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  dataList: any[] = [];
  searchList: any[] = [];
  selectedItem: any = null;
  selectText: string = "";
  textSearch: string = "";
  showResult: boolean = false;
  subClickOutside: Subscription | undefined;
  subClickAllUnit: Subscription | undefined;
  layerId: string = "";
  isExpandAll: boolean = true;
  checkQHC: boolean = true;
  checkQHPK: boolean = false;
  checkQCT: boolean = false;
  @ViewChild("selectContainer") refMultipleSelectContainer:
    | ElementRef
    | undefined;

  constructor(private mapService: MapService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if ("layerVisibleChanges" in changes) {
      // console.log('layer visible cap nhat lai', this.layerVisibleChanges)
      // let chng = changes.layerVisibleChanges;
      // let cur = JSON.stringify(chng.currentValue);
      // let prev = JSON.stringify(chng.previousValue);
      // if (cur !== prev) {
      this.updateTreeData();
      // }
    }
  }

  ngOnInit(): void {
    if (window) {
      this.subClickOutside = fromEvent(window, "click").subscribe((res) => {
        this.checkOnClickOutside(this.refMultipleSelectContainer, res);
      });
    }
    // this.inputDataList = MAP_TREE_LAYERS_DATA;
    this.dataList = this.inputDataList;
    this.createTree(this.inputDataList);
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   if ("inputDataList" in changes) {
  //     console.log(`inputDataList change`, this.inputDataList)
  //     const inputDataList = this.inputDataList
  //     this.createTree(inputDataList);
  //   }
  // }
  ngOnDestroy() {
    if (this.subClickOutside && !this.subClickOutside.closed)
      this.subClickOutside.unsubscribe();
    if (this.subClickAllUnit && !this.subClickAllUnit.closed)
      this.subClickAllUnit.unsubscribe();
  }

  createTree(dataList: any[]) {
    this.dataList = dataList;
    this.dataList = this.flatTreeData(
      this.dataList,
      [],
      1,
      this.dataList[0].parentIds
    );
  }

  updateTreeData() {
    this.dataList = this.dataList.map((item) => {
      if (this.layerVisibleChanges.includes(item.id)) item.checked = true;
      else item.checked = false;
      return item;
    });
  }

  checkOnClickOutside(elementRef: ElementRef | undefined, event: Event) {
    if (!elementRef) return;
    if (
      !elementRef!.nativeElement.contains(event.target) &&
      this.isVisible(elementRef!)
    ) {
      this.updateStatusShowResultList(false);
    }
  }

  updateStatusShowResultList(value: boolean) {
    this.showResult = value;
  }

  isVisible(elementRef: ElementRef): boolean {
    const elem = elementRef.nativeElement;
    return (
      !!elem &&
      !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
    );
  }

  addChildrenFieldToObject(dataList: any[]) {
    for (let i = 0; i < dataList.length; i++) {
      const unit = dataList[i];
      unit.children = dataList.filter((item) => item.parentId === unit.id);
      unit.show = true;
      unit.expanded = true;
    }
  }

  orderListByUnitLevel(dataList: any[]): any[] {
    return dataList.sort((a, b) => a.level - b.level);
  }

  flatTreeData(arr: any, result: any[], level: any, parentIds: string): any[] {
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      element.level = level;
      element.expanded = true;
      element.show = true;
      element.checked = element.defaultVisible;
      element.parentIds = parentIds ? parentIds + "_" + element.id : element.id;
      const hasInResult =
        result.findIndex((item) => item.id === element.id) > -1;
      if (hasInResult) continue;
      result.push({ ...element });
      if (element.children && element.children.length) {
        const descendantLevel = level + 1;
        this.flatTreeData(
          element.children,
          result,
          descendantLevel,
          element.parentIds
        );
      }
    }
    return result;
  }

  toggleRow(event: any, row: any, isExpand: any, rowIndex: any) {
    event.stopPropagation();
    this.dataList[rowIndex].expanded = isExpand;
    for (let i = rowIndex + 1; i < this.dataList.length; i++) {
      const item = this.dataList[i];
      if (item.parentIds.startsWith(row.parentIds + "_")) {
        if (item.level === row.level + 1) item.show = isExpand;
        else {
          const parent = this.dataList.find((ele) => ele.id === item.parentId);
          item.show = parent.show && parent.expanded;
        }
      }
    }
  }

  toggleCollaspeEnpandAll() {
    if (this.isExpandAll) this.collapseAll();
    else this.expandAll();
    this.isExpandAll = !this.isExpandAll;
  }
  collapseAll() {
    this.dataList = this.dataList.map((item) => {
      item.expanded = false;
      if (!item.isGroupLayer) item.show = false;
      return item;
    });
  }
  expandAll() {
    this.dataList = this.dataList.map((item) => {
      item.expanded = true;
      item.show = true;
      return item;
    });
  }

  trackFunc(index: any, item: any): string {
    return index; //item.id;
  }

  checkGroup(event: any) {
    const { checked, group } = event;
    this.selectGroupEvent.emit({ checked: checked, group: group });
  }

  checkGroupType(event: any) {
    const { checked, group } = event;
    // console.log("check gtype change", event);
    this.selectGroupType.emit({ checked: checked, group: group });
  }

  checkRow(event: any) {
    const { checked, layerId } = event;
    this.selectEvent.emit({ checked: checked, layerId: layerId });
  }
  clickInputText() {
    this.showResult = !this.showResult;
  }

  resetValue() {
    this.selectText = "";
    for (let i = 0; i < this.dataList.length; i++) {
      const element = this.dataList[i];
      element.checked = false;
    }
    this.selectEvent.emit([]);
  }

  outSelectedUnit() {
    const selectedList = this.dataList.reduce((acc, cur) => {
      cur.checked && acc.push(cur);
      return acc;
    }, []);
    this.selectEvent.emit(selectedList);
  }
  changeTextSearch(text: string) {
    this.searchList = this.dataList.filter((item) =>
      item.unitName.includes(text.trim())
    );
  }
  clearTextSearch() {
    this.textSearch = "";
    this.searchList = [];
  }
  closeWidget() {
    this.close.emit(true);
  }
  async onChangeOpacity(evt) {
    const { layerid, opacity } = evt;
    // const prevOpacity$ = this.mapService.getLayersOpacity$();
    // const prevOpacity = await firstValueFrom(prevOpacity$);
    // const updateOpacity = { ...prevOpacity };
    // updateOpacity[layerInfo.id] = evt.value;
    // this.mapService.setLayersOpacity$(updateOpacity);
    this.changeLayerOpacity.emit({ layerid: layerid, opacity: opacity });
  }

  zoomToLayer(row) {
    this.mapService.getBound(row.layer).subscribe((rs) => {
      if (rs && rs.code === 1) {
        const boxArray = rs.message.split(",");
        var southwest = new mapboxgl.LngLat(boxArray[0], boxArray[1]); // Tây Nam
        var northeast = new mapboxgl.LngLat(boxArray[2], boxArray[3]); // Đông Bắc
        var bounds = new mapboxgl.LngLatBounds(southwest, northeast);
        this.fitLayer.emit(bounds);
      }
    });
  }

  changeLayerOrder(type, row) {
    console.log("change layer order");
  }
}
