import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import * as _ from 'lodash';
import { MAP_LAYERS_ID } from 'src/app/contants';
import { MapService } from 'src/app/core/services/map.service';
import { API } from 'src/app/contants/ApiContants';
@Component({
  selector: 'app-quy-hoach',
  templateUrl: './quy-hoach.component.html',
  styleUrls: ['./quy-hoach.component.scss']
})
export class QuyHoachComponent implements OnInit, OnChanges {
  @Input() doAnQHInfo: any;
  @Output() fitPolygon = new EventEmitter<any>();
  dsVanBan: any[] = [];
  constructor(private mapService: MapService) { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if ("doAnQHInfo" in changes) {
      if (this.doAnQHInfo) {
        console.log('doAnQHInfo', this.doAnQHInfo)
        this.getThongTinVanBanPhapLy(this.doAnQHInfo.daqh_mahoso)
      }
    }
  }
  getThongTinVanBanPhapLy(maHSQH) {
    const searchChucNangParams = [
      {
        "field": 'MaHoSo',
        "operato": "=",
        "join": "and",
        "value": "\'" + maHSQH + "\'"
      }
    ]
    this.mapService.searchTable(MAP_LAYERS_ID.VanBanPhapLy, searchChucNangParams, false).subscribe((rs: any) => {
      console.log('rsss search hspl', rs);
      this.dsVanBan = rs;
    })
    // this.mapService.getThongTinQuyHoach(
    //   MAP_LAYERS_ID.VanBanPhapLy,
    //   "mahoso",
    //   maHSQH,
    //   false
    // )
    //   .subscribe((rs) => {
    //     let kInfo = {};
    //     if (rs && rs.dsThuocTinh && rs.dsThuocTinh.length) {
    //       rs.dsThuocTinh.forEach((kvp) => {
    //         kInfo = {
    //           ...kInfo,
    //           // [kvp.Key]: kvp.Value.toString()
    //           [kvp.Key]: this.isEmpty(kvp.Value) ? "" : kvp.Value.toString(),
    //         };
    //       });
    //       console.log("van ban phap ly ìno", kInfo);
    //       this.doAnQHInfo = _.isEmpty(kInfo) ? null : kInfo;
    //     }
    //   });
  }
  openQuyetDinh(vanban) {
    // var url = 'http://45.118.146.215:99/map/0-2024%20QD%20255%20QHCT%20Khu%20trung%20tam%20hanh%20chinh%20Sa%20Pa.pdf';
    console.log('vanban', vanban)
    // var url = vanban.DuongDanFile;
    var url = API.API_GEODATA + "/hoso/getfilehosostream?filepath=" + vanban.DuongDanFile;
    // window.open(url, '_blank').focus();
    window.open(url, '_blank');
  }
  hilightArea() {
    console.log('hhh area', this.doAnQHInfo)
    if (this.doAnQHInfo && this.doAnQHInfo.geom) {
      const geostr = this.doAnQHInfo.geom;
      if (geostr) {
        const geom: any = wellknown.parse(geostr);
        if (geom.type === 'MultiPolygon') {
          var mpolygon = turf.multiPolygon(geom.coordinates)
          this.fitPolygon.emit(mpolygon);
        } else {
          var polygon = turf.polygon(geom.coordinates)
          this.fitPolygon.emit(polygon);
        }
      }
    }
  }
  isEmpty(obj) {
    if (typeof obj === "object") {
      return Object.keys(obj).length === 0;
    } else return false;
  }
}
