import { Component, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import * as Constants from "../../../Constants/Constants"
import { ConverterService } from "../../../ServiceCommon/converter.service"
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AlertService } from "../../../ServiceCommon/alert.service"
import { FormControl } from '@angular/forms';
import FullEditor from 'ckeditor5-build-full'
@Component({
  selector: 'app-gen-html-form',
  templateUrl: './gen-html-form.component.html',
  styleUrls: ['./gen-html-form.component.scss']
})
export class GenHtmlFormComponent implements AfterViewInit {

  @Output() genHtmlFormComponentEmitter = new EventEmitter();
  public configsss = FullEditor;
  columns: any = [];
  dataTypeArray: any = Constants.DATA_TYPE_ARRAY;
  dataTypeObject: any = Constants.DATA_TYPE_OBJECT;
  error: any = false;
  maxstartDate: any = new Date(new Date().getFullYear(), 11, 31);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private converterService: ConverterService,
    private alertService: AlertService
  ) { }
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }


  async init(columns: any) {
    for (let index = 0; index < columns.length; index++) {
      var column = columns[index];
      if (column.datatype == Constants.DATA_TYPE_OBJECT.COMBOBOX_MULTI) {
        column["itemsControl"] = new FormControl([]);
      }
      if (column.itemsControl != undefined)
        column.itemsControl.valueChanges.subscribe((values: any) => {

        });
    }

    this.error = false;
    this.convertAllDate(columns).then(columns => {
      this.columns = columns;
    })

  }

  async onSelectChangeCombobox(e: any, column: any) {

    //  await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  onCkEditorChange(e: any, column: any) {
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }


  async onSelectChangeComboboxMulti(event: any, column: any) {
    column.value = event.value;
    if (column.value.indexOf("") >= 0)
      column.value = [""];
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  async onInputChange(column: any) {
    //await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  async onRadioChange(value: any, column: any) {
    column.value = value;
    // await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error })
  }

  async onFileChange(event: any, column: any) {
    // column.value = event.target.files[0];
    column.value = event.target.files;
    //// await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  async onDateChange(event: MatDatepickerInputEvent<Date>, column: any) {
    column.value = event;
    //  await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  async onCheckBoxChange(event: any, column: any) {
    column.value = event.target.checked;
    //  await this.setDefaultIfNull();
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }

  async autocompleteComponentEmiter(event, column) {
    console.log(event)
    console.log(column)
    column.value = event
    this.genHtmlFormComponentEmitter.emit({ columns: this.columns, column: column, error: this.error });
  }


  //neu co loi tra ve true
  async validate(): Promise<any> {
    return new Promise((resolve) => {
      var EMPTY = "empty";
      this.error = false;
      for (let index = 0; index < this.columns.length; index++) {
        var column = this.columns[index];
        column[EMPTY] = false;
        if (column.required && !column.disable) {

          if (column.datatype == Constants.DATA_TYPE_OBJECT.NUMBER &&
            (column.value == null || column.value == undefined || column.value == 0)) {
            column[EMPTY] = true;
            this.error = true;
          }
          else if (column.datatype == Constants.DATA_TYPE_OBJECT.CHECKBOX
            || column.datatype == Constants.DATA_TYPE_OBJECT.RADIO) {

          }
          else if (column.datatype == Constants.DATA_TYPE_OBJECT.DATE &&
            (column.value == null || column.value == undefined || column.value == 0)) {
            column[EMPTY] = true;
            this.error = true;
          }
          else {
            if (column.value == null || column.value == undefined || column.value.length == 0) {
              column[EMPTY] = true;
              this.error = true;
            }
          }

        }
      }

      console.log(this.error);
      if (this.error == true)
        this.alertService.error(Constants.Messages.DEFAULT_MESSAGE_REQUIRE_SAVE);
      // console.log(this.columns);

      resolve(this.error);
    })
  }


  async setDefaultIfNull() {
    for (let index = 0; index < this.columns.length; index++) {
      var column = this.columns[index];
      if (column.value == undefined) {
        if (column.datatype == Constants.DATA_TYPE_OBJECT.NUMBER)
          column.value = 0;
        else if (column.datatype == Constants.DATA_TYPE_OBJECT.DATE)
          column.value = 0;
        else if (column.datatype == Constants.DATA_TYPE_OBJECT.CHECKBOX)
          column.value = false;
        else if (column.datatype == Constants.DATA_TYPE_OBJECT.RADIO)
          column.value = false;
        else
          column.value = "";
      }
    }
    return this.columns;
  }

  async convertAllDate(columns: any) {
    for (let index = 0; index < columns.length; index++) {

      var column = columns[index];
      if (column.datatype == Constants.DATA_TYPE_OBJECT.DATE) {
        if (column.value > 0) {
          column.value = this.converterService.timestampToDate(column.value);
        }
      }

    }
    return columns;
  }

  async reset() {
    for (let index = 0; index < this.columns.length; index++) {
      var column = this.columns[index];
      if (column.datatype == Constants.DATA_TYPE_OBJECT.NUMBER)
        column.value = 0;
      else if (column.datatype == Constants.DATA_TYPE_OBJECT.DATE)
        column.value = 0;
      else if (column.datatype == Constants.DATA_TYPE_OBJECT.CHECKBOX)
        column.value = false;
      else if (column.datatype == Constants.DATA_TYPE_OBJECT.RADIO)
        column.value = false;
      else
        column.value = "";
    }
  }

  getFomValues() {
    let formValues: any = {}
    for (let index = 0; index < this.columns.length; index++) {
      var column = this.columns[index];
      formValues[column.name] = column.value;
    }
    return formValues;
  }
  setValue(name, value) {
    const colInfo = this.columns.find(item => item.name == name)
    if (colInfo) {
      colInfo.value = value
    }
  }
  updateData(name, data) {
    const colInfo = this.columns.find(item => item.name == name)
    if (colInfo) {
      colInfo.data = data
    }
  }
}
