<div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <!-- <app-sidebar [isCondensed]="isCondensed"></app-sidebar> -->

  <div class="admin-content h-100">
    <div class="page-content pb-0 px-0 content-scrollZ">
      <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
