<!-- vertical layout -->
<!-- <app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical> -->

<!-- horizontal layout -->
<!-- <app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal> -->
<div class="main">
  <app-header class="header" *ngIf="!isMobileScreen"></app-header>
  <div class="body_container">
    <router-outlet></router-outlet>
  </div>
</div>
