<div class="row mt-2">
    <div class="col-md-12 align-self-center">
      <label class="form-check-label">
        Liện kết điều kiện lọc:
      </label>
      <button type="button" class="btn btn-sm btn-light bg-white ms-3" (click)="addCondition()">
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="col-md-12">
      <form>
        <div *ngFor="let row of data; let i = index" class="row mt-2">
          <div class="col-md-6 pe-1">
            <ng-select [notFoundText]="'Không có dữ liệu'" [items]="optionsFilter" [multiple]="false" bindLabel="alias"
              bindValue="colname" (change)="changeSource($event, row, i)" [(ngModel)]="row.source" name="{{i}}-source">
              <ng-template ng-option-tmp let-item="item"> <!-- Template for option display -->
                {{ item.alias }} - ({{ item.colname }})
              </ng-template>
            </ng-select>
          </div>
          <div class="col-md-5 pe-1">
            <ng-select [notFoundText]="'Không có dữ liệu'" [items]="optionFilterGlobal" [multiple]="false" bindLabel="name"
              bindValue="value" (change)="changeTarget($event, row, i)" [(ngModel)]="row.target" name="{{i}}-target">
              <ng-template ng-option-tmp let-item="item"> <!-- Template for option display -->
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
          <div class="col-md-1 align-self-center px-0 ps-2">
            <i class="fa-solid fa-xmark text-danger font-size-14 cursor-pointer ms-0 "
              (click)="removeCondition(row, i)"></i>
          </div>
        </div>
      </form>
    </div>
  </div>