import { Component, Input, AfterViewInit,ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pagetitle1',
  templateUrl: './pagetitle1.component.html',
  styleUrls: ['./pagetitle1.component.scss']
})
export class Pagetitle1Component implements AfterViewInit {
 
  @Input() title: string;
  @Input() breadcrumbItems:any =[];
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  
  ngAfterViewInit(): void {
    console.log(this.title);
    this.changeDetectorRef.detectChanges()
  }

}
