import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { conditions } from 'src/app/contants';

@Component({
  selector: 'app-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss']
})
export class FormFilterComponent implements OnInit {
  @Input() filterConditions: any[] = [];
  @Input() optionsFilter: any = [];
  @Output() onAction = new EventEmitter<any>();
  conditions: any[] = conditions;
  constructor() { }

  ngOnInit(): void {
  }

  addCondition() {
    this.filterConditions.push({
      "operator": "equal",
      "value": "",
      "column": "",
      "between1": {},
      "between2": {}
    })
  }

  pushData(){
    let obj = {
      key: "set_data_filter",
      value: this.filterConditions
    }
    this.onAction.emit(obj);
  }

  changeNgSelect(event, item, index) {
    this.filterConditions[index] = {
      ...this.filterConditions[index],
      column: event.colname,
    }
    this.pushData();
  }

  changeOperator(event, index){
    this.filterConditions[index] = {
      ...this.filterConditions[index],
      operator: event.target.value,
    }
    this.pushData();
  }

  changeCondition(event, item, index, type) {
    this.filterConditions[index] = {
      ...this.filterConditions[index],
      [type]: event.target.value
    }
    this.pushData();
  }

  removeCondition(item, index) {
    this.filterConditions.splice(index, 1);
    this.pushData();
  }
}
