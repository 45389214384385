import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { WORKSPACE } from "../../contants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class ArcgisService {

  constructor(private httpRequest: ConfigService) {

  }

  getFolderArcgis() {
    return fetch(`${API.API_ARCGIS}?f=pjson`)
      .then(response => response.json());
  }

  getAllLayerbyFolder(folder) {
    return fetch(`${API.API_ARCGIS}/${folder}?f=pjson`)
      .then(response => response.json());
  }
  
  getAlldataFeature(feature) {
    return fetch(`${API.API_ARCGIS}/${feature}/FeatureServer/0/query?where=1=1&outFields=*&f=pjson`)
      .then(response => response.json());
  }

  getAllConfigFeature(feature) {
    return fetch(`${API.API_ARCGIS}/${feature}/FeatureServer/0?f=pjson`)
      .then(response => response.json());
  }

  filterDataFeature(feature, query){
    return fetch(`${API.API_ARCGIS}/${feature}/FeatureServer/0/query?where=${query}&outFields=*&f=pjson`)
    .then(response => response.json());
  }
}