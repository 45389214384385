import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  @Output() onAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  handleBreadCrumd(item, idx){
    let obj = {
      key: "click_item_breadCrum",
      value: {
        ...item,
      },
      idx
    }
    this.onAction.emit(obj);
  }
}
