import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StorageService } from 'src/app/quan-tri/ServiceCommon/storage.service';
import * as Constants from './../../../../quan-tri/Constants/Constants'
@Component({
  selector: 'app-left-map-control',
  templateUrl: './left-map-control.component.html',
  styleUrls: ['./left-map-control.component.scss']
})
export class LeftMapControlComponent implements OnInit, OnChanges {
  @Input() mapMode: string;
  @Output() toggleShowAdministrative = new EventEmitter<any>();
  @Output() toggleShowSearch = new EventEmitter<any>();
  @Output() activeInfoFeature = new EventEmitter<any>();
  @Output() toogleShowLayers = new EventEmitter<any>();
  @Output() toogleShowXY = new EventEmitter<any>();
  @Output() toogleShowLegend = new EventEmitter<any>();

  isLoggedIn: boolean = false;
  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    console.log('init contrl', this.mapMode)
    const self = this;
    self.storageService.get(Constants.AUTH_TOKEN).then(token => {
      console.log('tttt', token);
      if (token) {
        this.isLoggedIn = true;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    let textChange = changes.text;
    console.log('changes', changes)
  }
  showAdministrative() {
    this.toggleShowAdministrative.emit()
  }
  showSearch() {
    this.toggleShowSearch.emit()
  }
  activeInfo() {
    this.activeInfoFeature.emit()
  }

  showLayers() {
    this.toogleShowLayers.emit()
  }

  showLegend() {
    this.toogleShowLegend.emit()
  }

}
