import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { OptionSelect } from "src/app/model/form";
import { CatalogService } from 'src/app/core/services/catalog.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-map',
  templateUrl: './filter-map.component.html',
  styleUrls: ['./filter-map.component.scss']
})
export class FilterMapComponent implements OnInit {
  @Input() data: any;
  @Output() onAction = new EventEmitter<any>();
  isChoiceSpace: boolean = false;
  symbol: string[] = [];
  configFilter: any = [];
  options: OptionSelect[] = [];
  villages: any = [];
  listDDL: any = [];
  ddlroot: any = [];
  filterForm: UntypedFormGroup;
  submitted = false;

  constructor(private catalogService: CatalogService, private formBuilder: UntypedFormBuilder) {

   }
 
  ngOnInit(): void {
    this.configFilter = this.data?.filter?.in || [];
    this.getListDDL();
    let configBuilder = {};

    this.configFilter.forEach(element => {
      configBuilder = {
        ...configBuilder,
        [element.col || element.name]: [],
      }
    });

    this.filterForm = this.formBuilder.group(configBuilder);
  }

  get valFilter() { return this.filterForm.controls; }

  getListDDL() {
    this.catalogService.getDataDDL(this.data.tbkey).subscribe((res) => {
      if (res["status"] === 200) {
        this.ddlroot = _.cloneDeep(res["data"]);
        this.listDDL = _.cloneDeep(res["data"]);
      }
    })
  }

  checkedSpace(ev) {
    ev.preventDefault();
    this.isChoiceSpace = ev.checked;
  }

  onSelect(event) {
    switch (event.key) {
      case "element_select":
        if (event.value.code === "mahuyen") {
          this.villages = event.value.option?.options?.children || [];
        }
        break;

    }
    console.log("-----------event-----------", event);
  }

  getOptions(key) {
    switch (key) {
      case "maxa":
        return this.options = this.villages.map((item) => {
          return {
            value: item.id,
            label: item.data,
            option: item
          }
        })
      default: return [];
    }
  }
  handleChangeDropdown(val) {
    this.symbol = val;
  }
  ngAfterViewInit() {
  }

  drawShape(type) {
    let obj = {
      key: "draw_shape",
      value: type
    }
    this.onAction.emit(obj);
  }
  onChangeSelect(ev, key: string) {
    switch (key) {
      case "mahuyen":
        let lstMaxa = [...this.ddlroot.maxa];
        this.listDDL.maxa = lstMaxa.filter(item => item.parent_value === ev.target.value);
        break;
      default: break;
    }
    console.log("ev", ev.target.value);

  }
  converSymbolToText(symbol = '=', value){
    if(value === "") return "";
    switch(symbol){
      case "=":
        return `EQUAL|${value}`;
      case "<":
        return `LESS|${value}`;
      case ">":
        return `GREATER|${value}`;
      case "[]":
        return `BETWEEN|${value}`;
      default: return ""
    }
  }

  renderPlaceHolder(symbol='='){
    switch(symbol){
      case "=":
        return "Nhập giá trị bằng";
      case "<":
        return "Nhập giá trị nhỏ hơn";
      case ">":
        return "Nhập giá trị lớn hơn";
      case "[]":
        return "Nhập giá trị trong khoảng (ví dụ 1:5)";
      default: return "Nhập giá trị";
    }
  }

  onSubmit(ev) {
    this.submitted = true;
    if (this.filterForm.invalid) {
      return;
    }
    
    let filter_values = {}
    this.configFilter.forEach((element, idx) => {
      if (element.kieu === "number" || element.type === 'esriFieldTypeInteger' || element.type === 'esriFieldTypeDouble') {
        filter_values = {
          ...filter_values,
          [element.col || element.name]: this.converSymbolToText(this.symbol[idx], this.valFilter[element.col || element.name].value || '')
        }
      } else {
        filter_values = {
          ...filter_values,
          [element.col || element.name]: this.valFilter[element.col || element.name].value || ''
        }
      }
    });

    console.log("=======filterForm=========", filter_values);

    let obj = {
      key: "submit_filter",
      value: filter_values
    }
    this.onAction.emit(obj);
  }
}
