import { Injectable, NgModule } from '@angular/core';
import { HttpService } from "../../../ServiceCommon/http.service"
import {API}  from "../../../../contants/ApiContants"
import { CommonService } from "../../../ServiceCommon/common.service"
import { StorageService } from "../../../ServiceCommon/storage.service"
import { CommonModule, Location } from '@angular/common';
import { ConverterService } from "../../../ServiceCommon/converter.service"
import * as Constants from "../../../Constants/Constants"
@Injectable({
  providedIn: 'root'
})
export class AuditServiceService {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private storageService: StorageService,
    private location: Location,
    private converterService: ConverterService
  ) {

  }

  async getAllAudit(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/audit/getAllAudit", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }
  async getTotalCount(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/audit/getTotalAuditCount", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  async insertLog(tenChucNang: any, moTaChucNang: any, maHanhDong: any, tenHanhDong: any, idBanGhi: any,path:any) {
    this.storageService.get(Constants.CACHE_USER).then(userName => {

      this.storageService.get(Constants.CACHE_MENU).then(menuStr => {

        console.log(menuStr);
       

        this.commonService.randomUUID().then(id =>{

          var para = {
           // id: id,
            _date_created: this.converterService.dateToTimestamp(new Date()),
            username: userName,
            func_name: tenChucNang,
            func_des: moTaChucNang,
            action_code: maHanhDong,
            action_name: tenHanhDong,
            record_id: idBanGhi,
            path:path
          }
          console.log(para);

         
         this.httpService.postJsonToken(API.API_MANAGER_DATA + "/audit/insertAudit", para)
              
         

        })
        

      })





    })

  }
}
