import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import * as Constants from '../../quan-tri/Constants/Constants';

// import "@angular/material/prebuilt-themes/indigo-pink.css"
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children?: TodoItemNode[];
  data?: any;
  code?: string;
  name?: string;
  icon?: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  code?: string;
  data?: any;
  name?: string;
  level?: number;
  expandable?: boolean;
  icon?: string;
  children?: any;
}

/**
 * The Json object for to-do list data.
 */

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    // const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    // this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    console.log("------obj----", obj)

    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.code = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.code = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ code: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.code = name;
    this.dataChange.next(this.data);
  }
}

/**
 * @title Tree with checkboxes
 */


@Component({
  selector: 'app-tree-view-folder',
  templateUrl: './tree-view-folder.component.html',
  styleUrls: ['./tree-view-folder.component.scss'],
  providers: [ChecklistDatabase],
})
export class TreeViewFolderComponent implements OnInit {
  buttons: any = Constants.BUTTONS;
  @Input() dataTree = [];
  @Output() onAction = new EventEmitter<any>();
  @Input() keyActive: string = "";


  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  @ViewChild('tree') tree;
  isFresh:boolean = false;

  constructor(private _database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    
    // _database.dataChange.subscribe(data => {
    //   let fakedata: TodoItemNode[] = this.dataTree;
    //   console.log("-------data----------", data);
    //   console.log("-------dataTree----------", this.dataTree);
    //   this.dataSource.data = fakedata;
    // });
  }

  setDataTree(){
  }

  ngOnChanges(event){
    if(event.dataTree && event.dataTree.currentValue && event.dataTree.currentValue != event.dataTree.currentValue.previousValue){
      console.log("--------dataTree-------", this.dataTree);
      this._database.dataChange.subscribe(data => {
        let dataTree: TodoItemNode[] = this.dataTree;
        this.dataSource.data = dataTree;
        this.treeControl.dataNodes.forEach(element => {
          if(element.data.checked && element.data.type === 'layer'){
            let obj = {
              key: "click_node",
              value: {
                ...element,
                checked: !this.checklistSelection.isSelected(element)
              }
            }
            this.onAction.emit(obj);
            this.checklistSelection.toggle(element);
          }
        });
      });
    }
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.code === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.code === node.code ? existingNode : new TodoItemFlatNode();
    flatNode.code = node.code;
    flatNode.name = node.name;
    flatNode.data = node.data;
    flatNode.children = node.children;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.icon = node.icon;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngOnInit(): void {

  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    node.data.checked = !this.checklistSelection.isSelected(node);
    let obj = {
      key: "click_node",
      value: {
        ...node,
        checked: !this.checklistSelection.isSelected(node)
      }
    }

    this.onAction.emit(obj);
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(element => element.data.checked = true);
    console.log("-----descendants------", descendants);

    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    node.data.checked = !this.checklistSelection.isSelected(node);
    let obj = {
      key: "click_node",
      value: {
        ...node,
        checked: !this.checklistSelection.isSelected(node)
      }
    }
    this.onAction.emit(obj);
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
  viewTable(node){
    let obj = {
      key: "view_table",
      value: node
    }
    this.onAction.emit(obj);
  }
  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  ngAfterViewInit() {
    this.dataSource.data = this.dataTree || [];
    this.tree.treeControl.expandAll();
    
  }

  zoomTo(node){
    let obj = {
      key: "zoom_to",
      value: {
        ...node,
        checked: this.checklistSelection.isSelected(node)
      }
    }
    this.onAction.emit(obj);
  }

  filter(node){
    let obj = {
      key: "filter",
      value: {
        ...node,
        checked: this.checklistSelection.isSelected(node)
      }
    }
    this.onAction.emit(obj);
  }

  clickNode(node){
    let obj = {
      key: "click_node",
      value: {
        ...node,
      }
    }
    this.onAction.emit(obj);
  }

  createFolder(node, type){
    let obj = {
      key: "create_folder",
      node,
      type
    }
    this.onAction.emit(obj);
  }

  deleteFolder(node){
    let obj = {
      key: "delete_folder",
      node,
    }
    this.onAction.emit(obj);
  }

  renameFolder(node){
    let obj = {
      key: "rename_folder",
      node,
    }
    this.onAction.emit(obj);
  }
}