import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
  @Input() startYear: number;
  @Input() endYear: number;
  @Output() dateSelected = new EventEmitter<NgbDateStruct>();
  @Output() yearSelected = new EventEmitter<number>();

  selectedYear: number;
  selectedDate: NgbDateStruct;

  years: number[] = [];

  constructor() { }

  ngOnInit() {
    this.generateYearRange();
  }

  generateYearRange() {
    for (let year = this.startYear; year <= this.endYear; year++) {
      this.years.push(year);
    }
  }

  selectYear(year: number) {
    this.selectedYear = year;
    this.yearSelected.emit(year);
  }

  onDateSelected(date: NgbDateStruct) {
    this.selectedDate = date;
    this.dateSelected.emit(date);
  }
}