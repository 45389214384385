import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapService } from 'src/app/core/services/map.service';

@Component({
  selector: 'app-base-layer-picker',
  templateUrl: './base-layer-picker.component.html',
  styleUrls: ['./base-layer-picker.component.scss']
})
export class BaseLayerPickerComponent implements OnInit {
  @Input() quyHoachID: string;
  @Output() onClose = new EventEmitter<any>();
  @Output() changeBaseLayer = new EventEmitter<any>();
  featureProps: any[] = [];
  soLuongThua: number = 0;
  dsThua: any[] = [];
  baseLayerId: string = '';
  showThuaDat: boolean = false;
  constructor(
    private mapService: MapService
  ) { }

  changeLayer(evt: any) {
    const layerId = evt.target.id;
    this.mapService.setBaseLayerId$(layerId);
    this.changeBaseLayer.emit(layerId)
  }

  ngOnInit() {
    this.mapService.getBaseLayerId$().subscribe(rs => {
      // console.log('change baselayeid',rs)
      this.baseLayerId = rs;
    })
  }

  close() {
    this.onClose.emit();
  }

  trackFunc(index: any, item: any): string {
    return index;// item.id;
  }

}
