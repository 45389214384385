import { Component, EventEmitter, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';



@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements AfterViewInit {
 
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges()
  }



  @Output() autocompleteComponentEmiter = new EventEmitter();

  keyword: any;
  data: any;// string[] = ['Option 1', 'Option 2', 'Option 3']; 
  placeHolder: any;
  value: any;
  // Observable to handle user input
  typeaheadOptions$: Observable<any> = of([]);

  init(data: any, value: any, keyword: any) {
    console.log(value);
    console.log(keyword);
    this.data = data;
    this.value = value;
    this.keyword = keyword;
  }

  selectEvent(item: any) {
    console.log(item);
    this.autocompleteComponentEmiter.emit({ search: "", data: item });
    // do something with selected item
  }

  onChangeSearch(item:any) {
    console.log(item);
  //  this.autocompleteComponentEmiter.emit({search:item, data:{}});
    // do something with selected item
  }
  onFocused(item: any) {
    console.log(item);
    //this.autocompleteComponentEmiter.emit({search:"", data:item});
    // do something with selected item
  }
  onKeydown(event: any) {
    if (event.key === "Enter") {
      this.autocompleteComponentEmiter.emit({ search: event.target.value, data: {} });
    }
  }

}
