<!-- <p>thong-tin-quy-hoach works!</p> -->
<div class="table-info" cdkDrag>
    <div class="header">
        <h5 style="margin-bottom: 0px;">Danh sách thửa đất dính vùng quy hoạch đã chọn</h5>
        <!-- <button type="button" (click)="close()" class="btn closebtn" style="padding:10px;"> -->
        <div class="btn-group">
            <!-- <button class="btn btn-exp btn-sm" data-toggle="dropdown"><i class="fa fa-bars"></i>
                Xuất dữ liệu</button> -->
            <div style="display:flex;gap:12px;" *ngIf="isLoggedIn">
                <span (click)="tableExport('excel')">
                    <i class="fa fa fa-file-excel fa-lg" style="color:#179568"></i>
                    Xuất excel
                </span>
                <span (click)="tableExport('pdf')">
                    <i class="fa fa-file-pdf fa-lg" style="color:#f57017"></i>
                    Xuất pdf
                </span>
                <!-- <span>
                  
                    <a (click)="tableExport('excel')">
                        Xuất excel</a>
                </span>
                <span>
                    <a (click)="tableExport('word')">
                        <img src="assets/dist/img/word.png" width="24" alt="logo">Xuất word</a>
                </span>

                <span>
                    <a (click)="tableExport('pdf')">
                        <img src="assets/dist/img/pdf.png" width="24" alt="logo">Xuất pdf</a>
                </span> -->
            </div>
        </div>
        <span (click)="close()">
            <i class="fa fa-close fa-lg"></i>
        </span>
        <!-- </button> -->
    </div>
    <hr style="margin:10px; height:1px;background:#ccc;" />
    <div class="analyst-bar" *ngIf="isLoggedIn">
        <h5 style="margin-bottom: 0px;">Phân tích quy hoạch</h5>
        <select class="form-control" [(ngModel)]="selectedQH" style="width:200px;" name="category">
            <option *ngFor="let option of dmQH" value="{{ option.value }}">
                {{ option.name }}
            </option>
        </select>
        <button type="button" class="btn btn-primary" (click)="analyst()">
            <i class="bx bx-smile font-size-16 align-middle me-2"></i> Phân tích
        </button>
    </div>
    <div class="body">
        <!-- <h3> thông tin quy hoạch</h3> -->
        <div *ngIf="currentPageData.length else noData" class="table-responsive">
            <table class="table table-bordered table-striped table-nowrap mb-0">
                <thead>
                    <tr>
                        <th scope="col" style="width:32px;">Vị trí</th>
                        <th *ngFor="let col of tableColumns; let i = index" class="text-center">
                            {{col.alias}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of currentPageData; index as rowIndex; trackBy: trackFunc">
                        <td scope="col" style="width:32px;" class="text-center">
                            <span (click)="locateParcel(row)">
                                <i class="fa fa-map-marker fa-lg" style="color:#179568"></i>
                            </span>
                        </td>
                        <td *ngFor="let col of tableColumns; let i = index" class="text-center">
                            {{row[col.name]}}
                            <!-- {{col.alias}} -->
                        </td>
                        <!-- <td scope="col">{{row.index}}</td>
                        <td scope="col" class="text-center">
                            {{row.soto}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.sothututhu}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.loaidatbd}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.dientich}}
                        </td>
                        <td *ngIf="analyst" scope="col" class="text-center">
                            {{row.t0_mucdichsud}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.tile}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.dientichtrong}}
                        </td>
                        <td scope="col" class="text-center">
                            {{row.dientichngoai}}
                        </td>
                        <td *ngIf="analyst" scope="col" class="text-center">
                            {{row.t1_chucnang}}
                        </td>
                        <td *ngIf="analyst" scope="col" class="text-center">
                            {{row.dientichtrong1}}
                        </td>
                        <td *ngIf="analyst" scope="col" class="text-center">
                            {{row.dientichngoai1}}
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
            <div class="row">
                <app-paginator [pageSize]="pageSize" [total]="total" (nextPage)="nextPage($event)"></app-paginator>
            </div>
        </div>
        <ng-template #noData>
            <div
                style="padding:15px;width:100%;display:flex;justify-content: center;align-items: center; flex-direction: column;gap:24px;">
                <img src="/assets/images/map/no_data.png" />
                <h4>Không có dữ liệu</h4>
            </div>
        </ng-template>
        <!-- </div> -->
    </div>
</div>