import { Component,AfterViewInit,ChangeDetectorRef} from '@angular/core';
import {LoadingService} from "../../../ServiceCommon/loading.service"
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit{
  loading: boolean = false;
  constructor(
    private changeDetectorRef:ChangeDetectorRef,
    private loadingService: LoadingService
  ){}
  ngAfterViewInit(): void {
    this.loadingService.loading$.subscribe((loading) => {
      console.log(loading);
      this.loading = loading;
    });

    this.changeDetectorRef.detectChanges()
  }

    
  
}
