import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-setup-database',
  templateUrl: './setup-database.component.html',
  styleUrls: ['./setup-database.component.scss']
})
export class SetupDatabaseComponent implements OnInit {
  @Input() typeSource;
  @Input() typeConnect;
  @Input() data:any = {};
  @Output() onAction = new EventEmitter<any>();
  hidePass: boolean = true;
  formConfigPostgres: UntypedFormGroup;
  submitted: boolean = false;

  get valFormConfigPostgres() { return this.formConfigPostgres.controls; }

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
    
   }

  ngOnInit(): void {
    this.formConfigPostgres = this.formBuilder.group({
      name: [null, Validators.required],
      host: [null, Validators.required],
      port: [null, Validators.required],
      databaseName: [null, Validators.required],
      username: [null, Validators.required],
      password: [null, this.typeConnect === 'source' ? Validators.required : null],
    })
    console.log("===========zzzzzzzzzzzzzzzzzzz==========", JSON.stringify(this.data));

  }

  ngOnChanges(event) {
    console.log("===========event==========", event);
    if(JSON.stringify(event?.data?.currentValue || {}) != JSON.stringify(event?.data?.previousValue)){
      this.data = _.cloneDeep(event?.data?.currentValue);
      if(this.typeConnect === 'source'){

      }else{
        this.formConfigPostgres.controls.name.patchValue(event?.data?.currentValue?.name);
        this.formConfigPostgres.controls.host.patchValue(event?.data?.currentValue?.connectionConfiguration?.host);
        this.formConfigPostgres.controls.port.patchValue(event?.data?.currentValue?.connectionConfiguration?.port);
        this.formConfigPostgres.controls.databaseName.patchValue(event?.data?.currentValue?.connectionConfiguration?.database);
        this.formConfigPostgres.controls.username.patchValue(event?.data?.currentValue?.connectionConfiguration?.username);
      }
    }
  }

  onSubmit(){
    this.submitted = true;
    if(this.formConfigPostgres.invalid){
      return;
    }
    let obj = {
      key:"submit_create_source",
      value: this.valFormConfigPostgres
    }
    this.onAction.emit(obj)
  }

}
