import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class MinioService {

    constructor(private httpRequest: ConfigService) {

    }

    uploadFile(file, bucket = "path", filename = "path"){
      return this.httpRequest.post(`${API.BASE_URL}/files-managers/upload/${bucket}/${filename}`, file);
    }


}
