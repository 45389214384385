<div class="info-container" style="position: relative; background: rgb(245, 245, 245);">
        <div style="display:flex;align-items: center;gap:16px;width:100%;">
                <a href="javascript:;" (click)="hilightArea()"
                        class="btn btn-icon btn-outline-success btn-circle active"
                        style="display:flex;justify-content: center;align-items: center;width:32px;height:32px;">
                        <i class="fas fa-map-marker-alt" style="font-size: 1rem;"></i></a>
                <h4 class="text-dark font-weight-bold" style="margin-bottom: 0px;">Ô QUY HOẠCH:</h4>
        </div>
        <div *ngIf="oQHInfo">
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Ký hiệu:</label>
                        <label class="col-8 pl-1">
                                {{oQHInfo.oqh_kyhieuoqh}}
                        </label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted"><label
                                class="col-4 font-weight-bold">Chức
                                năng:</label><label class="col-8 pl-1">
                                {{oQHInfo.oqh_chucnangsdd}}
                                <span *ngIf="oQHInfo.oqh_chuyendoichucnangsdd==='1'" class="text-info"> (Quy hoạch có thay đổi
                                        so với
                                        hiện
                                        trạng)</span></label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Diệntích:</label>
                        <label class="col-8 pl-1">{{oQHInfo.oqh_dientich_ha}} ha</label>
                </div>
                <!-- <div class="form-group mb-2 row row-paddingless ng-star-inserted"><label class="col-4 font-weight-bold">Mật độ
                        XD:</label><label class="col-8 pl-1">từ 40% đến
                        100%</label></div> -->
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Dân số</label>
                        <label class="col-8 pl-1">{{oQHInfo.oqh_danso}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-5 font-weight-bold">Tầng cao tối thiểu:</label>
                        <label class="col-7 pl-1">{{oQHInfo.oqh_tangcaotoithieu}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-5 font-weight-bold">Tầng cao tối đa:</label>
                        <label class="col-7 pl-1">{{oQHInfo.oqh_tangcaotoida}}</label>
                </div>
                <!-- <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label title="Hệ số sử dụng đất" class="col-4 font-weight-bold">Hệ số SDĐ:</label>
                        <label class="col-8 pl-1"> {{oQHInfo.oqh_hesosdd}}</label>
                </div> -->
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label title="Hệ số sử dụng đất" class="col-4 font-weight-bold">Ghi chú:</label>
                        <label class="col-8 pl-1"> {{oQHInfo.oqh_ghichu}}</label>
                </div>
        </div>
</div>