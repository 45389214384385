import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import wellknown from 'wellknown';
import * as Constants from '../../../../quan-tri/Constants/Constants';

@Component({
  selector: 'app-search-poi',
  templateUrl: './search-poi.component.html',
  styleUrls: ['./search-poi.component.scss']
})
export class SearchPOIComponent implements OnInit, OnChanges {
  @Input() POIResults: any[];
  @Input() showLoading:boolean;
  @Output() onCloseSearch = new EventEmitter<any>();
  @Output() onRadiusChange = new EventEmitter<any>();
  @Output() onPOIClick = new EventEmitter<any>();
  searchResults: any[];
  radius: number = 1000;
  vnWords: any = Constants.POI_CATEGORIES;
  total: number = 0;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if ("POIResults" in changes) {
      if (this.POIResults && this.POIResults.length) {
        const formattedResults = this.POIResults.map(item => {
          return {
            ...item.properties
          }
        })
        this.total = formattedResults.length;
        let grouped_data = _.groupBy(formattedResults, 'class')
        this.searchResults = grouped_data;
      }
    }
  }

  ngOnInit(): void {
  }
  close() {
    this.onCloseSearch.emit(this.radius);
  }
  changeRadius(radius) {
    if (this.radius !== radius) {
      this.radius = radius
      this.onRadiusChange.emit(radius)
    }
  }
  goToPOI(wkt) {
    if (wkt) {
      const geo = wellknown.parse(wkt)
      this.onPOIClick.emit(geo);
    }
  }
}
