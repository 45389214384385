<div class="post-container">
  <div class="modal-header">
    <h2>yêu cầu thanh toán</h2>
    <label>Để khai thác dữ liệu xin vui lòng thanh toán theo quy định</label>
  </div>

  <div class="modal-body">
    <img src="assets/qrcode/main.jpg" width="300" />
  </div>
  <div class="modal-footer">
    <div class="col-sm-12">
      <button type="button" class="btn btn-primary w-md m-b-5" (click)="save()">
        Thanh toán
      </button>
      <button
        type="button"
        class="btn btn-secondary w-md m-b-5"
        (click)="close()"
      >
        Đóng
      </button>
    </div>
  </div>
</div>
<!-- </div> -->
