<div (click)="disableContextMenu()" oncontextmenu="return false;">
  <div class="d-flex">
    <div>
      <i class="fa-sharp fa-solid fa-map-location-dot me-2"></i>
      <span class="fw-bold me-2">{{view?.name}}</span>
      <button class="btn btn-sm btn-light" (click)="openConfigView()">
        <i class="fa-solid fa-gears"></i>
      </button>

    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12 pe-0">
      <div (click)="disableContextMenu()"
        class="cursor-pointer  ngb-panel-disable position-relative w-100 d-flex justify-content-between"
        (click)="isExpend.layer = !isExpend.layer">
        <div class="font-size-14 fw-600" (contextmenu)="onrightclickRoot($event)">
          <span class="text-black"><i class="fa-solid fa-layer-group me-2"></i>
            Layers</span>
        </div>
        <button class="border-none bg-none" [matMenuTriggerFor]="menuFolder"
          aria-label="Example icon-button with a menu">
          <i class="fa-solid fa-ellipsis cursor-pointer"></i>
        </button>
        <mat-menu #menuFolder="matMenu">
          <div>
            <button mat-menu-item (click)="handleCreate(layerRoot, 'folder')" class="h-35px" [role]="buttons.ADD.VALUE">
              <i class="fa-solid fa-folder-plus font-size-16 text-warning me-2"></i>
              <span>Thêm thư mục</span>
            </button>
            <button mat-menu-item (click)="handleCreate(layerRoot, 'WMS')" class="h-35px" [role]="buttons.ADD.VALUE">
              <i class="fa-solid fa-file-circle-plus font-size-16 text-primary me-2"></i>
              <span>Thêm lớp</span>
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-tree #treeEditMap class="c-mat-tree pe-0" [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList
      (cdkDropListDropped)="drop($event)">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding cdkDrag [cdkDragData]="node"
        (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
        (cdkDragReleased)="dragEnd()" matTreeNodePaddingIndent="10px">
        <button mat-icon-button disabled></button>
        <div class="d-flex justify-content-between w-100">
          <mat-checkbox *ngIf="node.data.type != 'folder'"
            class="w-80 checklist-leaf-node c-checklist-leaf align-self-center"
            [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)"
            (contextmenu)="onrightClick($event, node)"><span
              title="{{node.data.text}}">{{renderTextCheckbox(node.data.text)}}</span></mat-checkbox>

          <i *ngIf="node.icon" class="{{node.icon}}"></i>
          <span *ngIf="node.data.type === 'folder'">
            <i class="fa-solid fa-folder me-2"></i>
            <span title="{{node.data.text}}">{{renderTextCheckbox(node.data.text)}}</span>
          </span>
          <div (click)="disableContextMenu()" class="more-action d-flex">
            <button class="border-none bg-none" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <i class="fa-solid fa-ellipsis cursor-pointer"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div *ngIf="node.data.type != 'folder'">
                <button mat-menu-item class="h-35px" (click)="openModal(content, node)">
                  <i class="fa-solid fa-gear font-size-16 text-secondary me-2"></i>
                  <span>Thiết lập</span>
                </button>
                <button mat-menu-item (click)="handleAction(node, 'config_display')" class="h-35px">
                  <i class="fa-solid fa-tv font-size-16 text-secondary me-2"></i>
                  <span>Thiết lập thông tin hiển thị</span>
                </button>
                <button mat-menu-item (click)="handleAction(node, 'config_filter')" class="h-35px">
                  <i class="fa-solid fa-filter font-size-16 text-primary me-2"></i>
                  <span>Thiết lập chế độ lọc</span>
                </button>
                <button mat-menu-item (click)="handleAction(node, 'move_to')" class="h-35px">
                  <i class="fa-solid fa-minimize font-size-16 text-delete me-2"></i>
                  <span>Di chuyển tới</span>
                </button>
                <button mat-menu-item class="h-35px" (click)="handleAction(node, 'rename_doc')">
                  <i class="fa-solid fa-pen font-size-16 text-delete me-2"></i>
                  <span>Đổi tên</span>
                </button>
                <button mat-menu-item class="h-35px" (click)="handleAction(node, 'delete_doc')">
                  <i class="fa-solid fa-trash font-size-16 text-delete me-2"></i>
                  <span>Xóa</span>
                </button>
              </div>
              <div *ngIf="node.data.type === 'folder'">
                <button mat-menu-item class="h-35px">
                  <i class="fa-solid fa-gear font-size-16 text-secondary me-2"></i>
                  <span>Thiết lập</span>
                </button>
                <button mat-menu-item (click)="handleCreate(node, 'folder')" class="h-35px" [role]="buttons.ADD.VALUE">
                  <i class="fa-solid fa-folder-plus font-size-16 text-warning me-2"></i>
                  <span>Thêm thư mục</span>
                </button>
                <button mat-menu-item (click)="handleCreate(node, 'WMS')" class="h-35px" [role]="buttons.ADD.VALUE">
                  <i class="fa-solid fa-file-circle-plus font-size-16 text-primary me-2"></i>
                  <span>Thêm lớp</span>
                </button>
                <button mat-menu-item (click)="handleAction(node, 'move_to')" class="h-35px">
                  <i class="fa-solid fa-minimize font-size-16 text-delete me-2"></i>
                  <span>Di chuyển tới</span>
                </button>
                <button mat-menu-item class="h-35px" (click)="handleAction(node, 'rename_doc')">
                  <i class="fa-solid fa-pen font-size-16 text-delete me-2"></i>
                  <span>Đổi tên</span>
                </button>
                <button mat-menu-item class="h-35px" (click)="handleAction(node, 'delete_doc')" [role]="buttons.DELETE.VALUE">
                  <i class="fa-solid fa-trash font-size-16 text-delete me-2"></i>
                  <span>Xóa</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10px"
        cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()"
        (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">
        <button mat-icon-button matTreeNodeToggle (click)="expansionModel.toggle(node.id)">
          <i *ngIf="!treeControl.isExpanded(node)" class="fa-solid fa-caret-right"></i>
          <i *ngIf="treeControl.isExpanded(node)" class="fa-solid fa-sort-down pb-1"></i>
        </button>

        <div (click)="disableContextMenu()" class="more-action d-flex justify-content-between w-100">
          <span>
            <i class="fa-solid fa-folder font-size-16 me-2"></i>
            <span title="{{node.data.text}}">{{renderTextCheckbox(node.data.text)}}</span>
          </span>
          <button class="border-none bg-none" [matMenuTriggerFor]="menuFolder"
            aria-label="Example icon-button with a menu">
            <i class="fa-solid fa-ellipsis cursor-pointer"></i>
          </button>
          <mat-menu #menuFolder="matMenu">
            <div>
              <button mat-menu-item class="h-35px">
                <i class="fa-solid fa-gear font-size-16 text-secondary me-2"></i>
                <span>Thiết lập</span>
              </button>
              <button mat-menu-item (click)="handleCreate(node, 'folder')" class="h-35px" [role]="buttons.ADD.VALUE">
                <i class="fa-solid fa-folder-plus font-size-16 text-warning me-2"></i>
                <span>Thêm thư mục</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="layers" class="h-35px" [role]="buttons.ADD.VALUE">
                <i class="fa-solid fa-file-circle-plus font-size-16 text-primary me-2"></i>
                <span>Thêm lớp</span>
              </button>
              <button mat-menu-item (click)="handleAction(node, 'move_to')" class="h-35px">
                <i class="fa-solid fa-minimize font-size-16 text-delete me-2"></i>
                <span>Di chuyển tới</span>
              </button>
              <button mat-menu-item class="h-35px" (click)="handleAction(node, 'rename_doc')">
                <i class="fa-solid fa-pen font-size-16 text-delete me-2"></i>
                <span>Đổi tên</span>
              </button>
              <button mat-menu-item class="h-35px" (click)="handleAction(node, 'delete_doc')" [role]="buttons.DELETE.VALUE">
                <i class="fa-solid fa-trash font-size-16 text-delete me-2"></i>
                <span>Xóa</span>
              </button>
            </div>
          </mat-menu>
          <mat-menu #layers="matMenu">
            <button mat-menu-item (click)="handleCreate(node, 'WMS')">
              <i class="fa-solid fa-layer-group me-1"></i>
              WMS
            </button>
            <button mat-menu-item (click)="handleCreate(node, 'VECTOR')">
              <i class="fa-solid fa-layer-group me-1"></i>
              Vector</button>
          </mat-menu>

        </div>
      </mat-tree-node>
    </mat-tree>
  </div>

  <!-- // click chuot phai -->
  <div *ngIf="contextmenu">
    <div *ngIf="nodeRightClick.data.type != 'root' " class="contextmenu"
      [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="openModal(content, nodeRightClick)"
        data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
        <i class="fa-solid fa-gear align-middle me-1"></i>Thiết lập</a>
      <a class="dropdown-item my-2" href="javascript: void(0); "
        (click)="handleAction(nodeRightClick, 'config_display')">
        <i class="fa-solid fa-tv align-middle me-1"></i> Thiết lập thông tin hiển
        thị</a>
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="handleAction(nodeRightClick, 'config_filter')">
        <i class="fa-solid fa-filter align-middle me-1"></i>
        Thiết lập chế độ lọc</a>
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="handleAction(nodeRightClick, 'move_to')"><i
          class="fa-solid fa-minimize align-middle me-1"></i>Di
        chuyển tới</a>
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="handleAction(nodeRightClick, 'rename_doc')"><i
          class="fa-solid fa-pen align-middle me-1"></i>Đổi
        tên</a>
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="handleAction(nodeRightClick, 'delete_doc')"><i
          class="fa-solid fa-trash align-middle me-1"></i>Xóa</a>
    </div>

    <div *ngIf="nodeRightClick.data.type === 'root' " class="contextmenu"
      [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY + 150}">
      <a class="dropdown-item my-2" href="javascript: void(0);" (click)="handleCreate(nodeRightClick, 'folder')"
        data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
        <i class="fa-solid fa-folder-plus font-size-16 text-warning align-middle me-1"></i>Thêm thư mục</a>
      <a class="dropdown-item my-2" href="javascript: void(0); " (click)="handleCreate(nodeRightClick, 'WMS')">
        <i class="fa-solid fa-file-circle-plus align-middle me-1"></i>Thêm lớp</a>
    </div>
  </div>

  <!-- show modal -->
  <ng-template #content role="document" let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel">Thiết lập</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form class="form-search" [formGroup]="formThietLap" (ngSubmit)="onSubmit('thiet_lap')">
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-lg-6">
            <div>
              <label class="form-label fw-600">LayerId</label>
              <input class="form-control" type="text" disabled placeholder="Nhập layer id"
                [defaultValue]="nodeSelected.data.layerid">
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <label class="form-label fw-600">Tên lớp</label>
              <input class="form-control" type="text" placeholder="Nhập tên lớp" name="text" formControlName="text"
                [defaultValue]="nodeSelected.data.text">
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-4 ">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="legend_use" name="legend_use"
                formControlName="legend_use">
              <label class="form-check-label" for="formCheck2">
                Hiển thị chú giải
              </label>
            </div>
          </div>
          <div class="col-lg-8 d-flex">
            <div class="me-2">
              <button type="button" class="btn btn-light" (click)="triggerChooseFile(modalupload)"
                data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
                <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Chọn ảnh chú giải
              </button>
            </div>

            <div *ngIf="checkTypeVal(legendImage) === 'image'">
              <img src="{{HOST_RESOURCE}}/{{legendImage.uri}}" class="image-card-5 cursor-pointer"
                (click)="openImage(legendImage.uri, 0)" />
            </div>
          </div>

        </div>
        <div class="row mt-3">
          <div class="col-lg-12 d-flex justify-content-center">
            <div class="form-check font-size-14">
              <input class="form-check-input" type="radio" value="wms" id="wms" formControlName="layertype">
              <label class="form-check-label" for="wms">
                WMS
              </label>
            </div>
            <div class="form-check mx-5 font-size-14">
              <input class="form-check-input" type="radio" value="arcgis" id="arcgis" formControlName="layertype">
              <label class="form-check-label" for="arcgis">
                ArcGIS
              </label>
            </div>
            <div class="form-check me-5 font-size-14">
              <input class="form-check-input" type="radio" value="vector" id="vector" formControlName="layertype">
              <label class="form-check-label" for="vector">
                Vector
              </label>
            </div>
            <div class="form-check font-size-14">
              <input class="form-check-input" type="radio" value="geotiff" id="geotiff" formControlName="layertype">
              <label class="form-check-label" for="geotiff">
                GeoTiff
              </label>
            </div>
          </div>
        </div>

        <ng-container [ngSwitch]="valFormThietLap.layertype.value" class="">
          <ng-container *ngSwitchCase="'wms'">
            <div class="row mt-3">
              <div class="col-lg-12 d-flex">
                <div class="align-self-center me-2 w-30"><span class="fw-600">Chọn nguồn dữ liệu</span></div>
                <div class="w-100">
                  <ng-select [notFoundText]="'Không có dữ liệu'" (change)="onSelectSource($event)"
                    [items]="tableSources" [multiple]="false" bindLabel="name" bindValue="id"
                    [ngClass]="{ 'is-invalid': submitted && valFormThietLap.tbkey.errors }" formControlName="tbkey"
                    name="tbkey">
                  </ng-select>
                </div>
              </div>
            </div>
            <div>
              <div class="row mt-3">
                <div class="col-lg-6 ">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="formCheck3" name="wmsExternal"
                      formControlName="wmsExternal" (change)="handleChangeWMS($event)">
                    <label class="form-check-label" for="formCheck3">
                      WMS ngoài hệ thống
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="wmsOutSystem" class="row mt-3">
                <div class="col-lg-12">
                  <input class="form-control" type="search" placeholder="Nhập link wms"
                    formControlName="linkWmsExternal" name="linkWmsExternal">
                </div>
              </div>
              <div *ngIf="!wmsOutSystem" class="row mt-3">
                <div class="col-lg-12">
                  <label class="form-label fw-600"><i class="fa-solid fa-database"></i> Chọn WMS</label>
                  <ng-select [notFoundText]="'Không có dữ liệu'" [items]="listWMS" [multiple]="false" bindLabel="name"
                    bindValue="layer" [ngClass]="{ 'is-invalid': submitted && valFormThietLap.wms.errors }"
                    formControlName="wms" name="wms">
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'arcgis'">
            <div class="row mt-3">
              <div class="col-lg-12 d-flex">
                <div class="align-self-center me-2 w-30"><span class="fw-600">Chọn thư mục Arcgis</span></div>
                <div class="w-100">
                  <ng-select [notFoundText]="'Không có dữ liệu'" (change)="onSelectFolder($event)"
                    [items]="arcgisFolders" [multiple]="false" bindLabel="name" bindValue="name"
                    [ngClass]="{ 'is-invalid': submitted && valFormThietLap.folderArcgis.errors }"
                    formControlName="folderArcgis" name="folderArcgis">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 d-flex">
                <div class="align-self-center me-2 w-30"><span class="fw-600">Chọn lớp hiển thị</span></div>
                <div class="w-100">
                  <ng-select [notFoundText]="'Không có dữ liệu'"
                    [items]="listMapserver" [multiple]="false" bindLabel="name" bindValue="name"
                    [ngClass]="{ 'is-invalid': submitted && valFormThietLap.mapServer.errors }" formControlName="mapServer"
                    name="mapServer">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 d-flex">
                <div class="align-self-center me-2 w-30"><span class="fw-600">Chọn lớp dữ liệu</span></div>
                <div class="w-100">
                  <ng-select [notFoundText]="'Không có dữ liệu'"
                    [items]="listFeatureServer" [multiple]="false" bindLabel="name" bindValue="name"
                    [ngClass]="{ 'is-invalid': submitted && valFormThietLap.featureServer.errors }"
                    formControlName="featureServer" name="featureServer">
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'vector'">
            <div class="row mt-3">
              <div class="col-lg-12 d-flex">
                <div class="align-self-center me-2 w-30"><span class="fw-600">Chọn nguồn dữ liệu</span></div>
                <div class="w-100">
                  <ng-select [notFoundText]="'Không có dữ liệu'" (change)="onSelectSource($event)"
                    [items]="tableSources" [multiple]="false" bindLabel="name" bindValue="id"
                    [ngClass]="{ 'is-invalid': submitted && valFormThietLap.tbkey.errors }" formControlName="tbkey"
                    name="tbkey">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6">
                <label class="form-label fw-600">Styles</label>
              </div>
              <div class="col-lg-6">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="formCheck2">
                  <label class="form-check-label" for="formCheck2">
                    Sử dụng vectortile
                  </label>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-lg-12">
                <div class="input-group">
                  <!-- <input type="text" class="form-control " id="autoSizingInputGroup" placeholder="Tìm style"> -->
                  <div class="d-flex w-100">
                    <ng-select [notFoundText]="'Không có dữ liệu'"
                      [items]="listStyle" [multiple]="false" bindLabel="name" bindValue="id" class="w-100"
                      [ngClass]="{ 'is-invalid': submitted && valFormThietLap.idStyle.errors }"
                      formControlName="idStyle" name="idStyle">
                    </ng-select>
                    <!-- <div (click)="handleStyle('edit')" class="input-group-text" matTooltip="Tìm style" matTooltipPosition="above">
                    <i class="fa-solid fa-pen cursor-pointer"></i>
                  </div> -->
                  <div (click)="handleStyle('edit')" class="input-group-text cursor-pointer"
                      matTooltipPosition="above">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </div>
                    <div (click)="handleStyle('create')" class="input-group-text cursor-pointer"
                      matTooltipPosition="above">
                      <i class="fa-solid fa-plus "></i>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>




        <div class="row mt-3">
          <div class="col-lg-12">
            <textarea class="form-control" rows="3" placeholder="Nhập điều kiện filter"></textarea>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-4">
            <div>
              <label class="form-label fw-600">Min Zoom</label>
              <input class="form-control" type="number" placeholder="Nhập min zoom" name="minzoom"
                formControlName="minzoom">
            </div>
          </div>
          <div class="col-lg-4">
            <div>
              <label class="form-label fw-600">Max Zoom</label>
              <input class="form-control" type="number" placeholder="Nhập max zoom" name="maxzoom"
                formControlName="maxzoom">
            </div>
          </div>
          <div class="col-lg-4">
            <div>
              <label class="form-label fw-600">Z-index</label>
              <input class="form-control" type="number" placeholder="Nhập z-index" name="zindex"
                formControlName="zindex">
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-6 ">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="formCheck4" formControlName="checked" name="checked">
              <label class="form-check-label" for="formCheck4">
                Mặc định sẽ bật
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal"
          (click)="modal.close('Close click')" [role]="buttons.CLOSE.VALUE">Đóng</button>
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveConfig(modal)" [role]="buttons.SAVE.VALUE">Lưu
          lại</button>
      </div>
    </form>
  </ng-template>

  <!-- show modal -->
  <ng-template #modalupload role="document" let-modal>
    <div class="modal-header py-2">
      <div class="d-flex justify-content-between w-100">
        <h5 class="modal-title cursor-pointer align-self-center" id="myModalLabel">
          < Trở lại</h5>
            <form class="app-search d-none d-xl-block p-0">
              <div class="position-relative">
                <input type="text" class="form-control" placeholder="Tìm kiếm">
                <span class="bx bx-search-alt"></span>
              </div>
            </form>
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form class="form-search" [formGroup]="formThietLap" (ngSubmit)="onSubmit('thiet_lap')">

      <div class="modal-body">
        <div class="row-item pb-2">
          <i class="fa-solid fa-folder font-size-20 me-2" style="color: #FFD764;"></i>
          <span>data</span>
        </div>
        <div class="row-item py-2">
          <div class="d-flex justify-content-between w-100">
            <div>
              <i class="fa-solid fa-file-image me-2"></i>
              <span>image.png</span>
            </div>
            <div>
              <span class="c-border me-2">
                <i class="fa-regular fa-trash-can cursor-pointer"></i>
              </span>
              <span class="c-border">
                <i class="fa-solid fa-cloud-arrow-down cursor-pointer"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between ">
        <div class="button-left">
          <button type="button" class="btn btn-light me-2" (click)="triggerChooseFile(modalupload)"
            data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
            <i class="bx bx-folder-plus font-size-16 align-middle me-2"></i> Tạo thư mục
          </button>
          <button type="button" class="btn btn-light" (click)="triggerChooseFile(modalupload)"
            data-bs-target=".bs-example-modal-lg" data-bs-toggle="modal" data-bs-target="#myModal">
            <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Tải lên
          </button>
        </div>
        <div class="button-right">
          <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
            (click)="modal.close('Close click')" [role]="buttons.CLOSE.VALUE">Đóng</button>
          <button type="button" class="btn btn-primary waves-effect waves-light" [role]="buttons.SAVE.VALUE">Lưu lại</button>
        </div>
      </div>
    </form>
  </ng-template>
  <app-select-image #selectFile (onAction)="changeAction($event)" [hideUpload]="false"
    [typeSelect]="'file'"></app-select-image>

</div>