import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import wellknown from "wellknown";
// import { TruCuuHoa } from './../../QuanLyTaiSan/quan-ly-tai-san/model/truCuuHoa';
// import { MapService } from '../../../@core/data/service/map.service';
import {
  MapboxExportControl,
  Size,
  PageOrientation,
  Format,
  DPI,
} from "@watergis/mapbox-gl-export";
import { API } from "src/app/contants/ApiContants";
import { TreeLayersComponent } from "../tree-layers/tree-layers.component";
import {
  MAP_TREE_LAYERS_DATA,
  MAP_TREE_LAYERS_DIACHINH_DATA,
} from "src/app/contants/map_layers";
import Layer from "ol/layer/Layer";
import { MapService } from "src/app/core/services/map.service";
import { MAP_LAYERS_ID } from "src/app/contants";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import * as Constants from "../../quan-tri/Constants/Constants";
import * as _ from "lodash";
import { QuyHoachFetureIDS } from "./types";
import { SearchComponent } from "./components/search/search.component";
import { Threebox } from "threebox-plugin";
// import { Threebox } from 'threebox-plugin/dist/threebox';

// import "@watergis/mapbox-gl-export/css/styles.css";
// import { TEN_LOAI_TAI_SAN } from '../../QuanLyTaiSan/TaiSanConstant';
@Component({
  selector: "app-mapbox",
  templateUrl: "./mapbox.component.html",
  styleUrls: ["./mapbox.component.scss"],
})
export class MapboxComponent implements AfterViewInit {
  @ViewChild("mapContainer") mapContainerRef: any;
  @ViewChild("treeLayer") treeLayer: TreeLayersComponent;
  @ViewChild("searchBox") searchBox: SearchComponent;
  @Output() onPointUp = new EventEmitter<any>();
  // @Input() processId: string = "";
  // @Input() features;
  // @Input() isRaw: boolean;
  // @Input() processName: string;
  mapLoaded: boolean = false;
  map: any;
  // style = 'mapbox://styles/mapbox/streets-v12';
  style = "/assets/data/style.json";
  // style = 'http://45.118.146.215:99/assets/data/style.json';
  // style = 'http://45.118.146.215:99/map/style.json';
  //toa do can tho
  // lat = 10.032345350572172
  // lng = 105.75830629543853
  //toa do vung cap nuoc cn0000
  // 105.66796700632693, 21.08645314809972
  // 21.095429, 105.702944
  //22.35198,103.84465
  lat = 22.35198;
  lng = 103.84465;
  // maxBounds = [[101.865, 19.663], [109.072, 23.544]];
  // center: [105.75830629543853, 10.032345350572172] //hanoi
  // maxBounds = [[101.865, 19.663], [109.072, 23.544]];
  currentZoom = 12;
  showXY: boolean = false;
  showBaseLayer: boolean = false;
  showLayer: boolean = false;
  showAdministrative: boolean = false;
  showSearch: boolean = false;
  showSearchPOI: boolean = false;
  userX: number = 0;
  userY: number = 0;
  drawControl: any = null;
  mode: string = "default";
  measureResult: string = "";
  showInfo: boolean = false;
  showQHInfo: boolean = false;
  showLegend: boolean = false;
  featureProps: any = {};
  selectedFeatures: any = [];
  assetTypeName: string = "";
  visibleLayers: any = [];
  quyHoachIDSDD: string = "";
  quyHoachIDXD: QuyHoachFetureIDS;
  treeLayerSelectedID: any = null;
  showThuaDat: boolean = false;
  dsThua: any[] = [];
  searchParcelParams: any;
  searchRadius: number;
  POILoc: any;
  POIMarker: any;
  locatePOIMarker: any;
  POIResults: any[];
  loadingSearchPOI: boolean = false;
  searchQHCCount: number = 0;
  searchQHPKCount: number = 0;
  searchQHCTCount: number = 0;
  showSearchResult: boolean = false;
  layerId: string = "";
  images: any = ["https://i.ytimg.com/vi/nlYlNF30bVg/hqdefault.jpg"];
  imageIndex: number = 0;
  isShowImage: boolean = false;
  tb: any = null;
  camera: any;
  scene: any;
  renderer: any;
  groupType: string = "QHC";
  treeLayerData: any[] = MAP_TREE_LAYERS_DATA;
  treeLayerDatDiaChinh: any[] = MAP_TREE_LAYERS_DIACHINH_DATA;
  poiRadius: number = 1000;
  constructor(
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private mapService: MapService
  ) { }

  ngOnInit() { }

  removeLogo() {
    // const logoElem = document.querySelector('.mapboxgl-compact-logo')
    const logoElem = document.querySelector(".mapboxgl-ctrl-logo");
    if (logoElem) {
      logoElem.parentElement.removeChild(logoElem);
    }
  }

  async initMap() {
    let self = this;
    var origin: any = [this.lng, this.lat];
    // var origin: any = [107.23613980836815, 50.693601134121312];
    const map = new mapboxgl.Map({
      accessToken:
        "pk.eyJ1IjoiY2hpbmhscyIsImEiOiIwNFhac19NIn0.ywzcihERU9ETEyUxphFcIQ",
      container: this.mapContainerRef.nativeElement,
      style: this.style,
      // style: "mapbox://styles/mapbox/streets-v12",
      center: origin,
      zoom: 1,
      pitch: 0,
      attributionControl: false,
      // boxZoom: true,
      // maxBounds: new mapboxgl.LngLatBounds([103.3072, 22.0576], [104.3921, 22.6343])
    });
    // eslint-disable-next-line no-undef
    const tb = (window["tb"] = new Threebox(
      map,
      map.getCanvas().getContext("webgl"),
      {
        defaultLights: true,
      }
    ));

    map.boxZoom.enable();
    // map.on('zoomend', function () {
    //   var currentZoom = map.getZoom();
    //   const treeLayers = MAP_TREE_LAYERS_DATA;
    //   const visibleLayers = []
    //   treeLayers.forEach(groupLayer => {
    //     if (groupLayer.isGroupLayer) {
    //       groupLayer.children.forEach(layer => {
    //         const layerInfo: any = map.getLayer(layer.id);
    //         if (layerInfo) {
    //           if (layerInfo['metadata'] === 'manual') {
    //             if (layerInfo.visibility === 'visible') visibleLayers.push(layer.id);
    //             return;
    //           }
    //           if ((layer.maxZoom < currentZoom || layer.minZoom > currentZoom) || !layer.maxZoom || !layer.minZoom) {
    //             map.setLayoutProperty(layer.id, 'visibility', 'none');
    //           } else {
    //             map.setLayoutProperty(layer.id, 'visibility', 'visible');
    //             visibleLayers.push(layer.id);
    //           }
    //         }
    //       })
    //     }
    //   })
    //   self.visibleLayers = visibleLayers;
    // });
    // Add map controls
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );
    //waiting map container initialized
    // setTimeout(() => {
    var draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        line_string: true,
        point: true,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      },
    });

    this.drawControl = draw;
    map.addControl(draw, "bottom-left");
    console.log('draw control', this.drawControl);
    map.on("load", () => {
      this.loadImages();
    });
    // map.on("idle", () => {
    //   map.addControl(new MapboxExportControl({
    //     PageSize: Size.A3,
    //     PageOrientation: PageOrientation.Portrait,
    //     Format: Format.PNG,
    //     DPI: DPI[96],
    //     // Crosshair: true,
    //     PrintableArea: true,
    //   }), 'bottom-right');
    // })
    // map.once('styledata', () => {
    //   console.log('map on style ddddddddddddddddddddddddddddd==============')
    // })
    map.on("style.load", () => {
      // this.initLayer()
      // this.updateLayerOpacity();
      // console.log("map", map);
      // map.addSource('mapbox-dem', {
      //   "type": "raster-dem",
      //   "url": "mapbox://mapbox.mapbox-terrain-dem-v1",
      // });
      // map.setTerrain({ "source": "mapbox-dem" });
      this.initHilightLayer();
      this.mapLoaded = true;
    });
    map.on("click", self.onMapClick.bind(self));
    map.on("draw.create", self.updateArea.bind(self));
    map.on("draw.delete", self.updateArea.bind(self));
    map.on("draw.update", self.updateArea.bind(self));
    // if (window) {
    //   //add export control
    //   // const { MapboxExportControl, Size, PageOrientation, Format, DPI } = await import("@watergis/mapbox-gl-export");
    // map.addControl(new MapboxExportControl({
    //   PageSize: [420, 297],
    //   PageOrientation: PageOrientation.Portrait,
    //   Format: Format.PNG,
    //   DPI: DPI[96],
    //   Crosshair: true,
    //   PrintableArea: true,
    //   accessToken: 'pk.eyJ1IjoiY2hpbmhscyIsImEiOiIwNFhac19NIn0.ywzcihERU9ETEyUxphFcIQ'
    // }), 'bottom-right');
    // map.addControl(new MapboxExportControl({
    //   PageSize: Size.A3,
    //   PageOrientation: PageOrientation.Portrait,
    //   Format: Format.PNG,
    //   DPI: DPI[96],
    //   Crosshair: true,
    //   PrintableArea: true,
    // }), 'bottom-right');
    // }

    this.map = map;
    // this.initThree();
    //hide draw tool
    const groupControls = document.getElementsByClassName('mapboxgl-ctrl-group');
    const drawControlContainer: any = groupControls[groupControls.length - 1];
    if (drawControlContainer) drawControlContainer.style.display = 'none'

    this.removeLogo();
  }
  goToPlace(place) {
    if (place && place.geometry.location) {
      this.map.flyTo({
        center: [place.geometry.location.lng, place.geometry.location.lat],
        zoom: this.currentZoom,
        pitch: 0,
        essential: true,
      });
    }
  }
  initThree() {
    // const THREE = this.tb;
    // console.log('three', Threebox)

    // // // use the three.js GLTF loader to add the 3D model to the three.js scene
    // // const loader = new THREE.GLTFLoader();
    // // loader.load(
    // //     'https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf',
    // //     (gltf) => {
    // //         this.scene.add(gltf.scene);
    // //     }
    // // );
    // // this.map = map;
    // const map = this.map;
    // // use the Mapbox GL JS map canvas for three.js
    // this.renderer = new THREE.WebGLRenderer({
    //   canvas: map.getCanvas(),
    //   // context: gl,
    //   antialias: true
    // });

    // this.renderer.autoClear = false;
    // this.tb(this.map, null, { antialias: true })
    const map = this.map;
    // console.log('map context',map.getCanvas().getContext('webgl'))
    window["tb"] = new Threebox(map, map.getCanvas().getContext("webgl"), {
      defaultLights: true,
    });
    const THREE = window["tb"];
    console.log("tb", window["tb"]);
    this.camera = window["tb"].camera;
    this.scene = window["tb"].scene;

    // create two three.js lights to illuminate the model

    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
    directionalLight2.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight2);
    //instantiate a red sphere and position it at the origin lnglat
    var sphere = window["tb"]
      .sphere({ color: "red", material: "MeshStandardMaterial" })
      .setCoords([this.lng, this.lat]);
    window["tb"].add(sphere);
  }
  ngAfterViewInit(): void {
    let self = this;
    mapboxgl as typeof mapboxgl;
    this.initMap();
    // this.changeDetectorRef.detectChanges();
  }

  initControls() {
    let self = this;
    // this.map.on('click', function (e: any) {
    //   var coordinates = e.lngLat;
    //   new mapboxgl.Popup()
    //     .setLngLat(coordinates)
    //     .setHTML('you clicked here: <br/>' + coordinates)
    //     .addTo(self.map);
    // });
  }

  resize() {
    this.map.resize();
    console.log("=======resize==========");
  }

  getEditedGeometry() {
    let geoedited = "";
    if (this.mode === "edit") {
      const featureCollection = this.drawControl.getAll();
      if (featureCollection.features && featureCollection.features.length) {
        var fea = featureCollection.features[0];
        geoedited = wellknown.stringify(fea);
      }
      this.mode = "";
    }
    return geoedited;
  }

  loadImages() {
    let self = this;
    const ic_w = 24;
    const ic_h = 24;
    const POI_KEYS = Object.keys(Constants.POI_CATEGORIES);
    POI_KEYS.forEach((name) => {
      if (this.map.hasImage(name)) {
        this.map.removeImage(name);
      }
      let image = new Image(ic_w, ic_h);
      image.onload = () => this.map.addImage(name, image);
      image.src = "/assets/images/POI/" + name + ".png";
    });

    //tree
    if (!this.map.hasImage("tree")) {
      let treeImage = new Image(ic_w, ic_h);
      treeImage.onload = () => this.map.addImage("tree", treeImage);
      treeImage.src = "/assets/images/POI/tree.png";
    }
    //cong
    if (!this.map.hasImage("cong")) {
      let congImage = new Image(ic_w, ic_h);
      congImage.onload = () => this.map.addImage("cong", congImage);
      congImage.src = "/assets/images/POI/cong.png";
    }

    //cong trinh tieu thoat nuoc
    if (!this.map.hasImage("rainwater")) {
      let rainwaterImage = new Image(ic_w, ic_h);
      rainwaterImage.onload = () =>
        this.map.addImage("rainwater", rainwaterImage);
      rainwaterImage.src = "/assets/images/POI/rainwater.png";
    }
    //cong trinh dau moi cap nuoc
    if (!this.map.hasImage("water_supply")) {
      let waterSupplyImage = new Image(ic_w, ic_h);
      waterSupplyImage.onload = () =>
        this.map.addImage("water_supply", waterSupplyImage);
      waterSupplyImage.src = "/assets/images/POI/water_supply.png";
    }
  }

  ngOnChanges(event) { }

  initHilightLayers() {
    this.map.addSource("hilightFeatures", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.map.addSource("hilightParcelFeatures", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.map.addSource("hilightSplitParcelFeatures", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    // this.map.addSource('parcel_hilighted', {
    //   type: 'geojson',
    //   data: {
    //     "type": "FeatureCollection",
    //     "features": []
    //   }
    // });

    this.map.addLayer({
      id: "pointHiglightLayer",
      type: "circle",
      source: "hilightFeatures",
      layout: {
        visibility: "visible",
      },
      filter: ["==", "$type", "Point"],
      paint: {
        "circle-radius": 5,
        // 'circle-color': '#d2930c',
        "circle-color": "blue",
      },
    });

    this.map.addLayer({
      id: "lineHiglightLayer",
      type: "line",
      source: "hilightFeatures",
      filter: ["==", "mode", "normal"],
      layout: {
        "line-join": "round",
        "line-cap": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "blue",
        "line-width": 2,
      },
    });

    this.map.addLayer({
      id: "poiHiglightLayer",
      type: "line",
      source: "hilightFeatures",
      filter: ["==", "mode", "circle"],
      layout: {
        "line-join": "round",
        "line-cap": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "green",
        "line-width": 2,
        "line-dasharray": [2, 2],
      },
    });

    this.map.addLayer({
      id: "parcelHiglightLayer",
      type: "line",
      source: "hilightSplitParcelFeatures",
      layout: {
        "line-join": "round",
        "line-cap": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "#179568",
        "line-width": 2,
      },
    });

    this.map.addLayer({
      id: "parcelSplitHiglightLayer",
      type: "fill",
      source: "hilightParcelFeatures",
      // 'layout': {
      //   'line-join': 'round',
      //   'line-cap': 'round',
      //   'visibility': 'visible'
      // },
      paint: {
        // 'line-color': 'black',
        // 'line-width': 2
        "fill-outline-color": "#484896",
        "fill-color": "#6e599f",
        "fill-opacity": 0.75,
      },
    });

    // this.map.addLayer(
    //   {
    //     'id': 'parcel-highlighted',
    //     'type': 'fill',
    //     'source': 'parcel_hilighted',
    //     'paint': {
    //       'fill-outline-color': '#484896',
    //       'fill-color': '#6e599f',
    //       'fill-opacity': 0.75
    //     }
    //   });
  }


  initPOILayers() {
    this.map.addSource("POIFeatures", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.map.addLayer({
      id: "POIResultLayer",
      type: "symbol",
      source: "POIFeatures",
      layout: {
        // 'icon-image': 'airport',//['get', 'class'],
        "icon-allow-overlap": true,
        "icon-image": ["get", "class"],
        "icon-size": 1,
      },
      paint: {},
    });
  }
  updateLayer() {
    const map = this.map;
    map.triggerRepaint();
  }
  updateLayerOpacity(evt) {
    const map = this.map;
    const self = this;
    if (!map) return;
    console.log("updateLayerOpacity", evt);
    const { layerid, opacity } = evt;

    var style = this.map.getStyle();
    // Lọc các layer có cùng source-layer
    var glayers = style.layers.filter(function (layer) {
      if (layerid.indexOf("ThuaDat") !== -1) {
        return layer.source && layer["source-layer"] === layerid;
      } else if (self.groupType) {
        return (
          layer.source &&
          layer["source-layer"] === layerid &&
          (layer.id.startsWith(self.groupType + "_") || layer.id == layerid)
        );
      } else return layer.source && layer["source-layer"] === layerid;
    });
    if (glayers && glayers.length) {
      glayers.forEach((layerInfo) => {
        if (layerInfo.type === "line")
          map.setPaintProperty(layerInfo.id, "line-opacity", opacity);
        else if (layerInfo.type === "fill")
          map.setPaintProperty(layerInfo.id, "fill-opacity", opacity);
        else if (layerInfo.type === "raster")
          map.setPaintProperty(layerInfo.id, "raster-opacity", opacity);
      });
    }

    // const layerInfo = map.getLayer(layerid);
    // console.log("layer info", layerInfo);

    // this.mapService.getLayersOpacity$().subscribe(rs => {
    //   console.log('rs',rs)
    //   if (this.treeLayerSelectedID) {
    //     const layerOpacity = rs[this.treeLayerSelectedID];
    //     map.setPaintProperty(this.treeLayerSelectedID, 'fill-opacity', layerOpacity);
    //   }
    // })
  }

  initLayer() {
    const self = this;
    try {
      const treeLayers = MAP_TREE_LAYERS_DATA;
      // const firstLayerID = MAP_TREE_LAYERS_DATA[1].children[0].id;
      const visibleLayers = [];
      // treeLayers.forEach((groupLayer) => {
      //   if (groupLayer.isGroupLayer) {
      //     groupLayer.children.forEach((layer) => {
      //       if (layer.defaultVisible) visibleLayers.push(layer.id);
      //       //add source
      //       if (layer.type === "raster") {
      //         let SID = "src_" + layer.id;
      //         if (layer.source) SID = layer.source;
      //         if (!self.map.getSource(SID)) {
      //           self.map.addSource(SID, {
      //             type: "raster",
      //             tiles: [layer.url],
      //             tileSize: 256,
      //             minzoom: layer.minZoom,
      //             maxzoom: layer.maxZoom,
      //           });

      //           const layerAdd = {
      //             id: layer.id,
      //             type: layer.type,
      //             source: "src_" + layer.id,
      //             "source-layer": layer.id,
      //             minzoom: layer.minZoom,
      //             maxzoom: layer.maxZoom,
      //             layout: {
      //               visibility: layer.defaultVisible ? "visible" : "none",
      //             },
      //           };
      //           if (!self.map.getLayer(layer.id)) {
      //             self.map.addLayer(layerAdd);
      //           }
      //         }
      //       } else {
      //         let SID = "src_" + layer.id;
      //         if (layer.source) SID = layer.source;
      //         if (!self.map.getSource(SID)) {
      //           self.map.addSource(SID, {
      //             type: "vector",
      //             tiles: [
      //               API.API_GEODATA +
      //                 "/map/tile/" +
      //                 layer.layer +
      //                 "/{z}/{x}/{y}.pbf",
      //             ],
      //             // 'minzoom': layer.minZoom,
      //             // 'maxzoom': layer.maxZoom
      //           });
      //         }

      //         //add layer'
      //         const layerAdd = {
      //           id: layer.id,
      //           type: layer.type,
      //           source: layer.source ? layer.source : "src_" + layer.id,
      //           "source-layer": layer.layer,
      //           filter:
      //             layer.filter && layer.filter.length > 0
      //               ? layer.filter
      //               : ["all"],
      //           // 'minzoom': layer.minZoom,
      //           // 'maxzoom': layer.maxZoom,
      //           layout:
      //             layer.style && layer.style.layout
      //               ? layer.style.layout
      //               : {
      //                   visibility: layer.defaultVisible ? "visible" : "none",
      //                 },
      //           paint:
      //             layer.style && layer.style.paint
      //               ? layer.style.paint
      //               : {
      //                   // 'fill-color': 'blue', // blue color fill
      //                   // 'fill-opacity': 0.5
      //                 },
      //           // 'paint':
      //           // {
      //           //   'fill-color': 'blue', // blue color fill
      //           //   'fill-opacity': 0.5
      //           // }
      //         };
      //         if (!self.map.getLayer(layer.id)) {
      //           self.map.addLayer(layerAdd);
      //         }
      //       }
      //     });
      //   }
      // });
      self.visibleLayers = visibleLayers;
      if (!self.map.getSource("hilightFeatures")) {
        this.initHilightLayers();
      }
      if (!self.map.getSource("POIFeatures")) {
        this.initPOILayers();
      }

    } catch (ex) {
      console.error("init layer exception", ex);
    }
  }

  initHilightLayer() {
    const self = this;
    // self.visibleLayers = visibleLayers;
    console.log("init hilight", self);
    if (!self.map.getSource("hilightFeatures")) {
      this.initHilightLayers();
    }
    if (!self.map.getSource("POIFeatures")) {
      this.initPOILayers();
    }
  }

  fitLayer(box) {
    console.log("fit layer ", box);
    this.map.fitBounds(box, { padding: 20 });
  }

  toggleShowSearch() {
    this.showSearch = !this.showSearch;
    if (this.showSearch) {
      this.mode = "search";
    } else {
      this.mode = "";
    }
  }
  toggleShowLegend() {
    this.showLegend = !this.showLegend;
  }
  toogleShowThuaDat() {
    this.showThuaDat = !this.showThuaDat;
  }
  toggleShowSearchPOI() {
    this.showSearchPOI = !this.showSearchPOI;
    if (this.showSearchPOI) {
      this.mode = "search_poi";
      this.activeInfoFeature();
    }
  }
  activeInfoFeature() {
    if (this.mode === "info") {
      this.mode = "";
      this.map.getCanvas().style.cursor = "pointer";
      this.clearHilightFeatures();
    } else if (this.mode === "search_poi") {
      this.map.getCanvas().style.cursor = "pointer";
    } else {
      this.mode = "info";
      this.map.getCanvas().style.cursor = "help";
    }
  }
  deactiveInfoFeature() {
    this.mode = "";
    this.map.getCanvas().style.cursor = "grab";
  }
  toogleShowLayers() {
    this.showLayer = !this.showLayer;
  }

  toogleShowMeasure() {
    this.showLayer = !this.showLayer;
  }
  onClearSearch() {
    this.clearHilightFeatures();
    //reset filter
    const map = this.map;
    map.setFilter(MAP_LAYERS_ID.QHC_A, ["==", "oqh_loaiqh", "QHC"]);
    map.setFilter(MAP_LAYERS_ID.QHC_P, ["==", "oqh_loaiqh", "QHC"]);
    map.setFilter(MAP_LAYERS_ID.QHPK_A, ["==", "oqh_loaiqh", "QHPK"]);
    map.setFilter(MAP_LAYERS_ID.QHPK_P, ["==", "oqh_loaiqh", "QHPK"]);
    map.setFilter(MAP_LAYERS_ID.QHCT_A, ["==", "oqh_loaiqh", "QHCT"]);
    map.setFilter(MAP_LAYERS_ID.QHCT_P, ["==", "oqh_loaiqh", "QHCT"]);
  }
  onCloseSearch() {
    this.showSearch = false;
    this.clearHilightFeatures();
    //reset filter
    const map = this.map;
    map.setFilter(MAP_LAYERS_ID.QHC_A, ["==", "oqh_loaiqh", "QHC"]);
    map.setFilter(MAP_LAYERS_ID.QHC_P, ["==", "oqh_loaiqh", "QHC"]);
    map.setFilter(MAP_LAYERS_ID.QHPK_A, ["==", "oqh_loaiqh", "QHPK"]);
    map.setFilter(MAP_LAYERS_ID.QHPK_P, ["==", "oqh_loaiqh", "QHPK"]);
    map.setFilter(MAP_LAYERS_ID.QHCT_A, ["==", "oqh_loaiqh", "QHCT"]);
    map.setFilter(MAP_LAYERS_ID.QHCT_P, ["==", "oqh_loaiqh", "QHCT"]);
  }
  onCloseThongTinQuyHoach() {
    this.showQHInfo = false;
    this.clearHilightFeatures();
  }
  onCloseFeatureInfo() {
    this.showInfo = false;
    this.clearHilightFeatures();
  }

  onGroupTypeCheckChange(evt) {
    const self = this;
    const { checked, group } = evt;
    this.groupType = group;
    var style = this.map.getStyle();
    const qhxdLayers = style.layers.filter(function (layer) {
      return (
        layer.source &&
        layer["source-layer"] &&
        layer["source-layer"].startsWith("QHXD_") &&
        layer["source-layer"] != "QHXD_LaoCai_ThuaDat"
      );
    });
    var glayers = style.layers.filter(function (layer) {
      return layer.id && layer.id.startsWith(self.groupType + "_SDD");
    });
    const gsourceLayers = _.groupBy(glayers, "source-layer");
    console.log("map", self.map);
    //tat tat ca layer cung source
    // Object.keys(gsourceLayers).forEach((src_layer) => {
    //   const srcLayers = qhxdLayers.filter(
    //     (item) => item.source && item["source-layer"] == src_layer
    //   );
    //   if (srcLayers && srcLayers.length) {
    //     srcLayers.forEach((layerInfo) => {
    //       self.map.setLayoutProperty(layerInfo.id, "visibility", "none");
    //     });
    //   }
    // });
    //tat cac lop khac
    qhxdLayers.forEach((layerInfo) => {
      self.map.setLayoutProperty(layerInfo.id, "visibility", "none");
    });

    if (glayers && glayers.length) {
      glayers.forEach((layerInfo) => {
        this.map.setLayoutProperty(
          layerInfo.id,
          "visibility",
          checked ? "visible" : "none"
        ); // Tắt la
      });
    }
    if (glayers && glayers.length) {
      glayers.forEach((layerInfo) => {
        this.map.setLayoutProperty(
          layerInfo.id,
          "visibility",
          checked ? "visible" : "none"
        ); // Tắt la
      });
      this.map.triggerRepaint();
    }
  }

  onGroupLayerCheckChange(evt: any) {
    const { checked, group } = evt;
    const self = this;
    if (group && group.children && group.children.length) {
      var style = this.map.getStyle();
      group.children.forEach((treeLayer) => {
        var glayers = style.layers.filter(function (layer) {
          return (
            layer.source &&
            layer["source-layer"] === treeLayer.id &&
            (layer.id.startsWith(self.groupType + "_") ||
              layer["source-layer"] == "QHXD_LaoCai_ThuaDat")
          );
        });
        if (glayers && glayers.length) {
          glayers.forEach((layerInfo) => {
            this.map.setLayoutProperty(
              layerInfo.id,
              "visibility",
              checked ? "visible" : "none"
            ); // Tắt la
          });
        }
      });
    } else {
      // var style = this.map.getStyle();
      // var glayers = style.layers.filter(function (layer) {
      //   return layer.id.startsWith(group.id + "_");
      // });
      // console.log("glayers", glayers);
      // const gsourceLayers = _.groupBy(glayers, "source-layer");
      // console.log("gsource layers", gsourceLayers);
      // if (glayers && glayers.length) {
      //   glayers.forEach((layerInfo) => {
      //     this.map.setLayoutProperty(
      //       layerInfo.id,
      //       "visibility",
      //       checked ? "visible" : "none"
      //     ); // Tắt la
      //   });
      // }
    }
  }
  onLayerCheckChange(evt: any) {
    const self = this;
    const { checked, layerId } = evt;
    var style = this.map.getStyle();
    // Lọc các layer có cùng source-layer
    var glayers = style.layers.filter(function (layer) {
      if (layerId.indexOf("ThuaDat") !== -1) {
        return layer.source && layer["source-layer"] === layerId;
      } else if (self.groupType) {
        return (
          layer.source &&
          layer["source-layer"] === layerId &&
          (layer.id.startsWith(self.groupType + "_") || layer.id == layerId)
        );
      } else return layer.source && layer["source-layer"] === layerId;
    });
    if (glayers && glayers.length) {
      glayers.forEach((layerInfo) => {
        self.map.setLayoutProperty(
          layerInfo.id,
          "visibility",
          checked ? "visible" : "none"
        ); // Tắt layer
      });
    }
    this.map.triggerRepaint();
  }
  onSelectedLayerChange(evt: any) {
    const { layerId } = evt;
    this.treeLayerSelectedID = layerId;
  }

  toogleShowXY() {
    this.showXY = !this.showXY;
    this.showBaseLayer = false;
  }
  gotoXY() {
    this.map.flyTo({
      center: [this.userX, this.userY],
      essential: true,
    });
  }
  goToPoint(evt) {
    this.map.flyTo({
      center: evt.coordinates,
      zoom: 16,
      essential: true,
    });
    if (this.locatePOIMarker) this.locatePOIMarker.remove();
    var marker = new mapboxgl.Marker({ color: "#b40219", offset: [0, -25] })
      .setLngLat(evt.coordinates)
      .addTo(this.map);
    this.locatePOIMarker = marker;
  }
  async flyToWithMarker(x: any, y: any) {
    this.map.flyTo({
      center: [x, y],
      essential: true,
    });
  }

  cancelXY() {
    this.showXY = false;
  }

  overViewMap() {
    var southwest = new mapboxgl.LngLat(77.3751927499909, -0.3430056257296883); // Tây Nam
    var northeast = new mapboxgl.LngLat(131.94941747238306, 27.725469569348036); // Đông Bắc
    var bounds = new mapboxgl.LngLatBounds(southwest, northeast);
    this.map.fitBounds(bounds, {
      padding: 20, // Khoảng cách giữa biên của bản đồ và khung nhìn tổng quan
    });
  }
  toggleShowBaseLayer() {
    this.showBaseLayer = !this.showBaseLayer;
    this.showXY = false;
  }
  invalidate() {
    if (this.map) this.map.resize();
  }

  changeBaseLayer(layerId: string) {
    const self = this;
    this.map.setStyle("/assets/data/style_" + layerId + ".json");
    setTimeout(() => {
      // this.initLayer();
      self.map.addSource('mapbox-dem', {
        "type": "raster-dem",
        "url": "mapbox://mapbox.mapbox-terrain-dem-v1",
      });
      self.map.setTerrain({ "source": "mapbox-dem" });
      self.initHilightLayer();
    }, 400);
  }
  onMapClick(evt: any) {
    let self = this;
    let hilightSource = this.map.getSource("hilightFeatures");
    if (this.mode === "info") {
      let hilightParcelSource = this.map.getSource(
        "hilightSplitParcelFeatures"
      );
      if (hilightParcelSource)
        hilightParcelSource.setData({
          type: "FeatureCollection",
          features: [],
        });
      // const queryLayers = [
      //   ...this.visibleLayers.filter(
      //     (item) => item.toLowerCase().indexOf("label") == -1
      //   ),
      // ];
      const queryLayers = [
        MAP_LAYERS_ID.THUADAT,
        MAP_LAYERS_ID.QHC_LODAT,
        MAP_LAYERS_ID.QHPK_LODAT,
        MAP_LAYERS_ID.QHCT_LODAT,
        MAP_LAYERS_ID.QHC_A,
        MAP_LAYERS_ID.QHC_P,
        MAP_LAYERS_ID.QHPK_A,
        MAP_LAYERS_ID.QHPK_P,
        MAP_LAYERS_ID.QHCT_A,
        MAP_LAYERS_ID.QHCT_P,
        MAP_LAYERS_ID.QHSDDD_LABEL
      ];
      var features = this.map.queryRenderedFeatures(evt.point, {
        layers: queryLayers,
      });

      // var features = this.map.queryRenderedFeatures(evt.point);
      console.log("features", features);
      if (features && features.length) {
        var fea = features[0];
        const qhLayers = [
          MAP_LAYERS_ID.THUADAT,
          MAP_LAYERS_ID.QHC_LODAT,
          MAP_LAYERS_ID.QHPK_LODAT,
          MAP_LAYERS_ID.QHCT_LODAT,
          MAP_LAYERS_ID.QHC_A,
          MAP_LAYERS_ID.QHC_P,
          MAP_LAYERS_ID.QHPK_A,
          MAP_LAYERS_ID.QHPK_P,
          MAP_LAYERS_ID.QHCT_A,
          MAP_LAYERS_ID.QHCT_P,
          MAP_LAYERS_ID.QHSDDD_LABEL
        ];
        //thong tin quy hoach
        if (qhLayers.includes(fea.layer.id)) {
          this.showInfo = false;
          this.showQHInfo = true;
          let tdid = "";
          let ldid = "";
          let qhctid = "";
          let qhpkid = "";
          let qhcid = "";
          features.forEach((fea) => {
            if (fea.layer.id === MAP_LAYERS_ID.THUADAT)
              tdid = fea.properties.objectid;
            else if (
              fea.layer.id === MAP_LAYERS_ID.QHC_LODAT ||
              fea.layer.id === MAP_LAYERS_ID.QHPK_LODAT ||
              fea.layer.id === MAP_LAYERS_ID.QHCT_LODAT ||
              fea.layer.id === MAP_LAYERS_ID.QHSDDD_LABEL
            )
              ldid = fea.properties.objectid;
            else if (
              fea.layer.id === MAP_LAYERS_ID.QHCT_A ||
              fea.layer.id === MAP_LAYERS_ID.QHCT_P ||
              fea.layer.id === MAP_LAYERS_ID.OQH_A ||
              fea.layer.id === MAP_LAYERS_ID.OQH_P
            )
              qhctid = fea.properties.objectid;
            else if (
              fea.layer.id === MAP_LAYERS_ID.QHPK_A ||
              fea.layer.id === MAP_LAYERS_ID.QHPK_P
            )
              qhpkid = fea.properties.objectid;
            else if (
              fea.layer.id === MAP_LAYERS_ID.QHC_A ||
              fea.layer.id === MAP_LAYERS_ID.QHC_P
            )
              qhcid = fea.properties.objectid;
          });
          console.log("qhctid", qhctid);
          setTimeout(() => {
            this.quyHoachIDXD = {
              thuaDatId: tdid ? parseInt(tdid) : null,
              loDatId: ldid ? parseInt(ldid) : null,
              oQuyHoachId: qhctid ? parseInt(qhctid) : null,
              kQuyHoachId: qhpkid ? parseInt(qhpkid) : null,
              qhcId: qhcid ? parseInt(qhcid) : null,
            };
            console.log("hqhhhqq", this.quyHoachIDXD);
          }, 300);
          let layerName = "";
          let queryId = null;
          if (tdid) {
            queryId = tdid;
            layerName = MAP_LAYERS_ID.THUADAT_NAME;
          } else if (ldid) {
            queryId = ldid;
            layerName = MAP_LAYERS_ID.CO_CAU_PHAN_LO;
          } else if (qhctid) {
            queryId = qhctid;
            layerName = MAP_LAYERS_ID.OQH_NAME;
          } else if (qhpkid) {
            queryId = qhpkid;
            layerName = MAP_LAYERS_ID.KHUQH;
          } else if (qhcid) {
            queryId = qhcid;
            layerName = MAP_LAYERS_ID.KHUQH;
          }
          if (queryId && layerName) {
            this.mapService
              .getThongTinQuyHoach(layerName, "objectid", queryId, false)
              .subscribe((rs) => {
                if (rs && rs.dsThuocTinh && rs.dsThuocTinh.length) {
                  const geomObj = rs.dsThuocTinh.find(
                    (item) => item.Key === "geom"
                  );
                  if (geomObj) {
                    const pfea = wellknown.parse(geomObj.Value);
                    if (pfea) {
                      const hFea = {
                        ...fea,
                        geometry: { ...pfea },
                        properties: {
                          mode: "normal",
                        },
                      };
                      hilightSource.setData({
                        type: "FeatureCollection",
                        features: [hFea],
                      });
                    } else {
                      hilightSource.setData({
                        type: "FeatureCollection",
                        features: [fea],
                      });
                    }
                  }
                }
              });
          }
        } else {
          this.showInfo = true;
          this.showQHInfo = false;
          this.layerId = fea.layer.id;
          this.featureProps = fea.properties;
          hilightSource.setData({
            type: "FeatureCollection",
            features: [fea],
          });
        }
      } else {
        this.clearHilightFeatures();
        this.showQHInfo = false;
      }
    } else if (this.mode === "search_poi") {
      if (this.POIMarker) this.POIMarker.remove();
      this.clearHilightFeatures();
      const { lng, lat } = evt.lngLat;
      var marker = new mapboxgl.Marker()
        .setLngLat([lng, lat]) // coordinates of the marker [lng, lat]
        .addTo(this.map);
      const spoint = turf.point([lng, lat]);
      this.POIMarker = marker;
      this.POILoc = spoint;
      const circleGeom = turf.buffer(spoint, this.poiRadius, {
        units: "meters",
        steps: 36,
      });
      const circleFeature = {
        ...circleGeom,
        properties: {
          mode: "circle",
        },
      };
      hilightSource.setData({
        type: "FeatureCollection",
        features: [circleFeature],
      });
      var wkt = wellknown.stringify(circleGeom);
      this.handleSearchPOI(wkt);
    }
    // const { lng, lat } = evt.lngLat;
    // const coordinate = [lng, lat];
    // const elevation = this.map.queryTerrainElevation(coordinate);
    // console.log('elevation', elevation)
  }
  handleCloseSearchPOI() {
    this.clearHilightFeatures();
    this.showSearchPOI = false;
    if (this.POIMarker) this.POIMarker.remove();
    if (this.locatePOIMarker) this.locatePOIMarker.remove();
    this.mode = "";
    let POISource = this.map.getSource("POIFeatures");
    POISource.setData({
      type: "FeatureCollection",
      features: [],
    });
  }
  handleSearchPOIRadiusChange(radius) {
    this.poiRadius = radius;
    if (this.mode === "search_poi") {
      let hilightSource = this.map.getSource("hilightFeatures");
      this.clearHilightFeatures();
      const spoint = this.POILoc;
      const circleGeom = turf.buffer(spoint, radius, {
        units: "meters",
        steps: 36,
      });
      const circleFeature = {
        ...circleGeom,
        properties: {
          mode: "circle",
        },
      };
      hilightSource.setData({
        type: "FeatureCollection",
        features: [circleFeature],
      });
      var wkt = wellknown.stringify(circleGeom);
      this.handleSearchPOI(wkt);
    }
  }

  handleViewPortChange(areaFeature) {
    let hilightSource = this.map.getSource("hilightParcelFeatures");
    const pcenter = turf.center(areaFeature);
    this.map.flyTo({
      center: pcenter.geometry.coordinates,
      zoom: 13,
      essential: true,
    });
    hilightSource.setData({
      type: "FeatureCollection",
      features: [areaFeature],
    });
  }
  hilightParcel(parcelFeature) {
    let hilightSource = this.map.getSource("hilightParcelFeatures");
    const pcenter = turf.center(parcelFeature);
    this.map.flyTo({
      center: pcenter.geometry.coordinates,
      zoom: 18,
      essential: true,
    });
    hilightSource.setData({
      type: "FeatureCollection",
      features: [parcelFeature],
    });
  }

  hilightSplitParcel(parcelFeature) {
    let hilightSource = this.map.getSource("hilightSplitParcelFeatures");
    const pcenter = turf.center(parcelFeature);
    this.map.flyTo({
      center: pcenter.geometry.coordinates,
      zoom: 20,
      essential: true,
    });
    hilightSource.setData({
      type: "FeatureCollection",
      features: [parcelFeature],
    });
  }

  updateArea() {
    let self = this;
    if (this.drawControl) {
      var data = this.drawControl.getAll();
      if (data.features.length > 0) {
        const drawFea = data.features[0];
        // console.log("on drawFea drawFeadrawFeadrawFeadrawFea", drawFea);
        // console.log("mode", this.mode);
        // console.log("searchRadius", self.searchRadius);
        // console.log("draw control", this.drawControl);
        if (this.mode === "search") {
          if (drawFea.geometry.type === "LineString") {
            if (self.searchRadius && self.searchRadius > 0) {
              const lineBuffer = turf.buffer(drawFea, self.searchRadius, {
                units: "meters",
              });
              this.drawControl.deleteAll();
              this.drawControl.add(lineBuffer);
            }
          } else if (
            drawFea.geometry.type === "Polygon" ||
            drawFea.geometry.type === "MultiplePolygon"
          ) {
            var area = turf.area(data);
          } else if (drawFea.geometry.type === "Point") {
            const geomBuffer = turf.buffer(drawFea, self.searchRadius, {
              units: "meters",
            });
            this.drawControl.deleteAll();
            this.drawControl.add(geomBuffer);
          }
        }
      }
    }
  }
  clearHilightFeatures() {
    if (!this.map) return;
    let hilightSource = this.map.getSource("hilightFeatures");
    if (hilightSource)
      hilightSource.setData({
        type: "FeatureCollection",
        features: [],
      });

    let hilightParcelSource = this.map.getSource("hilightParcelFeatures");
    if (hilightParcelSource)
      hilightParcelSource.setData({
        type: "FeatureCollection",
        features: [],
      });

    let hilightSplitParcelSource = this.map.getSource(
      "hilightSplitParcelFeatures"
    );
    if (hilightSplitParcelSource)
      hilightSplitParcelSource.setData({
        type: "FeatureCollection",
        features: [],
      });
  }
  hilightData(row, geocol) {
    // const features = this.map.queryRenderedFeatures({ layers: [this.processId], filter: ["any", ["==", ["id"], row.id]] });
  }

  async editFeature(rawData: any, geoCol: string, geometryType: string) { }

  clearEditFeature() {
    this.drawControl.deleteAll();
    this.drawControl.changeMode(this.drawControl.modes.SIMPLE_SELECT);
  }
  isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }
  getSearchGeometry() {
    let geofilter = "";
    if (this.mode === "search") {
      const featureCollection = this.drawControl.getAll();
      if (featureCollection.features && featureCollection.features.length) {
        var fea = featureCollection.features[0];
        geofilter = wellknown.stringify(fea);
      }
    }
    return geofilter;
  }
  activeSearch(evt) {
    const { type, radius } = evt;
    this.mode = "search";
    this.searchRadius = radius;
    this.clearEditFeature();
    this.clearHilightFeatures();
    this.drawControl.deleteAll();
    if (type == "line") {
      this.drawControl.changeMode(this.drawControl.modes.DRAW_LINE_STRING);
    } else if (type == "polygon") {
      this.drawControl.changeMode(this.drawControl.modes.DRAW_POLYGON);
    } else if (type == "point") {
      this.drawControl.changeMode(this.drawControl.modes.DRAW_POINT);
    }
  }

  handleSearch(searchParams) {
    const self = this;
    const map = this.map;
    this.showSearchResult = false;
    console.log('search pấm', searchParams)
    map.setFilter(MAP_LAYERS_ID.QHC_A, null);
    map.setFilter(MAP_LAYERS_ID.QHC_P, null);
    map.setFilter(MAP_LAYERS_ID.QHPK_A, null);
    map.setFilter(MAP_LAYERS_ID.QHPK_P, null);
    map.setFilter(MAP_LAYERS_ID.QHCT_A, null);
    map.setFilter(MAP_LAYERS_ID.QHCT_P, null);

    if (!searchParams) {
      this.clearEditFeature();
      this.clearHilightFeatures();
      return;
    }
    const geoFilter = this.getSearchGeometry();
    if (searchParams.cnxd) {
      //doi filter null apply
      setTimeout(() => {
        var filter = ["==", "oqh_chucnangsdd", searchParams.cnxd];
        const layerQHCA = map.getLayer(MAP_LAYERS_ID.QHC_A);
        const layerQHCP = map.getLayer(MAP_LAYERS_ID.QHC_P);
        const layerQHPKA = map.getLayer(MAP_LAYERS_ID.QHPK_A);
        const layerQHPKP = map.getLayer(MAP_LAYERS_ID.QHPK_P);

        const layerQHCTA = map.getLayer(MAP_LAYERS_ID.QHCT_A);
        const layerQHCTP = map.getLayer(MAP_LAYERS_ID.QHCT_P);

        if (layerQHCA.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHC_A],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHCCount = filterCN.length;
        }
        if (layerQHCP.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHC_P],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHCCount = filterCN.length;
        }
        if (layerQHPKA.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHPK_A],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHPKCount = filterCN.length;
        }
        if (layerQHPKP.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHPK_P],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHCTCount = filterCN.length;
        }
        if (layerQHCTA.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHCT_A],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHPKCount = filterCN.length;
        }
        if (layerQHCTP.visibility !== "none") {
          const cFeatures = map.queryRenderedFeatures({
            layers: [MAP_LAYERS_ID.QHCT_P],
          });
          const filterCN = cFeatures.filter(
            (item) => item.properties.oqh_chucnangsdd == searchParams.cnxd
          );
          this.searchQHCTCount = filterCN.length;
        }
        map.setFilter(MAP_LAYERS_ID.QHC_A, filter);
        map.setFilter(MAP_LAYERS_ID.QHC_P, filter);
        map.setFilter(MAP_LAYERS_ID.QHPK_A, filter);
        map.setFilter(MAP_LAYERS_ID.QHPK_P, filter);
        map.setFilter(MAP_LAYERS_ID.QHCT_A, filter);
        map.setFilter(MAP_LAYERS_ID.QHCT_P, filter);
        setTimeout(() => {
          this.searchBox.setResults({
            QHC: this.searchQHCCount,
            QHPK: this.searchQHPKCount,
            QHCT: this.searchQHCTCount,
          });
          this.showSearchResult = true;
        }, 400);
      }, 300);
    } else if (searchParams.address) {
      this.mode = "info";
      const lat = parseFloat(searchParams.address.lat)
      const lng = parseFloat(searchParams.address.lon)
      map.flyTo({
        center: [lng, lat],
        zoom: 16,
        pitch: 0,
        essential: true,
      });

      map.once("zoomend", () => {
        self.mode = "info";
        // this.mode = "info";
        setTimeout(() => {
          map.fire('click', {
            lngLat: new mapboxgl.LngLat(lng, lat)
          })
        }, 200);
      })
      if (this.locatePOIMarker) this.locatePOIMarker.remove();
      var marker = new mapboxgl.Marker({ color: "#b40219", offset: [0, -25] })
        .setLngLat([lng, lat])
        .addTo(this.map);
      this.locatePOIMarker = marker;

    } else if (searchParams.coordinates) {
      const lat = searchParams.coordinates.lat
      const lng = searchParams.coordinates.lng
      map.flyTo({
        center: [lng, lat],
        zoom: 16,
        pitch: 0,
        essential: true,
      });
      if (this.locatePOIMarker) this.locatePOIMarker.remove();
      var marker = new mapboxgl.Marker({ color: "#b40219", offset: [0, -25] })
        .setLngLat([lng, lat])
        .addTo(this.map);
      this.locatePOIMarker = marker;
      map.once("zoomend", () => {
        self.mode = "info";
        setTimeout(() => {
          map.fire('click', {
            lngLat: new mapboxgl.LngLat(lng, lat)
          })
        }, 200);
      })
    }
    else {
      const filterParams = [];
      if (geoFilter) {
        filterParams.push({
          field: "geom",
          operato: "ST_Intersects",
          join: "and",
          value: geoFilter,
        });
      }

      if (searchParams.ccode)
        filterParams.push({
          field: "maxa",
          operato: "=",
          join: "and",
          value: "'" + searchParams.ccode.toString() + "'",
        });

      if (searchParams.soto || searchParams.sothua) {
        if (searchParams.soto)
          filterParams.push({
            field: "soto",
            operato: "=",
            join: "and",
            value: "'" + searchParams.soto.toString() + "'",
          });
        if (searchParams.sothua)
          filterParams.push({
            field: "sothua",
            operato: "=",
            join: "and",
            value: "'" + searchParams.sothua.toString() + "'",
          });
      }
      if (filterParams.length) {
        this.searchParcelParams = filterParams;
        this.mapService
          .searchParcel("QHXD_LaoCai_ThuaDat", filterParams)
          .subscribe((rs: any) => {
            this.dsThua = rs;
            this.showThuaDat = true;
            const parcelFeatures = [];
            rs.forEach((p) => {
              const pGeometry = wellknown.parse(p.geom);
              parcelFeatures.push({
                type: "feature",
                geometry: pGeometry,
                properties: {
                  ...p,
                  geom: "",
                },
              });
            });
            map.getSource("hilightParcelFeatures").setData({
              type: "FeatureCollection",
              features: [...parcelFeatures],
            });
          });
      }
    }
  }
  handleSearchPOI(geoFilter) {
    const filterParams = [
      {
        field: "geom",
        operato: "ST_Intersects",
        join: "and",
        value: geoFilter,
      },
    ];
    if (geoFilter) {
      this.loadingSearchPOI = true;
      this.mapService
        .searchPOI("POI_LaoCai", filterParams)
        .subscribe((rs: any) => {
          this.dsThua = rs;
          const searchFeatures = [];
          rs.forEach((p) => {
            const pGeometry = wellknown.parse(p.geom);
            searchFeatures.push({
              type: "Feature",
              geometry: pGeometry,
              properties: {
                ...p,
              },
            });
          });
          let POISource = this.map.getSource("POIFeatures");
          this.POIResults = searchFeatures;
          this.loadingSearchPOI = false;
          if (searchFeatures && searchFeatures.length) {
            POISource.setData({
              type: "FeatureCollection",
              features: [...searchFeatures],
            });
          } else {
            POISource.setData({
              type: "FeatureCollection",
              features: [],
            });
          }
        });
    }
  }

  handleChangeMapScene(evt: boolean) {
    if (!evt) {//show 3D
      this.map.easeTo({
        pitch: 70, // Góc nghiêng của bản đồ (đặt giá trị từ 0 đến 60 độ)
        duration: 1000 // Thời gian chuyển động
      });
    } else {
      this.map.easeTo({
        pitch: 0, // Góc nghiêng của bản đồ (đặt giá trị từ 0 đến 60 độ)
        duration: 1000 // Thời gian chuyển động
      });
    }
  }
  toggleShowAdministrative() {
    this.showAdministrative = !this.showAdministrative;
    if (!this.showAdministrative) this.clearHilightFeatures();
  }
  onCloseImageViewer() {
    this.isShowImage = false;
  }
  showImage(imageId) {
    this.images = ["/assets/cayxanh/" + imageId + ".jpg"];
    this.isShowImage = true;
  }
}
