<ng-container>
    <div *ngFor="let column of columns; let i = index"
        class="{{dataTypeArray.includes(column.datatype) ? ('inline-block col-sm-'+column.col) : 'inline-block d-none'}}">
        <div [ngSwitch]="column.datatype" class="gen-html-form">

            <div class="form-group width100" *ngSwitchCase="'text'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <input type="text" class="form-control" placeholder={{column.placeholder}}
                    [(ngModel)]="columns[i].value" name={{column.name}} (blur)="onInputChange(column)"
                    [disabled]="column.disable" [required]="column.required">
            </div>
            <div class="form-group width100" *ngSwitchCase="'email'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <input type="email" class="form-control" placeholder={{column.placeholder}}
                    [(ngModel)]="columns[i].value" name={{column.name}} (blur)="onInputChange(column)"
                    [disabled]="column.disable" [required]="column.required">
            </div>
            <div class="form-group width100" *ngSwitchCase="'number'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <input type="number" class="form-control" placeholder={{column.placeholder}}
                    [(ngModel)]="columns[i].value" name={{column.name}} (blur)="onInputChange(column)"
                    [disabled]="column.disable" [required]="column.required">
            </div>

            <div class="form-group  i-check width100" *ngSwitchCase="'checkbox'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <br>
                <input type="checkbox" [(ngModel)]="columns[i].value" name={{column.export}}
                    (blur)="onInputChange(column)" [disabled]="column.disable" [required]="column.required">
                <label>{{column.placeholder}}</label>
            </div>

            <div class="form-group width100" *ngSwitchCase="'password'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label }}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <input type="password" class="form-control" placeholder="******" [(ngModel)]="columns[i].value"
                    name={{column.name}} (blur)="onInputChange(column)" [disabled]="column.disable"
                    [required]="column.required">


            </div>

            <div class="form-group  width100" *ngSwitchCase="'file'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <input type="file" [multiple]="column.multiple" (change)="onFileChange($event,column)"
                    name={{column.name}} [disabled]="column.disable" [required]="column.required"
                    accept="{{column.accept?column.accept:'*.*'}}">
            </div>
            <div class="form-group  width100" *ngSwitchCase="'ckeditor'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <ckeditor [data]="columns[i].value" [editor]="configsss" [(ngModel)]="columns[i].value"
                    name="classic-editor" (change)="onCkEditorChange($event,column)">
                </ckeditor>
            </div>
            <div class="form-group  width100" *ngSwitchCase="'textarea'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <textarea class="form-control" rows="3" required [(ngModel)]="columns[i].value" name={{column.name}}
                    (blur)="onInputChange(column)" [disabled]="column.disable" [required]="column.required"></textarea>
            </div>
            <div class="form-group  width100" *ngSwitchCase="'combobox'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <select class="form-control" name={{column.name}} [(ngModel)]="columns[i].value"
                    (change)="onSelectChangeCombobox($event,column)" [disabled]="column.disable"
                    [required]="column.required">
                    <option *ngFor="let item of column.data" [ngValue]="item.value">{{item.label}}</option>
                </select>
            </div>

            <div class="form-group  width100" *ngSwitchCase="'autocomplete'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <app-autocomplete (autocompleteComponentEmiter)="autocompleteComponentEmiter($event,column)">
                </app-autocomplete>
            </div>

            <div class="form-group width100" *ngSwitchCase="'radio'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label>{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <br>
                <label class="radio-inline" *ngFor="let item of column.data">
                    <input [value]="item.value" [checked]="item.value == column.value? 'checked':''" type="radio"
                        name={{column.name}} (change)="onRadioChange(item.value,column)" [disabled]="column.disable"
                        [required]="column.required">
                    {{item.label}}</label>
            </div>
            <div class="form-group width1002" *ngSwitchCase="'date'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <label for="datepicker">{{column.label}}</label>
                <p *ngIf="column.required && !column.disable">*</p>
                <div class="input-group">

                    <input type="text" class="form-control" bsDatepicker [maxDate]="maxstartDate" [bsConfig]="{ 
                        isAnimated: true,
                         dateInputFormat: column.format ==undefined ? 'DD/MM/YYYY': column.format,
                    minMode:column.minmode==undefined ? 'day':column.minmode,adaptivePosition: true
                 }" [(ngModel)]="columns[i].value" name={{column.name}} (ngModelChange)="onDateChange($event,column)"
                        [disabled]="column.disable" [required]="column.required">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="width100" *ngSwitchCase="'label'">
                <label>{{column.label}}</label>
            </div>
            <div class="form-group dropdown  width100" *ngSwitchCase="'combobox_multi'"
                class="{{column.required && column.empty?'ct-required':''}}">
                <div>
                    <label>{{column.label}}</label>
                    <p *ngIf="column.required && !column.disable">*</p>
                </div>

                <mat-form-field appearance="fill">
                    <mat-label> {{column.placeholder}}</mat-label>
                    <mat-select [formControl]="column.itemsControl" multiple
                        (selectionChange)="onSelectChangeComboboxMulti($event,column)">
                        <mat-option *ngFor="let item of column.data" [value]="item.value">

                            <mat-pseudo-checkbox class="example-pseudo-checkbox"></mat-pseudo-checkbox>
                            {{item.label}}

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</ng-container>