<div class="feature-info" cdkDrag>
    <div class="header">
        <h4 style="margin-bottom: 0px;">Hình ảnh</h4>
        <button type="button" (click)="close()" class="btn closebtn">
            <i class="fa fa-close"></i>
        </button>
    </div>
    <hr style="margin:10px; height:1px;background:#ccc;" />
    <div class="feature-info-body">
        <ngx-image-viewer [src]="imageUrls"
            [config]="{customBtns:[{name: 'link', icon: 'fa fa-paperclip'}]}" (customEvent)="handleEvent($event)">
        </ngx-image-viewer>
    </div>
</div>