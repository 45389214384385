import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import * as turf from "@turf/turf";
@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class MeasureComponent implements AfterViewInit {
  @Input() map: any;
  @Input() drawControl: any;
  mode: string = '';
  constructor() { }

  ngAfterViewInit(): void {
    const map = this.map;
    const self = this;
    map.on("draw.create", self.updateArea.bind(this));
    map.on("draw.delete", self.updateArea.bind(this));
    map.on("draw.update", self.updateArea.bind(this));
    this.initLayer()
    if (!this.map.getSource("measureControlFeatures")) {
      this.initLayer();
    }
  }

  ngOnInit(): void {
  }

  initLayer() {
    this.map.addSource("measureControlFeatures", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    this.map.addLayer({
      id: "MLineLayer",
      type: "line",
      source: "measureControlFeatures",
      filter: ["==", "mode", "line"],
      layout: {
        "line-join": "round",
        "line-cap": "round",
        visibility: "visible",
      },
      paint: {
        "line-color": "orange",
        "line-width": 1,
        "line-dasharray": [2, 2],
      },
    });


    this.map.addLayer({
      id: "MPointLayer",
      type: "circle",
      source: "measureControlFeatures",
      layout: {
        visibility: "visible",
      },
      paint: {
        "circle-radius": 3,
        "circle-color": "orange",
        "circle-stroke-width": 1,
        "circle-stroke-color": "white"
      },
    });

    this.map.addLayer({
      id: "MAreaLayer",
      type: "fill",
      filter: ["==", "mode", "area"],
      source: "measureControlFeatures",
      paint: {
        "fill-outline-color": "orange",
        "fill-color": "orange",
        "fill-opacity": 0.2,
      },
    });
    this.map.addLayer({
      id: "MTextLayer",
      type: 'symbol',
      source: "measureControlFeatures",
      layout: {
        "text-field": ['get', 'label'],
        'text-anchor': 'center',
        'text-size': 12,
        'text-offset': [0, 0],
        'text-allow-overlap': false,
        "text-font": [
          "Noto Sans Bold"
        ]
      },
      paint: {
        'text-color': 'green'
      }
    });
  }

  measureLength() {
    this.mode = "line"
    this.drawControl.changeMode("draw_line_string");
  }

  measureArea() {
    this.mode = "area"
    this.drawControl.changeMode("draw_polygon");
  }

  clear() {
    this.drawControl.deleteAll();
    let mSource = this.map.getSource("measureControlFeatures");
    if (mSource) {
      mSource.setData({
        type: "FeatureCollection",
        features: []
      });
    }
  }
  formatNumber(num) {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  updateArea() {
    let self = this;
    let mSource = self.map.getSource("measureControlFeatures");
    if (this.drawControl) {
      var data = this.drawControl.getAll();
      this.drawControl.deleteAll();
      if (data.features.length > 0) {
        const drawFea = data.features[0];
        drawFea.properties = {
          mode: this.mode
        }
        if (drawFea.geometry.type === "LineString") {
          if (mSource) {
            const coords = drawFea.geometry.coordinates;
            const mfeatures = [];
            for (let i = 0; i < coords.length - 1; i++) {
              const start = coords[i];
              const end = coords[i + 1];
              const startPoint = turf.point(start);
              const endPoint = turf.point(end);
              const midpoint = turf.midpoint(startPoint, endPoint);
              // const length = turf.distance(startPoint, endPoint, { units: 'meters' });
              const lengthInMeters = turf.distance(startPoint, endPoint, { units: 'meters' });
              let formattedLength;
              if (lengthInMeters > 1000) {
                const lengthInKm = lengthInMeters / 1000;
                formattedLength = (Math.round(lengthInKm * 100) / 100).toString() + ' km';
              } else {
                formattedLength = (Math.round(lengthInMeters * 100) / 100).toString() + ' m';
              }

              mfeatures.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: midpoint.geometry.coordinates
                },
                properties: {
                  label: formattedLength
                }
              });
            }
            mSource.setData({
              type: "FeatureCollection",
              features: [drawFea, ...mfeatures]
            });
          }
        } else if (
          drawFea.geometry.type === "Polygon" ||
          drawFea.geometry.type === "MultiplePolygon"
        ) {
          var area = turf.area(data);
          var centroid = turf.centroid(drawFea);
          var formattedArea;

          if (area < 1000000) {
            formattedArea = (this.formatNumber(Math.round(area * 100) / 100)).toString() + ' m²';
          } else {
            var areaInKm2 = area / 1000000;
            formattedArea = (Math.round(areaInKm2 * 100) / 100).toString() + ' km²';
          }
          centroid.properties = {
            label: formattedArea
          }
          const feas = [drawFea, centroid];
          if (mSource)
            mSource.setData({
              type: "FeatureCollection",
              features: feas
            });
        } else if (drawFea.geometry.type === "Point") {
        }
      }
    }
  }
}
