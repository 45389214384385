import { Injectable, Component, Inject, PLATFORM_ID } from '@angular/core';
import { StorageService } from './storage.service';
import * as Constants from '../Constants/Constants';
import { isPlatformBrowser } from '@angular/common';
import { ConverterService } from './converter.service';
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private storageService: StorageService,
    private converterService: ConverterService,
  ) { }


  async checkPermision(): Promise<any> {
    return new Promise((resolve) => {
      this.storageService.get(Constants.AUTH_TOKEN).then((token) => {
        if (token != undefined && token != '') resolve(true);
        else {
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = Constants.FORMS.LOGIN;
          }

          resolve(false);
        }
      });
    });
  }

  async logError(error: any): Promise<any> {
    return new Promise((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        window.location.href = Constants.FORMS.LOGIN;
      }
      resolve(true);
    });
  }

  getStatus(status: any) {
    if (status == null || status == undefined)
      return "";

    if (
      status.toLowerCase() ==
      Constants.STATUS_USER.ACTIVE.VALUE.toLocaleLowerCase()
    ) {
      return Constants.STATUS_USER.ACTIVE.LABEL;
    }
    if (
      status.toLowerCase() ==
      Constants.STATUS_USER.IN_ACTIVE.VALUE.toLocaleLowerCase()
    )
      return Constants.STATUS_USER.IN_ACTIVE.LABEL;

    if (
      status.toLowerCase() ==
      Constants.STATUS_USER.DELETED.VALUE.toLocaleLowerCase()
    )
      return Constants.STATUS_USER.DELETED.LABEL;

    return '';
  }

  async mapColumnsToObject(cloumns: any, object: any): Promise<any> {
    return new Promise((resolve) => {
      for (let index = 0; index < cloumns.length; index++) {
        const cloumn = cloumns[index];

        //if(object!=null && object[cloumn.name] !=undefined)
        if (cloumn.datatype != Constants.DATA_TYPE_OBJECT.DATE) {
          object[cloumn.name] = cloumn.value;
        }
        else {
          if (cloumn.value != null && cloumn.value != undefined) {
            object[cloumn.name] = cloumn.value
            // object[cloumn.name] = this.converterService.dateToTimestamp(
            //   cloumn.value
            // );
          }
          else
            object[cloumn.name] = 0;
        }
      }

      resolve(object);
    });
  }

  //Find objects in array1 that are not in array2 based on the 'key' property
  async findObjectsNotInArray(
    array1: any,
    array2: any,
    key: any
  ): Promise<any> {
    return new Promise((resolve) => {
      if (array1 == null || array2 == null)
        resolve(array1);
      // Extract ids from array2
      const idsInArray2 = new Set(array2.map((obj: any) => obj[key]));

      // Filter objects in array1 that are not in array2 based on the 'id' property
      const objectsNotInArray2 = array1.filter(
        (obj: any) => !idsInArray2.has(obj[key])
      );

      resolve(objectsNotInArray2);
    });
  }

  async randomUUID(): Promise<any> {
    return new Promise((resolve) => {
      resolve(uuidv4());
    });
  }

  isNumber(value: any): Promise<any> {
    return new Promise((resolve) => {
      //resolve(typeof value === 'number');
      resolve(!isNaN(value))
    })
  }

  validateEmail(email: any): Promise<any> {
    return new Promise((resolve) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      resolve(emailPattern.test(email));
    })
  }

  // Hàm kiểm tra dữ liệu số điện thoại
  validatePhoneNumber(phone: any): Promise<any> {
    return new Promise((resolve) => {

      this.isNumber(phone).then(number => {
        if (number && phone.length >= 10 && phone.length < 13)
          resolve(true);
        else
          resolve(false);
      })

    })
  }

  getLabelByValue(dm: any, value: any) {

    if (dm == null || dm == undefined || dm.length == 0
      || value == null || value == undefined || value.length == 0)
      return "";
    else {
      var item = dm.find((x: any) => x.value == value);
      if (item != null && item != undefined) {
        return item.label;
      }
    }
  }

  getValueByLabel(dm: any, label: any) {

    if (dm == null || dm == undefined || dm.length == 0
      || label == null || label == undefined || label.length == 0)
      return "";
    else {
      var item = dm.find((x: any) => x.label == label);
      if (item != null && item != undefined) {
        return item.value;
      }
    }
  }

  checkLabelExistsInDanhMuc(dm: any, label: any) {

    if (dm == null || dm == undefined || dm.length == 0
      || label == null || label == undefined || label.length == 0)
      return false;
    else {
      var item = dm.find((x: any) => x.label == label);
      if (item != null && item != undefined) {
        return true;
      }
      else
        return false;
    }
  }

  addAllToCombobox(dm: any) {
    if (dm == null)
      return dm;
    dm.unshift(Constants.CHON_ALL);

    return dm;
  }

  async getNameColumnOfExcel(index: any): Promise<any> {
    return new Promise((resolve) => {

      var rs = Constants.COL_EXCEL[index];
      console.log(rs);

      resolve(rs);
    })
  }

  getFirstDayInMonth() {
    const today = new Date();  // Get the current date
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return this.converterService.dateToTimestamp(firstDayOfMonth)
  }

  getLastDayInMonth() {
    const today = new Date();  // Get the current date
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return this.converterService.dateToTimestamp(lastDayOfMonth)
  }

  removeVietnameseDiacritics(str: string): string {
    const accentsMap = {
      'à': 'a', 'á': 'a', 'ạ': 'a', 'ả': 'a', 'ã': 'a', 'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ậ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ặ': 'a', 'ẳ': 'a', 'ẵ': 'a',
      'è': 'e', 'é': 'e', 'ẹ': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ê': 'e', 'ề': 'e', 'ế': 'e', 'ệ': 'e', 'ể': 'e', 'ễ': 'e',
      'ì': 'i', 'í': 'i', 'ị': 'i', 'ỉ': 'i', 'ĩ': 'i',
      'ò': 'o', 'ó': 'o', 'ọ': 'o', 'ỏ': 'o', 'õ': 'o', 'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ộ': 'o', 'ổ': 'o', 'ỗ': 'o', 'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ợ': 'o', 'ở': 'o', 'ỡ': 'o',
      'ù': 'u', 'ú': 'u', 'ụ': 'u', 'ủ': 'u', 'ũ': 'u', 'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ự': 'u', 'ử': 'u', 'ữ': 'u',
      'ỳ': 'y', 'ý': 'y', 'ỵ': 'y', 'ỷ': 'y', 'ỹ': 'y',
      'đ': 'd',
      // Add the uppercase equivalents
      'À': 'A', 'Á': 'A', 'Ạ': 'A', 'Ả': 'A', 'Ã': 'A', 'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ậ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ặ': 'A', 'Ẳ': 'A', 'Ẵ': 'A',
      'È': 'E', 'É': 'E', 'Ẹ': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ệ': 'E', 'Ể': 'E', 'Ễ': 'E',
      'Ì': 'I', 'Í': 'I', 'Ị': 'I', 'Ỉ': 'I', 'Ĩ': 'I',
      'Ò': 'O', 'Ó': 'O', 'Ọ': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ộ': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ợ': 'O', 'Ở': 'O', 'Ỡ': 'O',
      'Ù': 'U', 'Ú': 'U', 'Ụ': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ự': 'U', 'Ử': 'U', 'Ữ': 'U',
      'Ỳ': 'Y', 'Ý': 'Y', 'Ỵ': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y',
      'Đ': 'D'
    };

    return str.split('').map(char => accentsMap[char] || char).join('');
  }

   setWidthModal(){
  $(document).ready(()=>{
    console.log("jquery ok");
    $('.modal-dialog').css('max-width', '95%');
  })
}

}
