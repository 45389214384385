<div class="feature-info">
  <div class="header">
    <h4 style="margin-bottom: 0px">Thông tin đối tượng</h4>
    <button type="button" (click)="close()" class="btn closebtn">
      <i class="fa fa-close"></i>
    </button>
  </div>
  <hr style="margin: 10px; height: 1px; background: #ccc" />
  <div class="feature-info-body">
    <owl-carousel-o *ngIf="tableName === 'QHXD_LaoCai_HTKT_CayXanh'" [options]="carouselOption" class="events navs-carousel">
      <ng-template
        carouselSlide
        style="flex: 0 0 20%"
        *ngFor="let img of postImages"
      >
        <div class="item">
          <div class="card text-center team-box">
            <div class="card-body">
              <div>
                <img [src]="img.src" alt="" height="250px" class="rounded" />
                <!-- ./assets/images/slides/cantho_danhmucdautu.png -->
              </div>

              <!-- <div class="mt-3">
                              <a [href]="ungDung.link" >
                                <h5>{{ungDung.label}}</h5>
                              </a>
                            </div> -->
            </div>
            <!-- <div class="card-footer bg-transparent border-top">
              <div class="d-flex mb-0 team-social-links">
                <button class="btn-success btn w-100">Xem chi tiết</button>
              </div>
            </div> -->
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
    <table class="table table-bordered">
      <tr
        *ngFor="let prop of featureProps; index as rowIndex; trackBy: trackFunc"
      >
        <td>{{ prop.key }}</td>
        <td>{{ prop.value }}</td>
      </tr>
      <tr *ngIf="tableName == 'QHXD_LaoCai_CayXanh'">
        <td>Hình ảnh</td>
        <td>
          <a
            href="javascript:void(0)"
            (click)="openImage(this.featureInfo['malienket'])"
            >{{ this.featureInfo["malienket"] }}</a
          >
        </td>
      </tr>
      <tr *ngIf="tableName.indexOf('Camera') !== -1">
        <td>Video stream</td>
        <td>
          <a
            href="javascript:void(0)"
            (click)="openVideo(this.featureInfo['id'])"
            >Xem video</a
          >
        </td>
      </tr>
    </table>
    <!-- <div *ngIf="!isLoadingBlock">
            <a href="javascript:void(0)" (click)="showDSThua()">Danh sách thửa đất dính quy hoạch: {{soLuongThua}}</a>
        </div> -->
  </div>
  <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
</div>
