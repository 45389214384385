import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { conditions } from 'src/app/contants';

@Component({
  selector: 'app-map-column',
  templateUrl: './map-column.component.html',
  styleUrls: ['./map-column.component.scss']
})
export class MapColumnComponent implements OnInit {
  @Input() optionFilterGlobal: any[] = [];
  @Input() data: any = [];
  @Input() optionsFilter: any = [];
  @Output() onAction = new EventEmitter<any>();
  conditions: any[] = conditions;
  constructor() {
   }

  ngOnInit(): void {
  }

  addCondition() {
    this.data.push({
      "source": "",
      "target": ""
    })
  }

  pushData(){
    let obj = {
      key: "set_map_column",
      value: this.data
    }
    this.onAction.emit(obj);
  }

  changeSource(event, item, index) {
    console.log("=======event=======", event);
    this.data[index] = {
      ...this.data[index],
      source: event.colname,
    }
    console.log("=======data=======", this.data);

    this.pushData();
  }

  changeOperator(event, index){
    this.data[index] = {
      ...this.data[index],
      operator: event.target.value,
    }
    this.pushData();
  }

  changeTarget(event, item, index) {
    this.data[index] = {
      ...this.data[index],
      target: event.value,
    }
    console.log("=======data=======",event, this.data);

    this.pushData();
  }

  removeCondition(item, index) {
    this.data.splice(index, 1);
    this.pushData();
  }
}
