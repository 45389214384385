import { Action, createReducer, on } from '@ngrx/store';
import { increment, decrement, setWorkspaceId } from '../action/commons.actions';
export interface CommonState {
  count: number;
  isLoading: boolean,
  workspaceId: string
}

export const initialState: CommonState = {
  count: 0,
  isLoading: false,
  workspaceId: ""
};

const commonsReducer = createReducer(
  initialState,
  on(setWorkspaceId, (state, { workspaceId }) => {
    return {
      ...state,
      workspaceId
    }
  }),
);

export function reducer(state: CommonState | undefined, action: Action) {
  return commonsReducer(state, action);
}
