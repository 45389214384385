<div *ngIf="isLoading" class="container-loading">
  <div id="status">
    <div class="spinner-chase">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
  </div>
  <!-- <div class="modal-content modal-loading">
    
  </div> -->
</div>