<header id="page-topbar">
  <div class="navbar-header navbar-header-bg">
    <div class="d-flex h-100">
      <!-- LOGO -->
      <div class="navbar-brand-box h-100">
        <a routerLink="/" class="logo logo-dark h-100">
          <span class="logo-sm h-100">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg h-100">
            <img src="assets/images/logo.png" alt="" class="h-100">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light h-100">
          <span class="logo-sm h-100">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg h-100">
            <img src="assets/images/logo.png" alt="" class="h-100">
          </span>
        </a>
      </div>

      <!-- App Search-->
      <form class="app-search align-self-center d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Tìm kiếm">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        
        <!-- <a routerLink="/admin/co-so-du-lieu">
          <button type="button" class="btn header-item">
            <i class="mdi mdi-ballot-outline"></i>
            <i class="fa-solid fa-database"></i>
            Cơ sở dữ liệu
          </button>
        </a> -->
        <!-- <a routerLink="/etl/quy-trinh">
          <button type="button" class="btn header-item">
            <i class="mdi mdi-ballot-outline"></i>
            <i class="fa-solid fa-right-left"></i>
            Chuyển đổi dữ liệu
          </button>
        </a> -->

        <!-- <a routerLink="/admin/reports">
          <button type="button" class="btn header-item">
            <i class="mdi mdi-ballot-outline"></i>
            <i class="fa-solid fa-clipboard-list"></i>
            Danh sách báo cáo
          </button>
        </a> -->
        <!-- <a routerLink="/admin/quan-tri"> -->
        <!-- <button type="button" class="btn header-item">
            <i class="fa-solid fa-screwdriver-wrench"></i>
            Quản trị
          </button> -->

        <!-- <a class="dropdown" ngbDropdown>
          <button class="btn header-item dropdown-toggle" type="button" id="dropdownMenuButton" ngbDropdownToggle>
            <i class="fa-solid fa-screwdriver-wrench"></i>
            Quản trị <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" routerLink="/quan-tri">Quản trị hệ thống</a>
            <a class="dropdown-item" routerLink="/admin/quan-tri/danh-muc-tai-nguyen">Quản lý chủ đề dữ liệu</a>
            <a class="dropdown-item" routerLink="/admin/quan-tri/meta-data">Quản lý metadata</a>
          </div>
        </a> -->

        <!-- </a> -->

        <!-- <mat-menu #administration="matMenu">
          <button mat-menu-item>Quản trị người dùng</button>
          <button mat-menu-item [matMenuTriggerFor]="manageSystem">Quản trị hệ thống</button>
        </mat-menu>
        <mat-menu #manageSystem="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="history">Nhật ký hệ thống</button>
          <button mat-menu-item [matMenuTriggerFor]="config">Cấu hình hệ thống</button>
          <button mat-menu-item [matMenuTriggerFor]="manageRole">Quản trị phân quyền</button>
        </mat-menu>

        <mat-menu #history="matMenu">
          <button mat-menu-item>Lịch sử hệ thống</button>
          <button mat-menu-item>Sao lưu</button>
        </mat-menu>

        <mat-menu #config="matMenu">
          <button mat-menu-item>Cấu hình thông báo</button>
          <button mat-menu-item>Cấu hình khai thác dữ liệu mở</button>
        </mat-menu>
        <mat-menu #manageRole="matMenu">
          <button mat-menu-item>Quản trị vai trò</button>
        </mat-menu> -->
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Tìm kiếm" aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada icon-top-right"></i>
          <span class="badge bg-danger rounded-pill icon-top-right-badge">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Tiêu đề </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">Xem tất cả</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">Xem thêm</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/icon/user.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/quan-tri/profile"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            Thông tin cá nhân</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Đăng xuất</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>

    </div>
  </div>
</header>