<form *ngIf="typeSource === 'postgres' || typeSource === 'mysql' || typeSource === 'mssql'"
  [formGroup]="formConfigPostgres" (ngSubmit)="onSubmit()">
  <div class="card-title">
    Tạo kết nối CSDL
  </div>
  <div class="row mt-3">
    <div class="col-sm-6">
      <div>
        <label class="form-label fw-600 mb-0">Tên kết nối CSDL</label>
        <input class="form-control" type="text" formControlName="name" placeholder="Nhập tên kết nối CSDL"
          [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.name.errors }">
        <div *ngIf="submitted && valFormConfigPostgres.name.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.name.errors.required">Bắt buộc</div>
        </div>
      </div>
      <div class="mt-3">
        <label class="form-label fw-600 mb-0">Host</label>
        <input class="form-control" type="text" placeholder="Nhập host" formControlName="host"
          [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.host.errors }">
        <div *ngIf="submitted && valFormConfigPostgres.host.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.host.errors.required">Bắt buộc</div>
        </div>
      </div>
      <div class="mt-3">
        <label class="form-label fw-600 mb-0">Port</label>
        <input class="form-control" type="number" placeholder="Nhập port" formControlName="port"
          [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.port.errors }">
        <div *ngIf="submitted && valFormConfigPostgres.port.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.port.errors.required">Bắt buộc</div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="">
        <label class="form-label fw-600 mb-0">Tên CSDL</label>
        <input class="form-control" type="text" formControlName="databaseName" placeholder="Nhập tên CSDL"
          [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.databaseName.errors }">
        <div *ngIf="submitted && valFormConfigPostgres.databaseName.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.databaseName.errors.required">Bắt buộc</div>
        </div>
      </div>
      <div class="mt-3">
        <label class="form-label fw-600 mb-0">Tài khoản</label>
        <input class="form-control" type="text" formControlName="username" placeholder="Nhập tên tài khoản"
        [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.username.errors }">
        <div *ngIf="submitted && valFormConfigPostgres.username.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.username.errors.required">Bắt buộc</div>
        </div>
      </div>
      <div class="mt-3">
        <label class="form-label fw-600 mb-0">Mật khẩu</label>
        <div class="input-group auth-pass-inputgroup">
          <input type="{{hidePass ? 'password' : 'text'}}" formControlName="password" class="form-control" placeholder="**********"
          [ngClass]="{ 'is-invalid': submitted && valFormConfigPostgres.password.errors }" autocomplete="new-password"
            aria-label="Password" aria-describedby="password-addon">
          <button *ngIf="hidePass" class="btn btn-light btn-border" type="button" id="password-addon"
            (click)="hidePass = !hidePass">
            <i class="mdi mdi-eye-outline font-size-14 pe-0"></i>
          </button>
          <button *ngIf="!hidePass" class="btn btn-light btn-border" type="button" id="password-addon"
            (click)="hidePass = !hidePass">
            <i class="mdi mdi-eye-off font-size-14 pe-0"></i>
          </button>
        </div>
        <div *ngIf="submitted && valFormConfigPostgres.password.errors" class="invalid-feedback">
          <div *ngIf="valFormConfigPostgres.password.errors.required">Bắt buộc</div>
        </div>
      </div>

      <!-- <label class="form-label fw-600 font-size-16">Security</label> -->
      <!-- <div class="mt-4">
          <div class="my-fieldset mt-3">
            <div class="my-legend-title">
              SSL Modes
            </div>
            <div class="my-legend-action">
              <div class="dropdown" ngbDropdown>
                <button class="btn btn-light dropdown-toggle btn-sm" type="button" ngbDropdownToggle>
                  Disable <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);">Disable</a>
                  <a class="dropdown-item" href="javascript: void(0);">Allow</a>
                  <a class="dropdown-item" href="javascript: void(0);">Prefer</a>
                  <a class="dropdown-item" href="javascript: void(0);">Require</a>
                  <a class="dropdown-item" href="javascript: void(0);">Verify-ca</a>
                  <a class="dropdown-item" href="javascript: void(0);">Verify-full</a>
                </div>
              </div>
            </div>
          </div>
        </div> -->


      <!-- <div class="my-fieldset mt-4">
          <div class="my-legend-title">
            SSH Tunnel Method
          </div>
          <div class="my-legend-action">
            <div class="dropdown" ngbDropdown>
              <button class="btn btn-light dropdown-toggle btn-sm" type="button" ngbDropdownToggle>
                No Tunnel <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">No Tunnel</a>
                <a class="dropdown-item" href="javascript: void(0);">SSH Key Authentication</a>
                <a class="dropdown-item" href="javascript: void(0);">Password Authentication</a>
              </div>
            </div>
          </div>
        </div> -->

    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 text-end">
      <button class="btn btn-primary"><i class="fa-solid fa-floppy-disk"></i> Lưu thiết lập</button>
    </div>
  </div>
</form>