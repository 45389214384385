import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() rate: number = 0;
  @Input() position: string = "";
  listVote: any = [];
  listDefault: any = [];
  previousRate: number = 0;
  constructor() { }

  ngOnInit(): void {
    this.calRate();
  }

  ngOnChanges(event) {
    if (event?.rate?.currentValue != this.previousRate) {
      this.previousRate = event?.rate?.currentValue;
      this.calRate();
    }
  }

  calRate() {
    if (Number(this.rate.toFixed(0)) > 5 || Number(this.rate.toFixed(0)) < 0) this.rate = 5;
    this.listVote = Number(this.rate.toFixed(0)) ? new Array(Number(this.rate.toFixed(0))) : [];
    let valueDefault = 5 - Number(this.rate.toFixed(0));
    this.listDefault = valueDefault ? new Array(valueDefault) : [];

  }

}
