import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class ETLService {

  constructor(private httpRequest: ConfigService) {

  }

  genQuerystring(params) {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }

  createConnect(body) {
    return this.httpRequest.post(`${API.API_ETL}/private/sys-connection/create`, body);
  }

  updateConnect(body) {
    return this.httpRequest.put(`${API.API_ETL}/private/sys-connection/update`, body);
  }

  getListConnect(params) {
    return this.httpRequest.get(`${API.API_ETL}/private/sys-connection/all?${this.genQuerystring(params)}`)
  }

  getListProcess(params) {
    return this.httpRequest.get(`${API.API_ETL}/private/etl-process/find-all?${this.genQuerystring(params)}`)
  }

  getDetailProcess(id) {
    return this.httpRequest.get(`${API.API_ETL}/private/etl-process/process-detail?processId=${id}`)
  }

  createProccess(body) {
    return this.httpRequest.post(`${API.API_ETL}/private/etl-process/create`, body);
  }

  updateProcess(body) {
    return this.httpRequest.put(`${API.API_ETL}/private/etl-process/update`, body);
  }


  deleteConnect(id) {
    return this.httpRequest.delete(`${API.API_ETL}/private/sys-connection/remove?connectionId=${id}`);
  }

  deleteProcess(id) {
    return this.httpRequest.delete(`${API.API_ETL}/private/etl-process/remove?processId=${id}`);
  }

  getListWorkspace() {
    return this.httpRequest.post(`${API.API_ELT}/workspaces/list`, {});
  }

  createBuildProject(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/create`, body);
  }

  getListBuildProject(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/list`, body);
  }

  deleteBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/delete`, body);
  }

  getListVersionBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/declarative_source_definitions/list_manifests`, body);
  }

  updateActiveBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/declarative_source_definitions/update_active_manifest`, body);
  }

  getDetailbuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/get_with_manifest`, body);
  }

  updateBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/update`, body);
  }

  createManifest(body) {
    return this.httpRequest.post(`${API.API_ELT}/declarative_source_definitions/create_manifest`, body);
  }

  createBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/create`, body);
  }

  publishBuilder(body) {
    return this.httpRequest.post(`${API.API_ELT}/connector_builder_projects/publish`, body);
  }

  getDestinations(body) {
    return this.httpRequest.post(`${API.API_ELT}/destinations/list`, body);
  }

  getSources(body) {
    return this.httpRequest.post(`${API.API_ELT}/sources/list`, body);
  }

  getConnectionsBySources(body) {
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/list`, body);
  }

  updateStatusCnn(body) {
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/update`, body);
  }

  getListConnection(body) {
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/list`, body);
  }

  getDataTransformRaw(params) {
    return this.httpRequest.get(`${API.API_ETL}/private/transform/view-raw-data?${this.genQuerystring(params)}`);
  }

  getDataTransformValid(params) {
    return this.httpRequest.get(`${API.API_ETL}/private/transform/view-data-valid?${this.genQuerystring(params)}`);
  }

  getAllRules() {
    return this.httpRequest.get(`${API.API_ETL}/private/rule/rule-ddl`);
  }

  createJob(body) {
    return this.httpRequest.post(`${API.API_ETL}/private/transform/modify-job`, body);
  }

  checkConnection(body) {
    return this.httpRequest.post(`${API.API_ELT}/scheduler/sources/check_connection`, body);
  }

  createSource(body) {
    return this.httpRequest.post(`${API.API_ELT}/sources/create`, body);
  }

  discoverSchema(body) {
    return this.httpRequest.post(`${API.API_ELT}/sources/discover_schema`, body);
  }

  createConnection(body) {
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/create`, body);
  }

  getSourceById(id) {
    let body = {
      "sourceId": id
    }
    return this.httpRequest.post(`${API.API_ELT}/sources/get`, body);
  }

  getDestinationById(id) {
    let body = {
      "destinationId": id
    }
    return this.httpRequest.post(`${API.API_ELT}/destinations/get`, body);
  }

  deleteSource(id) {
    let body = {
      "sourceId": id
    }
    return this.httpRequest.post(`${API.API_ELT}/sources/delete`, body);
  }

  deleteDestination(id) {
    let body = {
      "destinationId": id
    }
    return this.httpRequest.post(`${API.API_ELT}/destinations/delete`, body);
  }

  getListSourceDefinitions(body) {
    return this.httpRequest.post(`${API.API_ELT}/source_definitions/list`, body);
  }

  createDestination(body) {
    return this.httpRequest.post(`${API.API_ELT}/destinations/create`, body);
  }

  modifyJobStatus(processId) {
    return this.httpRequest.put(`${API.API_ETL}/private/transform/modify-job-status?processId=${processId}&jobStatus=ON`, {});
  }

  getConnectionById(id) {
    let body = { 
      "connectionId": id, 
      "withRefreshedCatalog": false 
    }
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/get`, body);
  }

  updateConnection(body){
    return this.httpRequest.post(`${API.API_ELT}/web_backend/connections/update`, body);
  }

  updateDataFeature(body){
    return this.httpRequest.post(`${API.API_GEODATA}/Data/updateFeatures`, body);
  }

}
