import { WORKSPACE } from ".";

// env dev

export const API = {
  BASE_MAP_MOBILE_URL: "http://202.134.19.107:2022/api/v1",
  BASE_URL: "http://203.171.21.64:6001/api/v1",
  API_WMS: `http://203.171.21.64/geoserver/${WORKSPACE}/wms`,
  HOST_RESOURCE: "http://203.171.21.64:9000",
  API_ARCGIS: "http://10.161.10.120/arcgis/rest/services",
  KEY_GOOGLE: "AIzaSyCX4BvUM_iLMa1RW6opc0c2Rl79A-lzfAQ",
  URL_PHOTO_REFERENCE: "https://maps.googleapis.com/maps/api/place/photo",
  API_ETL: "http://203.171.21.64:3300/api/v1",
  API_ELT: "http://203.171.21.64:8000/api/v1",
  URL_SDE: "http://10.161.10.120:3301/api/v1",
  // API_MANAGER_AUTH:"http://203.171.21.64:6060/oauth2.0",
  API_MANAGER_DATA: "http://203.171.21.64:8089/api/v1/public",
  // API_MANAGER_AUTH: 'http://45.118.146.215:99/auth/api',
  API_MANAGER_AUTH: "https://gtsvietnam.net/auth/api",
  // API_GEODATA: 'http://45.118.146.215:99/rest/api',
  // API_GEODATA: "http://localhost:5000/api",
  // API_GEODATA: "http://localhost:90/api",
  API_GEODATA: "https://gtsvietnam.net/rest/api",
  API_ACCOUNT: "https://gtsvietnam.net/user/api",
  // API_ACCOUNT: "http://localhost:91/api",
  API_GEONAME: "https://api.3geonames.org",
  // API_ACCOUNT: "http://localhost:91/api",
};

// env product
// export const API = {
//   BASE_MAP_MOBILE_URL: 'http://202.134.19.107:2022/api/v1',
//   BASE_URL: 'http://192.168.11.26:8080/api/v1',
//   API_WMS: `http://203.171.21.64/geoserver/${WORKSPACE}/wms`,
//   HOST_RESOURCE: "http://203.171.21.64:9000",
//   API_ARCGIS: "https://192.168.12.13:6443/arcgis/rest/services",
//   KEY_GOOGLE: 'AIzaSyCX4BvUM_iLMa1RW6opc0c2Rl79A-lzfAQ',
//   URL_PHOTO_REFERENCE: 'https://maps.googleapis.com/maps/api/place/photo',
//   API_ETL: "http://192.168.11.22:3300/api/v1",
//   API_ELT: "http://192.168.11.22:8000/",
//   URL_SDE: "http://192.168.11.26:3301/api/v1",
//   API_MANAGER_AUTH:"http://203.171.21.64:6060/oauth2.0",
//   API_MANAGER_DATA:"http://203.171.21.64:8089/api/v1/public",
//   API_GEODATA: 'http://203.171.21.64:8086/api',
// };
