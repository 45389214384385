import { Injectable } from '@angular/core';
//import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    //private toastrService: ToastrService
  ) { }

  async success(message: any) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title:message,
      showConfirmButton: false,
      timer: 3000
    });

    // this.toastrService.success(message, '', {
    //   messageClass: 'alert custom_alerts alert-success alert-dismissible fade show'
    // });
  }

  async warning(message: any) {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title:message,
      showConfirmButton: false,
      timer: 3000
    });
    // this.toastrService.warning(message, '', {
    //   messageClass: 'alert custom_alerts alert-warning alert-dismissible fade show'
    // });
  }

  async error(message: any) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title:message,
      showConfirmButton: false,
      timer: 3000
    });
    // this.toastrService.error(message, '', {
    //   messageClass: 'alert custom_alerts alert-danger alert-dismissible fade show'
    // });
  }

}
