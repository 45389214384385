import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import * as _ from 'lodash';
@Component({
  selector: 'app-quy-hoach',
  templateUrl: './quy-hoach.component.html',
  styleUrls: ['./quy-hoach.component.scss']
})
export class QuyHoachComponent implements OnInit, OnChanges {
  @Input() doAnQHInfo: any;
  @Output() fitPolygon = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if ("doAnQHInfo" in changes) {
      if (this.doAnQHInfo) {
        console.log('doAnQHInfo', this.doAnQHInfo)
      }
    }
  }
  openQuyetDinh() {
    var url = 'http://45.118.146.215:99/map/0-2024%20QD%20255%20QHCT%20Khu%20trung%20tam%20hanh%20chinh%20Sa%20Pa.pdf';
    // window.open(url, '_blank').focus();
    window.open(url, '_blank');
  }
  hilightArea() {
    console.log('hhh area', this.doAnQHInfo)
    if (this.doAnQHInfo && this.doAnQHInfo.geom) {
      const geostr = this.doAnQHInfo.geom;
      if (geostr) {
        const geom: any = wellknown.parse(geostr);
        if (geom.type === 'MultiPolygon') {
          var mpolygon = turf.multiPolygon(geom.coordinates)
          this.fitPolygon.emit(mpolygon);
        } else {
          var polygon = turf.polygon(geom.coordinates)
          this.fitPolygon.emit(polygon);
        }
      }
    }
  }
}
