<div class="baselayer-picker" cdkDrag>
    <div class="header">
        <h5>Bản đồ nền</h5>
        <button type="button" (click)="close()" class="btn closebtn" style="padding:5px;">
            <i class="fa fa-close"></i>
        </button>
    </div>
    <hr style="margin:3px; height:1px;background:#ccc;" />
    <div class="body">
        <div class="d-flex">
            <input id="satellite-streets-v12" type="radio" [(ngModel)]="baseLayerId" (click)="changeLayer($event)"
                name="rtoggle" [value]="'satellite-streets-v12'" checked="checked">
            <label class="pl-5 mb-0" for="satellite-streets-v12">Nền vệ tinh</label>
        </div>
        <div class="d-flex">
            <input id="light-v11" type="radio" [(ngModel)]="baseLayerId" (click)="changeLayer($event)" name="rtoggle"
                [value]="'light-v11'">
            <label class="pl-5 mb-0" for="light-v11">Bản đồ sáng</label>
        </div>
        <div class="d-flex">
            <input id="dark-v11" type="radio" [(ngModel)]="baseLayerId" (click)="changeLayer($event)" name="rtoggle"
                [value]="'dark-v11'">
            <label class="pl-5 mb-0" for="dark-v11">Bản đồ đêm</label>
        </div>
        <div class="d-flex">
            <input id="streets-v12" [(ngModel)]="baseLayerId" type="radio" (click)="changeLayer($event)" name="rtoggle"
                [value]="'streets-v12'">
            <label class="pl-5 mb-0 text-nowrap" for="streets-v12">Bản đồ đường phố</label>
        </div>
        <div class="d-flex">
            <input id="outdoors-v12" type="radio" [(ngModel)]="baseLayerId" (click)="changeLayer($event)" name="rtoggle"
                [value]="'outdoors-v12'">
            <label class="pl-5 mb-0 text-nowrap" for="outdoors-v12">Bản đồ địa hình</label>
        </div>
    </div>
</div>