import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';
import { ConfigService } from './config.service';
import { API } from "../../contants/ApiContants";

@Injectable({ providedIn: 'root' })
export class DDLService {
    constructor(private httpRequest: ConfigService) { }

    getListAdministrative(lv, code) {
        return this.httpRequest.get(`${API.API_ETL}/public/dvhc/find?level=${lv}${code ? `&code=${code}` : ''}`)
    }

    getDDLStyle() {
        return this.httpRequest.get(`${API.BASE_URL}/private/style-open-layers/ddl`);
    }
}
