<div class="info-container" style="position: relative; background: rgb(245, 245, 245);">
        <div style="display:flex;align-items: center;gap:16px;width:100%;">
                <a href="javascript:;" (click)="hilightArea()"
                        class="btn btn-icon btn-outline-success btn-circle active"
                        style="display:flex;justify-content: center;align-items: center;width:32px;height:32px;">
                        <i class="fas fa-map-marker-alt" style="font-size: 1rem;"></i></a>
                <h4 class="text-dark font-weight-bold" style="margin-bottom: 0px;">ĐỒ ÁN QUY HOẠCH:</h4>
        </div>
        <div *ngIf="doAnQHInfo" class="doan-info">

                <!-- <div class="form-group mb-2 row row-paddingless ng-star-inserted"><label
                                class="col-4 font-weight-bold">:</label>
                        <label class="col-8 pl-1">
                                {{doAnQHInfo.daqh_loaiquyhoach}}
                        </label>
                </div> -->
                <div class="form-group mb-2 row row-paddingless ng-star-inserted"><label
                                class="col-4 font-weight-bold">Tên đồ án:</label>
                        <label class="col-8 pl-1">
                                {{doAnQHInfo.daqh_tendoan}}
                        </label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Địa điểm:</label>
                        <label class="col-8 pl-1"> {{doAnQHInfo.daqh_diadiemquyhoach}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Diện tích:</label>
                        <label class="col-8 pl-1"> {{doAnQHInfo.daqh_dientich}} ha</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Dân số:</label>
                        <label class="col-8 pl-1"> {{doAnQHInfo.daqh_danso}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Tỷ lệ:</label>
                        <label class="col-8 pl-1"> {{doAnQHInfo.daqh_tyle}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-4 font-weight-bold">Quyết định:</label>
                        <label class="col-8 pl-1">{{doAnQHInfo.daqh_soquyetdinh}}
                                <span class="ng-star-inserted"> {{doAnQHInfo.daqh_ngayquyetdinh}}</span>
                        </label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-5 font-weight-bold">Đơn vị phê duyệt:</label>
                        <label class="col-7 pl-1">{{doAnQHInfo.daqh_donvipheduyet}}</label>
                </div>
                <div class="form-group mb-2 row row-paddingless ng-star-inserted">
                        <label class="col-5 font-weight-bold">Văn bản pháp lý:</label>
                        <label class="col-7 pl-1"></label>
                </div>
                <div class="input-group mb-3 ng-star-inserted" style="cursor: pointer;">
                        <div class="input-group-prepend">
                                <span class="input-group-text"><i class="text-primary fas fa-file-pdf"
                                                style="font-size: 24px;"></i></span>
                        </div><span class="text-primary input-group-text form-control d-block"
                                style="text-overflow: ellipsis; overflow: hidden;" title="Phê duyệt Quy hoạch và Quy định quản lý theo đồ án Điều chỉnh quy hoạch
                                chi tiết xây dựng Khu trung tâm hành chính mới thị xã Sa Pa, tỉnh Lào Cai">
                                <a href="javascript:void(0)" (click)="openQuyetDinh()">Phê
                                        duyệt Quy hoạch và Quy định quản lý theo đồ án Điều chỉnh quy hoạch
                                        chi tiết xây dựng Khu trung tâm hành chính mới thị xã Sa Pa, tỉnh Lào Cai
                                </a>
                        </span>
                </div>
                <div class="mb-2 ng-star-inserted">
                        <div class="form-group mb-2">
                                <label class="font-weight-bold">Bản đồ quy hoạch tổng mặt bằng sử dụng đất</label>
                        </div>
                        <div class="ng-star-inserted">
                                <div style="padding-left: 2rem; display: flex;" class="ng-star-inserted">
                                </div>
                        </div>
                </div>
        </div>
</div>