<div class="top-map-control">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group d-flex">
        <button type="button"  title="Tìm kiếm xung quanh">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa fa-search fa-lg" style="color:#179568;"></i>
            </span>
        </button>
        <span style="padding:5px;">
            <a href="javascript:;" (click)="showSearch()" style="font-size: 15px;font-weight:bold;">Cơ sở hạ tầng lân cận (1 km)</a>
        </span>
    </div>
</div>