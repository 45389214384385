import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as _ from "lodash";
import { data_google } from "src/app/commons/initData";
import { MapService } from "src/app/core/services/map.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-place-search-control",
  templateUrl: "./place-search-control.component.html",
  styleUrls: ["./place-search-control.component.scss"],
})
export class PlaceSearchControlComponent implements OnInit {
  @Output() goToPlace = new EventEmitter<any>();
  places = [];
  keysearch: string = "";
  debouncedSearch: any;
  showSelect: boolean = true;
  constructor(private mapService: MapService) {
    this.debouncedSearch = _.debounce(this.search, 3000);
    this.places = data_google;
  }

  ngOnInit(): void {}

  changePlace(event) {
    this.keysearch = event.target.value;
    this.debouncedSearch();
  }

  search = async () => {
    await this.mapService.searchPlace(this.keysearch).subscribe({
      next: (res) => {
        if (res["status"] === "OVER_QUERY_LIMIT") {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: res["error_message"],
            showConfirmButton: false,
            timer: 4000,
          });
        } else if (res["status"] === "OK") {
          this.places = res["results"];
        }
      },
    });
  };
  toggleSelect() {
    this.showSelect = !this.showSelect;
  }
  changeOrigin(event) {
    this.showSelect = false;
    this.goToPlace.emit(event);
  }
}
