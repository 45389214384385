import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.scss']
})
export class DialogUploadComponent implements OnInit {
  files: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {}
  onSelect(event) {
    this.files.push(...event.addedFiles);
  }
  onRemove(f, index) {
    this.files.splice(index, 1);
  }
  save(){
    this.ngbActiveModal.close(this.files);
  }
  close(){
    this.ngbActiveModal.close();
  }

}
