import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import * as _ from 'lodash';
@Component({
  selector: 'app-oquy-hoach',
  templateUrl: './oquy-hoach.component.html',
  styleUrls: ['./oquy-hoach.component.scss']
})
export class OQuyHoachComponent implements OnInit, OnChanges {
  @Input() oQHInfo: any;
  @Output() fitPolygon = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ("oQHInfo" in changes) {
      // console.log('thong tin o quy hoach', this.oQHInfo)
      if (this.oQHInfo) {

      }
    }
  }
  hilightArea() {
    if (this.oQHInfo && this.oQHInfo.geom) {
      const geostr = this.oQHInfo.geom;
      if (geostr) {
        const geom: any = wellknown.parse(geostr);
        if (geom.type === 'MultiPolygon') {
          var mpolygon = turf.multiPolygon(geom.coordinates)
          this.fitPolygon.emit(mpolygon);
        } else {
          var polygon = turf.polygon(geom.coordinates)
          this.fitPolygon.emit(polygon);
        }
      }
    }
  }
}
