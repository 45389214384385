import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectImageComponent } from 'src/app/components/select-image/select-image.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { CsdlService } from 'src/app/core/services/csdl.service';
import Swal from 'sweetalert2';
import { StyleService } from 'src/app/core/services/style.service';
import { API } from 'src/app/contants/ApiContants';
import { TableService } from 'src/app/core/services/table.service';
import * as Constants from '../../quan-tri/Constants/Constants';

@Component({
  selector: 'app-config-style-vector',
  templateUrl: './config-style-vector.component.html',
  styleUrls: ['./config-style-vector.component.scss']
})
export class ConfigStyleVectorComponent implements OnInit {
  formStyle: UntypedFormGroup;
  buttons: any = Constants.BUTTONS;
  typeSelectImage: string = "folder";
  listLegend: any = [];
  tableSources: any = [];
  cols: any = [];
  styleRecord;
  HOST_RESOURCE = API.HOST_RESOURCE;
  @Output() onAction = new EventEmitter<any>();
  @Input() idStyle: string;
  @ViewChild('selectImage', { static: true }) selectFile: SelectImageComponent;
  @ViewChild('modalConfigStyle', { static: true }) modalConfigStyle: any;
  formStyleRecord: UntypedFormGroup;
  _version: number = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private catalogService: CatalogService,
    private csdlService: CsdlService,
    private styleService: StyleService,
    private tableService: TableService
  ) {
    this.formStyle = this.formBuilder.group({
      vectorName: ["", [Validators.required]],
      vectorType: [null, [Validators.required]],
      useGroup: [null],
      useLabel: [null],
      tbkey: [null],
      field: [null],
      method: ['equal'],
      font: [null],
      fontSize: [null],
      fontColor: [null],
      column: [null],
      zoomStart: [null],
      image: [null],
      offsetX: [null],
      offsetY: [null],
      // vector_radius: [null],
      // vector_stroke_color: ["#000000"],
      // vector_stroke_width: [null],
      // vector_fill_color: ["#000000"],
      // vector_line_dash: [null],
      // vector_line_cap: [null],
      // vector_line_join: [null],
      label_font_family: [null],
      label_font_color: ['#000000'],
      label_font_size: [null],
      label_zoom_start: [null],
      label_column: [null],
      vector_radius: [null],
      vector_stroke_color: ["#000000"],
      vector_stroke_width: [null],
      vector_fill_color: ["#000000"],
      vector_line_dash: [null],
      vector_line_cap: [null],
      vector_line_join: [null],
    })
    this.formStyleRecord = this.formBuilder.group({
      vector_radius: [null],
      vector_stroke_color: ["#000000"],
      vector_stroke_width: [null],
      vector_fill_color: ["#000000"],
      vector_line_dash: [null],
      vector_line_cap: [null],
      vector_line_join: [null],
    })
  }

  get valFormStyle() { return this.formStyle.controls; }
  get valFormStyleRecord() { return this.formStyleRecord.controls; }


  ngOnInit(): void {
    this.catalogService.getTableSource().subscribe(res => {
      if (res["status"] === 200) {
        this.tableSources = res["data"];
        if(this.idStyle){
          this.styleService.getStyleById(this.idStyle).subscribe({
            next: (res) => {
              if(res["status"] === 200){
                let data = res["data"];
                this.bindDataStyle(data)
              }
            }
          })
        }
      }
    })
    
  }

  bindDataStyle(data){
    this.formStyle.controls.vectorName.patchValue(data.name);
    this.formStyle.controls.vectorType.patchValue(data.info.vectorType);
    this.formStyle.controls.useGroup.patchValue(data.info.useGroup);
    this.formStyle.controls.useLabel.patchValue(data.info.useLabel);
    this.formStyle.controls.tbkey.patchValue(data.info.tbkey);
    this.formStyle.controls.field.patchValue(data.info.field);
    this.formStyle.controls.method.patchValue(data.info.method);
    this.formStyle.controls.image.patchValue(data.info.image);
    this.formStyle.controls.offsetX.patchValue(data.info.offsetX);
    this.formStyle.controls.offsetY.patchValue(data.info.offsetY);
    this.formStyle.controls.label_font_family.patchValue(data.info.label_font_family);
    this.formStyle.controls.label_font_color.patchValue(data.info.label_font_color);
    this.formStyle.controls.label_font_size.patchValue(data.info.label_font_size);
    this.formStyle.controls.label_zoom_start.patchValue(data.info.label_zoom_start);
    this.formStyle.controls.label_column.patchValue(data.info.label_column);

    this.formStyle.controls.vector_radius.patchValue(data.info.vector_radius);
    this.formStyle.controls.vector_stroke_color.patchValue(data.info.vector_stroke_color);
    this.formStyle.controls.vector_stroke_width.patchValue(data.info.vector_stroke_width);
    this.formStyle.controls.vector_fill_color.patchValue(data.info.vector_fill_color);
    this.formStyle.controls.vector_line_dash.patchValue(data.info.vector_line_dash);
    this.formStyle.controls.vector_line_cap.patchValue(data.info.vector_line_cap);
    this.formStyle.controls.vector_line_join.patchValue(data.info.vector_line_join);

    this.listLegend = data.info.series;
    this.cols = this.tableSources.find(item => item.id == data.info.tbkey) || {};
    this._version = data._version;
  }

  onSelectSource(event) {
    console.log("=====onSelectSource=========", event);
    this.cols = event || {};
  }

  updateStyle(body) {
    this.styleService.updateStyle(body).subscribe({
      next: (res) => {
        if (res["status"] === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Thao tác thành công !!",
            showConfirmButton: false,
            timer: 3000
          });
          let obj = {
            key: "update_style_success",
            value: ""
          }
          this.onAction.emit(obj);
        }
      },
      error: (err) => {
        console.log("errrrrrrr", err);
      }
    })
  }

  onSubmit() {
    if (this.formStyle.invalid) {
      console.log("=======invalid=========", this.formStyle.invalid)
      return;
    }
    let style = {
      vectorType: this.valFormStyle.vectorType.value || null,
      useGroup: this.valFormStyle.useGroup.value || null,
      useLabel: this.valFormStyle.useLabel.value || null,
      tbkey: this.valFormStyle.tbkey.value || null,
      field: this.valFormStyle.field.value || null,
      method: this.valFormStyle.method.value || null,
      font: this.valFormStyle.font.value || null,
      fontSize: this.valFormStyle.fontSize.value || null,
      fontColor: this.valFormStyle.fontColor.value || null,
      column: this.valFormStyle.column.value || null,
      zoomStart: this.valFormStyle.zoomStart.value || null,
      image: this.valFormStyle.image.value || null,
      offsetX: this.valFormStyle.offsetX.value || null,
      offsetY: this.valFormStyle.offsetY.value || null,
      vector_radius: this.valFormStyle?.vector_radius.value || null,
      vector_stroke_color: this.valFormStyle?.vector_stroke_color.value || null,
      vector_stroke_width: this.valFormStyle?.vector_stroke_width.value || null,
      vector_fill_color: this.valFormStyle?.vector_fill_color.value || null,
      vector_line_dash: this.valFormStyle?.vector_line_dash.value || null,
      vector_line_cap: this.valFormStyle?.vector_line_cap.value || null,
      vector_line_join: this.valFormStyle?.vector_line_join.value || null,
      label_font_family: this.valFormStyle?.label_font_family.value || null,
      label_font_color: this.valFormStyle?.label_font_color.value || null,
      label_font_size: this.valFormStyle?.label_font_size.value || null,
      label_zoom_start: this.valFormStyle?.label_zoom_start.value || null,
      label_column: this.valFormStyle?.label_column.value || null,
      series: this.listLegend
    }
    let body = {
      "_version": this._version,
      "id": this.idStyle,
      "name": this.valFormStyle.vectorName.value || "",
      "info": style
    }
    if (this.idStyle) {
      this.updateStyle(body);
    } else {
      let bodyCreate = {
        "data_type": "style",
        "name": this.valFormStyle.vectorName.value || "",
        "parent_id": "root",
        "size": null
      }
      this.csdlService.createFolder(bodyCreate).subscribe({
        next: (res) => {
          if (res["status"] === 200) {
            this.idStyle = res["data"]["id"];
            body["id"] = this.idStyle;
            this.updateStyle(body);
          }
        }
      })
    }
  }

  addRowLegend() {
    this.listLegend.push({
      note: "",
      value: "",
      min: null,
      max: null,
      image: null,
      vector: {},
    })
  }
  openSelectFile() {
    this.typeSelectImage = "file";
    this.styleRecord = {};
    this.selectFile.getAllFolder();
    this.selectFile.openModalChooseFile();

  }
  triggerChooseFile(record, idx) {
    this.typeSelectImage = "file";
    this.selectFile.getAllFolder();
    this.styleRecord = {
      record: record,
      index: idx
    }
    this.selectFile.openModalChooseFile();
  }

  onHandle(event) {
    console.log("==========event=========", event);
    switch (event.key) {
      case "selected_file":
        if(this.styleRecord.index != undefined){
          this.listLegend[this.styleRecord.index] = {
            ...this.listLegend[this.styleRecord.index],
            ["image"]: event?.value?.data?.info?.uri
          }
        }else{
          this.formStyle.controls.image.patchValue(event?.value?.data?.info?.uri);
        }
        

        break;
    }
  }

  changeColor(event, key) {
    this.formStyle.controls[key].patchValue(event.target.value);
  }

  changeColorRecord(event, key) {
    this.formStyleRecord.controls[key].patchValue(event.target.value);
  }

  changeValueLegend(event, idx, key) {
    this.listLegend[idx] = {
      ...this.listLegend[idx],
      [key]: event.target.value
    }
  }

  deleteLegend(row, idx) {
    this.listLegend.splice(idx, 1);
  }

  getValueLegend() {
    this.tableService.getValueColumn(this.valFormStyle.tbkey.value, this.valFormStyle.field.value).subscribe({
      next: (res) => {
        if (res["status"] === 200) {
          this.listLegend = [];
          this.listLegend = (res["data"] || []).map(item => {
            return {
              note: "",
              value: item,
              min: null,
              max: null,
              image: null,
              vector: {},
            }
          })
          console.log("========res=========", res)
        }
      }
    })
  }

  openModalConfig(record, idx) {
    this.styleRecord = {
      vector: record.vector,
      index: idx
    }
    this.modalService.open(this.modalConfigStyle, { size: 'xl' });
  }

  saveStyleRecord(modal) {
    this.listLegend[this.styleRecord.index] = {
      ...this.listLegend[this.styleRecord.index],
      vector: {
        ...this.styleRecord.vector,
        vector_radius: this.valFormStyleRecord.vector_radius.value || "",
        vector_stroke_color: this.valFormStyleRecord.vector_stroke_color.value || "",
        vector_stroke_width: this.valFormStyleRecord.vector_stroke_width.value || "",
        vector_fill_color: this.valFormStyleRecord.vector_fill_color.value || "",
        vector_line_dash: this.valFormStyleRecord.vector_line_dash.value || "",
        vector_line_cap: this.valFormStyleRecord.vector_line_cap.value || "",
        vector_line_join: this.valFormStyleRecord.vector_line_join.value || "",
      }
    }
    modal.dismiss();
  }
}
