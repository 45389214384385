import { Component, OnInit, Input } from '@angular/core';
// import { TemplateError } from "../../contants/Template";

declare const $: any;

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  @Input() isLoading = false;
  constructor() { }

  ngOnInit() {
  }

}

