import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

import { StorageService } from "../../quan-tri/ServiceCommon/storage.service";
import * as Constants from "../../quan-tri/Constants/Constants"
import { MenuService } from "../../quan-tri/@core/data/service/menu.service"
import { SecRoleButtonsService } from "../../quan-tri/@core/data/service/sec-role-buttons.service"


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  username: string = localStorage.getItem("user");

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,

    private storageService: StorageService,
    private menuService: MenuService,
    private secRoleButtonsService: SecRoleButtonsService
  ) {

  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }


    // this.findRoleByUserId().then(roles => {
    //   this.showMenuByRole(roles);
    // })

    // var self = this;
    // $(document).ready(function () {
    //   // self.hideAllMenu();
    //   self.removeMenuByClick();
    //   //self.setRoleButtonWhenClick();
    // })
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


  // bat dau Phan quyen menu
  async findRoleByUserId() {
    var userid = await this.storageService.get(Constants.CACHE_ID_USER)//.then(userid => {
    var roles = await this.menuService.findRoleByUserId(userid)//.then(roles => {

    if(roles !=null && roles.length >0)
    this.storageService.set(Constants.CACHE_MENU, JSON.stringify(roles), Constants.TIME_CACHE_MAX_MINUTE);
    return roles;
  }


  // async hideAllMenu() {
  //   //$(document).ready(function () {
  //   this.menuService.getAllMenuFromHtml(".navbar-header a").then(menus => {
  //     menus.forEach(menu => {
  //       $('.navbar-header a[href="' + menu.link + '"]').hide();
  //     })
  //   })

  //   this.menuService.getAllMenuFromHtmlChilden(".simplebar-content ul li a").then(menus => {
  //     menus.forEach(menu => {
  //       $('.simplebar-content a[href="' + menu.link + '"]').hide();
  //     });
  //   })
  //   //})
  // }

  async getAllMenuFromHtml() {
    var menus = await this.menuService.getAllMenuFromHtml(".navbar-header a")//.then(menus => {
    var menus2 = await this.menuService.getAllMenuFromHtmlChilden(".simplebar-content ul li a");

    var map = [...menus, ...menus2];
    return map;
  }


  async showMenuByRole(roles:any) {
    if (roles == null || roles == undefined || roles.length ==0)
      return;
    var menus = await this.getAllMenuFromHtml();
   
    menus.forEach(menu => {
      var exists = roles.findIndex(x => x.link == menu.link);
      // console.log(exists);
      // console.log(menu.link);
      if (exists >= 0) {

        $('.navbar-header a[href="' + menu.link + '"]').show();
        $('.simplebar-content a[href="' + menu.link + '"]').show();
      }
      else {
        $('.navbar-header a[href="' + menu.link + '"]').hide();
        $('.simplebar-content a[href="' + menu.link + '"]').hide();
      }
    });
    // })

  }

  async removeMenuByClick() {
    var self = this;

    $("a").on('click', () => {
      // self.hideAllMenu();
      self.storageService.get(Constants.CACHE_MENU).then(roles => {
        if (roles != null && roles != undefined && roles.length > 0)
        self.showMenuByRole(JSON.parse(roles));
      })
    })
  }

  // ket thuc Phan quyen menu


  // bat dau role button
  async setRoleButtonWhenClick() {
    if ($ == null || $ == undefined)
      return;

    var self = this;
    $('a').click(function (event) {
      var url = self.router.url;
      var split = url.split('?');
      if (split.length > 0)
        url = split[0];

      if (url == null || url == undefined || url.length == 0)
        return;
      self.setHideAllButtons();

      self.getRoleButtonsByLinkAndUser(url).then((roleButtons: any) => {

        for (let rb = 0; rb < roleButtons.length; rb++) {
          var roleButton = roleButtons[rb];

          if (roleButton != null && roleButton.buttons != undefined && roleButton.buttons.length > 0) {
            var buttons = JSON.parse(roleButton.buttons);
            for (let b = 0; b < buttons.length; b++) {
              $('button[role="' + buttons[b] + '"]').show();
            }

          }

        }
      })


    })
  }

  async setHideAllButtons() {
    $('button[role="' + Constants.BUTTONS.ADD.VALUE + '"]').hide();
    $('button[role="' + Constants.BUTTONS.UPDATE.VALUE + '"]').hide();
    $('button[role="' + Constants.BUTTONS.SAVE.VALUE + '"]').hide();
    $('button[role="' + Constants.BUTTONS.DELETE.VALUE + '"]').hide();
    $('button[role="' + Constants.BUTTONS.RECOVER.VALUE + '"]').hide();
    $('button[role="' + Constants.BUTTONS.EXPORT.VALUE + '"]').hide();
  }


  async getRoleButtonsByLinkAndUser(link: any) {
    var userId = await this.storageService.get(Constants.CACHE_ID_USER);//.then(userid => {

    var para = {
      userId: userId,
      link: link
    }
    return await this.secRoleButtonsService.findByLinkAndUser(para)//.then((rs: any) => {

  }

  //ket thuc role button

}
