<div class="left-map-control">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group" *ngIf="isLoggedIn">
        <button type="button" (click)="showAdministrative()" title="Đơn vị hành chính">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa fa-th-large fa-lg"></i>
            </span>
        </button>
    </div>
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button type="button" (click)="showSearch()" [ngClass]="{'selected': mapMode === 'search'}" title="Tìm kiếm">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa fa-search"></i>
            </span>
        </button>
    </div>
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button type="button" (click)="activeInfo()"  [ngClass]="{'selected': mapMode === 'info'}"
            title="Thông tin đối tượng">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa-info-circle fa-lg"></i>
            </span>
        </button>
    </div>
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button type="button" (click)="showLayers()" title="Lớp bản đồ chuyên đề">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa-map fa-lg"></i>
            </span>
        </button>
    </div>

    <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button type="button" (click)="showLegend()" title="Chú giải">
            <span class="mapboxgl-ctrl-icon">
                <i class="fa fa-th-list fa-lg"></i>
            </span>
        </button>
    </div>


    <!-- <button type="button" (click)="toggleShowSearch()" title="Info feature" class="btn-search btn btn-circle m-b-5"><i
        class="fa fa fa-search" style="font-size: 20px;width:20px;color:dimgrey"></i></button>
    <button type="button" (click)="activeInfoFeature()" title="Info feature" class="btn-info btn btn-circle m-b-5"><i
        class="fa fa-info-circle" style="font-size: 20px;width:20px;color:dimgrey"></i></button>
    <button type="button" (click)="toogleShowLayers()" title="All Layers"
      class="btn-layers btn btn-primary btn-circle m-b-5"><i class="fa fa-th-list"
        style="font-size: 20px;width:20px;"></i></button>
    <button type="button" (click)="toogleShowMeasure()" title="Measure Tool"
      class="btn-layers btn btn-primary btn-circle m-b-5"><i class="fa fa-th-list"
        style="font-size: 20px;width:20px;"></i></button> -->

</div>