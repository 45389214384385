import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  @Input() breadcrumbItems;
  @Output() onAction = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  handleBreadCrumd(item, idx){
    let obj = {
      key: "click_item_breadCrum",
      value: {
        ...item,
      },
      idx
    }
    this.onAction.emit(obj);
  }
}
