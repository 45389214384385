<div>
  <nav
    class="navbar navbar-expand-lg navigation fixed-top sticky navigation-c"
    (window:scroll)="windowScroll()"
    id="navbar"
  >
    <!-- <div class="w-100 text-end pt-2 px-2">
		<a href="javascript: void(0);" class="">
			<img src="assets/images/flags/us.jpg" alt="Header Language" height="16" class="me-2" (click)="changeLanguage($event, 'en')">
			<img src="assets/images/flags/vietnam.jpg" alt="Header Language" height="16" (click)="changeLanguage($event, 'vi')">
		</a>
	</div> -->
    <div class="container mw-100">
      <a class="navbar-logo d-flex py-1" routerLink="/">
        <img
          src="assets/images/logo.png"
          alt=""
          height="60"
          class="logo logo-dark me-2"
        />
        <img
          src="assets/images/logo.png"
          alt=""
          height="60"
          class="logo logo-light me-2"
        />
        <div class="align-self-center">
          <!-- <h5 class="mb-0 title-web fw-bold">CỔNG DỮ LIỆU GIS</h5> -->
          <!-- <h5 class="mb-0 title-web fw-500 mt-1 font-size-12"><i>THÀNH PHỐ CẦN THƠ</i></h5> -->
          <!-- <h5 class="mb-0 title-web fw-500 mt-1">Cổng thông tin đất đai tỉnh Cà Mau</h5> -->
          <h5 class="mb-0 title-web fw-bold mt-1">
            Hệ thống thông tin Quy hoạch xây dựng tỉnh Lào Cai
          </h5>
        </div>
      </a>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
        data-toggle="collapse"
        data-target="#topnav-menu-content"
        (click)="toggleMenu()"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <div
        class="collapse navbar-collapse ms-auto justify-content-end"
        id="topnav-menu-content"
      >
        <!-- <ul class="navbar-nav ms-auto" id="topnav-menu">
        <li class="nav-item">
          <a class="nav-link text-uppercase" routerLink="/"><strong>Trang chủ</strong></a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-uppercase" routerLink="/extrapages/service-map" routerLinkActive="active"><strong>DỊCH VỤ BẢN ĐỒ</strong></a>
        </li>
        <li class="nav-item text-uppercase">
          <a class="nav-link text-uppercase" routerLink="/"><strong>TÀI LIỆU</strong></a>
        </li>
        <li class="nav-item text-uppercase">
          <a class="nav-link text-uppercase" routerLink="/"><strong>LIÊN HỆ</strong></a>
        </li>
      </ul> -->
      </div>
      <div class="my-2 ms-lg-2 ms-auto" id="topnav-menu" (click)="toggleUser()">
        <button
          *ngIf="isLoggedIn"
          type="button"
          class="btn btn-sm px-3 font-size-20 header-item"
          data-toggle="collapse"
          data-target="#topnav-menu-content"
          style="color: white"
        >
          <i class="fa-regular fa-user me-1"></i>
          {{ userName }}
        </button>
        <a
          *ngIf="!isLoggedIn"
          routerLink="/account/auth/login"
          class="btn btn-success w-xs me-2"
        >
          <i class="fa-regular fa-user me-1"></i>
          ĐĂNG NHẬP
        </a>
      </div>
    </div>
  </nav>
  <div class="user-context-menu" *ngIf="showUserContext" (click)="toggleUser()">
    <ul class="navbar-nav ms-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/phan-anh/thong-tin-ca-nhan"
          ><strong>Thông tin cá nhân</strong></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/phan-anh/phan-anh-kien-nghi"
          ><strong>Phản ánh kiến nghị</strong></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/phan-anh/phan-anh-cua-ban"
          ><strong>Phản ánh của bạn</strong></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/phan-anh/doi-mat-khau">
          <strong>Đổi mật khẩu</strong></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" (click)="logOut()"
          ><strong>đăng xuất</strong></a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link text-uppercase" routerLink="/"><strong>Trang chủ</strong></a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-uppercase" routerLink="/extrapages/service-map" routerLinkActive="active"><strong>DỊCH
            VỤ BẢN ĐỒ</strong></a>
      </li>
      <li class="nav-item text-uppercase">
        <a class="nav-link text-uppercase" routerLink="/"><strong>TÀI LIỆU</strong></a>
      </li>
      <li class="nav-item text-uppercase">
        <a class="nav-link text-uppercase" routerLink="/"><strong>LIÊN HỆ</strong></a>
      </li> -->
    </ul>
  </div>
</div>
