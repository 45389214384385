import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-feature-info",
  templateUrl: "./feature-info.component.html",
  styleUrls: ["./feature-info.component.scss"],
})
export class FeatureInfoComponent implements OnInit, OnChanges {
  @Input() tableName: string;
  @Input() featureInfo: any;
  @Output() onClose = new EventEmitter<any>();
  @Output() fitParcelBound = new EventEmitter<any>();
  @Output() showImage = new EventEmitter<any>();
  @Output() showVideo = new EventEmitter<any>();
  featureProps: any[] = [];
  soLuongThua: number = 0;
  dsThua: any[] = [];
  showThuaDat: boolean = false;
  isLoadingBlock: boolean = false;
  images: any = [];
  imageIndex: number = 0;
  postImages: any = [
    {
      id: 1,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-1.jpg",
    },
    {
      id: 2,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-2.jpg",
    },
    {
      id: 3,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-3.jpg",
    },
    {
      id: 4,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-4.jpg",
    },
    {
      id: 5,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-4.jpg",
    },
    {
      id: 6,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-6.jpg",
    },
    {
      id: 7,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-7.jpg",
    },
    {
      id: 7,
      src: "https://gtsvietnam.net/assets/cayxanh/CX-8.jpg",
    },
  ];
  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: true,
    dots: true,
    responsive: {
      680: {
        items: 1,
      },
    },
  };
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if ("featureInfo" in changes) {
      this.isLoadingBlock = true;
      this.chuanHoaDuLieuThuocTinh(this.featureInfo);
      setTimeout(() => {
        this.isLoadingBlock = false;
      }, 300);
    }
  }
  close() {
    this.onClose.emit();
  }

  chuanHoaDuLieuThuocTinh(props: any) {
    const featureProps = [];
    console.log("tblName", this.tableName);
    console.log("props", props);
    Object.keys(props).forEach((key) => {
      // let key = prop.Key;
      const value = props[key];
      if (
        key &&
        key !== "geom" &&
        key !== "objectid" &&
        key !== "s_geom" &&
        key !== "object_id" &&
        key !== "maid" &&
        key !== "s_xdaidien" &&
        key !== "s_ydaidien" &&
        key !== "madoituong" &&
        key !== "malienket" &&
        key !== "xdaidien" &&
        key !== "ydaidien" &&
        key !== "guid" &&
        key !== "parentguid" &&
        key !== "shape_length" &&
        key !== "shape_leng" &&
        key !== "mucdichsud" &&
        key !== "shape_area"
      ) {
        if (key === "kehoachsdd") key = "Kế hoạch sử dụng đất";
        else if (key === "matinh") key = "Mã tỉnh";
        else if (key === "mahuyen") key = "Mã huyện";
        else if (key === "tenhuyen") key = "Tên huyện";
        else if (key === "tenvungquy") key = "Tên vùng quy hoạch";
        else if (key === "mucdichsud") key = "Mục đích sử dụng";
        else if (key === "nhan_mdsd") key = "Mục đích sử dụng";
        else if (key === "namquyhoac") key = "Năm quy hoạch";
        else if (key === "mahoso") key = "Mã hồ sơ";
        else if (key === "chucnang") key = "Chức năng";
        else if (key === "ttqh") key = "Thông tin quy hoạch";
        else if (key === "ghichu") key = "Ghi chú";
        else if (key === "maxa") key = "Mã xã";
        else if (key === "tenxa") key = "Tên xã";
        else if (key === "chieucao") key = "Chiều cao";
        else if (key === "donviquanl") key = "Đơn vị quản lý";
        else if (key === "hientrang") key = "Hiện trạng";
        else if (key === "phanloai") key = "Phân loại";
        else if (key === "tengoi") key = "Tên gọi";
        else if (key === "tenxa") key = "Tên xã";
        featureProps.push({
          key: key,
          value: value.toString() == "[object Object]" ? "" : value,
        });
      }
    });
    // if (this.tableName === 'QHXD_LaoCai_CayXanh') {
    //   featureProps.push({
    //     key: 'malienket',
    //     value: props['malienket']
    //   })
    // }
    this.featureProps = featureProps;
    // return featureProps;
  }
  trackFunc(index: any, item: any): string {
    return index; // item.id;
  }
  openImage(id) {
    // console.log('open image', id)
    // this.images.push('/assets/cayxanh/' + id + '.jpg'
    // )
    this.showImage.emit(id);
  }
  openVideo(id) {
    this.showVideo.emit(id);
  }
}
