import { Injectable } from "@angular/core";
import { HttpService } from "../../../ServiceCommon/http.service";
import { API } from "../../../../contants/ApiContants";
import { CommonService } from "../../../ServiceCommon/common.service";
import { BehaviorSubject } from "rxjs";
import { User } from "src/app/core/models/auth.models";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(
    private httpService: HttpService,
    private commonService: CommonService
  ) {}
  userInfo$ = new BehaviorSubject<User>(null);

  setCurrentUser(user: any) {
    this.userInfo$.next(user);
  }
  async getAll(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .getJsonToken(API.API_ACCOUNT + "/user/searchaccount", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async getUserByID(id: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/getById", id)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  getTotalCount(para: any): Promise<any> {
    console.trace();
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/getTotalCount", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  getById(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/getById", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  insert(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/insert", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  update(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/update", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  changeStatusUser(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/changeStatusUser", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  existsByUsername(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + "/users/existsByUsername", para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async getProfile(access_token: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .getJson(
          API.API_MANAGER_AUTH + "/profile1?access_token=" + access_token
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async getRoles(): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .getJsonToken(API.API_ACCOUNT + "/user/getroles", {})
        .then(
          (data) => {
            resolve(data);
          },
          (err: any) => {
            resolve(null);
          }
        );
    });
  }
}
