import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-scene-switch',
  templateUrl: './scene-switch.component.html',
  styleUrls: ['./scene-switch.component.scss']
})
export class SceneSwitchComponent implements OnInit {
  @Output() switch = new EventEmitter<any>();
  is2D: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.is2D = !this.is2D;
    this.switch.emit(this.is2D);
  }

}
