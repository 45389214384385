import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import * as _ from "lodash";
@Component({
  selector: "app-lo-quy-hoach",
  templateUrl: "./lo-quy-hoach.component.html",
  styleUrls: ["./lo-quy-hoach.component.scss"],
})
export class LoQuyHoachComponent implements OnInit {
  @Input() loQHInfo: any;
  @Output() fitPolygon = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges): void {
    if ("loQHInfo" in changes) {
      console.log("thong tin lllo quy hoach", this.loQHInfo);
      if (this.loQHInfo) {
      }
    }
  }
  hilightArea() {
    if (this.loQHInfo && this.loQHInfo.geom) {
      const geostr = this.loQHInfo.geom;
      if (geostr) {
        const geom: any = wellknown.parse(geostr);
        console.log('higlight geom', geom);
        if (geom.type === "MultiPolygon") {
          var mpolygon = turf.multiPolygon(geom.coordinates);
          this.fitPolygon.emit(mpolygon);
        } else {
          var polygon = turf.polygon(geom.coordinates);
          this.fitPolygon.emit(polygon);
        }
      }
    }
  }
}
