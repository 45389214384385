import { DEFAULT_LAYERS_OPACITY, MAP_LAYERS_ID } from ".";
import { API } from "./ApiContants";
import {
  QHSDD_STYLE,
  QHXD_COLOR,
  QHXD_PATTERN,
  THUADAT_STYLE,
} from "./map_style";

export class TreeNode {
  children?: TreeNode[] | undefined;
  item?: object | undefined;
  parentIds?: string | undefined;
  label: string | undefined;
  source?: string;
  id: string | undefined;
  layer?: string;
  isLayer?: boolean;
  order?: number;
  isGroupLayer?: boolean;
  minZoom?: number;
  maxZoom?: number;
  filter?: any;
  type?: string;
  url?: string;
  defaultVisible?: boolean;
  show?: boolean;
  style?: {
    layout?: object;
    paint?: object;
  };
}

/** Flat to-do item node with expandable and level information */
export class TreeFlatNode {
  item?: object | undefined;
  parentIds?: string | undefined;
  label: string | undefined;
  id: string | undefined;
  level: number = 0;
  expandable: boolean = true;
  type?: string;
}

// export const URL_API_GEODATA = 'http://203.171.21.64:8088/api';
// export const MAP_TREE_LAYERS_DATA: TreeNode[] = [
//   // {
//   //     label: "Ảnh vệ tinh UAV",
//   //     id: 'UAV',
//   //     isGroupLayer: true,
//   //     children: [
//   //         {
//   //             label: "Nền UAV",
//   //             id: 'Cache_Basemap_UAV',
//   //             layer: 'Cache_Basemap_UAV',
//   //             layerid: MAP_LAYERS_ID.UAV,
//   //             isLayer: true,
//   //             order: 1,
//   //             minZoom: 8,
//   //             maxZoom: 21,
//   //             defaultVisible: true,
//   //             type: 'raster',
//   //             url: `${API.API_GEODATA}/map/mapcache/Cache_Basemap/tile/{z}/{x}/{y}.png`,
//   //         }
//   //     ]
//   // },
//   {
//     label: "Quy hoạch SDD",
//     id: "QHSDD",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "QHXD_LaoCai_RanhGioiQuyHoachA",
//         id: MAP_LAYERS_ID.QHC,
//         layer: "QHXD_LaoCai",
//         isLayer: true,
//         order: 2,
//         minZoom: 5,
//         maxZoom: 13,
//         defaultVisible: true,
//         type: "fill",
//         filter: ["==", "oqh_loaiqh", "QHC"],
//         style: {
//           paint: {
//             "fill-color": QHXD_COLOR,
//             "fill-opacity": 0.8,
//           },
//         },
//       },
//       {
//         label: "Quy hoạch phân khu",
//         id: MAP_LAYERS_ID.QHPK,
//         layer: "QHXD_LaoCai",
//         isLayer: true,
//         order: 2,
//         minZoom: 13,
//         maxZoom: 22,
//         defaultVisible: true,
//         type: "fill",
//         filter: ["==", "oqh_loaiqh", "QHPK"],
//         style: {
//           paint: {
//             "fill-color": QHXD_COLOR,
//             "fill-opacity": 0.8,
//           },
//         },
//       },
//       // {
//       //     label: "Ranh giới quy hoạch",
//       //     id: "QHC_BORDER_1",
//       //     source: 'src_QHXD_LaoCai_KhuQuyHoach',
//       //     layer: 'QHXD_LaoCai_KhuQuyHoach',
//       //     isLayer: true,
//       //     order: 2,
//       //     minZoom: 5,
//       //     maxZoom: 22,
//       //     defaultVisible: true,
//       //     show: false,
//       //     type: 'line',
//       //     filter: ['==', 'oqh_loaiqh', 'QHC'],
//       //     style: {
//       //         layout: {
//       //             "visibility": "visible"
//       //         },
//       //         paint: {
//       //             'line-color': 'white',
//       //             'line-width': 1,
//       //             'line-opacity': 1,
//       //         }
//       //     }
//       // },
//       {
//         label: "Ranh giới quy hoạch chung",
//         id: MAP_LAYERS_ID.QHC_BORDER,
//         source: "src_QHXD_LaoCai_KhuQuyHoach",
//         layer: "QHXD_LaoCai_KhuQuyHoach",
//         isLayer: true,
//         order: 2,
//         minZoom: 5,
//         maxZoom: 22,
//         defaultVisible: true,
//         show: false,
//         type: "line",
//         filter: ["==", "kqh_loaiqh", "QHC"],
//         style: {
//           layout: {
//             visibility: "visible",
//           },
//           paint: {
//             "line-color": "brown",
//             "line-width": 1,
//             "line-opacity": 1,
//             "line-dasharray": [3, 3],
//           },
//         },
//       },
//       {
//         label: "Ranh giới quy hoạch phân khu",
//         id: MAP_LAYERS_ID.QHC_BORDER_PK,
//         source: "src_QHXD_LaoCai_KhuQuyHoach",
//         layer: "QHXD_LaoCai_KhuQuyHoach",
//         isLayer: true,
//         order: 2,
//         minZoom: 13,
//         maxZoom: 22,
//         defaultVisible: true,
//         show: false,
//         type: "line",
//         filter: ["==", "kqh_loaiqh", "QHPK"],
//         style: {
//           layout: {
//             visibility: "visible",
//           },
//           paint: {
//             "line-color": "brown",
//             "line-width": 1,
//             "line-opacity": 1,
//             "line-dasharray": [3, 3],
//           },
//         },
//       },
//       {
//         label: "Quy hoạch chi tiết",
//         id: MAP_LAYERS_ID.QHCT,
//         layer: "QHXD_LaoCai",
//         isLayer: true,
//         order: 2,
//         minZoom: 13,
//         maxZoom: 22,
//         defaultVisible: false,
//         type: "fill",
//         filter: ["==", "oqh_loaiqh", "QHCT"],
//         style: {
//           layout: {
//             visibility: "none",
//           },
//           paint: {
//             "fill-opacity": 1,
//             "fill-pattern": QHXD_PATTERN,
//             "fill-outline-color": "gray",
//           },
//         },
//       },
//       {
//         label: "Nhãn QH chung",
//         id: "QHC_LaoCai_label",
//         layer: "QHXD_LaoCai_NhanKhuQuyHoach",
//         source: "src_QHXD_LaoCai_NhanKhuQuyHoach",
//         isLayer: true,
//         order: 1,
//         minZoom: 5,
//         maxZoom: 13,
//         defaultVisible: true,
//         type: "symbol",
//         filter: ["==", "kqh_loaiqh", "QHC"],
//         style: {
//           layout: {
//             visibility: "visible",
//             "icon-image": "nhandiadanh",
//             "icon-text-fit": "both",
//             "icon-text-fit-padding": [5, 20, 5, 20],
//             "text-field": ["get", "kqh_tendoituong"],
//             "text-size": 12,
//             "text-font": ["Open Sans Regular"], // Font chữ của nhãn
//             "text-offset": [0, 0.6],
//             "text-anchor": "top",
//           },
//           paint: {
//             "text-color": "black",
//             "text-halo-width": 2,
//             "text-halo-color": "white",
//           },
//         },
//       },
//       {
//         label: "Nhãn QH Phân Khu",
//         id: "QHPK_LaoCai_label_pk",
//         layer: "QHXD_LaoCai_NhanKhuQuyHoach",
//         source: "src_QHXD_LaoCai_NhanKhuQuyHoach",
//         isLayer: true,
//         order: 1,
//         minZoom: 13,
//         maxZoom: 22,
//         defaultVisible: false,
//         type: "symbol",
//         filter: ["==", "kqh_loaiqh", "QHPK"],
//         style: {
//           layout: {
//             visibility: "none",
//             "icon-image": "nhandiadanh",
//             "icon-text-fit": "both",
//             "icon-text-fit-padding": [5, 20, 5, 20],
//             "text-field": ["get", "kqh_tendoituong"],
//             "text-size": 12,
//             "text-font": ["Open Sans Regular"], // Font chữ của nhãn
//             "text-offset": [0, 0.6],
//             "text-anchor": "top",
//           },
//           paint: {
//             "text-color": "black",
//             "text-halo-width": 2,
//             "text-halo-color": "white",
//           },
//         },
//       },
//       {
//         label: "Nhãn QH chi tiết",
//         id: "QHXD_LaoCai_label",
//         layer: "QHXD_LaoCai_NhanOQuyHoach",
//         isLayer: true,
//         order: 1,
//         minZoom: 15,
//         maxZoom: 22,
//         defaultVisible: false,
//         filter: ["==", "noqh_loaiqh", "QHCT"],
//         type: "symbol",
//         style: {
//           layout: {
//             visibility: "none",
//             "text-field": ["get", "noqh_chucnangsdd"],
//             "text-size": 12,
//             "text-font": ["Open Sans Regular"], // Font chữ của nhãn
//             "text-offset": [0, 0.6],
//             "text-anchor": "top",
//           },
//           paint: {
//             "text-color": "black",
//             "text-halo-width": 1,
//             "text-halo-color": "white",
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "QH Không gian kiến trúc cảnh quan",
//     id: "QHKGKTCQ",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Không gian cây xanh",
//         id: "QHXD_LaoCai_KhongGianCayXanh",
//         layer: "QHXD_LaoCai_KhongGianCayXanh",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "fill",
//         style: {
//           paint: {
//             "fill-opacity": 1,
//             "fill-pattern": "datcayxanh",
//           },
//         },
//       },
//       {
//         label: "Không gian mặt nước",
//         id: "QHXD_LaoCai_KhongGianMatNuoc",
//         layer: "QHXD_LaoCai_KhongGianMatNuoc",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "fill",
//         style: {
//           paint: {
//             "fill-opacity": 1,
//             "fill-pattern": "matnuoc",
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: " Quy hoạch GT & chỉ giới",
//     id: "QHGT",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Chỉ giới đường đỏ",
//         id: "QHXD_LaoCai_ChiGioiDuongDo",
//         layer: "QHXD_LaoCai_ChiGioiDuongDo",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "red",
//             "line-width": 1,
//             "line-opacity": 1,
//             "line-dasharray": [3, 3],
//           },
//         },
//       },
//       {
//         label: "Chỉ giới xây dựng",
//         id: "QHXD_LaoCai_ChiGioiXayDung",
//         layer: "QHXD_LaoCai_ChiGioiXayDung",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "black",
//             "line-width": 1,
//             "line-opacity": 1,
//             // 'line-dasharray': [2, 2]
//           },
//         },
//       },
//       {
//         label: "Mạng lưới giao thông",
//         id: "QHXD_LaoCai_MangLuoiGiaoThongDuongBo",
//         layer: "QHXD_LaoCai_MangLuoiGiaoThongDuongBo",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "white",
//             "line-width": 1,
//             "line-opacity": 0.7,
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "Quy hoạch Cấp điện",
//     id: "QHCD",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Mạng lưới phân phối điện",
//         id: "QHXD_LaoCai_MangLuoiPhanPhoiDien",
//         layer: "QHXD_LaoCai_MangLuoiPhanPhoiDien",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "#FFFF00",
//             "line-width": 2,
//             "line-opacity": 1,
//             "line-dasharray": [3, 3],
//           },
//         },
//       },
//       {
//         label: "Công trình cấp điện",
//         id: "QHXD_LaoCai_CongTrinhCapDien",
//         layer: "QHXD_LaoCai_CongTrinhCapDien",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "circle",
//         style: {
//           layout: {
//             visibility: "none",
//             // 'icon-image': 'station',
//             // 'icon-size': 1,
//             // 'icon-allow-overlap': true,
//             // 'line-width': 1,
//             // 'line-opacity': 1,
//             // 'line-dasharray': [3, 3]
//           },
//           paint: {
//             "circle-color": "green",
//             "circle-radius": 5,
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "Quy hoạch Chiếu sáng",
//     id: "QHCS",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Mạng lưới điện chiếu sáng",
//         id: "QHXD_LaoCai_MangLuoiDienChieuSang",
//         layer: "QHXD_LaoCai_MangLuoiDienChieuSang",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "orange",
//             "line-width": 2,
//             "line-opacity": 1,
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "Quy hoạch Cấp nước",
//     id: "QHCN",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Mạng lưới cấp nước",
//         id: "QHXD_LaoCai_MangLuoiCapNuoc",
//         layer: "QHXD_LaoCai_MangLuoiCapNuoc",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "blue",
//             "line-width": 2,
//             "line-opacity": 1,
//           },
//         },
//       },
//       {
//         label: "Công trình đầu mối cấp nước",
//         id: "QHXD_LaoCai_CongTrinhDauMoiCapNuoc",
//         layer: "QHXD_LaoCai_CongTrinhDauMoiCapNuoc",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "symbol",
//         style: {
//           layout: {
//             visibility: "none",
//             "icon-image": "water_supply",
//             "icon-size": 1,
//             "icon-allow-overlap": true,
//             // 'line-width': 1,
//             // 'line-opacity': 1,
//             // 'line-dasharray': [3, 3]
//           },
//           paint: {},
//         },
//       },
//     ],
//   },
//   {
//     label: "QH thoát nước mưa/ CBKT san nền",
//     id: "QHTNM",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Mạng lưới thoát nước",
//         id: "QHXD_LaoCai_MangLuoiThoatNuocMua",
//         layer: "QHXD_LaoCai_MangLuoiThoatNuocMua",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           paint: {
//             "line-color": "blue",
//             "line-width": 2,
//             "line-opacity": 1,
//           },
//         },
//       },
//       {
//         label: "Công trình tiêu thoát nước",
//         id: "QHXD_LaoCai_CongTrinhTieuThoatNuoc",
//         layer: "QHXD_LaoCai_CongTrinhTieuThoatNuoc",
//         isLayer: true,
//         order: 2,
//         // minZoom: 14,
//         // maxZoom: 22,
//         defaultVisible: false,
//         type: "symbol",
//         style: {
//           layout: {
//             visibility: "none",
//             "icon-image": "rainwater",
//             "icon-size": 1,
//             "icon-allow-overlap": true,
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "QH thoát nước thải, nghĩa trang & CTR",
//     id: "QHTNM",
//     isGroupLayer: true,
//     children: [],
//   },
//   {
//     label: "QH thông tin liên lạc",
//     id: "QHTTLL",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Mạng lưới thông tin liên lạc",
//         id: "QHXD_LaoCai_MangLuoiThongTinLienLac",
//         layer: "QHXD_LaoCai_MangLuoiThongTinLienLac",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "line",
//         style: {
//           layout: {
//             visibility: "none",
//           },
//           paint: {
//             "line-color": "green",
//             "line-width": 2,
//             "line-opacity": 1,
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "QH DMC",
//     id: "QHDMC",
//     isGroupLayer: true,
//     children: [],
//   },
//   {
//     label: "Địa chính",
//     id: "DiaChinh",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Thửa đất",
//         id: MAP_LAYERS_ID.THUADAT,
//         layer: "QHXD_LaoCai_ThuaDat",
//         isLayer: true,
//         order: 3,
//         minZoom: 8,
//         maxZoom: 22,
//         defaultVisible: false,
//         type: "fill",
//         style: {
//           paint: {
//             "fill-color": "#8B4513 ",
//             // 'line-width': 1,
//             "fill-opacity": 0.8,
//             "fill-outline-color": "black",
//             // 'fill-color': THUADAT_STYLE,
//             // 'fill-color': 'gray',
//             // 'fill-opacity': DEFAULT_LAYERS_OPACITY[MAP_LAYERS_ID.THUADAT]
//           },
//         },
//       },
//     ],
//   },
//   {
//     label: "Hạ tầng kỹ thuật",
//     id: "HTKT",
//     isGroupLayer: true,
//     children: [
//       {
//         label: "Cây xanh",
//         id: "QHXD_LaoCai_CayXanh",
//         layer: "QHXD_LaoCai_CayXanh",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "symbol",
//         style: {
//           layout: {
//             visibility: "none",
//             "icon-image": "tree",
//             "icon-size": 1,
//             "icon-allow-overlap": true,
//           },
//         },
//       },
//       {
//         label: "Cống",
//         id: "QHXD_LaoCai_Cong",
//         layer: "QHXD_LaoCai_Cong",
//         isLayer: true,
//         order: 2,
//         defaultVisible: false,
//         type: "symbol",
//         style: {
//           layout: {
//             visibility: "none",
//             "icon-image": "cong",
//             "icon-size": 1,
//             "icon-allow-overlap": true,
//           },
//         },
//       },
//     ],
//   },
// ];

export const MAP_TREE_LAYERS_DATA = [
  {
    id: "QHSDD",
    label: "1.Quy hoạch SDD",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_RanhGioiQuyHoachA",
        label: "Ranh giới đồ án quy hoạch",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai",
        label: "Ô quy hoạch",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_NhanOQuyHoach",
        label: "Nhãn ô quy hoạch",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_CoCauPhanLo",
        label: "Lô đất",
        defaultVisible: true,
      },
    ],
  },
  {
    id: "QHKGKTCQ",
    label: "2.QH Không gian KTCQ",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_KhongGianMatNuoc",
        label: "Không gian mặt nước",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_KhongGianCayXanh",
        label: "Không gian cây xanh",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHGT",
    label: "3.QH Giao thông & chỉ giới",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_MangLuoiGiaoThongDuongBo",
        label: "Mạng lưới giao thông đường bộ",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_BoVia",
        label: "Bó vỉa",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_ChiGioiDuongDo",
        label: "Chỉ giới đường đỏ",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_ChiGioiXayDung",
        label: "Chỉ giới xây dựng",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHTNM",
    label: "4.QH Thoát nước mưa",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_PhanVungLuuVucThoatNuocMua",
        label: "Phân vùng lưu vực TNM",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_MangLuoiThoatNuocMua",
        label: "Mạng lưới TNM",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HuongThoatNuocMua",
        label: "Hướng thoát nước mưa",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_CongTrinhTieuThoatNuoc",
        label: "Công trình tiêu thoát nước",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHCD",
    label: "5.QH Cấp điện - Chiếu sáng",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_MangLuoiDienChieuSang",
        label: "Mạng lưới điện chiếu sáng",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_MangLuoiPhanPhoiDien",
        label: "Mạng lưới phân phối điện",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_CongTrinhCapDien",
        label: "Công trình cấp điện",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHTNT",
    label: "6.QH Thoát nước thải",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_MangLuoiThoatNuocThai",
        label: "Mạng lưới thoát nước thải",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HuongThoatNuocThai",
        label: "Hướng thoát nước thải",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_DiemCaoDoThoatNuocThai",
        label: "Điểm cao độ thoát nước thải",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHTTLL",
    label: "7.QH Thông tin liên lạc",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_MangLuoiThongTinLienLac",
        label: "Mạng lưới thông tin liên lạc",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_MangLuoiTramThongTin",
        label: "Trạm vệ tinh",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "QHDMC",
    label: "8.QH Đánh giá môi trường",
    isGroupLayer: true,
    defaultVisible: false,
    children: [
      {
        id: "QHXD_LaoCai_DanhGiaMoiTruongChienLuoc",
        label: "Đánh giá môi trường chiến lược",
        defaultVisible: false,
      },
    ],
  },
];
export const MAP_TREE_LAYERS_DIACHINH_DATA = [
  {
    id: "QHTD",
    label: "1.Ranh Thửa đất",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_ThuaDat",
        label: "Thửa đất",
        defaultVisible: true,
      },
    ],
  },
];

export const HA_TANG_KY_THUAT_TREE_LAYERS_DATA = [
  {
    id: "HTGT",
    label: "Hạ tầng giao thông",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_HTKT_DuongGiaoThong",
        label: "Đường giao thông",
        defaultVisible: true,
      },

      {
        id: "QHXD_LaoCai_HTKT_CameraGiaoThong",
        label: "Camera giao thông",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_HTKT_CotDenGiaoThong",
        label: "Cột đèn giao thông(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTGT_BenXe",
        label: "Bến xe(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTGT_BaiDoXe",
        label: "Bãi đỗ xe(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTGT_TramDung",
        label: "Trạm dừng nghỉ(trống)",
        defaultVisible: false,
      },
      // {
      //   id: "QHXD_LaoCai_HTKT_CayXanh",
      //   label: "Cây xanh",
      //   defaultVisible: true,
      // },
      // {
      //   id: "QHXD_LaoCai_HTKT_Mo",
      //   label: "Mỏ",
      //   defaultVisible: true,
      // },
      // {
      //   id: "QHXD_LaoCai_HTKT_Cong",
      //   label: "Cống",
      //   defaultVisible: true,
      // },
    ],
  },
  {
    id: "HTCS",
    label: "Hạ tầng chiếu sáng",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_HTKT_CapChieuSang",
        label: "Cáp chiếu sáng",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_HTKT_CotDen",
        label: "Cột đèn chiếu sáng",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_HTCS_TuDien",
        label: "Tủ điện(trống)",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "HTCX",
    label: "Hạ tầng cây xanh & đô thị",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_HTKT_CayXanh",
        label: "Cây xanh",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_HTDT_ViaHe",
        label: "Vỉa hè(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTDT_VuonHoa",
        label: "Vườn Hoa(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTDT_CongVien",
        label: "Công viên(trống)",
        defaultVisible: false,
      },
    ],
  },
  {
    id: "HTCTN",
    label: "Hạ tầng cấp thoát nước",
    isGroupLayer: true,
    defaultVisible: true,
    children: [
      {
        id: "QHXD_LaoCai_HTCTN_NhaMay",
        label: "Nhà máy(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTCTN_OngChinh",
        label: "Đường ống chính(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTCTN_OngPhu",
        label: "Đường ống phụ(trống)",
        defaultVisible: false,
      },
      {
        id: "QHXD_LaoCai_HTKT_Cong",
        label: "Cống thoát nước",
        defaultVisible: true,
      },
      {
        id: "QHXD_LaoCai_HTKT_HoGa",
        label: "Hố ga thoát nước",
        defaultVisible: false,
      },
    ],
  },
];
