<!-- breadcrumb item -->
<div class="row">
    <div class="col-12">
        <div class="page-title-box pb-3 d-flex align-items-center justify-content-between">
            <div>
                <h4 class="mb-0 font-size-18 text-primary">{{ title }}</h4>
            </div>

        </div>
    </div>
</div>

<app-loading></app-loading>