<!-- <div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>
  <app-header class="header" *ngIf="!isMobileScreen"></app-header>
  <app-sidebar [isCondensed]="isCondensed" [menuSidebar]="menu"></app-sidebar>

  <div class="main-content h-100">
    <div class="page-content pb-0">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div> -->
<div id="layout-wrapper">
  <app-header class="header" *ngIf="!isMobileScreen"></app-header>
  <div class="body_container">
    <app-sidebar [isCondensed]="isCondensed" [menuSidebar]="menu"></app-sidebar>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
    <app-rightsidebar></app-rightsidebar>
  </div>
</div>
