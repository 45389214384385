import { Injectable } from '@angular/core';
//import { HelperService } from '../service/helper.service';
import { CookieService } from "ngx-cookie-service";
@Injectable({
  providedIn: 'root'
})
export class StorageService {



  constructor(
    private cookie: CookieService
  ) { }

  // async set(key: string, data: any, minutes: any): Promise<any> {
  //   return new Promise(resolve => {
  //     this.cookie.set(key, data, this.addMinutes(minutes));
  //     resolve(data);
  //   })
  // }

  async set(key: string, data: any, minutes: any): Promise<any> {
    return new Promise(resolve => {
      localStorage.setItem(key, data);
    //  this.cookie.set(key, data, this.addMinutes(minutes));
      resolve(data);
    })
  }

  async get(key: any): Promise<any> {
    return new Promise(resolve => {
      // var rs = this.cookie.get(key);
      // console.log(rs);
      var rs =  localStorage.getItem(key);
      if (rs != null && rs.length > 0)
        resolve(rs);
      else
        resolve(null);
    })
  }

  async delete(key: string): Promise<any> {
    return new Promise(resolve => {
      this.cookie.set(key, '', new Date("Thu, 01 Jan 1970 00:00:01 GMT"));
      resolve(true);
    })
  }

  async deleteAll(): Promise<any> {
    return new Promise(resolve => {
     // this.cookie.deleteAll();
     localStorage.clear();
      resolve(true);
    })
  }

  addMinutes(minute: number) {
    var now = new Date();
    now.setMinutes(now.getMinutes() + minute); // timestamp
    now = new Date(now); // Date object
    return now;
  }



}
