import { Injectable } from '@angular/core';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {

  constructor() { }

  replaceAllString(str: any, find: any, replace: any) {
    return str.replace(new RegExp(find, 'gi'), replace);
  }

  addMinutes(date: any, minute: any) {
    var now = date;
    now.setMinutes(now.getMinutes() + minute); // timestamp
    now = new Date(now); // Date object
    return now;
  }


  dateToStringDdMmYyyy(date: any) {
    
    if(date ==null || date==undefined )
    return "";
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  timestampToDateStringDdMmYyyy(timestamp: any) {
    if(timestamp ==null || timestamp <=0)
    return "";
    var date = this.timestampToDate(timestamp);
    if(date ==null)
    return "";

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  getRandom(length: any) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));

  }

  dateToTimestamp(date: any) {
    if (date != null && date != undefined && date.toString().length > 5)
      return Math.round(date.getTime());
    else
      return 0;
  }



  formatCurrency(number: any) {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      console.log(e);
      return number;
    }
  }

  timestampToDate(timestamp: any) {
    try {
      if (timestamp == 0)
        return null;
      return new Date(timestamp);
    } catch (e) {
      console.log(e);
      return new Date();
    }
  }

  stringToDate(dateString: string) {
    var d = new Date();
    try {
      const matches = dateString.match(/\d+/)
      if (matches?.length) {
        var timestamp = parseInt(matches[0]);
        d = new Date(timestamp);
      }
    } catch (e) {
      console.log(e);
    }
    return d;
  }

  //dateStr dd/MM/yyyy
  stringToDate2(dateStr: string): Date {
    // Split the string by '/'
    const parts = dateStr.split('/');

    // Extract day, month, and year from the split string
    // Note: Subtract 1 from month because months are 0-indexed in JavaScript Dates
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    // Create a new Date object using the extracted components
    return new Date(year, month, day);
  }

  //dateStr dd/MM/yyyy HH:mm:ss
  stringToDate3(dateStr: string, fomart: any): Date {
    // Split the string by '/'
    const delimiters = ["/", " ", ":"];
    const parts = this.splitString(dateStr, delimiters);

    // Extract day, month, and year from the split string
    // Note: Subtract 1 from month because months are 0-indexed in JavaScript Dates
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const hour = parseInt(parts[3], 10);
    const minute = parseInt(parts[4], 10);
    const second = parseInt(parts[5], 10);

    // Create a new Date object using the extracted components
    return new Date(year, month, day,hour,minute,second);
  }

  splitString(input: string, delimiters: string[]): string[] {
    // Create a regular expression from the delimiters
    // Escape special characters in delimiters and join them with '|'
    const regexPattern = delimiters.map(delimiter => `\\${delimiter}`).join('|');
    const regex = new RegExp(regexPattern, 'g');

    // Split the input string using the constructed regular expression
    return input.split(regex);
  }

  playAudio(filePath: any) {
    try {
      let audio = new Audio();
      audio.src = filePath;
      audio.load();
      audio.play();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }


}
