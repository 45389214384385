import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { GenHtmlFormComponent } from '../../../GenHtml/gen-html-form/gen-html-form.component';
import { DmChungService } from '../../../../@core/data/service/dm-chung.service';
import * as Constants from '../../../../Constants/Constants';
import { UsersService } from '../../../../@core/data/service/users.service';
import { AlertService } from '../../../../ServiceCommon/alert.service';
import { CommonService } from '../../../../ServiceCommon/common.service';
import { StorageService } from '../../../../ServiceCommon/storage.service';
import {AuditServiceService} from "../../../../@core/data/service/audit-service.service"
import {Location } from '@angular/common';
import {LoadingService} from "../../../../ServiceCommon/loading.service"
import { HelperService } from '../../../../ServiceCommon/helper.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements AfterViewInit {
  @ViewChild(GenHtmlFormComponent) genHtmlFormComponent:
    | GenHtmlFormComponent
    | undefined;
  data: any = {};
  title: any = '';
  isEdit = true;

  columns: any = [];
  buttons: any = Constants.BUTTONS;
  

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dmChungService: DmChungService,
    private usersService: UsersService,
    private alertService: AlertService,
    private commonService: CommonService,
    private storageService: StorageService,
    private auditServiceService:AuditServiceService,
    private location:Location,
    private loadingService:LoadingService
  ) {}

  ngAfterViewInit(): void {
    this.storageService.get(Constants.CACHE_ID_USER).then((userId) => {
      // this.init(userId);
    });

    this.changeDetectorRef.detectChanges();
  }
  async init(userId: any) {
    console.log(this.isEdit);
    console.log(this.data);
    this.data = await this.getUserByID(userId);
    console.log(this.data);

    var statusUser = await this.getStatusUser();
    var statusUserNew = statusUser.map((x: any) => ({
      value: x.ma,
      label: x.ten,
    }));

    this.columns = [
      {
        datatype: Constants.DATA_TYPE_OBJECT.TEXT,
        disable: this.isEdit ? true : false,
        value: this.data.username,
        name: 'username',
        label: 'Tên đăng nhập',
        placeholder: 'Tên đăng nhập',
        required: true,
        col: 6,
      },
      {
        datatype: Constants.DATA_TYPE_OBJECT.TEXT,
        disable: this.isEdit ? true : false,
        value: this.data.password,
        name: 'password',
        label: 'Mật khẩu',
        placeholder: 'Mật khẩu',
        required: this.isEdit ? true : false,
        col: 6,
      },
      {
        datatype: Constants.DATA_TYPE_OBJECT.TEXT,
        disable: false,
        value: this.data.fullName,
        name: 'fullName',
        label: 'Họ và tên',
        placeholder: 'Họ và tên',
        required: true,
        col: 6,
      },
      {
        datatype: Constants.DATA_TYPE_OBJECT.TEXT,
        disable: false,
        value: this.data.phone,
        name: 'phone',
        label: 'Số điện thoại',
        placeholder: 'Số điện thoại',
        required: false,
        col: 6,
      },
      {
        datatype: Constants.DATA_TYPE_OBJECT.TEXT,
        disable: false,
        value: this.data.email,
        name: 'email',
        label: 'Email',
        placeholder: 'Email',
        required: false,
        col: 6,
      },
      {
        datatype: Constants.DATA_TYPE_OBJECT.COMBOBOX,
        disable: false,
        value: this.data._status,
        name: '_status',
        label: 'Trạng thái',
        placeholder: 'Trạng thái',
        required: true,
        col: 6,
        data: statusUserNew,
      },
    ];

    this.genHtmlFormComponent?.init(this.columns);
  }

  async getStatusUser() {
    return await this.dmChungService.findByLoai({
      loai: Constants.STATUS_USER.TYPE,
    });
  }

  async getUserByID(id: any) {
    return await this.usersService.getUserByID(id);
  }
  async genHtmlFormComponentEmitter(event: any) {
    var data = await this.commonService.mapColumnsToObject(
      event.columns,
      this.data
    );
    this.data = data;
    console.log(this.data);
  }
  // Hàm kiểm tra dữ liệu email
  validateEmail(): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(this.data.email);
  }

  // Hàm kiểm tra dữ liệu số điện thoại
  validatePhoneNumber(): boolean {
    const phonePattern = /^\d{10,10}$/;
    return phonePattern.test(this.data.phone);
  }
  async update() {
    
    // Kiểm tra dữ liệu email và số điện thoại trước khi lưu vào cơ sở dữ liệu
    if (this.data.email !=undefined && !this.validateEmail() ) {
      this.alertService.error('Email không hợp lệ.');
      return;
    }

    if (this.data.phone !=undefined && !this.validatePhoneNumber()) {
      this.alertService.error('Số điện thoại không hợp lệ.');
      return;
    }
  
    var data ={...this.data};
    console.log(data);
    data["user_id"] = data.userId;
    data["full_name"] = data.fullName;
    delete data.userId
    delete data.fullName
    delete data.password;
    
    console.log(data);
    this.loadingService.showLoading();
    this.usersService.update(data).then((rs: any) => {
      this.loadingService.hideLoading();

      console.log(rs);
      if (rs != null && rs ==true) {
        this.auditServiceService.insertLog("Cập nhật Profile","người dùng cập nhật thông tin tài khoản",
        Constants.BUTTONS.EDIT_INFO.VALUE,Constants.BUTTONS.EDIT_INFO.LABEL,data.user_id, this.location.path());

        this.alertService.success(Constants.Messages.UPDATE_SUCCESS);
      } else this.alertService.error(Constants.Messages.UPDATE_ERROR);
    });
  }
}
