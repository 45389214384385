<div class="feature-info" cdkDrag>
    <div class="header">
        <h5 style="margin-bottom: 0px;">Tìm kiếm quy hoạch</h5>
        <button type="btn btn-circle" style="border:none;border-radius: 50%; background: white;" (click)="close()">
            <i class="fa fa-close fa-lg"></i>
        </button>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <div style="display: flex;flex-direction: column;justify-content: flex-start; ">
        <a *ngFor="let search of listSearch; let i = index" (click)="clickSearchType($event, search)"
            class="lst-item-layer" href="javascript: void(0);">
            <input class="form-check-input me-2" type="radio" name="formRadios" id="formRadios{{i}}"
                value={{search.value}} [(ngModel)]="selectedSearch">
            <label class="form-check-label fw-500" for="formRadios{{i}}">
                {{search.label}}
            </label>
        </a>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <div class="body">

        <form class="form-search" [formGroup]="formSearch" (ngSubmit)="handleSearch()">
            <ul ngbNav #searchTab="ngbNav" [activeId]="1" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                        <span class="d-none d-sm-block">Tìm theo thuộc tính</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="selectedSearch==='td'">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                        <span class="d-none d-sm-block">Tìm không gian</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                    </ng-template>
                </li>
            </ul>
            <hr style="margin:-1px;height:1px;background:#ccc;" />
            <div [ngbNavOutlet]="searchTab" class="c-tab-content-st"></div>
            <ng-template #TabContent>
                <div *ngIf="selectedSearch==='qhsdd'" class="mt-3">
                    <label class="form-label">Mục đích sử dụng</label>
                    <div>
                        <div style="display: flex;align-items: center;">
                            <input class="form-control" list="dmMDSD" id="exampleDataList"
                                placeholder="Nhập để tìm kiếm..." formControlName="mdsd" name="mdsd">
                            <span *ngIf="formSearch.get('mdsd').value" style="position:relative;left: -20px;">
                                <i class="fa fa-close fa-lg" (click)="clearInput('mdsd')"></i>
                            </span>
                        </div>
                        <datalist id="dmMDSD">
                            <option value="">------none-------</option>
                            <option *ngFor="let dm of dmMDSD; index as rowIndex;" value={{dm.value}}>
                                {{dm.label}}
                            </option>
                        </datalist>
                    </div>
                </div>
                <div *ngIf="selectedSearch==='qhxd'" class="mt-3">
                    <label class="form-label">Chức năng</label>
                    <div style="display: flex;align-items: center;">
                        <input class="form-control" list="dmCN" id="exampleDataList1" placeholder="Nhập để tìm kiếm..."
                            formControlName="cnxd" name="cnxd">
                        <span *ngIf="formSearch.get('cnxd').value" style="position:relative;left: -20px;">
                            <i class="fa fa-close fa-lg" (click)="clearInput('cnxd')"></i>
                        </span>
                    </div>
                    <div>
                        <datalist id="dmCN">
                            <option value="">------none-------</option>
                            <option *ngFor="let dm of dmCN; index as rowIndex;" value={{dm.value}}>
                                {{dm.label}}
                            </option>
                        </datalist>
                    </div>
                </div>
                <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
                <div *ngIf="showResult" class="search-result">
                    <h5 style="text-align: center;">Kết quả tìm kiếm:</h5>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch chung:</span>
                        <span style="width:70px"><strong>{{searchResult.QHC}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHC')"></i>
                        </span>
                    </div>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch phân khu:</span>
                        <span style="width:70px"><strong>{{searchResult.QHPK}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHPK')"></i>
                        </span>
                    </div>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch chi tiết:</span>
                        <span style="width:70px"><strong>{{searchResult.QHCT}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHCT')"></i>
                        </span>
                    </div>
                </div>
                <!-- <hr style="margin:5px;height:1px;background:#ccc;" /> -->
                <div *ngIf="selectedSearch==='td'">
                    <div class="mt-3">
                        <label class="form-label">Địa phận xã</label>
                        <div style="display: flex;align-items: center;">
                            <input class="form-control" list="dmXa" placeholder="Nhập để tìm kiếm..."
                                formControlName="ccode" name="ccode">
                            <span *ngIf="formSearch.get('ccode').value" style="position:relative;left: -20px;">
                                <i class="fa fa-close fa-lg" (click)="clearInput('ccode')"></i>
                            </span>
                        </div>
                        <div>
                            <datalist id="dmXa">
                                <option value="">------none-------</option>
                                <option *ngFor="let dm of dmXa; index as rowIndex;" value={{dm.value}}>
                                    {{dm.label}}
                                </option>
                            </datalist>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="form-label">Số hiệu mảnh</label>
                        <div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <input class="form-control" placeholder="Nhập số hiệu mảnh" formControlName="soto"
                                    name="soto">
                                <span *ngIf="formSearch.get('soto').value" style="position:relative;left: -20px;">
                                    <i class="fa fa-close fa-lg" (click)="clearInput('soto')"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="form-label">Số hiệu thửa</label>
                        <div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <input class="form-control" placeholder="Nhập số thửa đất" formControlName="sothua"
                                    name="sothua">
                                <span *ngIf="formSearch.get('sothua').value" style="position:relative;left: -20px;">
                                    <i class="fa fa-close fa-lg" (click)="clearInput('sothua')"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #TabContent1>
                <!-- <p class="p-3 mb-0" style="color:black;">
                    tab không gian
                </p> -->
                <div class="spatial-container">
                    <!-- <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" 
                            value="polygon">
                        <label class="form-check-label" for="inlineRadio1">Tìm theo vùng</label>
                    </div> -->
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa-minus fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchLine()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo đường
                        </button>
                    </div>
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa-square fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchPolygon()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo vùng
                        </button>
                    </div>
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa fa-circle fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchPoint()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo bán kính
                        </button>

                    </div>
                    <div class="flex-gap-12">
                        <label style="width:200px;margin-bottom: 0px;">Bán kính(m)</label>
                        <input type="number" formControlName="radius" class="form-control" placeholder="Nhập bán kính">
                        <button type="button" style="margin-left:5px;width:50px;" (click)="clearDraw()"
                            title="Tìm kiếm theo bán kính" class="btn-layers btn-default btn-outline btn btn-circle">
                            <i class="fa fa-times" style="font-size: 20px;width:16px;"></i>
                        </button>
                    </div>
                    <div *ngIf="showRadiusError">
                        <span style="color:red;">Chưa nhập bán kính !</span>
                    </div>
                    <!-- <button type="button" (click)="searchPolygon()" title="Tìm kiếm theo vùng"
                        class="btn-layers btn-default btn-outline btn btn-circle">
                        <img src="/assets/images/map/polygon.png" style="width:18px;height:18px;" />
                        Tìm theo vùng
                    </button> -->
                    <!-- <div class="flex-gap-12">
                        <button type="button" (click)="searchPoint()" title="Tìm kiếm theo bán kính"
                            class="btn-layers btn-default btn-outline btn btn-circle">
                            <img src="/assets/images/map/oval.png" style="width:18px;height:18px;" />
                            Tìm theo bán kính
                        </button>
                       
                    </div> -->
                </div>

            </ng-template>
        </form>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <div class="modal-footer">
        <div class="button-right">
            <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
                (click)="close()">Đóng</button>
            <button type="submit" class="btn btn-primary waves-effect waves-light" (click)="handleSearch()">
                <i class="fa fa-search"></i> Tìm kiếm
            </button>
        </div>
    </div>
</div>
<app-table-quy-hoach *ngIf="isShowTable" (onClose)="closeTable()" [(tableData)]="tableData"  (fitParcelBound)="locatePolygon($event)"></app-table-quy-hoach>