<div class="tree-container">
  <div class="header">
    <h5>Lớp bản đồ chuyên đề</h5>
    <!-- <span
        *ngIf="isExpandAll"
        class="ic-toggle"
        (click)="toggleCollaspeEnpandAll()"
        title="Đóng lại tất cả"
      >
        <i
          class="fa fa-folder"
          style="color: #179568; font-size: 18px"
          aria-hidden="true"
        ></i>
      </span>
      <span
        *ngIf="!isExpandAll"
        class="ic-toggle"
        (click)="toggleCollaspeEnpandAll()"
        title="Mở rộng tất cả"
      >
        <i
          class="fa fa-folder-open"
          style="color: #179568; font-size: 18px"
          aria-hidden="true"
        ></i>
      </span> -->
    <button
      type="button"
      (click)="closeWidget()"
      class="btn"
      style="padding: 8px"
    >
      <i class="fa fa-close"></i>
    </button>
  </div>
  <hr style="margin: 3px; height: 1px; background: #ccc" />
  <div class="body">
    <app-group-layer
      [isQH]="true"
      [groupTitle]="'Loại quy hoạch'"
      [(layerVisibleChanges)]="layerVisibleChanges"
      [(inputDataList)]="dataList"
      (changeLayerOpacity)="onChangeOpacity($event)"
      (selectEvent)="checkRow($event)"
      (selectGroupEvent)="checkGroup($event)"
      (selectGroupType)="checkGroupType($event)"
      (close)="closeWidget()"
      (fitLayer)="zoomToLayer($event)"
    ></app-group-layer>
    <div *ngIf="isQH" class="sub-group">
      <div>
        <app-group-layer
          [isQH]="false"
          [groupTitle]="'Địa chính'"
          [(layerVisibleChanges)]="layerVisibleChanges"
          [(inputDataList)]="inputDataList1"
          (changeLayerOpacity)="onChangeOpacity($event)"
          (selectEvent)="checkRow($event)"
          (selectGroupEvent)="checkGroup($event)"
          (selectGroupType)="checkGroupType($event)"
          (close)="closeWidget()"
          (fitLayer)="zoomToLayer($event)"
        ></app-group-layer>
      </div>
    </div>
  </div>
</div>
