<mat-tree #tree class="c-mat-tree" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="10px"
    class="node-empty">
    <div class="d-flex justify-content-between w-100">
    </div>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10px"
    (click)="handleChoice(node)" class="{{nodeSelect.data.id === node.data.id ? 'node-active' : 'node-hover'}}">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.code">
      <i *ngIf="!treeControl.isExpanded(node)" class="fa-solid fa-caret-right"></i>
      <i *ngIf="treeControl.isExpanded(node)" class="fa-solid fa-sort-down"></i>
    </button>
    <i class="fa-solid fa-folder text-warning mx-1"></i>
    <span>{{node.data.text}}</span>

  </mat-tree-node>
</mat-tree>

<!-- 
<mat-tree #tree class="c-mat-tree" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node class="c-tree-node" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
    matTreeNodePaddingIndent="10px">
    <button mat-icon-button disabled></button>
    <i *ngIf="!node?.data?.info" class="fa-solid fa-folder text-warning mx-1"></i>
    <span class="checklist-leaf-node">{{node?.data?.name || ""}}</span>
    <button *ngIf="!node?.data?.info" class="btn btn-sm btn-light p-0 ms-1" (click)="addNewItem(node)">
      <i class="fa-solid fa-plus mx-1"></i>
    </button>
    <div *ngIf="pidNewFolder === node?.data?.id" class="d-flex mb-2">
      <button mat-icon-button disabled></button>
      <form [formGroup]="formFolder" (ngSubmit)="createNewFolder(node)" class="w-100">
        <div class="d-flex">
          <input type="text" class="form-control w-50 form-control-sm ms-4" formControlName="name" name="name"
            placeholder="Nhập tên thư mục" [ngClass]="{'is-invalid': submitted && valFormFolder.name.errors}">
          <div *ngIf="submitted && valFormFolder.name.errors" class="invalid-feedback">
            <span *ngIf="valFormFolder.name.errors.required">Bắt buộc nhập</span>
          </div>
          <button type="submit" class="btn btn-sm btn-light btn-success ms-1">
            <i class="fa-solid fa-check"></i>
          </button>

        </div>

      </form>

    </div>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding matTreeNodePaddingIndent="10px">
    <button mat-icon-button disabled></button>
    <mat-form-field>
      <mat-label>Thư mục mới</mat-label>
      <input matInput #itemValue placeholder="Ex. Lettuce">
    </mat-form-field>
    <button mat-button (click)="saveNode(node, itemValue.value)">Lưu</button>
  </mat-tree-node>

  <mat-tree-node class="c-tree-node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
    matTreeNodePaddingIndent="10px">
    <button class="pe-1" mat-flat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
      <i *ngIf="!node?.data?.info" class="fa-solid fa-folder text-warning mx-1"></i>
      <span>{{node?.data?.name || ""}}</span>
    </button>
    <button *ngIf="!node?.data?.info" class="btn btn-sm btn-light p-0 ms-1" (click)="addNewItem(node)">
      <i class="fa-solid fa-plus mx-1"></i>
    </button>
    <div *ngIf="pidNewFolder === node?.data?.id" class="d-flex mb-2">
      <button mat-icon-button disabled></button>
      <form [formGroup]="formFolder" (ngSubmit)="createNewFolder(node)" class="w-100">
        <div class="d-flex">
          <input type="text" class="form-control w-50 form-control-sm ms-3" formControlName="name" name="name"
            placeholder="Nhập tên thư mục" [ngClass]="{'is-invalid': submitted && valFormFolder.name.errors}">
          <div *ngIf="submitted && valFormFolder.name.errors" class="invalid-feedback">
            <span *ngIf="valFormFolder.name.errors.required">Bắt buộc nhập</span>
          </div>
          <button type="submit" class="btn btn-sm btn-light btn-success ms-1">
            <i class="fa-solid fa-check"></i>
          </button>
        </div>
      </form>
    </div>
  </mat-tree-node>
</mat-tree> -->