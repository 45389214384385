<div class="legend-container" cdkDrag>
    <div class="header">
        <h4>Chú giải quy hoạch xây dựng</h4>
        <button type="button" (click)="close()" class="btn closebtn" style="padding:5px;">
            <i class="fa fa-close"></i>
        </button>
    </div>
    <hr style="margin:3px; height:1px;background:#ccc;" />
    <div class="body">
        <img src="/assets/images/map/legend_xd.png" width="500" />
    </div>
    <!-- <div class="header">
        <ul ngbNav #searchTab="ngbNav" [activeId]="1" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                    <span class="d-none d-sm-block">Chú giải quy hoạch sử dụng đất</span>
                </a>
                <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                    <span class="d-none d-sm-block">Chú giải quy hoạch xây dựng</span>
                </a>
                <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                </ng-template>
            </li>
        </ul> <button type="button" (click)="close()" class="btn closebtn" style="padding:5px;">
            <i class="fa fa-close"></i>
        </button>
    </div>
    <hr style="margin:3px; height:-1px;background:#ccc;" />
    <div class="body">
        <div [ngbNavOutlet]="searchTab" class="c-tab-content-st">
            <ng-template #TabContent>
                <img src="/assets/images/map/legend.png" width="500" />
            </ng-template>
            <ng-template #TabContent1>
                <img src="/assets/images/map/legend_xd.png" width="500" />
            </ng-template>
        </div>
    </div> -->
</div>