import { Injectable } from '@angular/core';
import { HttpService } from '../../../ServiceCommon/http.service';
import {API}  from "../../../../contants/ApiContants"
import { CommonService } from '../../../ServiceCommon/common.service';
@Injectable({
  providedIn: 'root'
})
export class SecRoleButtonsService {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService
  ) { }

  async getAll(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/getAll', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async insert(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/insert', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async update(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/update', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }
  async delete(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/delete', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async getTotalCount(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/getTotalCount', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async findByLinkAndUser(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/role-buttons/findByLinkAndUser', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }
  
  
}
