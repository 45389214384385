<div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed" [menuSidebar]="menu"></app-sidebar>

  <div class="main-content h-100">
    <div class="page-content pb-0">
      <router-outlet></router-outlet>
    </div>
    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

<app-rightsidebar></app-rightsidebar>