<div class="feature-info">
    <div class="header">
        <h5 style="margin-bottom: 0px;">Tìm kiếm quy hoạch</h5>
        <button type="btn btn-circle" style="border:none;border-radius: 50%; background: white;" (click)="close()">
            <i class="fa fa-close fa-lg"></i>
        </button>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <div style="display: flex;flex-direction: column;justify-content: flex-start; ">
        <a *ngFor="let search of listSearch; let i = index" (click)="clickSearchType($event, search)"
            class="lst-item-layer" href="javascript: void(0);">
            <input class="form-check-input me-2" type="radio" name="formRadios" id="formRadios{{i}}"
                value={{search.value}} [(ngModel)]="selectedSearch">
            <label class="form-check-label fw-500" for="formRadios{{i}}">
                {{search.label}}
            </label>
        </a>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <app-loading-block [isLoading]="isLoadingBlock"></app-loading-block>
    <div class="body">
        <form class="form-search" [formGroup]="formSearch" (ngSubmit)="handleSearch()">
            <ul ngbNav #searchTab="ngbNav" [activeId]="1" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                        <span class="d-none d-sm-block">Tìm theo thuộc tính</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="selectedSearch==='td'">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                        <span class="d-none d-sm-block">Tìm không gian</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" *ngIf="selectedSearch==='qhxd'">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                        <span class="d-none d-sm-block">Tìm địa chỉ</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="tabDiaChi"></ng-template>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" *ngIf="selectedSearch==='qhxd'">
                    <a ngbNavLink>
                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                        <span class="d-none d-sm-block">Tìm tọa độ</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="tabToaDo"></ng-template>
                    </ng-template>
                </li>
            </ul>
            <hr style="margin:-1px;height:1px;background:#ccc;" />
            <div [ngbNavOutlet]="searchTab" class="c-tab-content-st"></div>
            <ng-template #TabContent>
                <div *ngIf="selectedSearch==='qhsdd'" class="mt-3">
                    <label class="form-label">Mục đích sử dụng</label>
                    <div>
                        <div style="display: flex;align-items: center;">
                            <input class="form-control" list="dmMDSD" id="exampleDataList"
                                placeholder="Nhập để tìm kiếm..." formControlName="mdsd" name="mdsd">
                            <span *ngIf="formSearch.get('mdsd').value" style="position:relative;left: -20px;">
                                <i class="fa fa-close fa-lg" (click)="clearInput('mdsd')"></i>
                            </span>
                        </div>
                        <datalist id="dmMDSD">
                            <option value="">------none-------</option>
                            <option *ngFor="let dm of dmMDSD; index as rowIndex;" value={{dm.label}}>
                                {{dm.label}}
                            </option>
                        </datalist>
                    </div>
                </div>
                <div *ngIf="selectedSearch==='qhxd'" class="mt-3">
                    <label class="form-label">Chức năng</label>
                    <div style="display: flex;align-items: center;">
                        <input class="form-control" list="dmCN" id="exampleDataList1" placeholder="Nhập để tìm kiếm..."
                            formControlName="cnxd" name="cnxd">
                        <span *ngIf="formSearch.get('cnxd').value" style="position:relative;left: -20px;">
                            <i class="fa fa-close fa-lg" (click)="clearInput('cnxd')"></i>
                        </span>
                    </div>
                    <div>
                        <datalist id="dmCN">
                            <option value="">------none-------</option>
                            <option *ngFor="let dm of dmCN; index as rowIndex;" value={{dm.label}}>
                                {{dm.label}}
                            </option>
                        </datalist>
                    </div>
                </div>

                <div *ngIf="showResult" class="search-result">
                    <h5 style="text-align: center;">Kết quả tìm kiếm:</h5>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch chung:</span>
                        <span style="width:70px"><strong>{{searchResult.QHC}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHC')"></i>
                        </span>
                    </div>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch phân khu:</span>
                        <span style="width:70px"><strong>{{searchResult.QHPK}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHPK')"></i>
                        </span>
                    </div>
                    <div class="result-item">
                        <span class="result-label">Quy hoạch chi tiết:</span>
                        <span style="width:70px"><strong>{{searchResult.QHCT}}</strong> kết quả</span>
                        <span style="cursor:pointer;" title="Xem chi tiết">
                            <i class="fa fa-table fa-lg" (click)="showTable('QHCT')"></i>
                        </span>
                    </div>
                </div>
                <!-- <hr style="margin:5px;height:1px;background:#ccc;" /> -->
                <div *ngIf="selectedSearch==='td'">
                    <div class="mt-3">
                        <label class="form-label">Địa phận xã</label>
                        <div style="display: flex;align-items: center;">
                            <input class="form-control" list="dmXa" placeholder="Nhập để tìm kiếm..."
                                formControlName="ccode" name="ccode">
                            <span *ngIf="formSearch.get('ccode').value" style="position:relative;left: -20px;">
                                <i class="fa fa-close fa-lg" (click)="clearInput('ccode')"></i>
                            </span>
                        </div>
                        <div>
                            <datalist id="dmXa">
                                <option value="">------none-------</option>
                                <option *ngFor="let dm of dmXa; index as rowIndex;" value={{dm.value}}>
                                    {{dm.label}}
                                </option>
                            </datalist>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="form-label">Số hiệu mảnh</label>
                        <div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <input class="form-control" placeholder="Nhập số hiệu mảnh" formControlName="soto"
                                    name="soto">
                                <span *ngIf="formSearch.get('soto').value" style="position:relative;left: -20px;">
                                    <i class="fa fa-close fa-lg" (click)="clearInput('soto')"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="form-label">Số hiệu thửa</label>
                        <div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <input class="form-control" placeholder="Nhập số thửa đất" formControlName="sothua"
                                    name="sothua">
                                <span *ngIf="formSearch.get('sothua').value" style="position:relative;left: -20px;">
                                    <i class="fa fa-close fa-lg" (click)="clearInput('sothua')"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #TabContent1>
                <div class="spatial-container" style="padding-top:10px;">
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa-minus fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchLine()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo đường
                        </button>
                    </div>
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa-square fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchPolygon()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo vùng
                        </button>
                    </div>
                    <div class="flex-gap-12">
                        <span class="image-circle">
                            <i class="fa fa fa-circle fa-lg" aria-hidden="true"></i>
                        </span>
                        <button type="button" class="btn-primary" (click)="searchPoint()" title="Tìm kiếm theo đường"
                            class="btn  btn-primary" style="width:135px">
                            Tìm theo bán kính
                        </button>

                    </div>
                    <div class="flex-gap-12">
                        <label style="width:200px;margin-bottom: 0px;">Bán kính(m)</label>
                        <input type="number" formControlName="radius" class="form-control" placeholder="Nhập bán kính">
                        <button type="button" style="margin-left:5px;width:50px;" (click)="clearDraw()"
                            title="Tìm kiếm theo bán kính" class="btn-layers btn-default btn-outline btn btn-circle">
                            <i class="fa fa-times" style="font-size: 20px;width:16px;"></i>
                        </button>
                    </div>
                    <div *ngIf="showRadiusError">
                        <span style="color:red;">Chưa nhập bán kính !</span>
                    </div>
                    <!-- <button type="button" (click)="searchPolygon()" title="Tìm kiếm theo vùng"
                        class="btn-layers btn-default btn-outline btn btn-circle">
                        <img src="/assets/images/map/polygon.png" style="width:18px;height:18px;" />
                        Tìm theo vùng
                    </button> -->
                    <!-- <div class="flex-gap-12">
                        <button type="button" (click)="searchPoint()" title="Tìm kiếm theo bán kính"
                            class="btn-layers btn-default btn-outline btn btn-circle">
                            <img src="/assets/images/map/oval.png" style="width:18px;height:18px;" />
                            Tìm theo bán kính
                        </button>
                       
                    </div> -->
                </div>

            </ng-template>
            <ng-template #tabDiaChi>
                <div style="padding-top:10px;">
                    <div class="search-box width100">
                        <div class="position-relative">
                            <i class="bx bx-search-alt search-icon cursor-pointer"
                                (click)="onChangeAddressSearch($event)"></i>
                            <input type="search" formControlName="address" class="form-control ps-3 pe-5"
                                placeholder="Nhập địa chỉ ..." (keydown.enter)="onChangeAddressSearch($event)"
                                list="results" />
                        </div>
                    </div>
                    <datalist id="results" *ngIf="addressTable">
                        <option *ngFor="let result of addressTable" class="search-option" value={{result.display_name}}>
                            {{ result.display_name}}
                        </option>
                    </datalist>
                </div>
                <!-- <div class="mb-3">
                    <label for="keyword">Address</label>
                    <input class="custom-input" type="text" id="addressLineTbx" formControlName="addressLine" />
                </div> -->
                <!-- <input class="custom-input" type="text" placeholder="Nhập địa chỉ" autofocus
                    (change)="onChangeAddressSearch($event)" />
                <ng-autocomplete *ngIf="addressTable.length" style="width:100%;" [data]="addressTable"
                    placeholder="Nhập địa chỉ" (selected)='handleSelectAddress($event)'
                    historyIdentifier="referenceTable" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template> -->
            </ng-template>
            <ng-template #tabToaDo>
                <!-- <div style="display: flex;flex-direction: column;justify-content: flex-start;gap:8px; ">
                    <div>
                        <label class="form-label">Hệ toạ độ</label>
                        <div style="display: flex;flex-direction: row;justify-content: flex-start;gap:8px; ">
                            <a *ngFor="let project of listProjects; let i = index" (click)="chooseProject(project)"
                                class="lst-item-layer" href="javascript: void(0);">
                                <input class="form-check-input me-2" type="radio" value={{project.value}} name="proj"
                                    formControlName="proj">
                                <label class="form-check-label fw-500" for="formProjects{{i}}">
                                    {{project.label}}
                                </label>
                            </a>
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: row;justify-content: flex-start;gap:8px;">
                        <label class="form-label" style="width:64px">Kinh độ</label>
                        <input type="number" name="lng" formControlName="lng">
                    </div>
                    <div style="display: flex;flex-direction: row;justify-content: flex-start;gap:8px;">
                        <label class="form-label" style="width:64px">Vĩ độ</label>
                        <input type="number" name="lat" formControlName="lat">
                    </div>
                </div> -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <div>
                                <h5 class="title">Dán link Google Map</h5>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="mat-expansion-panel-body ng-tns-c81-10">
                                <div class="input-group ng-tns-c81-10">
                                    <div class="input-group-prepend ng-star-inserted">
                                        <button type="button" class="btn btn-primary" title="Dán"><i
                                                class="fas fa-paste"></i></button>
                                    </div>
                                    <input type="text" name="linkGG" formControlName="linkGG"
                                        placeholder="Copy từ GoogleMap và dán vào đây"
                                        class="form-control ng-untouched ng-pristine ng-valid"
                                        style="font-size: 100% !important;">
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <div>
                                <h5 class="title">Nhập toạ độ Google Map</h5>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="mat-expansion-panel-body ng-tns-c81-12">
                                <div class="form-group row mb-4 ng-tns-c81-12">
                                    <div class="col-12"><label>Kinh độ, vĩ
                                            độ</label>
                                        <input type="text" formControlName="latLng"
                                            placeholder="Ví dụ: 21.115737,105.956338"
                                            class="form-control ng-untouched ng-pristine ng-valid">
                                    </div>
                                </div>
                                <div class="text-center" style="color:black;">---- hoặc ----<span></span></div>
                                <div class="form-group row mb-4 ng-tns-c81-12">
                                    <div class="col-6"><label>Kinh
                                            độ</label><input type="number" formControlName="lng"
                                            placeholder="Ví dụ: 105.956338"
                                            class="form-control ng-untouched ng-pristine ng-valid"></div>
                                    <div class="col-6"><label>Vĩ
                                            độ</label><input type="number" formControlName="lat"
                                            placeholder="Ví dụ: 21.115737"
                                            class="form-control ng-untouched ng-pristine ng-valid">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel>
                        <ng-template ngbPanelTitle>
                            <div>
                                <h5 class="title">Nhập toạ độ VN2000</h5>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div role="region"
                                class="mat-expansion-panel-content ng-tns-c81-14 ng-trigger ng-trigger-bodyExpansion"
                                id="cdk-accordion-child-5" aria-labelledby="mat-expansion-panel-header-5"
                                style="visibility: visible;">
                                <div class="mat-expansion-panel-body ng-tns-c81-14">
                                    <div class="form-group row mb-4 ng-tns-c81-14"><label
                                            class="col-5 col-form-label">Kinh độ</label>
                                        <div class="col-7">
                                            <input type="number" formControlName="lng2000"
                                                class="form-control ng-untouched ng-pristine ng-valid">
                                        </div>
                                    </div>
                                    <div class="form-group row mb-0 ng-tns-c81-14"><label
                                            class="col-5 col-form-label">Vĩ độ</label>
                                        <div class="col-7">
                                            <input type="number" formControlName="lat2000"
                                                class="form-control ng-untouched ng-pristine ng-valid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

            </ng-template>
        </form>
    </div>
    <hr style="margin:10px;height:1px;background: #ccc;" />
    <div class="modal-footer">
        <div class="button-right">
            <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
                (click)="close()">Đóng</button>
            <button type="submit" class="btn btn-primary waves-effect waves-light" (click)="handleSearch()">
                <i class="fa fa-search"></i> Tìm kiếm
            </button>
        </div>
    </div>
</div>
<app-table-quy-hoach *ngIf="isShowTable" (onClose)="closeTable()" [(tableData)]="tableData"
    (fitParcelBound)="locatePolygon($event)"></app-table-quy-hoach>