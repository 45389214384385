<ng-template #cModal role="document" let-modal>
  <div class="modal-header py-2">
    <div class="d-flex justify-content-between w-100 fw-600 font-size-16">
      Tải ảnh lên
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form class="" [formGroup]="formUpload" (ngSubmit)="submitUpload(modal)">
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label">Tên ảnh</label>
        <input class="form-control" type="search" placeholder="Nhập tên ảnh" name="fileName" formControlName="fileName"
          [ngClass]="{'is-invalid': submitted && valFormUpload.fileName.errors}" required>
        <div *ngIf="submitted && valFormUpload.fileName.errors" class="invalid-feedback">
          <span *ngIf="valFormUpload.fileName.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Đường dẫn thư mục <i
            class="ms-2 fa-solid fa-folder-tree text-warning cursor-pointer"></i></label>
        <div class="position-relative">
          <input class="form-control" type="search" placeholder="Nhập tên thư mục lưu trữ" name="bucketName"
            formControlName="bucketName" [ngClass]="{'is-invalid': submitted && valFormUpload.bucketName.errors}"
            required>
          <div *ngIf="submitted && valFormUpload.bucketName.errors" class="invalid-feedback">
            <span *ngIf="valFormUpload.bucketName.errors.required">Bắt buộc nhập</span>
          </div>
          <i class="fa-solid fa-folder-tree text-warning icon-choose-folder cursor-pointer"></i>
        </div>
      </div>
      <div class="mb-3">
        <ngx-dropzone (change)="onSelect($event)">
          <ngx-dropzone-label>Chọn hoặc kéo thả ảnh Bản đồ!</ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files; let i = index"
            [file]="f" [removable]="true" (removed)="onRemove(f, i)">
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
        <div *ngIf="submitted && !files.length" class="text-danger">
          <span>Bắt buộc nhập</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="button-right">
        <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
          (click)="modal.close('Close click')" [role]="buttons.CLOSE.VALUE">Đóng</button>
        <button type="submit" class="btn btn-primary waves-effect waves-light"><i class="fa-solid fa-floppy-disk" [role]="buttons.SAVE.VALUE"></i>
          Lưu
          lại</button>
      </div>
    </div>
  </form>
</ng-template>

<!-- show modal -->
<ng-template #modalupload role="document" let-modal>
  <div class="modal-header py-2">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title cursor-pointer align-self-center" id="myModalLabel" (click)="handleBack()">
        < Trở lại</h5>
          <div class="align-self-center">
            <span class="d-flex cursor-pointer" (click)="addNewItem()">
              <i class="align-self-center font-size-20 fa-solid fa-folder-plus text-warning me-2"></i>
              <span>New folder</span>
            </span>
          </div>
          <form class="app-search d-none d-xl-block p-0">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Tìm kiếm">
              <span class="bx bx-search-alt"></span>
            </div>
          </form>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form class="form-search">
    <div class="modal-body h-40-vh pt-0 mt-0 content-scroll">
      <div>
        <div class="d-flex">
          <i class="fa-solid fa-tree text-success align-self-center"></i>
          <app-bread-crumb [breadcrumbItems]="breadCrumbItems" (onAction)="onHandle($event)"></app-bread-crumb>
        </div>
        <div *ngFor="let node of (listView?.dataView || []); let i = index"
          class="row-folder cursor-pointer d-flex w-100 py-1" (click)="handleSelectFile(node)">
          <div *ngIf="(typeSelect==='file') || (typeSelect==='folder' && !node.data.info)" class="d-flex">
            <div *ngIf="idRename != node?.data?.id" class="{{idFileSelected === node.data.id ? 'node-active p-1' : ''}}">
              <i *ngIf="!node.data.info" class="align-self-center fa-solid fa-folder text-warning mx-1"></i>
              <span *ngIf="node.data.info && typeSelect==='file'">
                <container-element [ngSwitch]="detectFileByFileName(node?.data?.name || '')">
                  <i *ngSwitchCase="'image'" class="align-self-center fa-solid fa-image text-primary mx-1"></i>
                  <i *ngSwitchCase="'excel'" class="align-self-center fa-regular fa-file-excel text-success mx-1"></i>
                  <i *ngSwitchCase="'shp'" class="align-self-center fa-solid fa-road text-danger mx-1"></i>
                  <i *ngSwitchCase="'video'" class="align-self-center fa-regular fa-file-video text-primary mx-1"></i>
                  <i *ngSwitchCase="'txt'" class="align-self-center fa-solid fa-file-lines text-muted mx-1"></i>
                  <i *ngSwitchCase="'other'" class="align-self-center fa-regular fa-file mx-1"></i>   
                </container-element>
              </span>
              <span  class="checklist-leaf-node" (dblclick)="dblclickFile($event, node,modal)">{{node?.data?.name || ""}}</span>
            </div>
            <div *ngIf="idRename === node?.data?.id">
              <ng-template [ngTemplateOutlet]="templateEdit"></ng-template>
            </div>
            <div class="d-flex ps-5">
              <i class="align-self-center text-primary only-show-hover fa-solid fa-pen-to-square cursor-pointer me-2"
                (click)="rename(node, i)"></i>
              <i class="align-self-center text-danger only-show-hover fa-solid fa-trash-can cursor-pointer"
                (click)="deleteResource(node, i)"></i>
            </div>
          </div>
        </div>
        <div *ngIf="showInputAddNew">
          <ng-template [ngTemplateOutlet]="templateEdit"></ng-template>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between ">
      <div class="button-left">
        <div *ngIf="!hideUpload" class="upload-btn-wrapper">
          <button class="btn btn-light" (click)="uploadFile()"><i
              class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Tải lên</button>
          <input class="d-none" multiple="multiple" type="file" name="myfile" id="input-upload" (change)="upFile($event)" />
        </div>
      </div>
      <div class="button-right">
        <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
          (click)="modal.close('Close click')" [role]="buttons.CLOSE.VALUE">Đóng</button>
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="save(modal)" [role]="buttons.SAVE.VALUE"><i
            class="fa-solid fa-floppy-disk"></i>
          Lưu lại</button>
      </div>

    </div>
  </form>
</ng-template>

<ng-template #templateEdit>
  <form [formGroup]="formFolder" (ngSubmit)="submitFile()" class="w-100">
    <div class="d-flex">
      <input type="text" class="form-control w-50 form-control-sm" formControlName="name" name="name"
        placeholder="Nhập tên thư mục" [ngClass]="{'is-invalid': submitted && valFormFolder.name.errors}">
      <div *ngIf="submitted && valFormFolder.name.errors" class="invalid-feedback">
        <span *ngIf="valFormFolder.name.errors.required">Bắt buộc nhập</span>
      </div>
      <button type="submit" class="btn btn-sm btn-light ms-1">
        <i class="fa-solid fa-check"></i>
      </button>
      <button type="button" class="btn btn-sm btn-light ms-1" (click)="closeFormFolder()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </form>
</ng-template>