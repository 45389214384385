<div class="tree-container">
  <div class="header">
    <h5>{{groupTitle}}</h5>
    <span
      *ngIf="isExpandAll"
      class="ic-toggle"
      (click)="toggleCollaspeEnpandAll()"
      title="Đóng lại tất cả"
    >
      <i
        class="fa fa-folder"
        style="color: #179568; font-size: 18px"
        aria-hidden="true"
      ></i>
    </span>
    <span
      *ngIf="!isExpandAll"
      class="ic-toggle"
      (click)="toggleCollaspeEnpandAll()"
      title="Mở rộng tất cả"
    >
      <i
        class="fa fa-folder-open"
        style="color: #179568; font-size: 18px"
        aria-hidden="true"
      ></i>
    </span>
  </div>
  <div *ngIf="isQH" class="sub-header">
    <!-- <span class="sub-title"> Loại quy hoạch </span> -->
    <!-- <h4 class="sub-title">Loại quy hoạch</h4> -->
    <mat-radio-group
      class="group-type"
      aria-label="Lựa chọn QH"
      (change)="checkGroupType($event)"
    >
      <mat-radio-button style="margin-bottom: 0px" value="QHC"
        >QHC</mat-radio-button
      >
      <mat-radio-button value="QHPK">QHPK</mat-radio-button>
      <mat-radio-button value="QHCT">QHCT</mat-radio-button>
    </mat-radio-group>
    <!-- <mat-checkbox
        class="label-mb-0"
        [checked]="checkQHC"
        (change)="checkGroupType($event, 'QHC')"
        name="hidden"
      >
        QHC
      </mat-checkbox>
      <mat-checkbox
        class="label-mb-0"
        [checked]="checkQHPK"
        name="hidden"
        (change)="checkGroupType($event, 'QHPK')"
      >
        QHPK
      </mat-checkbox>
      <mat-checkbox
        class="label-mb-0"
        [checked]="checkQCT"
        name="hidden"
        (change)="checkGroupType($event, 'QHCT')"
      >
        QHCT
      </mat-checkbox> -->
  </div>
  <hr style="margin: 3px; height: 1px; background: #ccc" />
  <div class="body">
    <ng-container
      *ngFor="let row of dataList; index as rowIndex; trackBy: trackFunc"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
        [ngClass]="'pd-level-' + row.level"
        class="item"
        *ngIf="row.show"
      >
        <div style="display: flex; align-items: center; width: 90%">
          <div *ngIf="row.isGroupLayer; then groupLayer; else layerItem"></div>
          <ng-template #groupLayer>
            <span class="ic-toggle" *ngIf="row.expanded">
              <i
                class="fa fa-minus"
                style="color: #6e6e6e; font-size: 15px"
                aria-hidden="true"
                (click)="toggleRow($event, row, false, rowIndex)"
              ></i>
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkGroup($event, row)"
                />
              </span>
              <i
                class="fa fa-folder-open"
                style="color: #179568; font-size: 18px"
                aria-hidden="true"
              ></i>
            </span>

            <span class="ic-toggle" *ngIf="!row.expanded">
              <i
                class="fa fa-plus"
                style="color: #6e6e6e; font-size: 15px"
                aria-hidden="true"
                (click)="toggleRow($event, row, true, rowIndex)"
              ></i>
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkGroup($event, row)"
                />
              </span>
              <i
                class="fa fa-folder"
                style="color: #179568; font-size: 18px"
                aria-hidden="true"
              ></i>
            </span>
            <span class="text" style="font-weight: bold">
              {{ row.label }}
            </span>
          </ng-template>
          <ng-template #layerItem>
            <div class="layer-item" style="width: 100%">
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkRow($event, row)"
                />
              </span>
              <div style="width: 100%">
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span class="text">
                    {{ row.label }}
                  </span>
                  <button
                    class="btn btn-text"
                    style="padding: 3px"
                    title="Vừa khung nhìn"
                    (click)="zoomToLayer(row)"
                  >
                    <img
                      src="/assets/images/map/area.png"
                      width="24"
                      height="24"
                    />
                  </button>
                </div>
                <mat-slider
                  min="0"
                  value="1"
                  max="1"
                  step="0.1"
                  showTickMarks
                  style="width: 100%"
                  (change)="onChangeOpacity($event, row)"
                >
                  <input matSliderThumb />
                </mat-slider>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
