import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class CsdlService {

    constructor(private httpRequest: ConfigService) {

    }

    createFolder(body){
      return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/directory/create`, body);
    }

    getAllFolder(){
      return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/directory/all`);
    }

    deleteFolder(id){
      return this.httpRequest.delete(`${API.BASE_URL}/private/gis-db/resource/directory/delete?id=${id}`);
    }

    renameDoc(body, id){
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/directory/rename?id=${id}`, body); 
    }
    
    getMapDetail(id){
      return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/map/detail?key=${id}`);
    }

    moveDoc(body, id){
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/directory/move?id=${id}`, body); 
    }

    copyDoc(body, id, tableName, withData = false){
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/directory/duplicate?id=${id}&withData=${withData}${tableName ? `&tableName=${tableName}` : ''}`, body); 
    }

    shapefileToTable(id){
      return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/directory/shapefile-to-table?id=${id}`);
    }

    updateMapDetail(id, body){
      let bodyrq = {
          "layers": body.layers,
          "view": body.view,
          "_version": body._version,
      }
      return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/map/detail/update?key=${id}`, bodyrq);
    }

    downloadFile(data){
      return this.httpRequest.download(`${API.BASE_URL}/files-managers/download?bucket=${data.bucket}&object=${data.object}`);
    }

    

}
