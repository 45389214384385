<div class="right-map-control">
  <!-- <div>
      <button type="button" (click)="overViewMap()" title="Over view map"
        class="btn-overview btn btn-primary btn-circle m-b-5"><i class="fa fa-globe"></i></button>
    </div> -->
  <!-- <div class="mt-3">
      <button type="button" (click)="toogleShowXY()" title="Go to XY" class="btn-xy btn btn-primary btn-circle m-b-5"><i
          class="fa-solid fa-map-location-dot"></i></button>
    </div> -->

  <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button type="button" (click)="showBaseLayer()" title="Bản đồ nền">
      <span class="mapboxgl-ctrl-icon">
        <i class="fa fa-globe fa-lg"></i>
      </span>
    </button>
  </div>
  <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button type="button" (click)="showXY()" title="Go to XY">
      <span class="mapboxgl-ctrl-icon">
        <i class="fa-solid fa-pencil"></i>
      </span>
    </button>
  </div>
</div>
