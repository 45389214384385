export const version = "v1.1_15_8_2023 14:00";
export const api_map = "http://125.234.103.44:8080/geoserver/giskiengiang/wms";
export const type_file = {
  image: ["png", "jpg"],
  excel: ["xlsx"],
  video: ["mp4", "m3u8"],
};
export const TYPE_COMPONENT = {
  chart: "chart",
  text: "text",
  config_detail: "config_detail",
};
export const TYPE_CODE = {
  MAP: "MAP",
  LAYER: "LAYER",
};
export const projection_base = "EPSG:3857";
export const projection_vn2000 = "EPSG:5897";
export const projection_EPSG_4326 = "EPSG:4326";

export const spatialReference = 4326;
export const TYPE_SPACE = {
  RADIUS: "RADIUS",
  POLYGON: "POLYGON",
  LINE: "LINE",
};

export const PROJECTIOM = "4326";
export const ROOT = "root";
export const TYPE_LAYER = {
  folder: "folder",
  layer: "layer",
};

export const TYPE_GEOM = [
  "Point",
  "MultiPoint",
  "LineString",
  "MultiLineString",
  "Polygon",
  "MultiPolygon",
];

export const define_type = {
  varchar: "varchar",
  text: "text",
  integer: "integer",
  bigint: "bigint",
  double: "double",
  file: "file",
  image: "image",
  boolean: "boolean",
  date: "date",
  datetime: "datetime",
  point: "Point",
  MultiPoint: "MultiPoint",
  LineString: "LineString",
  MultiLineString: "MultiLineString",
  Polygon: "Polygon",
  MultiPolygon: "MultiPolygon",
  html: "html",
};

export const control_input = {
  datetimepicker: "datetimepicker",
  datepicker: "datepicker",
  textbox: "textbox",
  number: "number",
  file: "file",
  html: "html",
  ddl: "ddl",
  boolean: "boolean",
  geometry: "geometry",
};

export const DATATYPE = [
  {
    group: "Text",
    options: [
      {
        label: "Character Varying",
        value: "varchar",
      },
      {
        label: "text",
        value: "text",
      },
    ],
  },
  {
    group: "Number",
    options: [
      {
        label: "integer",
        value: "integer",
      },
      {
        label: "bigint",
        value: "bigint",
      },
      {
        label: "double",
        value: "double",
      },
    ],
  },
  {
    group: "File",
    options: [
      {
        label: "file",
        value: "file",
      },
      {
        label: "image",
        value: "image",
      },
    ],
  },
  {
    group: "Boolean",
    options: [
      {
        label: "boolean",
        value: "boolean",
      },
    ],
  },
  {
    group: "Date",
    options: [
      {
        label: "date",
        value: "date",
      },
      {
        label: "datetime",
        value: "datetime",
      },
    ],
  },
  {
    group: "Geom",
    options: [
      {
        label: "point",
        value: "Point",
      },
      {
        label: "linestring",
        value: "LineString",
      },

      {
        label: "polygon",
        value: "MultiPolygon",
      },
    ],
  },
  {
    group: "Other",
    options: [
      {
        label: "html",
        value: "html",
      },
    ],
  },
];

export const WORKSPACE = "giscantho";

export const KEY_SEARCH_MAP = {
  way: 2,
  around: 3,
  land: 4,
  coordinates: 5,
  search_place: 6,
  search_direction: 7,
};

export const contants = {
  group_type_catalog: "portal",
};

export const conditions = [
  {
    value: "equal",
    label: "=",
  },
  {
    value: "greater",
    label: ">",
  },
  {
    value: "less",
    label: "<",
  },
  {
    value: "GREATER_OR_EQUAL",
    label: ">=",
  },
  {
    value: "less_or_equal",
    label: "<=",
  },
  {
    value: "between",
    label: "khoảng",
  },
  // {
  //   value: "%",
  //   label: "%"
  // }
];

export const optionFilterGlobal = [
  {
    name: "Tỉnh",
    value: "province",
  },
  {
    name: "Huyện",
    value: "district",
  },
  {
    name: "Năm",
    value: "year",
  },
];

export const YearFilterReport = [
  {
    name: "2023",
    value: 2023,
  },
  {
    name: "2022",
    value: 2022,
  },
  {
    name: "2021",
    value: 2021,
  },
  {
    name: "2020",
    value: 2020,
  },
  {
    name: "2019",
    value: 2019,
  },
];

export const func_query = [
  {
    label: "Tính tổng",
    value: "sum",
  },
  {
    label: "Tính giá trị trung bình",
    value: "average",
  },
  {
    label: "Đếm số lượng",
    value: "count",
  },
];

export const comparison_date = [
  {
    label: "Ngày",
    value: "DAY",
  },
  {
    label: "Tháng",
    value: "MONTH",
  },
  {
    label: "Năm",
    value: "YEAR",
  },
];

export const layer_type = {
  wms: "wms",
  arcgis: "arcgis",
  vector: "vector",
  geotiff: "geotiff",
};

export const type_charts = [
  {
    icon: "./assets/images/icon/column.png",
    name: "Column",
    code: "column",
  },
  {
    icon: "./assets/images/icon/stacked_column.png",
    name: "Stacked Column",
    code: "stackedColumn",
  },
  {
    icon: "./assets/images/icon/timeline.png",
    name: "Timeline",
    code: "timeline",
  },
  {
    icon: "./assets/images/icon/bar.png",
    name: "Bar",
    code: "bar",
  },
  {
    icon: "./assets/images/icon/stackbar.png",
    name: "Stacked Bar",
    code: "stackedBar",
  },
  {
    icon: "./assets/images/icon/line.png",
    name: "Line",
    code: "line",
  },
  {
    icon: "./assets/images/icon/area.png",
    name: "Area",
    code: "area",
  },
  {
    icon: "./assets/images/icon/scatter.png",
    name: "Scatter",
    code: "scatter",
  },
  {
    icon: "./assets/images/icon/bubble.png",
    name: "Bubble",
    code: "bubble",
  },
  {
    icon: "./assets/images/icon/pie.png",
    name: "Pie",
    code: "pie",
  },
  {
    icon: "./assets/images/icon/donut.png",
    name: "Donut",
    code: "donut",
  },
  {
    icon: "./assets/images/icon/gauge.png",
    name: "Gauge",
    code: "gauge",
  },
  {
    icon: "./assets/images/icon/polararea.png",
    name: "Polar area",
    code: "polarArea",
  },
  {
    icon: "./assets/images/icon/radial.png",
    name: "Radial bar",
    code: "radialBar",
  },
  {
    icon: "./assets/images/icon/radar.png",
    name: "Radar",
    code: "radar",
  },
  {
    icon: "./assets/images/icon/treemap.png",
    name: "Tree map",
    code: "treeMap",
  },
  {
    icon: "./assets/images/icon/heatmap.png",
    name: "Heatmap",
    code: "heatmap",
  },
  {
    icon: "./assets/images/icon/funnel.png",
    name: "Funnel",
    code: "funnel",
  },
];

// export const api_map = "http://192.168.89.116:8080/geoserver/giskiengiang/wms";
export const color_green = "#34c38f";
export const color_cancel = "#74788d";
export const levelAdministrative = {
  province: 1,
  disctrict: 2,
  commune: 3,
};
export const max_size = 10000;

export const type_copy = {
  copy_struct: "copy_struct",
  copy_struct_and_data: "copy_struct_and_data",
};

export const type_connect = [
  {
    name: "API",
    value: "API",
  },
  {
    name: "FTP",
    value: "FTP",
  },
];

export const METHOD = [
  {
    name: "POST",
    value: "POST",
  },
  {
    name: "GET",
    value: "GET",
  },
  {
    name: "PUT",
    value: "PUT",
  },
];

export const authentication_method = [
  {
    name: "No Auth",
    value: "NoAuth",
  },
  {
    name: "API Key",
    value: "APIKey",
  },
  {
    name: "Bearer",
    value: "Bearer",
  },
  {
    name: "Basic HTTP",
    value: "BasicHTTP",
  },
  {
    name: "OAuth",
    value: "OAuth",
  },
  {
    name: "Session Token",
    value: "SessionTokenAuthenticator",
  },
];

export const process_extraction_method = [
  {
    name: "Trích xuất toàn bộ",
    value: "extraction_all",
  },
  {
    name: "Trích xuất một phần",
    value: "extraction_part",
  },
];

export const process_extraction_frequency = [
  {
    name: "Hàng ngày",
    value: "daily",
  },
  {
    name: "Hàng tuần",
    value: "weekly",
  },
  {
    name: "Hàng tháng",
    value: "monthly",
  },
];

export const process_waiting_period = [
  {
    name: "18:00",
    value: "18:00",
  },
  {
    name: "20:00",
    value: "20:00",
  },
];

export const type_chart = [
  {
    type: "line",
    name: "Biểu độ đường",
  },
  {
    type: "column",
    name: "Biểu độ cột",
  },
  // {
  //   type: "bar",
  //   name: "Biểu độ đường"
  // }
];

export const PROJECTIONS = [
  {
    name: "VN2000",
    value: "VN2000",
  },
  {
    name: "WGS84",
    value: "WGS84",
  },
];

export const DEFINE = {
  proj4_vn2000:
    "+proj=tmerc +lat_0=0 +lon_0=105 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,-0.00928836,0.01975479,-0.00427372,0.252906278 +units=m +no_defs +type=crs",
  proj4_3857:
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs",
};

export const TYPE_FILE_FTP = [
  {
    label: "Excel",
    value: "excel",
  },
  {
    label: "Shapefile",
    value: "shapefile",
  },
];

export const replication_frequency = [
  {
    label: "Hàng giờ",
    value: "1",
  },
  {
    label: "2 giờ",
    value: "2",
  },
  {
    label: "3 giờ",
    value: "3",
  },
  {
    label: "6 giờ",
    value: "6",
  },
  {
    label: "9 giờ",
    value: "9",
  },
  {
    label: "12 giờ",
    value: "12",
  },
  {
    label: "24 giờ",
    value: "24",
  },
];

export const extraction_form = {
  full_refresh_overwrite:
    "Trích xuất Toàn bộ | Ghi đè (Full refresh | Overwrite)",
  full_refresh_append: "Trích xuất Toàn bộ | Thêm vào (Full refresh | Append)",
  incremental_append_dedup:
    "Trích xuất Gia tăng | Thêm vào (Incremental | Append)",
  incremental_append:
    "Trích xuất Gia tăng | Thêm vào và Loại trừ (Incremental | Append + Deduped)",
};

export const DM_MDSDD = [
  { label: "Đất nông nghiệp", value: "NNP" },
  { label: "Đất sản xuất nông nghiệp", value: "SXN" },
  { label: "Đất trồng cây hàng năm", value: "CHN" },
  { label: "Đất trồng lúa", value: "LUA" },
  { label: "Đất chuyên trồng lúa nước", value: "LUC" },
  { label: "Đất trồng lúa nước còn lại", value: "LUK" },
  { label: "Đất trồng lúa nương", value: "LUN" },
  { label: "Đất trồng cây hàng năm khác", value: "HNK" },
  { label: "Đất bằng trồng cây hàng năm khác", value: "BHK" },
  { label: "Đất nương rẫy trồng cây hàng năm khác", value: "NHK" },
  { label: "Đất trồng cây lâu năm", value: "CLN" },
  { label: "Đất lâm nghiệp", value: "LNP" },
  { label: "Đất rừng sản xuất", value: "RSX" },
  { label: "Đất có rừng sản xuất là rừng tự nhiên", value: "RSN" },
  { label: "Đất có rừng sản xuất là rừng trồng", value: "RST" },
  { label: "Đất đang được sử dụng để phát triển rừng sản xuất", value: "RSM" },
  { label: "Đất rừng phòng hộ", value: "RPH" },
  { label: "Đất có rừng phòng hộ là rừng tự nhiên", value: "RPN" },
  { label: "Đất có rừng phòng hộ là rừng trồng", value: "RPT" },
  { label: "Đất đang được sử dụng để phát triển rừng phòng hộ", value: "RPM" },
  { label: "Đất rừng đặc dụng", value: "RDD" },
  { label: "Đất có rừng đặc dụng là rừng tự nhiên", value: "RDN" },
  { label: "Đất có rừng đặc dụng là rừng trồng", value: "RDT" },
  { label: "Đất đang được sử dụng để phát triển rừng đặc dụng", value: "RDM" },
  { label: "Đất nuôi trồng thủy sản", value: "NTS" },
  { label: "Đất làm muối", value: "LMU" },
  { label: "Đất nông nghiệp khác", value: "NKH" },
  { label: "Đất phi nông nghiệp", value: "PNN" },
  { label: "Đất ở", value: "OTC" },
  { label: "Đất ở tại nông thôn", value: "ONT" },
  { label: "Đất ở tại đô thị", value: "ODT" },
  { label: "Đất chuyên dùng", value: "CDG" },
  { label: "Đất xây dựng trụ sở cơ quan", value: "TSC" },
  { label: "Đất quốc phòng", value: "CQP" },
  { label: "Đất an ninh", value: "CAN" },
  { label: "Đất xây dựng công trình sự nghiệp", value: "DSN" },
  { label: "Đất xây dựng trụ sở của tổ chức sự nghiệp", value: "DTS" },
  { label: "Đất xây dựng cơ sở văn hóa", value: "DVH" },
  { label: "Đất xây dựng cơ sở dịch vụ xã hội", value: "DXH" },
  { label: "Đất xây dựng cơ sở y tế", value: "DYT" },
  { label: "Đất xây dựng cơ sở giáo dục và đào tạo", value: "DGD" },
  { label: "Đất xây dựng cơ sở thể dục thể thao", value: "DTT" },
  { label: "Đất xây dựng cơ sở khoa học và công nghệ", value: "DKH" },
  { label: "Đất xây dựng cơ sở ngoại giao", value: "DNG" },
  { label: "Đất xây dựng công trình sự nghiệp khác", value: "DSK" },
  { label: "Đất sản xuất, kinh doanh phi nông nghiệp", value: "CSK" },
  { label: "Đất khu công nghiệp", value: "SKK" },
  { label: "Đất cụm công nghiệp", value: "SKN" },
  { label: "Đất khu chế xuất", value: "SKT" },
  { label: "Đất thương mại, dịch vụ", value: "TMD" },
  { label: "Đất cơ sở sản xuất phi nông nghiệp", value: "SKC" },
  { label: "Đất sử dụng cho hoạt động khoáng sản", value: "SKS" },
  { label: "Đất sản xuất vật liệu xây dựng, làm đồ gốm", value: "SKX" },
  { label: "Đất có mục đích công cộng", value: "CCC" },
  { label: "Đất giao thông", value: "DGT" },
  { label: "Đất thủy lợi", value: "DTL" },
  { label: "Đất có di tích lịch sử – văn hóa", value: "DDT" },
  { label: "Đất danh lam thắng cảnh", value: "DDL" },
  { label: "Đất sinh hoạt cộng đồng", value: "DSH" },
  { label: "Đất khu vui chơi, giải trí công cộng", value: "DKV" },
  { label: "Đất công trình năng lượng", value: "DNL" },
  { label: "Đất công trình bưu chính viễn thông", value: "DBV" },
  { label: "Đất chợ", value: "DCH" },
  { label: "Đất bãi thải, xử lý chất thải", value: "DRA" },
  { label: "Đất công trình công cộng khác", value: "DCK" },
  { label: "Đất cơ sở tôn giáo", value: "TON" },
  { label: "Đất cơ sở tín ngưỡng", value: "TIN" },
  {
    label: "Đất nghĩa trang, nghĩa địa, nhà tang lễ, nhà hỏa táng",
    value: "NTD",
  },
  { label: "Đất sông, ngòi, kênh, rạch, suối", value: "SON" },
  { label: "Đất có mặt nước chuyên dùng	", value: "MNC" },
  { label: "Đất phi nông nghiệp khác", value: "PNK" },
  { label: "Đất chưa sử dụng", value: "CSD" },
  { label: "Đất bằng chưa sử dụng", value: "BCS" },
  { label: "Đất đồi núi chưa sử dụng", value: "DCS" },
  { label: "Núi đá không có rừng cây", value: "NCS" },
  { label: "Đất có mặt nước ven biển (chỉ tiêu quan sát)", value: "MVB" },
  { label: "Đất mặt nước ven biển nuôi trồng thủy sản", value: "MVT" },
  { label: "Đất mặt nước ven biển có rừng", value: "MVR" },
  { label: "Đất mặt nước ven biển có mục đích khác", value: "MVK" },
];

// export const DM_CNXD = [
//   { label: "Đất hạ tầng kỹ thuật", value: 'HTKT' },
//   { label: "Đất công cộng thành phố", value: 'CCTP' },
//   { label: "Đất làng xóm hiện có trong khu vực phát triển đô thị", value: 'DLX' },
//   { label: "Mặt nước", value: 'MN' },
//   { label: "Đất cây xanh cách ly", value: 'CXCL' },
//   { label: "Đất công trình công cộng", value: 'CTCC' },
//   { label: "Đất ở đô thị", value: 'ODT' },
//   { label: "Đất hỗn hợp (Thương mại, dịch vụ, văn phòng, công trình hạ tầng xã hội...)", value: 'DHH' },
//   { label: "Đất cơ quan, viện nghiên cứu, trường đào tạo", value: 'DGD' },
//   { label: "Đất trường học", value: 'DTH' },
//   { label: "Đất dành cho địa phương (Quỹ đất dành phục vụ nhu cầu di dân, giãn dân, chuyển đổi nghề, bổ sung hạ tầng xã hội, HTKT...)", value: 'DXH' },
//   { label: "Đất đình chùa, công trình di tích, tôn giáo", value: 'DTG' },
//   { label: "Đất cây xanh thể dục thể thao", value: 'DTDTD' }
// ]

export const DM_CNXD = [
  { label: "An ninh", value: "AN" },
  { label: "Công cộng", value: "CC" },
  { label: "Đất cơ quan", value: "DQ" },
  { label: "Khu dịch vụ du lịch", value: "KDV" },
  { label: "Cây xanh chuyên dụng", value: "CXCD" },
  { label: "Đất nông nghiệp", value: "DNN" },
  { label: "Nghĩa trang", value: "NT" },
  { label: "Đất cây xanh sử dụng công cộng", value: "DCXSCC" },
  { label: "Đất cây xanh đô thị", value: "DCXDT" },
  { label: "Cây xanh đô thị", value: "CXDT" },
  { label: "Đất thể dục thể thao", value: "DTDTT" },
  { label: "Nhà ở", value: "NO" },
  { label: "Đất công trình dịch vụ", value: "DCTDV" },
  { label: "Hỗn hợp nhà ở và dịch vụ", value: "HHNOVDV" },
  { label: "Thể dục thể thao", value: "TDTT" },
  { label: "Đất di tích, tôn giáo", value: "DDTTG" },
  { label: "Đất du lịch", value: "DDL" },
  { label: "Đất khai thác vật liệu xây dựng", value: "DKTVLXD" },
  { label: "Đất ở nông thôn", value: "DONT" },
  { label: "Đất nghĩa trang", value: "DNT" },
  { label: "Đất bãi đỗ xe", value: "DBDX" },
  { label: "Văn hóa", value: "VH" },
  { label: "Đất hỗn hợp mật độ thấp", value: "DHHMDT" },
  { label: "Đất cơ quan, trụ sở", value: "DQTS" },
  { label: "Đất đơn vị ở", value: "DVO" },
  { label: "Y tế", value: "YT" },
  { label: "Hồ, ao, đầm", value: "HAD" },
  { label: "Đất an ninh", value: "DAN" },
  { label: "Di tích, tôn giáo", value: "DTTG" },
  { label: "Quốc phòng", value: "QP" },
  { label: "Trường THCS, Tiểu học,", value: "THCS" },
  { label: "Đất công cộng đô thị", value: "DCCDT" },
  { label: "Cơ quan, trụ sở", value: "CQTS" },
  { label: "Đất văn hóa", value: "DVH" },
  { label: "Hạ tầng kỹ thuật khác", value: "HTKT" },
  { label: "Đào tạo nghiên cứu", value: "DTNC" },
  { label: "Sông, suối, kênh rạch", value: "SSKR" },
  { label: "Đất lâm nghiệp", value: "DLN" },
  { label: "Đất giáo dục đào tạo", value: "DGDDT" },
  { label: "Đất hỗn hợp", value: "DHH" },
  { label: "Đất thương mại dịch vụ", value: "DTMDV" },
  { label: "Đất trung tâm TĐTT", value: "DTCTTDTT" },
  { label: "Đất cây xanh chuyên dùng, công cộng", value: "DCXCDCC" },
  { label: "Đất công trình đầu mối HTKT", value: "DCTDMHTKT" },
  { label: "Đất CT hạ tầng kỹ thuật khác", value: "DCTHTKT" },
  { label: "Đất khác", value: "DK" },
  { label: "Trường THCS, Tiểu học, Mầm non", value: "THCSM" },
  { label: "Đất nhà ở liền kề", value: "DNOLK" },
  { label: "Trường THPT", value: "THPT" },
];

export enum MAP_LAYERS_ID {
  // QHC = "SDD_OQH_P.QHC",
  QHC = "QHC_SDD_OQHA,QHC_SDD_OQHP",
  QHC_A = "QHC_SDD_OQHA",
  QHC_P = "QHC_SDD_OQHP",
  QHC_BORDER = "QHC_BORDER",
  QHC_BORDER_PK = "QHC_BORDER_PK",
  QHPK = "QHPK_SDD_OQHA,QHPK_SDD_OQHP",
  QHPK_A = "QHPK_SDD_OQHA",
  QHPK_P = "QHPK_SDD_OQHP",
  QHCT = "QHCT_SDD_OQHA,QHCT_SDD_OQHP",
  QHCT_A = "QHCT_SDD_OQHA",
  QHCT_P = "QHCT_SDD_OQHP",
  QHXD = "QHXD",
  // THUADAT = 'THUADAT',
  THUADAT = "ThuaDat_fill_16-22",
  QHC_LODAT = "QHC_SDD_LoDat_A",
  QHPK_LODAT = "QHPK_SDD_LoDatA",
  QHCT_LODAT = "QHCT_SDD_LoDatA",
  LODAT = "QHC_SDD_LoDat_A,QHPK_SDD_LoDatA,QHCT_SDD_LoDatA,",
  OQH = "QHXD_LaoCai_RanhGioiQuyHoachA",
  QHSDDD = "QHSDDD",
  QHSDDD_LABEL = "QHSDDD_LABEL",
  QHXD_LABEL = "QHXD_LABEL",
  UAV = "UAV",
  //ha tang ky thuat
  CameraGiaoThong = "QHXD_LaoCai_HTKT_CameraGiaoThong",
  CapChieuSang = "QHXD_LaoCai_HTKT_CapChieuSang",
  CayXanh = "QHXD_LaoCai_HTKT_CayXanh",
  Cong = "QHXD_LaoCai_HTKT_Cong",
  CotDen = "QHXD_LaoCai_HTKT_CotDen",
  DuongGiaoThong = "QHXD_LaoCai_HTKT_DuongGiaoThong",
  HoGa = "QHXD_LaoCai_HTKT_HoGa",
  Mo = "QHXD_LaoCai_HTKT_Mo",
}

export const DEFAULT_LAYERS_OPACITY = {
  [MAP_LAYERS_ID.THUADAT]: 0.8,
  [MAP_LAYERS_ID.QHSDDD]: 0.8,
  [MAP_LAYERS_ID.QHXD]: 1,
};
