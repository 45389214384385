<div class="tree-container">
  <div class="header">
    <h5>Lớp bản đồ chuyên đề</h5>
    <span
      *ngIf="isExpandAll"
      class="ic-toggle"
      (click)="toggleCollaspeEnpandAll()"
      title="Đóng lại tất cả"
    >
      <i
        class="fa fa-folder"
        style="color: #179568; font-size: 18px"
        aria-hidden="true"
      ></i>
    </span>
    <span
      *ngIf="!isExpandAll"
      class="ic-toggle"
      (click)="toggleCollaspeEnpandAll()"
      title="Mở rộng tất cả"
    >
      <i
        class="fa fa-folder-open"
        style="color: #179568; font-size: 18px"
        aria-hidden="true"
      ></i>
    </span>
    <button
      type="button"
      (click)="closeWidget()"
      class="btn"
      style="padding: 8px"
    >
      <i class="fa fa-close"></i>
    </button>
  </div>
  <hr style="margin: 3px; height: 1px; background: #ccc" />
  <div class="body">
    <ng-container
      *ngFor="let row of dataList; index as rowIndex; trackBy: trackFunc"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
        [ngClass]="'pd-level-' + row.level"
        class="item"
        *ngIf="row.show"
      >
        <div style="display: flex; align-items: center; width: 90%">
          <!-- collase expand -->
          <div *ngIf="row.isGroupLayer; then groupLayer; else layerItem"></div>
          <ng-template #groupLayer>
            <span class="ic-toggle" *ngIf="row.expanded">
              <i
                class="fa fa-minus"
                style="color: #6e6e6e; font-size: 15px"
                aria-hidden="true"
                (click)="toggleRow($event, row, false, rowIndex)"
              ></i>
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkGroup($event, row)"
                />
              </span>
              <i
                class="fa fa-folder-open"
                style="color: #179568; font-size: 18px"
                aria-hidden="true"
              ></i>
            </span>

            <span class="ic-toggle" *ngIf="!row.expanded">
              <i
                class="fa fa-plus"
                style="color: #6e6e6e; font-size: 15px"
                aria-hidden="true"
                (click)="toggleRow($event, row, true, rowIndex)"
              ></i>
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkGroup($event, row)"
                />
              </span>
              <i
                class="fa fa-folder"
                style="color: #179568; font-size: 18px"
                aria-hidden="true"
              ></i>
            </span>
            <span class="text" style="font-weight: bold">
              {{ row.label }}
            </span>
          </ng-template>
          <ng-template #layerItem>
            <div class="layer-item" style="width: 100%">
              <span>
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="row.checked"
                  (click)="checkRow($event, row)"
                />
              </span>
              <div style="width: 100%">
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div>
                    <span class="text">
                      {{ row.label }}
                    </span>
                    <span class="sub-text" style="color: red">
                      {{ row.sublabel }}
                    </span>
                  </div>
                  <button
                    class="btn btn-text"
                    style="padding: 3px"
                    title="Vừa khung nhìn"
                    (click)="zoomToLayer(row)"
                  >
                    <img
                      src="/assets/images/map/area.png"
                      width="24"
                      height="24"
                    />
                  </button>
                </div>
                <mat-slider
                  min="0"
                  value="1"
                  max="1"
                  step="0.1"
                  showTickMarks
                  style="width: 100%"
                  (change)="onChangeOpacity($event, row)"
                >
                  <input matSliderThumb />
                </mat-slider>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
