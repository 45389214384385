import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class StyleService {

    constructor(private httpRequest: ConfigService) {

    }

    updateStyle(body){
      return this.httpRequest.put(`${API.BASE_URL}/private/style-open-layers/update`, body);
    }

    getStyleById(id){
      return this.httpRequest.get(`${API.BASE_URL}/private/style-open-layers/find?id=${id}`);
    }
}
