import { Component, Inject, Input, ViewChild, EventEmitter, Output } from '@angular/core';
// Report viewer
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';

// data-visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
import FullEditor from 'ckeditor5-build-full'
import { Store, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { increment, decrement } from 'src/app/action/commons.actions';
import { AppState } from 'src/app/reducer';
import { getCommon } from 'src/app/selectors/commons.selector';
import { CKEditor5, ChangeEvent, FocusEvent, BlurEvent } from '@ckeditor/ckeditor5-angular';


@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent {
  public configsss = FullEditor;
  public serviceUrl: string;
  public reportPath: string;
  common$: Observable<any>;
  @Output() onAction = new EventEmitter<any>();
  @Input() editorData = "";
  @Input() modal = {};

  constructor(private store: Store<AppState>) {
    this.common$ = this.store.pipe(select(getCommon));
    this.serviceUrl = 'https://demos.boldreports.com/services/api/ReportViewer';
    this.reportPath = '~/Resources/docs/sales-order-detail.rdl';
  }

  onChange(event: ChangeEvent): void {
    // this.componentEvents.push('Editor model changed.');
    this.editorData = event.editor.getData();
  }

  ngOnChanges(event) {
    console.log("-------event---------", event)
    // if (event.data.currentValue != event.data.currentValue.previousValue) {
    // }
  }

  submit(){
    let obj = {
      key: "get_string_html",
      data: this.editorData,
      modal: this.modal
    }
    this.onAction.emit(obj);
  }
}
