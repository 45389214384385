import { Component, Input, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { MapService } from 'src/app/core/services/map.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tree-layer-context-menu',
  templateUrl: './tree-layer-context-menu.component.html',
  styleUrls: ['./tree-layer-context-menu.component.scss']
})
export class TreeLayerContextMenuComponent implements OnInit {
  @Input() layerId: string;
  defaultOpacity: number = 1;
  option: Options = {
    floor: 0,
    ceil: 1
  };
  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
  }

  async onChangeOpacity(evt) {
    const prevOpacity$ = this.mapService.getLayersOpacity$();
    const prevOpacity = await firstValueFrom(prevOpacity$);
    const updateOpacity = { ...prevOpacity }
    updateOpacity[this.layerId] = evt.value;
    this.mapService.setLayersOpacity$(updateOpacity);
  }

}
