import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class TableService {

  constructor(private httpRequest: ConfigService) {

  }

  getTableDetail(id, page, pageSize) {
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/details?id=${id}&page=${page}&size=${pageSize}`);
  }

  getTableDetailFilter(body, id, page, pageSize) {
    return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/table/details/filter?id=${id}&page=${page}&size=${pageSize}`, body);
  }

  convertGeomPoint(id, toPoint){
    return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/convert-geom-point?id=${id}&toPoint=${toPoint}`, {});
  }


  updateTable(order, id, add, update, drop, version) {
    let body = {
      "detail": [],
      "order": order,
      "_version": version
    };
    if(drop.length){
      let alter_drop = {
        "method": "alter_drop",
        "models": drop
      }
      body.detail.push(alter_drop);
    }
    
    if (add.length || update.length) {
      let alter_add = {
        "method": "alter_add",
        "models": add.concat(update)
      }
      body.detail.push(alter_add);
    }
 
    return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/update-column?id=${id}`, body);
  }

  updateDataTable(id, add, update, drop){
    let body = [];
    if (add.data.length) {
      body.push(add);
    }
    // if(update.data.length){
    //   body.push(update);
    // }
    if(drop.data.length){
      body.push(drop);
    }
    return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/table/update-data?id=${id}`, body);
  }

  checkTableIsExist(){
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/table-is-not-exist`);
  }

  importTable(body){
    return this.httpRequest.post(`${API.BASE_URL}/private/gis-db/resource/table/import-table`, body);
  }

  updateGeomType(body){
    return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/change-geom-type`, body);
  }

  exportFile(id){
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/export-excel?id=${id}`, {
      observe: 'response', responseType: 'blob' as 'json'
    });
  }

  exportShp(id, typeGeom = 'point'){
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/export-shapefile?id=${id}&geometryDataType=${typeGeom}`, {
      observe: 'response', responseType: 'blob' as 'json'
    });
  }

  getDataFromFile(id){
    return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/get-data-from-file?id=${id}`);
  }

  importDataTable(body, typeImport = 'excel'){
    if(typeImport === 'excel'){
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/import-excel`, body);
    }else if(typeImport === 'shp'){
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/import-shapefile`, body);
    }else{
      return this.httpRequest.put(`${API.BASE_URL}/private/gis-db/resource/table/copy-data-table`, body);
    }
  }

  getValueColumn(tbkey, column){
    return this.httpRequest.get(`${API.BASE_URL}/gis-portal/data-group-by-column?id=${tbkey}&column=${column}`);
  }
}
