<div #mapContainer class="map-container">
  <app-top-control (showSearchPOI)="toggleShowSearchPOI()"></app-top-control>
  <app-place-search-control (goToPlace)="goToPlace($event)"></app-place-search-control>
  <app-left-map-control (toggleShowAdministrative)="toggleShowAdministrative()" (toggleShowSearch)="toggleShowSearch()"
    (toogleShowLayers)="toogleShowLayers()" (activeInfoFeature)="activeInfoFeature()"
    (toogleShowLegend)="toggleShowLegend()" [(mapMode)]="mode"></app-left-map-control>
  <app-right-map-control (toogleShowBaseLayers)="toggleShowBaseLayer()"></app-right-map-control>
  <app-measure *ngIf="mapLoaded" [map]="map" [drawControl]="drawControl" (toogleShowBaseLayers)="toggleShowBaseLayer()">
  </app-measure>
  <app-scene-switch (switch)="handleChangeMapScene($event)"></app-scene-switch>
  <app-search-poi *ngIf="showSearchPOI" (onCloseSearch)="handleCloseSearchPOI()"
    (onRadiusChange)="handleSearchPOIRadiusChange($event)" [(POIResults)]="POIResults" (onPOIClick)="goToPoint($event)"
    [(showLoading)]="loadingSearchPOI"></app-search-poi>
  <div *ngIf="showXY" class="base-layer flex-col pt-0">
    <div class="text-end p-0">
      <button type="button" (click)="toogleShowXY()" class="btn btn-sm pe-0 mt-2">
        <i class="fa fa-close font-size-16"></i>
      </button>
    </div>
    <div class="d-flex">
      <label class="label-text pl-5 mb-0" for="satellite-streets-v12">Kinh độ</label>
      <input type="number" [(ngModel)]="userX" />
    </div>
    <div class="d-flex">
      <label class="label-text pl-5 mb-0" for="satellite-streets-v12">Vĩ độ</label>
      <input type="number" [(ngModel)]="userY" />
    </div>
    <div class="flex-end">
      <button type="button" (click)="gotoXY()" class="btn btn-primary m-b-5">
        Đi đến
      </button>
      <button type="button" (click)="cancelXY()" class="btn btn-inverse m-b-5 ml-15">
        Hủy
      </button>
    </div>
  </div>

  <app-tree-dvhc *ngIf="showAdministrative" (close)="toggleShowAdministrative()"
    (handleViewPortChange)="handleViewPortChange($event)"></app-tree-dvhc>

  <app-base-layer-picker *ngIf="showBaseLayer" (onClose)="toggleShowBaseLayer()"
    (changeBaseLayer)="changeBaseLayer($event)"></app-base-layer-picker>

  <app-qhtree-layer #treeLayer *ngIf="showLayer" [isQH]="true" [(inputDataList)]="treeLayerData"
    [(inputDataList1)]="treeLayerDatDiaChinh" [(layerVisibleChanges)]="visibleLayers"
    (selectLayerChange)="onSelectedLayerChange($event)" (changeLayerOpacity)="updateLayerOpacity($event)"
    (selectEvent)="onLayerCheckChange($event)" (selectGroupEvent)="onGroupLayerCheckChange($event)"
    (selectGroupType)="onGroupTypeCheckChange($event)" (close)="toogleShowLayers()" (fitLayer)="fitLayer($event)">
  </app-qhtree-layer>

  <!-- <app-tree-view #treeView [dataTree]="dataTree" (onAction)="onAction($event)"></app-tree-view> -->

  <app-search #searchBox *ngIf="showSearch" (onClose)="onCloseSearch()" (onActiveSpatialSearch)="activeSearch($event)"
    (onSearchParamsChange)="handleSearch($event)" [(searchQHCCount)]="searchQHCCount"
    [(searchQHPKCount)]="searchQHPKCount" [(searchQHCTCount)]="searchQHCTCount" [(showResult)]="showSearchResult"
    (hilightPolygon)="hilightParcel($event)" (clearSearch)="onClearSearch()"></app-search>

  <app-thong-tin-quy-hoach *ngIf="showQHInfo" (onClose)="onCloseThongTinQuyHoach()" [(quyHoachIDSDD)]="quyHoachIDSDD"
    [(quyHoachIDXD)]="quyHoachIDXD" (fitParcelBound)="hilightParcel($event)"
    (hilightSplitParcel)="hilightSplitParcel($event)">
  </app-thong-tin-quy-hoach>
  <app-feature-info *ngIf="showInfo" (onClose)="onCloseFeatureInfo()" [(featureInfo)]="featureProps"
    [(tableName)]="layerId" (showImage)="showImage($event)"></app-feature-info>

  <app-table-thua-dat *ngIf="showThuaDat" [(dsThua)]="dsThua" [(searchParcelParams)]="searchParcelParams"
    (onClose)="toogleShowThuaDat()" (fitParcelBound)="hilightParcel($event)"></app-table-thua-dat>
  <app-chu-giai-qhsdd *ngIf="showLegend" (onClose)="toggleShowLegend()"></app-chu-giai-qhsdd>
  <!-- <angular-image-viewer *ngIf="isShowImage" class="image-container" [src]="images"
    [(index)]="imageIndex"></angular-image-viewer> -->
  <app-image-viewer *ngIf="isShowImage" [(imageUrls)]="images" (onClose)="onCloseImageViewer()"></app-image-viewer>
</div>