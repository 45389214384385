import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testrender',
  templateUrl: './testrender.component.html',
  styleUrls: ['./testrender.component.scss']
})
export class TestrenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  renderlog(){
    console.log("==========renderlog========")
  }

}
