<div class="search-radius-poi">
  <div class="header">
    <h4 style="margin-bottom: 0px;text-align: center;">Điểm quân tâm lân cận</h4>
    <button type="button" (click)="close()" class="btn closebtn">
      <i class="fa fa-close"></i>
    </button>
  </div>
  <hr style="margin:3px; height:1px;background:#ccc;" />
  <div class="form-group mb-0 p-4 border-bottom"><label class="font-weight-bold" style="font-size: 15px;">Trong phạm
      vi bán kính</label>
    <div class="row">
      <div class="col-4 mb-2"><label class="radio">
          <input type="radio" [checked]="radius===500" name="rdoBanKinh" value="500" (change)="changeRadius(500)"><span
            class="mr-2"></span>500 m
        </label>
      </div>
      <div class="col-4"><label class="radio">
          <input type="radio" [checked]="radius===1000" name="rdoBanKinh" value="1000"
            (change)="changeRadius(1000)"><span class="mr-2"></span>1 km
        </label>
      </div>
      <div class="col-4"><label class="radio">
          <input type="radio" [checked]="radius===1500" name="rdoBanKinh" value="1500"
            (change)="changeRadius(1500)"><span class="mr-2"></span>1.5 km
        </label>
      </div>
      <div class="col-4"><label class="radio">
          <input type="radio" [checked]="radius===2000" name="rdoBanKinh" value="2000"
            (change)="changeRadius(2000)"><span class="mr-2"></span>2 km
        </label>
      </div>
      <div class="col-4"><label class="radio">
          <input type="radio" [checked]="radius===3000" name="rdoBanKinh" value="3000"
            (change)="changeRadius(3000)"><span class="mr-2"></span>3 km
        </label>
      </div>
      <div class="col-4"><label class="radio">
          <input type="radio" [checked]="radius===5000" name="rdoBanKinh" value="5000"
            (change)="changeRadius(5000)"><span class="mr-2"></span>5 km
        </label>
      </div>
    </div>
  </div>
  <hr style="margin:3px; height:1px;background:#ccc;" />
  <div style="padding:5px;">
    <h5 style="margin-bottom: 0px;">Kết quả tìm kiếm {{total}}</h5>
  </div>
  <hr style="margin:3px; height:1px;background:#ccc;" />
  <div class="panel-results">
    <app-loading-block [isLoading]="showLoading"></app-loading-block>
    <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true" class="c-accordion-select">
      <ngb-panel *ngFor="let p of searchResults | keyvalue">
        <ng-template ngbPanelTitle class="text-black">
          <div style="display:flex;gap:12px;align-items: center;">
            <img [src]="'/assets/images/POI/' + p.key + '.png'" width="24" height="24">
            <span>{{vnWords[p.key]}}({{searchResults[p.key].length}})</span>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="c-accordion-item" *ngFor="let item of searchResults[p.key]">
            <a href="javascript:;" (click)="goToPOI(item.geom)"
              class="btn btn-icon btn-outline-success btn-circle active"
              style="display:flex;justify-content: center;align-items: center;width:32px;height:32px;border-radius:50%;">
              <i class="fas fa-map-marker-alt" style="font-size: 1rem;"></i></a>
            {{ item.name }}
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>