import { Injectable, NgModule } from '@angular/core';

import { Observable, of, throwError, from } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { StorageService } from "./storage.service";
import * as Constants from "../Constants/Constants";
//import { HTTP } from '@ionic-native/http/ngx';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { AlertService } from "./alert.service"
import { LoadingService } from "./loading.service"
import { DomSanitizer } from '@angular/platform-browser';

const configUlr = "assets/config.json";




@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer
  ) { }

  getJson(url: any): Promise<any> {
    return new Promise(resolve => {
      this.httpClient.get(url).subscribe(
        res => resolve(res),
        msg => resolve(msg)
      );
    })
  }

  get(url, headers: any = {}): Promise<any> {
    return new Promise(resolve => {
      //  this.storageService.get(Constants.AUTH_TOKEN).then(token => {
      // var headers_object = new HttpHeaders().set("access_token", `Bearer ${token}`);
      resolve(this.httpClient.get(url)
      );
      // })
    })
  }

  delete(url: string, params: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        var headerJson = null;
        if (token != null && token != undefined && token != "")
          headerJson = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + token
            }),
            params
          };
        else
          headerJson = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            }),
            params
          }

        this.httpClient.delete(url, headerJson).subscribe(
          res => resolve(res),
          msg => resolve(msg)
        );
      })
    })
  }


  getData(url: string | URL | Request, access_token: any): Observable<any> {
    return from(
      fetch(
        url, // the url you are trying to access
        {
          headers: {
            //'Token': access_token
            'Token': access_token
          },
          method: 'GET', // GET, POST, PUT, DELETE
          mode: 'no-cors' // the most important option
        }
      ));
  }


  postJsonNoPara(url: string): Promise<any> {
    return new Promise(resolve => {

      var headerJson = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }

      this.httpClient.post(url, null, headerJson).subscribe(
        res => resolve(res),
        msg => {
          this.loadingService.hideLoading();
          resolve(msg)
        }
      );

    })
  }

  postTextNoToken(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      var headerJson = null;

      // headerJson = {
      //   headers: new HttpHeaders({
      //     'accept': 'text/plain'
      //   })
      // }
      this.httpClient.post(url, data).subscribe(
        res => resolve(res),
        msg => {
          this.loadingService.hideLoading();
          resolve(msg)
        }
      );

    })
  }

  postJsonNoToken(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      var headerJson = null;

      headerJson = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      this.httpClient.post(url, data, headerJson).subscribe(
        res => resolve(res),
        msg => {
          this.loadingService.hideLoading();
          resolve(msg)
        }
      );

    })
  }

  postJsonNoHeader(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.httpClient.post(url, data).subscribe(
        res => resolve(res),
        msg => {
          this.loadingService.hideLoading();
          resolve(msg)
        }
      );

    })
  }

  getJsonNoToken(url: string, params: any): Promise<any> {
    return new Promise(resolve => {
      var headerJson = null;
      headerJson = {
        params,
      };
      this.httpClient.get(url, headerJson).subscribe(
        res => {
          resolve(res)
        },
        msg => {
          this.loadingService.hideLoading();
          resolve(msg)
        }
      );
    });
  }

  getJsonToken(url: string, params: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        if (token === null || token === undefined) {
          this.navigateToLogin(token, "");
          resolve(null);
        }
        else {
          var headerJson = null;
          headerJson = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + token
            }),
            params,
          };

          this.httpClient.get(url, headerJson).subscribe(
            res => {
              resolve(res)
            },
            msg => {
              this.loadingService.hideLoading();
              if (msg != null && !msg.ok && msg.statusText != undefined && msg.statusText.length > 0) {
                this.alertService.error(msg.statusText)

                this.navigateToLogin(token, msg);
              }
              resolve(msg)
            }
          );
        }
      })
    });
  }

  postJsonToken(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        this.storageService.get(Constants.CACHE_ID_USER).then(userId => {
          if (token == null || token == undefined || userId == null || userId == undefined) {
            console.log("222");
            this.navigateToLogin(token, "");
            resolve(null);
          }
          else {
            console.log("3333");
            var headerJson = null;
            headerJson = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
                'user_id': userId
              })
            };

            this.httpClient.post(url, data, headerJson).subscribe(
              res => {

                resolve(res)
              },
              msg => {
                this.loadingService.hideLoading();
                console.log(url);
                console.log("token4444444444=" + token);
                console.log(msg);
                if (msg != null && !msg.ok && msg.statusText != undefined && msg.statusText.length > 0) {
                  this.alertService.error(msg.statusText)

                  this.navigateToLogin(token, msg);
                }
                resolve(msg)
              }
            );
          }
        })
      })
    });
  }

  putJsonToken(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        if (token == null || token == undefined) {
          this.navigateToLogin(token, "");
          resolve(null);
        }
        else {
          var headerJson = null;
          headerJson = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + token
            })
          };

          this.httpClient.put(url, data, headerJson).subscribe(
            res => {

              resolve(res)
            },
            msg => {
              this.loadingService.hideLoading();
              if (msg != null && !msg.ok && msg.statusText != undefined && msg.statusText.length > 0) {
                this.alertService.error(msg.statusText)

                this.navigateToLogin(token, msg);
              }
              resolve(msg)
            }
          );
        }
      })
    });
  }

  patchJsonToken(url: string, data: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        if (token == null || token == undefined) {
          this.navigateToLogin(token, "");
          resolve(null);
        }
        else {
          var headerJson = null;
          headerJson = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + token
            })
          };

          this.httpClient.patch(url, data, headerJson).subscribe(
            res => {

              resolve(res)
            },
            msg => {
              this.loadingService.hideLoading();
              if (msg != null && !msg.ok && msg.statusText != undefined && msg.statusText.length > 0) {
                this.alertService.error(msg.statusText)

                this.navigateToLogin(token, msg);
              }
              resolve(msg)
            }
          );
        }
      })
    });
  }


  navigateToLogin(token: any, error: any): Promise<any> {
    return new Promise(resolve => {
      console.log(token);
      console.log(error);
      // if (token == null || token == undefined || token.length == 0)
      //   this.router.navigate([Constants.FORMS.LOGIN]);

      //   if(error !=null &&  error.statusText==Constants.UN_AUTHORIZED)
      //   this.router.navigate([Constants.FORMS.LOGIN]);

      resolve(true);
    })
  }




  uploadFile(url: string, file: File): Promise<any> {
    return new Promise(resolve => {

      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        if (token == null || token == undefined) {
          this.navigateToLogin(token, "");
          resolve(null);
        }
        else {
          var headerJson = null;
          headerJson = {
            headers: new HttpHeaders({
              'Authorization': "Bearer " + token
            })
          };
        }

        //  this.httpClient.post(url, data, headerJson)
        const formData = new FormData();

        if (Array.isArray(file)) {
          file.forEach(fileItem => {
            console.log("append file", fileItem);
            formData.append('files', fileItem, fileItem.name);
          })
        } else {
          console.log("append file", file);
          formData.append('file', file, file.name);
        }
        console.log(url);
        console.log(formData);
        console.log(headerJson);

        this.httpClient.post(url, formData, headerJson).subscribe(
          res => {
            console.log(res);
            resolve(res)
          },
          msg => {
            this.loadingService.hideLoading();
            console.log(url);
            console.log("token55555=" + token);
            console.log(msg);
            if (msg != null && !msg.ok && msg.statusText != undefined && msg.statusText.length > 0) {
              this.alertService.error(msg.statusText)

              this.navigateToLogin(token, msg);
            }
            resolve(msg)
          }
        );



      })
    })

  }

  streamFile(url: any, fileName: any): Promise<any> {
    return new Promise(resolve => {
      this.storageService.get(Constants.AUTH_TOKEN).then(token => {
        // var headerJson = {};

        if (token == null || token == undefined) {
          this.navigateToLogin(token, "");
          resolve(null);
        }
        else {

          // Set up HTTP headers
          var headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

          // Set up HTTP parameters
          var params = new HttpParams().set('fileName', fileName);


          this.httpClient.get(url, { headers: headers, params: params, responseType: 'blob' }).subscribe((blob: any) => {
            const objectURL = URL.createObjectURL(blob);
            resolve(this.sanitizer.bypassSecurityTrustUrl(objectURL));
          });
        }
      })


    })
  }

  getConfigFile(): Promise<any> {
    return new Promise(resolve => {
      this.httpClient.get(configUlr).subscribe(
        res => resolve(res),
        msg => resolve(msg)
      );
    })
  }

  getFileLocal(path: string): Promise<any> {
    return new Promise(resolve => {
      this.httpClient.get(path).subscribe(
        res => resolve(res),
        msg => resolve(msg)
      );
    })
  }

  // getConfigFile(): Observable<any> {
  //   return this.httpClient.get(configUlr).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError));
  // }


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log(error.error.message);
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  // get() {
  //   this.http.get('http://ionic.io', {}, {})
  //     .then(data => {
  //       console.log(data);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }


  readExcelFile(file: File): Promise<any> {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        resolve(jsonData);
      };

      reader.readAsArrayBuffer(file);
    });
  }


  // postUrlencoded(url: string, data: any, header: { headers?: HttpHeaders | { [header: string]: string | string[]; } | undefined; context?: HttpContext | undefined; observe?: "body" | undefined; params?: HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined; reportProgress?: boolean | undefined; responseType: "arraybuffer"; withCredentials?: boolean | undefined; transferCache?: boolean | { includeHeaders?: string[] | undefined; } | undefined; }): Promise<any> {
  //   return new Promise(resolve => {


  //     this.httpClient.post(url, data, header).subscribe(
  //       res => resolve(res),
  //       msg => {
  //         resolve(msg)
  //       }
  //     );

  //   })
  // }

  // getJson1(url: string, header: { headers?: HttpHeaders | { [header: string]: string | string[]; } | undefined; context?: HttpContext | undefined; observe?: "body" | undefined; params?: HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined; reportProgress?: boolean | undefined; responseType: "arraybuffer"; withCredentials?: boolean | undefined; transferCache?: boolean | { includeHeaders?: string[] | undefined; } | undefined; }): Promise<any> {
  //   return new Promise(resolve => {

  //     this.httpClient.get(url,  header).subscribe(
  //       res => resolve(res),
  //       msg => {
  //         resolve(msg)
  //       }
  //     );

  //   })
  // }

}
