<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box pb-3 d-flex align-items-center justify-content-between">
      <div>
        <h4 class="mb-0 font-size-18 text-primary">{{ title }}</h4>
      </div>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" *ngIf="!item.active"><a routerLink="{{item.link}}">{{ item.label }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>