import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResizableColumn]'
})
export class ResizableColumnDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    const initialX = event.clientX;
    const initialWidth = this.el.nativeElement.offsetWidth;

    const onMouseMove = (moveEvent: MouseEvent): void => {
      const width = initialWidth + (moveEvent.clientX - initialX);
      this.renderer.setStyle(this.el.nativeElement, 'width', `${width}px`);
    };

    const onMouseUp = (): void => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }
}
