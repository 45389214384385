<div>
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #ccc;
    "
  >
    <canvas
      #streaming
      id="canvas"
      width="800"
      height="600"
      style="display: block; width: 100%"
    ></canvas>
    <!-- <vg-player
      style="width: 800px; height: 600px"
      (onPlayerReady)="onPlayerReady($event)"
    >
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>
      <video
        style="width: 800px; height: 600px"
        #media
        [vgMedia]="media"
        id="singleVideo"
        preload="auto"
        controls
      >
        <source
          preload="auto"
          autoplay="true"
          crossorigin
          src="rtsp://admin:camera234@10.19.60.7:554"
          type="video/rtsp"
        />
      </video>
    </vg-player> -->
    <!-- <vg-player
      (onPlayerReady)="onPlayerReady($event)"
      style="width: 800px; height: 600px"
    >
      <vg-overlay-play></vg-overlay-play>

      <vg-controls vgFor="singleVideo">
        <vg-play-pause></vg-play-pause>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>

      <video
        #media
        [vgMedia]="media"
        src="rtsp://admin:camera234@10.19.60.7:554"
        id="singleVideo"
        type="video/rtsp"
        preload="auto"
        crossorigin
      ></video>
    </vg-player> -->
  </div>
</div>
