<div class="">
  <div class="text-end py-2">
    <button type="button" class="btn btn-success me-2" (click)="submit()" >
      <i class="fa-solid fa-floppy-disk align-middle me-1" ></i> Lưu
    </button>
  </div>
  <ckeditor [data]="editorData" (change)="onChange($event)" [editor]="configsss" id="classic-editor" name="classic-editor">
  </ckeditor>
</div>

