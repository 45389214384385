import { Injectable } from '@angular/core';
import { HttpService } from "../../../ServiceCommon/http.service"
import { API } from "../../../../contants/ApiContants"
import { CommonService } from "../../../ServiceCommon/common.service"
import { ConfigService } from 'src/app/core/services/config.service';


@Injectable({
  providedIn: 'root'
})
export class DmChungService {

  constructor(
    private httpService: HttpService,
    private httpRequest: ConfigService,
    private commonService: CommonService
  ) { }

  async getAll(para: any): Promise<any> {
    return new Promise((resolve) => {
      this.httpService
        .postJsonToken(API.API_MANAGER_DATA + '/dm-chung/getAll', para)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.commonService.logError(err);
            resolve(null);
          }
        );
    });
  }

  async findByLoai(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/dm-chung/findByLoai", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  findByLoaiAndMa(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/dm-chung/findByLoaiAndMa", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  getDmTinh() {
    return this.httpRequest.post(`${API.API_GEODATA}/data/getTableData?layerName=DiaPhanTinh&includeGeom=false`, []);
  } 
  getDmTinhById(maTinh) {
    const filterParams = [
      {
        "field": "code",
        "operato": "=",
        "join": "and",
        "value": "\'" + maTinh + "\'"
      }
    ]
    return this.httpRequest.post(`${API.API_GEODATA}/data/getTableData?layerName=DiaPhanTinh&includeGeom=false`, filterParams);
  }
  getDmHuyen(maTinh) {
    const filterParams = [
      {
        "field": "pcode",
        "operato": "=",
        "join": "and",
        "value": "\'" + maTinh + "\'"
      }
    ]
    return this.httpRequest.post(`${API.API_GEODATA}/data/getTableData?layerName=DiaPhanHuyen&includeGeom=false`, filterParams);
  }
  getDmHuyenById(maHuyen) {
    const filterParams = [
      {
        "field": "code",
        "operato": "=",
        "join": "and",
        "value": "\'" + maHuyen + "\'"
      }
    ]
    return this.httpRequest.post(`${API.API_GEODATA}/data/getTableData?layerName=DiaPhanHuyen&includeGeom=false`, filterParams);
  }
  getDmXa(maHuyen) {
    const filterParams = [
      {
        "field": "dcode",
        "operato": "=",
        "join": "and",
        "value": "\'" + maHuyen + "\'"
      }
    ]
    return this.httpRequest.post(`${API.API_GEODATA}/data/getTableData?layerName=DiaPhanXa&includeGeom=false`, filterParams);
  }
}
