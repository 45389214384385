import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import wellknown from "wellknown";
import * as turf from "@turf/turf";
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { MapService } from 'src/app/core/services/map.service';
import { StorageService } from 'src/app/quan-tri/ServiceCommon/storage.service';
// import * as Constants from "./../../../../../quan-tri/Constants/Constants"
import * as Constants from "./../../../../../../quan-tri/Constants/Constants";
declare let jsPDF;

const shortConfigs = [
  {
    alias: "STT",
    name: "index",
  },
  {
    alias: "Mã hồ sơ",
    name: "oqh_mahoso",
  },
  {
    alias: "Tên",
    name: "oqh_tendoituong",
  },
  {
    alias: "Quy hoạch",
    name: "oqh_planppname",
  },
  {
    alias: "Chuyển đổi",
    name: "oqh_chuyendoichucnangsdd",
  },
  {
    alias: "Diện tích",
    name: "oqh_dientich",
  },
  {
    alias: "Dân số",
    name: "oqh_danso",
  },
  {
    alias: "Hệ số sdd",
    name: "oqh_hesosdd",
  }
]

const columnConfigs = [
  ...shortConfigs,
  {
    alias: "Chiem",
    name: "tile",
  },
  {
    alias: "DT Trong(m²)",
    name: "dientichtrong",
  },
  {
    alias: "DT Ngoài(m²)",
    name: "dientichngoai",
  }
]


@Component({
  selector: 'app-table-quy-hoach',
  templateUrl: './table-quy-hoach.component.html',
  styleUrls: ['./table-quy-hoach.component.scss']
})
export class TableQuyHoachComponent implements OnInit {
  @Input() loaiQH: string;
  @Input() chucNang: string;
  @Input() tableData: any[];
  @Output() onClose = new EventEmitter<any>();
  @Output() fitParcelBound = new EventEmitter<any>();
  pageSize: number = 5;
  total: number = 0;
  page: number = 1;
  currentPageData: any[] = [];
  selectedQH: any = null;
  isAnalystQHXD: boolean = false;
  isAnalystQHSDD: boolean = false;
  tableColumns: any[] = shortConfigs;
  isLoadingBlock: boolean = false;
  isLoggedIn: boolean = false;
  dmQH: any[] = [
    // {
    //   name: 'Tất cả',
    //   value: 'all'
    // },
    {
      name: 'QH Xây dựng',
      value: 'QHXD'
    }
  ]
  isLoading: boolean = false;
  constructor(
    private storageService: StorageService,
    private mapsService: MapService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if ("tableData" in changes) {
      console.log('tabldeeedddddd d', this.tableData)
      this.total = this.tableData.length;
      const startIndex = 0;
      const endIndex = startIndex + this.pageSize
      this.currentPageData = this.tableData.slice(startIndex, endIndex);
    }
  }

  ngOnInit(): void {
    let self = this;
    self.storageService.get(Constants.AUTH_TOKEN).then(token => {
      if (token) {
        this.isLoggedIn = true;
      }
    })
  }

  close() {
    this.onClose.emit();
  }

  trackFunc(index: any, item: any): string {
    return index;// item.id;
  }
  nextPage(event) {
    // this.isLoading = true;
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.tableData.length);
    this.currentPageData = this.tableData.slice(startIndex, endIndex);
  }
  locateParcel(row) {
    const geostr = row.geom;
    if (geostr) {
      const geom: any = wellknown.parse(geostr);
      if (geom.type === 'MultiPolygon') {
        var mpolygon = turf.multiPolygon(geom.coordinates)
        // var bbox = turf.bbox(mpolygon);
        this.fitParcelBound.emit(mpolygon);
      } else {
        var polygon = turf.polygon(geom.coordinates)
        // var bbox = turf.bbox(polygon);
        this.fitParcelBound.emit(polygon);
      }
    }
  }
  tableExport(type) {
    console.log('export ', type)
    if (type === 'excel')
      this.exportExcel()
    else if (type === 'pdf') {
      this.exportPdf()
    }
  }


  exportExcel() {
    const configCols = this.tableColumns;
    let dataExport = [];
    // Tiêu đề báo cáo
    // const reportTitle = "Danh sách thửa đất dính vào vùng quy hoạch";
    // // Thêm tiêu đề vào dataExport
    // let titleRow = {};
    // titleRow[""] = reportTitle; // Tiêu đề sẽ được ghi vào cột rỗng
    // dataExport.push(titleRow);
    this.tableData.forEach(item => {
      let temp = _.cloneDeep({});
      configCols.forEach(col => {
        temp = {
          ...temp,
          [col.alias]: item[col.name]
        }
      })

      if (JSON.stringify(temp) !== JSON.stringify({})) dataExport.push(temp);
    })

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExport);
    // const columnWidth = { width: 30 };
    let colsWidth = [];
    configCols.forEach(col => {
      colsWidth.push({ width: 15 })
    })
    worksheet['!cols'] = colsWidth;

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
      if (!worksheet[headerCell]) continue;
      worksheet[headerCell].s = {
        bold: true, // Chữ đậm
        fontSize: 14, // Cỡ chữ
        ...worksheet[headerCell].s // Bảo toàn các style khác nếu có
      };
    }

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "thuadat");

  }
  // searchQH() {
  //   if (this.loaiQH && this.chucNang) {
  //     // if (this.loaiQH !== 'QHCT') {
  //     const searchParams = [
  //       {
  //         "field": 'oqh_chucnangsdd',
  //         "operato": "=",
  //         "join": "and",
  //         "value": "\'" + this.chucNang + "\'"
  //       },
  //       {
  //         "field": 'oqh_loaiqh',
  //         "operato": "=",
  //         "join": "and",
  //         "value": "\'" + this.loaiQH + "\'"
  //       }
  //     ]
  //     this.mapsService.searchTable('QHXD_LaoCai', searchParams, true).subscribe(rs => {
  //       console.log('kq tim kiem', rs)
  //     })
  //     // }
  //   }
  // }

  exportPdf() {
    const borderLineOffset = 1;
    var doc = new jsPDF();
    // var col = columnConfigs.map(item => item.name);
    var col = this.tableColumns.map(item => item.alias);
    var rows = [];
    this.tableData.forEach(element => {
      // var temp = [element.index, element.name];
      var temp = [];
      this.tableColumns.forEach(item => {
        temp.push(element[item.name])
      })
      rows.push(temp);
    });

    // const font = 'ArialUnicodeMS';
    // const fontName = 'ArialUnicodeMS';
    // const fontStyle = 'normal';
    // console.log('jspdf', jsPDF)
    // console.log('doc', doc)
    // // jsPDF.API.events.push(['addFonts', amiriFont, fontName, fontStyle]);
    // jsPDF.API.addFont(amiriFont, 'standard', fontName);

    // doc.setFont(fontName, fontStyle);
    doc.autoTable(col, rows, {
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      lineHeight: 13,
      tableWidth: 'wrap',
      headerStyles: {
        theme: 'grid',
        height: 10
      },
      // theme: "plain",
      margin: { horizontal: 3, top: 20 },
      // startY: 0.47*doc.internal.pageSize.height,
      styles: { overflow: 'linebreak' },
      drawRow: (row, data) => {
        let firstCell = row.cells[0];
        let secondCell = row.cells[1];
        const columnWidth = data.table.columns[3].width;
        doc.line(data.cursor.x - columnWidth, data.cursor.y + row.height - borderLineOffset / 2, data.cursor.x, data.cursor.y + row.height - borderLineOffset / 2);
        doc.line(data.cursor.x - columnWidth, data.cursor.y + row.height + borderLineOffset / 2, data.cursor.x, data.cursor.y + row.height + borderLineOffset / 2);
      },
      drawHeaderRow: (head, data) => {
        doc.line(data.cursor.x, data.cursor.y + head.height, data.cursor.x + data.table.width, data.cursor.y + head.height);
      },
      columnStyles: {
        0: { columnWidth: 18 },
        1: { columnWidth: 25 },
        2: { columnWidth: 25 },
        3: { columnWidth: 25 },
        4: { columnWidth: 25 },
        5: { columnWidth: 20 },
        6: { columnWidth: 'auto' },
        7: { columnWidth: 'auto' }
      }
    });
    doc.save('thuadat.pdf');
  }
  
  isEmptyObject(obj: any): boolean {
    return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = fileName + '.xlsx';
    a.click();
  }
}
