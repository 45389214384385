import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
// import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  URL = 'assets/dashboard.json';

  constructor(private http: HttpClient) { }
  // getConfig() : Observable<any> {
  //   return this.http.get<any>(`${this.URL}`)
  // }

  get(url, headers: any = {}) {
    let token = localStorage.getItem("token") || "";
    var headers_object = new HttpHeaders().set("Authorization", `Bearer ${token}`)
    //TO DO
    .set("user-id", "c4104419-345c-4018-81c0-7812f82bb311");
    return this.http.get(url, {
      headers: headers_object,
      ...headers
    });
  }

  post(url, body, header: any = []) {
    let token = localStorage.getItem("token") || "";
    var headers_object = new HttpHeaders().set("Authorization", `Bearer ${token}`).set("user-id", "c4104419-345c-4018-81c0-7812f82bb311");
    if (header.length) {
      header.forEach(element => {
        headers_object.set(element.type, element.value)
      });
    }
    return this.http.post(url, body, {
      headers: headers_object
    });
  }

  delete(url) {
    let token = localStorage.getItem("token") || "";
    var headers_object = new HttpHeaders().set("Authorization", `Bearer ${token}`).set("user-id", "c4104419-345c-4018-81c0-7812f82bb311");
    return this.http.delete(url, {
      headers: headers_object
    })
  }

  put(url, body, headers: any = {}) {
    let token = localStorage.getItem("token") || "";
    var headers_object = new HttpHeaders().set("Authorization", `Bearer ${token}`).set("user-id", "c4104419-345c-4018-81c0-7812f82bb311");
    return this.http.put(url, body, {
      headers: headers_object,
      ...headers
    });
  }

  download(url: string) {
    let token = localStorage.getItem("token") || "";
    var headers_object = new HttpHeaders().set("Authorization", `Bearer ${token}`).set("user-id", "c4104419-345c-4018-81c0-7812f82bb311");
    return this.http.get(url, {
      headers: headers_object,
      observe: 'response' as 'body',
      responseType: 'blob' as 'blob'
    })
  }

}


