import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as _ from "lodash";
@Component({
  selector: "app-right-map-control",
  templateUrl: "./right-map-control.component.html",
  styleUrls: ["./right-map-control.component.scss"],
})
export class RightMapControlComponent implements OnInit {
  @Output() toogleShowBaseLayers = new EventEmitter<any>();
  @Output() toogleShowXY = new EventEmitter<any>();
  @Output() goToPlace = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  showBaseLayer() {
    this.toogleShowBaseLayers.emit();
  }

  showXY() {
    this.toogleShowXY.emit();
  }
}
