import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { contants } from 'src/app/contants';
import { MetadataService } from 'src/app/core/services/metaData.service';
import {API} from 'src/app/contants/ApiContants';
import Swal from 'sweetalert2';
import { SelectImageComponent } from '../select-image/select-image.component';
import * as Constants from '../../quan-tri/Constants/Constants';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  buttons: any = Constants.BUTTONS;
  @Input() menus: any = [];
  @Input() maps: any = [];
  @Input() level: any = 0;
  @Input() role: string = "";
  @Input() keyActive: string = "";
  @Output() onAction = new EventEmitter<any>();
  @ViewChild('cModalCatalog', { static: true }) cModalCatalog: any;
  @ViewChild('cModalResource', { static: true }) cModalResource: any;
  @ViewChild('cModal', { static: true }) cModal: any;
  @ViewChild('selectImage', { static: true }) selectImage: SelectImageComponent;

  host: string = API.HOST_RESOURCE;
  formCatalog: UntypedFormGroup;
  formResource: UntypedFormGroup;
  activeIds: string;
  submitted: boolean = false;
  isLoading: boolean = false;
  statusAction: string = "create";
  itemTarget: any;
  files: File[];
  formUpload: UntypedFormGroup;
  previewImage: any;
  fileSelected: any;
  idMapUpdate: string = "";
  version: number = 0;
  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private metadataService: MetadataService,
  ) {
    this.formUpload = this.formBuilder.group({
      bucketName: ['', [Validators.required]],
      file: ['', [Validators.required]],
      fileName: [''],
    });

    this.formCatalog = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      order: [''],
    });

    this.formResource = this.formBuilder.group({
      name: ['', [Validators.required]],
      id_map: ['', [Validators.required]],
      description: [''],
    });
  }

  get valFormCatalog() { return this.formCatalog.controls; }
  get valFormResource() { return this.formResource.controls; }
  get valFormUpload() { return this.formUpload.controls; }

  

  ngOnInit(): void {
    
  }

  submitUpload(modal){

  }

  openModalSelectImage(){
    this.selectImage.openModalChooseFile();
  }

  updateMap(node, map){
    this.statusAction = "edit";
    console.log("============node123=========", node, map);
    this.itemTarget = {
      id: node.id,
      data: node,
      map
    }
    this.selectImage.getAllFolder();
    this.idMapUpdate = map.id;
    this.formResource.controls.name.patchValue(map.value);
    this.formResource.controls.id_map.patchValue(map.id_map);
    this.formResource.controls.description.patchValue(map.description);
    // this.formResource.controls.id_map.patchValue(map.id_map);
    this.fileSelected = {
      ...(this.fileSelected || {}),
      data: {
        ...(this.fileSelected?.data || {}),
        info: {
          ...(this.fileSelected?.data?.info || {}),
          uri: map.thumbnails || ""
        }
      }
    }
    this.modalService.open(this.cModalResource)
  }

  addCatalog(event, menu) {
    event.preventDefault();
    this.itemTarget = menu;
    this.statusAction = "create";
    this.formCatalog.reset();
    this.submitted = false;
    this.modalService.open(this.cModalCatalog)
  }

  editCatalog(event, menu) {
    this.itemTarget = menu;
    this.statusAction = "edit";
    this.submitted = false;
    this.formCatalog.controls.name.patchValue(menu?.data?.name || "");
    this.formCatalog.controls.code.patchValue(menu?.data?.name || "");
    this.formCatalog.controls.order.patchValue(menu?.data?.sort || "");
    this.modalService.open(this.cModalCatalog)
  }

  addResource(event, menu) {
    event.preventDefault();
    this.fileSelected = null;
    this.itemTarget = menu;
    this.statusAction = "create";
    this.submitted = false;
    this.selectImage.getAllFolder();
    this.modalService.open(this.cModalResource)
  }

  deleteCatalog(event, menu) {
    this.itemTarget = menu;
    Swal.fire({
      title: `Bạn có muốn xóa <strong>${menu?.data?.name}</strong>?`,
      text: '',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Xóa'
    }).then(result => {
      if (result.value) {
        this.metadataService.deleteCatalog(menu.id).subscribe(res => {
          if (res["status"] === 200) {
            let obj = {
              key: "refresh_page",
            }
            this.onAction.emit(obj);
            this.isLoading = false;
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Xóa thành công',
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Không tìm được danh mục!!',
              showConfirmButton: false,
              timer: 3000
            });
          }
        },
          () => {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Không tìm được danh mục!!',
              showConfirmButton: false,
              timer: 3000
            });
          })
      }
    });
  }

  submitResource(modal) {
    this.submitted = true;
    if (this.formResource.invalid) {
      return false;
    }
    let bodyRequest = {
      "id_chuyen_de": this.itemTarget?.id,
      "id_layer": null,
      "id_map": this.valFormResource.id_map.value || "",
      "id_user": null,
      "ma_loai": "map",
      "name": this.valFormResource.name.value || "",
      "description": this.valFormResource.description.value || "",
      "group_type": contants.group_type_catalog,
      "thumbnails": this.fileSelected?.data?.info?.uri
    }
    if (this.statusAction === "create") {
      this.metadataService.addResource(bodyRequest).subscribe(res => {
        this.isLoading = false;
        if (res["status"] === 200) {
          modal.dismiss();
          let obj = {
            key: "refresh_page",
          }
          this.onAction.emit(obj);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cập nhật thành công !!',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }, (err) => {
        this.isLoading = false;
      })
    }else{
      console.log("========this.itemTarget?.data?._version=========", this.itemTarget)
      let bodyUpdate = {
        "_version": this.itemTarget?.map?._version || 0,
        "id": this.idMapUpdate,
        "name": this.valFormResource.name.value || "",
        "description": this.valFormResource.description.value || "",
        "thumbnails": this.fileSelected?.data?.info?.uri
      }
      this.metadataService.updateResource(bodyUpdate).subscribe(res => {
        this.isLoading = false;
        if (res["status"] === 200) {
          modal.dismiss();
          let obj = {
            key: "refresh_page",
          }
          this.onAction.emit(obj);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cập nhật thành công !!',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }, (err) => {
        this.isLoading = false;
      })
    }

  }


  onSelect(event) {
    console.log(event);
    this.files = event.addedFiles;
  }

  onRemove(f, i){
    this.files.splice(i, 1);
  }

  openModalUpload(){
    this.modalService.open(this.cModal, { size: 'lg'});
  }

  submitCatalog(modal) {
    this.submitted = true;
    if (this.formCatalog.invalid) {
      return false;
    }
    this.isLoading = true;
    if (this.statusAction === "create") {
      let bodyRequest = {
        "parent_id": this.itemTarget?.id || "root",
        "code": this.valFormCatalog.code.value || "",
        "name": this.valFormCatalog.name.value || "",
        "leaf": Number(this.itemTarget?.data?.leaf) + 1 || 0,
        "sort": this.valFormCatalog.order.value || 0,
        "group_type": contants.group_type_catalog
      }
      this.metadataService.addCatalog(bodyRequest).subscribe(res => {
        this.isLoading = false;
        if (res["status"] === 200) {
          modal.dismiss();
          let obj = {
            key: "refresh_page",
          }
          this.onAction.emit(obj);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cập nhật thành công !!',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }, (err) => {
        this.isLoading = false;
      })
    } else {
      console.log("=======itemTarget========", this.itemTarget);
      let bodyRequest = {
        "id": this.itemTarget?.id,
        "parent_id": this.itemTarget?.data?.parent_id || "",
        "code": this.valFormCatalog.code.value || "",
        "name": this.valFormCatalog.name.value || "",
        "sort": this.valFormCatalog.order.value || "",
        "_version": this.itemTarget?.data?._version || 0,
      }
      this.metadataService.updateCatalog(bodyRequest).subscribe(res => {
        this.isLoading = false;
        if (res["status"] === 200) {
          modal.dismiss();
          let obj = {
            key: "refresh_page",
          }
          this.onAction.emit(obj);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cập nhật thành công !!',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }, (err) => {
        this.isLoading = false;
      })
    }


  }

  stopCollap(event) {
    event.stopPropagation();
  }

  renderActiveIds(lstMenu: any = []) {
    if (!lstMenu) {
      return "";
    }
    let listIdActive = (lstMenu || []).map(item => item.id);
    return listIdActive.toString();
  }

  ngOnChanges(event) {
    if (event.menus) {
      let idsActiveTemp = (event.menus.currentValue || []).map(item => item.id);
      this.activeIds = idsActiveTemp.toString();
    }
  }

  onClickNode(node) {
    // this.clickItem(node.value);
    this.onAction.emit(node);
  }

  clickItem(node) {
    let obj = {
      key: "click_node",
      value: {
        ...node,
      }
    }
    this.onAction.emit(obj);
  }

  onChoice(event){
    if(event.key === "selected_file"){
      this.fileSelected = event.value;
    }
  }
}
