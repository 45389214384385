<div class="place-map-control">
  <div
    class="mapboxgl-ctrl mapboxgl-ctrl-group"
    style="display: flex; align-items: center"
  >
    <button
      type="button"
      (click)="toggleSelect()"
      title="Đi tới địa điểm"
      style="display: flex; justify-content: center; align-items: center"
    >
      <span
        class="mapboxgl-ctrl-icon"
        style="
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <i class="fa fa-map-pin fa-lg"></i>
      </span>
    </button>

    <ng-select
      *ngIf="showSelect"
      [notFoundText]="'Chọn địa điểm, địa danh'"
      placeholder="Chọn địa điểm, địa danh"
      [items]="places"
      [multiple]="false"
      bindLabel="name"
      bindValue="name"
      (input)="changePlace($event)"
      (change)="changeOrigin($event)"
      class="w-100 no-border"
      name="origin"
    >
      <ng-template ng-option-tmp let-item="item" style="border: none">
        <!-- Template for option display -->
        <div class="d-lfex">
          <img
            src="{{ item.icon }}"
            width="15px"
            height="15px"
            class="align-self-center me-2"
          />
          <span class="align-self-center">{{ item.name }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
