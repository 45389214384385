import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
} from "@angular/common/http";

import { environment } from "../environments/environment";

import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ComponentsModule } from "./components/components.module";
import { BoldReportViewerModule } from "@boldreports/angular-reporting-components";
// Report viewer
import "@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgParticlesModule } from "ng-particles";

// data-visualization
import "@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min";
import "@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min";
import { HomeComponent } from "./pages/home/home.component";
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducer";
import { LightboxModule } from "ngx-lightbox";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { MaterialModule } from "src/app/material/material.module";
import { TestrenderComponent } from "./testrender/testrender.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';

// import { AngularImageViewerModule } from "@hreimer/angular-image-viewer";
import { ImageViewerModule } from "ngx-image-viewer";
import { NgxDocViewerModule } from "ngx-doc-viewer";
// import { TreeDVHCComponent } from './tree-dvhc/tree-dvhc.component';
if (environment.defaultauth === "firebase") {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, HomeComponent, TestrenderComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    // ExtrapagesModule,
    BoldReportViewerModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    // ScrollToModule.forRoot(),
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgbModule,
    CKEditorModule,
    ComponentsModule,
    NgParticlesModule,
    LightboxModule,
    // ScrollingModule,
    DragDropModule,
    // StoreModule.forRoot({ common: commonsReducer })
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    ResizableModule,
    MaterialModule,
    NgxDocViewerModule,
    PdfViewerModule,
    ImageViewerModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeBackendInterceptor,
      multi: true,
    },
    NgbActiveModal,
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
