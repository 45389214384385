import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Location } from "@angular/common";
import { StorageService } from "../../quan-tri/ServiceCommon/storage.service";
import * as Constants from "../../quan-tri/Constants/Constants";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthfakeauthenticationService } from "src/app/core/services/authfake.service";
// import { LoginComponent } from "../../quan-tri/Pages/login/login.component"

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  currentSection = "home";
  isLoggedIn: boolean = false;
  userName: string = "";
  showUserContext: boolean = false;
  constructor(
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private authFackservice: AuthfakeauthenticationService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.checkDangNhap();
  }

  toggleMenu() {
    document.getElementById("topnav-menu-content").classList.toggle("show");
  }

  toggleUser() {
    // document.getElementById('topnav-user-content').classList.toggle('show');
    console.log("tg ussseerrr");
    this.showUserContext = !this.showUserContext;
  }

  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  //dqcuong them check dang nhap

  checkDangNhap() {
    var self = this;
    self.storageService.get(Constants.AUTH_TOKEN).then((token) => {
      console.log("tttt", token);
      if (token) {
        this.isLoggedIn = true;
      }
    });
    self.storageService.get(Constants.CACHE_USER).then((uname) => {
      this.userName = uname;
    });
    // $(document).ready(function () {
    //   $(".page-content a").on('click', () => {
    //     var path = self.location.path();
    //     console.log(path);
    //     var href = $(this).attr('href');
    //     console.log(href);

    //     if (path.indexOf("extrapages/service-map") >= 0 && href.indexOf("/maps/web") >= 0) {
    //       self.storageService.get(Constants.AUTH_TOKEN).then(token => {
    //         console.log(token);
    //         if (token) {
    //           this.isLoggedIn = true;
    //         }
    //       })
    //     }
    //   })
    // })
  }

  async openModal() {
    // const modalRef = this.ngbModal.open(LoginComponent, {
    //   backdrop: "static",
    //   // modalDialogClass: 'my-custom-modal'
    // });
    // modalRef.result
    //   .then((result) => {
    //     console.log("Modal closed with data:", result);
    //     // Handle the data passed back from the modal
    //   })
    //   .catch((reason) => {
    //     console.log("Modal dismissed with reason:", reason);
    //     // Handle the case where the modal was dismissed (closed without using close button)
    //   });
  }
  logOut() {
    this.storageService.set(
      Constants.AUTH_TOKEN,
      "",
      Constants.TIME_CACHE_MAX_MINUTE
    );
    this.storageService.set(
      Constants.CACHE_ID_USER,
      "",
      Constants.TIME_CACHE_MAX_MINUTE
    );
    this.storageService.set(
      Constants.CACHE_USER,
      "",
      Constants.TIME_CACHE_MAX_MINUTE
    );
    this.authFackservice.logout();
    //HelperService._layout = Constants.LAYOUTS.DEFAULT;
    // this.shareDataService.updateLayout();
    // this.getProfile(user.accessToken);
    this.router.navigate(["/account/auth/login"]);
  }
  //end dqcuong
}
