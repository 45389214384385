<div class="date-range-picker">
    <app-year-range-picker
      [startYear]="startYear"
      [endYear]="endYear"
      (yearSelected)="selectYear($event)"
    ></app-year-range-picker>
  
    <ngb-datepicker
      [(ngModel)]="selectedDate"
      (ngModelChange)="onDateSelected($event)"
      [startDate]="{ year: startYear, month: 1 }"
    ></ngb-datepicker>
  
    <div class="selected-year">Selected Year: {{ selectedYear }}</div>
  </div>
  