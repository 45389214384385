import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomImageEvent, ImageViewerConfig } from '@hreimer/angular-image-viewer';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>();
  @Input() imageUrls: any;
  title = 'angular-image-viewer';
  // images = [
  //   'https://i.ytimg.com/vi/nlYlNF30bVg/hqdefault.jpg',
  //   'https://www.askideas.com/media/10/Funny-Goat-Closeup-Pouting-Face.jpg'
  // ];

  imageIndexOne = 0;
  constructor() { }

  ngOnInit(): void {
  }

  config: ImageViewerConfig = {
    btnContainerClass: 'other',
    btnClass: 'btn btn-hover-primary px-1',
    btnSubClass: 'material-icons font-size-xl',
    zoomFactor: 0.1,
    containerBackgroundColor: '#ccc',
    wheelZoom: false,
    allowFullscreen: true,
    allowKeyboardNavigation: true,
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: false,
      prev: false,
      reset: true
    },
    btnIcons: {
      zoomIn: {
        text: 'zoom_in'
      },
      zoomOut: {
        text: 'zoom_out'
      },
      rotateClockwise: {
        text: 'rotate_right'
      },
      rotateCounterClockwise: {
        text: 'rotate_left'
      },
      next: {
        text: 'arrow_right'
      },
      prev: {
        text: 'arrow_left'
      },
      fullscreen: {
        text: 'fullscreen'
      },
      reset: {
        text: 'restore'
      },
    },
    customBtns: [
      {
        name: 'print',
        icon:
        {
          text: 'print'
        }
      },
      {
        name: 'link',
        icon:
        {
          text: 'link'
        }
      }
    ]
  };

  handleEvent(event: CustomImageEvent) {
    console.log(`${event.name} has been clicked on img ${event.imageIndex + 1}`);

    switch (event.name) {
      case 'print':
        console.log('run print logic');
        break;
    }
  }
  close() {
    this.onClose.emit();
  }
}
