import { Injectable, NgModule } from '@angular/core';
import { HttpService } from "../../../ServiceCommon/http.service"
import { API } from "../../../../contants/ApiContants"
import { CommonService } from "../../../ServiceCommon/common.service"
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService) {

  }
  async getAll(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/getAll", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  getTotalCount(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/getTotalCount", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }
  getById(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/getById", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  insert(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/insert", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  update(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/update", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }

  findRoleByUserId(para: any): Promise<any> {
    return new Promise(resolve => {
      this.httpService.postJsonToken(API.API_MANAGER_DATA + "/menus/findRoleByUserId", para)
        .then(data => {
          resolve(data);

        }, err => {
          this.commonService.logError(err);
          resolve(null);
        });
    })
  }


  getAllMenuFromHtml(parent: any): Promise<any> {
    return new Promise(resolve => {
      var menus = [];
      // Iterate over each element
      var self = this;
      $(parent).each(function () {
        //parent
        var descriptionParent = "";
        var item = {};
        // Perform actions with each element, this refers to the current element in the loop

        var link = $(this).attr('href');
        if (link != null && link != undefined && link.length > 3 && link.indexOf("/") >= 0) {

          descriptionParent = $(this).text().trim();
          // self.commonService.randomUUID().then(id => {
          //   item["id"] = id;
          //   item["label"] = descriptionParent.trim();
          item["link"] = link.trim();
          //item["description"] = descriptionParent;
          menus.push(item);
          //})



        }
      });

      resolve(menus);
    })
  }

  getAllMenuFromHtmlChilden(parent: any): Promise<any> {
    return new Promise(resolve => {
      var menus = [];
      // Iterate over each element

      var self = this;
      $(parent).each(function () {
        //parent
        var descriptionParent = "";
        var item = {};
        // Perform actions with each element, this refers to the current element in the loop

        var link = $(this).attr('href');
        descriptionParent = $(this).text();
        if (descriptionParent != null && descriptionParent != undefined && descriptionParent.length > 3) {

          // self.commonService.randomUUID().then(id => {
          //   item["id"] = id;
          //   item["label"] = descriptionParent.trim();
          item["link"] = link == null || link == undefined ? "" : link.trim();
          // item["description"] = descriptionParent.trim();
          menus.push(item);
          // })

          var chilrent = $(this).find("a");
          if (chilrent != null) {

            $(chilrent).each(function () {
              item = {};

              link = $(this).attr('href');
              var description = $(this).text();

              if (link != null && link != undefined && link.length > 3 && link.indexOf("/") >= 0) {
                // self.commonService.randomUUID().then(id => {
                //   item["id"] = id;
                //   item["label"] = description.trim();
                item["link"] = link.trim();
                //item["description"] = descriptionParent.trim() + " / " + description.trim();
                menus.push(item);
                //})
              }

            })

          }
        }
      });

      resolve(menus);
    })
  }
}
