import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder } from '@angular/forms';
import { DM_CNXD, DM_MDSDD } from 'src/app/contants';
import { MapService } from 'src/app/core/services/map.service';
import { DmChungService } from 'src/app/quan-tri/@core/data/service/dm-chung.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnChanges {
  // @Input() searchQHCCount: any;
  // @Input() searchQHPKCount: any;
  // @Input() searchQHCTCount: any;
  @Input() showResult: boolean;
  @Output() onClose = new EventEmitter<any>();
  @Output() clearSearch = new EventEmitter<any>();
  @Output() onSearchParamsChange = new EventEmitter<any>();
  @Output() onActiveSpatialSearch = new EventEmitter<any>();
  @Output() raidusOnChange = new EventEmitter<any>();
  @Output() hilightPolygon = new EventEmitter<any>();
  formSearch: UntypedFormGroup;
  dmMDSD: any[] = DM_MDSDD;
  dmXa: any[] = [];
  dmCN: any[] = DM_CNXD;
  radius: number = 10;
  showRadiusError: boolean = false;
  isShowTable: boolean = false;
  initCode: any = {
    level: 2,
    code: '088'
  }
  searchResult: any = {};
  listSearch: any[] = [
    {
      value: 'qhxd',
      label: 'Quy hoạch XD'
    },
    {
      value: 'td',
      label: 'Thửa đất'
    }
  ];
  selectedSearch: string = 'qhxd';
  loaiQH: string = '';
  chucNang: string = '';
  isLoadingBlock: boolean = false;
  groupResult: any = {};
  tableData: any[] = [];
  constructor(
    private dmService: DmChungService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private mapService: MapService
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngAfterViewInit(): void {
    this.dmService.getDmXa(this.initCode.code).subscribe((rs: any[]) => {
      if (rs && rs.length) {
        this.dmXa = rs.map(item => {
          return {
            label: item.name,
            value: "0" + item.code
          }
        })
      }
    })
    // this.changeDetectorRef.detectChanges()
  }

  ngOnInit(): void {
    this.formSearch = this.formBuilder.group({
      // mdsd: [''],
      ccode: [''],
      cnxd: [''],
      soto: [''],
      sothua: [''],
      radius: ['']
      // name: [''],
      // alias: [""],
      // sort: [0],
      // control: [""],
      // value: [[]],
      // leaf: [""],
      // standard: [""],
    });
  }

  close() {
    this.onClose.emit();
  }

  get formValues() {

    return this.formSearch.controls;
  }

  clearInput(cname) {
    // this.showResult = false;
    this.formSearch.get(cname).setValue(null);
    this.showResult=false;
    this.clearSearch.emit();
  }

  handleSearch() {
    if (this.formSearch.invalid) {
      return
    }
    const formValues = this.formSearch.getRawValue();
    if (formValues.cnxd) formValues.cnxd = this.dmCN.find(item => item.value === formValues.cnxd).label;
    this.chucNang = formValues.cnxd;
    const searchParams = {
      ...formValues
    }
    // this.showCount = false;
    this.isLoadingBlock = true;
    const searchChucNangParams = [
      {
        "field": 'oqh_chucnangsdd',
        "operato": "=",
        "join": "and",
        "value": "\'" + this.chucNang + "\'"
      }
    ]
    this.mapService.searchTable('QHXD_LaoCai', searchChucNangParams, true).subscribe((rs: any) => {
      this.isLoadingBlock = false;
      this.searchResult = {
        QHC: 0,
        QHPK: 0,
        QHCT: 0
      }
      if (rs && rs.length) {
        const groupResult = _.groupBy(rs, 'oqh_loaiqh');
        this.groupResult = groupResult;
        if (groupResult.QHC) {
          this.searchResult.QHC = groupResult.QHC.length;
        }
        if (groupResult.QHPK) {
          this.searchResult.QHPK = groupResult.QHPK.length;
        }
        if (groupResult.QHCT) {
          this.searchResult.QHCT = groupResult.QHCT.length;
        }
      }
    })
    this.onSearchParamsChange.emit(searchParams);
  }

  setResults(results) {
    // this.searchResult = results;
    // this.isLoadingBlock = false;
  }
  searchLine() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'line',
      radius: formValues.radius
    }
    this.onActiveSpatialSearch.emit(searchParams);
  }
  searchPolygon() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'polygon',
      radius: formValues.radius
    }
    this.onActiveSpatialSearch.emit(searchParams);
  }
  searchPoint() {
    const formValues = this.formSearch.getRawValue();
    const searchParams = {
      type: 'point',
      radius: formValues.radius
    }
    if (!formValues.radius) {
      this.showRadiusError = true;
      return;
    } else this.showRadiusError = false;
    this.onActiveSpatialSearch.emit(searchParams);
  }
  handleRadiusChange(evt) {
    const { value } = evt.target;
    this.radius = value;
    this.raidusOnChange.emit(value)
  }
  clearDraw() {
    this.onSearchParamsChange.emit(null);
  }
  clickSearchType(evt, search) {

  }
  showTable(type) {
    // this.loaiQH = type;
    this.tableData = [];
    console.log('show table', type)
    console.log('show this.groupResult', this.groupResult)
    console.log('show aaaaa', this.groupResult[type])

    if (this.groupResult[type]) {
      this.tableData = this.groupResult[type]
    }
    this.isShowTable = true;
  }
  locatePolygon(feature) {
    this.hilightPolygon.emit(feature)
  }
  closeTable() {

    this.isShowTable = false;
  }
}
