<div class="post-container">
    <div class="modal-header">
        <h2>Tạo thư mục</h2>
    </div>

    <div class="modal-body">
        <div class="upload-container">
            <ngx-dropzone class="upload-content" (change)="onSelect($event)">
                <ngx-dropzone-label style="
                      display: flex;
                      gap: 12px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    ">
                    <img class="" width="36" height="36" src="/assets/images/icon/upload_file.png" alt="" />
                    <span>Kéo hoặc thả file để tải file lên!</span>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files; index as i" [removable]="true" (removed)="onRemove(f, i)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-sm-12">
            <button type="button" class="btn btn-primary w-md m-b-5" (click)="save()">
                Tải lên
            </button>
            <button type="button" class="btn btn-secondary w-md m-b-5" (click)="close()">
                Đóng
            </button>
        </div>
    </div>
</div>