<!-- <mat-spinner *ngIf="isLoading" class="icon-spinner-inside-block position-absolute top-50 start-50 translate-middle"></mat-spinner>

<div *ngIf="isLoading" class="container-loading">
  
</div> -->


<div id="status" *ngIf="isLoading">
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>