<!-- <div *ngIf="loading" class="loading">
    <div class="ct-loading">
    <i class="fas fa-spinner fa-spin" style="display: inline-block;
    font-size: x-large;"></i>
    <p style="display: inline-block; padding-left: 10px; ">Loading...</p>
    </div>
</div> -->


<div *ngIf="loading" class="container-loading1">
    <div id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>