<div class="ng-autocomplete">
  <ng-autocomplete
    [data]="data"
    [(ngModel)]="value"
    [searchKeyword]="keyword"
    placeHolder={{placeHolder}}
    (keydown)="onKeydown($event)"
    
    (inputFocused)='onFocused($event)'
    (selected)='selectEvent($event)'
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate">
   
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item[keyword]"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>  
<!-- historyIdentifier="countries" -->
<!-- (inputChanged)='onChangeSearch($event)' -->
<!-- (selected)='selectEvent($event)' -->