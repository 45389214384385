import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          if (err.status === 401) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: "Thời gian đăng nhập đã hết",
              titleText: "Vui lòng đăng nhập lại !!",
              showConfirmButton: true,
              timer: 5000
            });
            sessionStorage.removeItem("token");
            this.router.navigate(['/account/auth/login']);
          } else {

            // let token = sessionStorage.getItem("token");
            // if (!token) this.authenticationService.logout();
            if (err.status === 503 || err.status === 500) {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err?.error?.message || `Lỗi ${err.status}`,
                showConfirmButton: true,
                timer: 5000
              });
              return;
            }

            console.log("============err.status==========", err)

            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err?.error?.message || "Lỗi không xác định",
              showConfirmButton: true,
              timer: 5000
            });
          }
    
          const error = err.error.message || err.statusText;
          return throwError(error);
        }));
      }
}
