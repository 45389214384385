<!-- <ng5-slider [(value)]="defaultOpacity" [options]="option"></ng5-slider> -->
<!-- <ngx-slier  [(value)]="defaultOpacity" [options]="option"></ngx-slider>    -->
<!-- <a class="dropdown-item" href="javascript: void(0);">
    <ng5-slider [(value)]="defaultOpacity" [options]="option"></ng5-slider>    
</a> -->
<!-- <ng5-slider [(value)]="defaultOpacity" [options]="option"></ng5-slider> -->
<mat-slider min="0" max="1" step="0.1" showTickMarks style="width:100%;" (change)="onChangeOpacity($event)">
    <input matSliderThumb value="1">
</mat-slider>
<!-- <div>
    <div class="mt-4 pt-3">
        <h5 class="font-size-14 mb-3">Độ trong suốt</h5>
        <ng5-slider [(value)]="defaultOpacity" [options]="option"></ng5-slider>
    </div>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="javascript: void(0);">Phóng tới lớp bản đồ</a>
</div> -->