<ngb-accordion #acc="ngbAccordion" activeIds="{{activeIds}}" [closeOthers]="false" class="c-accordion p-0">
  <ngb-panel *ngFor="let menu of menus; let i = index" id="{{menu.id}}" [disabled]="!menu.children.length"
    cardClass="{{menu.children.length ? '' : keyActive === menu?.data?.id ? 'c-acc-item-active': 'c-acc-item'}} ">
    <ng-template ngbPanelTitle class="text-black c-item-catalog">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex w-90">
          <i *ngIf="level === 0" class="fa-regular fa-map fw-bold me-1 align-self-center"></i>
          <strong *ngIf="menu.children.length">{{menu?.data?.name}}</strong>
          <div
            class="{{keyActive === menu?.data?.id ? 'fw-bold' : ''}} cursor-pointer ngb-panel-disable position-relative w-100"
            *ngIf="!menu.children.length" (click)="clickItem(menu?.data)">{{menu?.data?.name}}
          </div>
        </div>
        <div *ngIf="role === 'admin'">
          <button class="border-none bg-none pe-0" [matMenuTriggerFor]="menuFolder"
            aria-label="Example icon-button with a menu" (click)="stopCollap($event)">
            <i class="fa-solid fa-ellipsis cursor-pointer"></i>
          </button>
          <mat-menu #menuFolder="matMenu">
            <div>
              <button *ngIf="menu?.children?.length" mat-menu-item class="h-35px" (click)="addCatalog($event, menu)" [role]="buttons.ADD.VALUE">
                <i class="fa-solid fa-bars font-size-16 text-warning me-2"></i>
                <span>Thêm danh mục</span>
              </button>
              <button mat-menu-item class="h-35px" (click)="editCatalog($event, menu)">
                <i class="fa-regular fa-pen-to-square font-size-16 text-success me-2"></i>
                <span>Sửa danh mục</span>
              </button>
              <button type="button" mat-menu-item class="h-35px" (click)="deleteCatalog($event, menu)" [role]="buttons.DELETE.VALUE">
                <i class="fa-solid fa-trash-can font-size-16 text-danger me-2"></i>
                <span>Xóa danh mục</span>
              </button>
              <button mat-menu-item class="h-35px" (click)="addResource($event, menu)" [role]="buttons.ADD.VALUE">
                <i class="fa-solid fa-globe font-size-16 text-primary me-2"></i>
                <span>Thêm tài nguyên</span>
              </button>
            </div>
          </mat-menu>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-catalog [role]="role" [maps]="maps" [menus]="menu.children" [level]="level + 1"
        (onAction)="onClickNode($event)" [keyActive]="keyActive"></app-catalog>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

<ng-template #cModalCatalog role="document" let-modal>
  <div class="modal-header py-2">
    <div class="d-flex justify-content-between w-100 fw-600 font-size-16">
      Thông tin danh mục
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form class="" [formGroup]="formCatalog" (ngSubmit)="submitCatalog(modal)">
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label">Tên chuyên đề</label>
        <input class="form-control" type="search" placeholder="Nhập tên chuyên đề" name="name" formControlName="name"
          [ngClass]="{'is-invalid': submitted && valFormCatalog.name.errors}" required>
        <div *ngIf="submitted && valFormCatalog.name.errors" class="invalid-feedback">
          <span *ngIf="valFormCatalog.name.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Mã chuyên đề</label>
        <input class="form-control" type="search" placeholder="Nhập mã chuyên đề" name="code" formControlName="code"
          [ngClass]="{'is-invalid': submitted && valFormCatalog.code.errors}" required>
        <div *ngIf="submitted && valFormCatalog.code.errors" class="invalid-feedback">
          <span *ngIf="valFormCatalog.code.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Thứ tự</label>
        <input class="form-control" type="number" placeholder="Nhập thứ tự" name="order" formControlName="order"
          [ngClass]="{'is-invalid': submitted && valFormCatalog.order.errors}" required>
        <div *ngIf="submitted && valFormCatalog.order.errors" class="invalid-feedback">
          <span *ngIf="valFormCatalog.order.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="button-right">
        <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
          (click)="modal.close('Close click')"  >Đóng</button>
        <button type="submit" class="btn btn-primary waves-effect waves-light" >Lưu
          lại</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #cModalResource role="document" let-modal>
  <div class="modal-header py-2">
    <div class="d-flex justify-content-between w-100 fw-600 font-size-16">
      Thông tin tài nguyên
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form class="" [formGroup]="formResource" (ngSubmit)="submitResource(modal)">
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label">Tên tài nguyên</label>
        <input class="form-control" type="search" placeholder="Nhập tên tài nguyên" name="name" formControlName="name"
          [ngClass]="{'is-invalid': submitted && valFormResource.name.errors}" required>
        <div *ngIf="submitted && valFormResource.name.errors" class="invalid-feedback">
          <span *ngIf="valFormResource.name.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label fw-600"><i class="fa-solid fa-database me-2"></i>Chọn bản đồ</label>
        <ng-select [notFoundText]="'Không có dữ liệu'" [items]="maps" [multiple]="false" bindLabel="name" bindValue="id"
          [ngClass]="{ 'is-invalid': submitted && valFormResource.id_map.errors }" formControlName="id_map"
          name="id_map">
        </ng-select>
        <div *ngIf="submitted && valFormResource.id_map.errors" class="invalid-feedback">
          <span *ngIf="valFormResource.id_map.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Mô tả</label>
        <textarea class="form-control" rows="3" placeholder="Thêm mô tả"
          formControlName="description" name="description"
          [ngClass]="{ 'is-invalid': submitted && valFormResource.description.errors }"></textarea>
        <div *ngIf="submitted && valFormResource.description.errors" class="invalid-feedback">
          <span *ngIf="valFormResource.description.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">
        <!-- 
        <button type="button" class="btn btn-light" (click)="openModalUpload()">
          <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Tải ảnh lên
        </button> -->

        <button type="button" class="btn btn-light" (click)="openModalSelectImage()">
          <i class="bx bx-cloud-upload font-size-16 align-middle me-2"></i> Chọn ảnh từ thư viện
        </button>



        <div *ngIf="fileSelected" class="mt-3">
          <img src="{{host}}/{{fileSelected.data.info.uri}}" class="image-card" />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="button-right">
        <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
          (click)="modal.close('Close click')">Đóng</button>
        <button type="submit" class="btn btn-primary waves-effect waves-light" ><i class="fa-solid fa-floppy-disk"></i>
          Lưu
          lại</button>
      </div>
    </div>
  </form>
</ng-template>

<!-- <ng-template #cModal role="document" let-modal>
  <div class="modal-header py-2">
    <div class="d-flex justify-content-between w-100 fw-600 font-size-16">
      Tải ảnh lên
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form class="" [formGroup]="formUpload" (ngSubmit)="submitUpload(modal)">
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label">Tên ảnh</label>
        <input class="form-control" type="search" placeholder="Nhập tên ảnh" name="fileName" formControlName="fileName"
          [ngClass]="{'is-invalid': submitted && valFormUpload.fileName.errors}" required>
        <div *ngIf="submitted && valFormUpload.fileName.errors" class="invalid-feedback">
          <span *ngIf="valFormUpload.fileName.errors.required">Bắt buộc nhập</span>
        </div>
      </div>
      <div class="mb-3">

        <label class="form-label">Đường dẫn thư mục <i
            class="ms-2 fa-solid fa-folder-tree text-warning cursor-pointer"></i></label>
        <div class="position-relative">
          <input class="form-control" type="search" placeholder="Nhập tên thư mục lưu trữ" name="bucketName"
            formControlName="bucketName" [ngClass]="{'is-invalid': submitted && valFormUpload.bucketName.errors}" required>
          <div *ngIf="submitted && valFormUpload.bucketName.errors" class="invalid-feedback">
            <span *ngIf="valFormUpload.bucketName.errors.required">Bắt buộc nhập</span>
          </div>
          <i class="fa-solid fa-folder-tree text-warning icon-choose-folder cursor-pointer"></i>
        </div>

      </div>
      <div class="mb-3">
        <ngx-dropzone (change)="onSelect($event)">
          <ngx-dropzone-label>Chọn hoặc kéo thả ảnh Bản đồ!</ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files; let i = index" [file]="f" [removable]="true" (removed)="onRemove(f, i)">
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
        <div *ngIf="submitted && !files.length" class="invalid-feedback text-danger">
          <span>Bắt buộc nhập</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="button-right">
        <button type="button" class="btn btn-secondary waves-effect me-2" data-bs-dismiss="modal"
          (click)="modal.close('Close click')">Đóng</button>
        <button type="submit" class="btn btn-primary waves-effect waves-light">Lưu
          lại</button>
      </div>
    </div>
  </form>
</ng-template> -->
<app-select-image #selectImage (onAction)="onChoice($event)"></app-select-image>
<app-loading-screen [isLoading]="isLoading"></app-loading-screen>