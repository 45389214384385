
<div>
  <ng-particles id="tsparticles" [options]="particlesOptions" [particlesInit]="particlesInit"
    (particlesLoaded)="particlesLoaded($event)"></ng-particles>
  <!-- hero section start -->
  <section class="section hero-section bg-ico-hero" id="home">
    <div class="bg-overlay bg-primary"></div>
    <div class="container">
      <div class="row ">
        <div class="col-lg-7 slider_title animated">
          <div class="text-white-50 ">
            <h2 class="text-white fw-semibold mb-3 hero-title mt-5 line-height-1-4">CHÀO MỪNG ĐẾN VỚI <br> CỔNG THÔNG TIN GIS THÀNH PHỐ CẦN THƠ</h2>
            <p class="font-size-14 text-white line-height-1-5">Bạn đang có nhu cầu hỏi các thông tin về kế hoạch, quy
              hoạch sử dụng đất, giá đất, thông tin về quy hoạch xây dựng hoặc quy hoạch sử dụng đất nông nghiệp, Vui
              lòng tải ứng dụng Tra cứu thông tin GIS Thành phố Cần Thơ tại đây:</p>

            <div class="button-items mt-4 d-flex gap-2">
              <a routerLink="/download-app" [queryParams]="{link:'android'}" class="btn-download-app btn btn-light">
                <i class="fa-brands fa-apple font-size-18"></i>
                App Store
              </a>
              <a routerLink="/download-app" [queryParams]="{link:'ios'}" class="btn-download-app btn btn-light">
                <i class="fa-brands fa-android font-size-18"></i>
                Google Play
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-8 col-sm-10 ms-lg-auto">
          <img src="assets/images/layouts/banner-devices.png" width="100%">

        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- hero section end -->

  <!-- currency price section start -->
  <!-- <section class="section bg-white p-0">
    <div class="container">
      <div class="currency-price">
        <div class="row">
          <div class="col-md-12 d-flex">
            <div class="card w-100 box-search">
              <div class="card-body">
                <div class="d-flex">
                  <div class="col-md-10">
                    <input class="form-control" type="text" placeholder="Tìm kiếm" id="example-text-input">
                  </div>
                  <div class="col-md-2 text-center">
                    <button class="btn btn-warning">
                      <i class="fa-brands fa-searchengin me-2"></i>
                      <span>Tìm kiếm</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-danger font-size-16 cursor-pointer">Tìm kiếm nâng cao</div>
      </div>
    </div>
  </section> -->
  <!-- currency price section end -->


  <!-- about section start -->
  <!-- <section class="section pt-4 pt-5 gray-section" id="about">
    <div class="c-container w-75 mx-auto">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h3 class="text-uppercase">GIỚI THIỆU CHUNG</h3>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="text-muted px-5">
            <h1>{{'label.hello_text' | translate}}</h1>
            <p class="mb-4 text-justify font-size-16 mt-3">{{'label.sub_title_head' | translate}}</p>
          </div>
        </div>

        <div class="col-lg-6 ms-auto">
          <div class="vid-img">
            <iframe src="https://www.youtube.com/embed/GOR33RFA4xY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>

      <hr class="my-5">
    </div>
  </section> -->
  <!-- about section end -->

  <!-- Features start -->
  <!-- <section class="section bg-white" id="features-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h3 class="text-uppercase">{{'label.main_function' | translate}}</h3>
            <div class="small-title text-center">{{'label.sub_text_main_function' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/card-search.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Tìm Dữ Liệu Mong Muốn</h5>
                  <p class="text-muted mb-0">Bạn có thể tìm thấy dữ liệu mong muốn bằng cách sử dụng tính năng tìm kiếm
                    và tìm kiếm nâng cao.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/card-download.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Tải Xuống Dữ Liệu Mong Muốn</h5>
                  <p class="text-muted mb-0">Bạn có thể tải xuống dữ liệu mong muốn bằng cách đăng nhập vào hệ thống.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/data-map.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Dữ Liệu Mong Muốn trong Bản Đồ</h5>
                  <p class="text-muted mb-0">Bạn có thể xem dữ liệu trong bản đồ để kiểm tra xem dữ liệu trông như thế
                    nào khi được vẽ trên bản đồ.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/view-data.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Xem Siêu Dữ Liệu</h5>
                  <p class="text-muted mb-0">Bạn có thể xem siêu dữ liệu của dữ liệu mong muốn để biết thêm về dữ liệu.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/card-cart.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Thêm Dữ Liệu vào Giỏ Hàng</h5>
                  <p class="text-muted mb-0">Bạn có thể thêm nhiều tập dữ liệu vào giỏ hàng và tải xuống cùng nhau ở
                    định dạng đóng gói.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="card card-item">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img src="./assets/images/view-app.svg">
                </div>
                <div class="flex-grow-1">
                  <h5>Xem Ứng Dụng Không Gian Địa Lý</h5>
                  <p class="text-muted mb-0">Bạn có thể xem các ứng dụng và công cụ không gian địa lý hữu ích do các Cơ
                    quan Chính phủ khác nhau phát triển.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section class="section gray-section" id="features">
    <div class="c-container w-75 mx-auto">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h3 class="text-uppercase">THÔNG TIN GIÁ ĐẤT</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-1.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <span class="text-danger align-self-center">Ranh Giới Hành Chính</span>
                <button class="btn btn-sm btn-primary">Tự Do</button>
              </div>
              <h5 class="mt-2">Ranh giới hành chính - Các đơn vị địa phương của Nepal ( 1:1000000)</h5>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <div class="row">
                <div class="col-md-6">
                  <label>Tạo</label>
                </div>
                <div class="col-md-6"><label>Sửa đổi lần cuối</label></div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly mb-3">
              <button class="btn btn-danger">
                <i class="fa-solid fa-download me-2"></i>
                <span>Tải xuống</span>
              </button>
              <button class="btn btn-secondary">
                <i class="fa-regular fa-map me-2"></i>
                <span>Bản đồ</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-2.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <span class="text-danger align-self-center">Dữ liệu địa hình</span>
                <button class="btn btn-sm btn-primary">Tự Do</button>
              </div>
              <h5 class="mt-2">Lớp phủ đất ( 1:1000000)</h5>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <div class="row">
                <div class="col-md-6">
                  <label>Tạo</label>
                </div>
                <div class="col-md-6"><label>Sửa đổi lần cuối</label></div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly mb-3">
              <button class="btn btn-danger">
                <i class="fa-solid fa-download me-2"></i>
                <span>Tải xuống</span>
              </button>
              <button class="btn btn-secondary">
                <i class="fa-regular fa-map me-2"></i>
                <span>Bản đồ</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-3.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <span class="text-danger align-self-center">Dữ liệu địa hình</span>
                <button class="btn btn-sm btn-primary">Tự Do</button>
              </div>
              <h5 class="mt-2">Các khu định cư (Tỷ lệ - 1:1000k)</h5>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <div class="row">
                <div class="col-md-6">
                  <label>Tạo</label>
                </div>
                <div class="col-md-6"><label>Sửa đổi lần cuối</label></div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly mb-3">
              <button class="btn btn-danger">
                <i class="fa-solid fa-download me-2"></i>
                <span>Tải xuống</span>
              </button>
              <button class="btn btn-secondary">
                <i class="fa-regular fa-map me-2"></i>
                <span>Bản đồ</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-4.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <span class="text-danger align-self-center">Ranh Giới Hành Chính</span>
                <button class="btn btn-sm btn-primary">Tự Do</button>
              </div>
              <h5 class="mt-2">Ranh giới hành chính - Tỉnh ( 1:1000000)</h5>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <div class="row">
                <div class="col-md-6">
                  <label>Tạo</label>
                </div>
                <div class="col-md-6"><label>Sửa đổi lần cuối</label></div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
                <div class="col-md-6 text-muted">Ngày 20, tháng 9, Năm 2022</div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly mb-3">
              <button class="btn btn-danger">
                <i class="fa-solid fa-download me-2"></i>
                <span>Tải xuống</span>
              </button>
              <button class="btn btn-secondary">
                <i class="fa-regular fa-map me-2"></i>
                <span>Bản đồ</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center text-see-more text-danger">
          <span>Xem thêm</span>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Features end -->

  <!-- Roadmap start -->
  <!-- <section class="section bg-white" id="roadmap">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h3>QUY HOẠCH SỬ DỤNG ĐẤT</h3>
            <div class="small-title">Khám phá các ứng dụng và công cụ không gian địa lý do các cơ quan chính phủ khác
              nhau phát triển.
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-4 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-5.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5>Sử dụng đất Geoportal</h5>
              </div>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <p class="text-muted">Được phát hành</p>
              <h5>Ngày 19 tháng 10 năm 2022</h5>
            </div>
            <div class="text-center my-3">
              <button class="btn btn-danger">
                <i class="fa-regular fa-eye  me-2"></i>
                <span>Xem ứng dụng</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-6.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5>Bản đồ đơn vị địa phương Nepal</h5>
              </div>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <p class="text-muted">Được phát hành</p>
              <h5>Ngày 19 tháng 10 năm 2022</h5>
            </div>
            <div class="text-center my-3">
              <button class="btn btn-danger">
                <i class="fa-regular fa-eye  me-2"></i>
                <span>Xem ứng dụng</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex">
          <div class="card custom-card">
            <div class="card-header p-0">
              <img src="./assets/images/card/card-7.png" width="100%">
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5>Ứng dụng địa hình</h5>
              </div>
              <p class="para span-15">Lớp Ranh giới hành chính chứa các ranh giới của Đơn vị địa phương...</p>
              <p class="text-muted">Được phát hành</p>
              <h5>Ngày 19 tháng 10 năm 2022</h5>
            </div>
            <div class="text-center my-3">
              <button class="btn btn-danger">
                <i class="fa-regular fa-eye  me-2"></i>
                <span>Xem ứng dụng</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center text-see-more text-danger">
          <span>Xem thêm</span>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section class="section bg-discover">
    <div class="container">
      <h1>
        Khám Phá Các Bộ Dữ Liệu Không Gian Địa Lý khác nhau do các cơ quan chính phủ khác nhau cung cấp.
      </h1>
      <div class="text-center mt-5">
        <button class="btn btn-danger px-3 font-size-16">Khám phá</button>
      </div>
    </div>
  </section>


  <section class="section gray-section" id="ask_question">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="">{{'label.frequently_ask_question' | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <img src="assets/images/layouts/faq.png" alt="" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">{{'label.frequently_ask_question' | translate}}</h4>

              <ngb-accordion [closeOthers]="true" activeIds="static-1" class="custom-accordion">
                <ngb-panel id="static-1">
                  <ng-template ngbPanelTitle>
                    <a href="javascript: void(0);" class="accordion-list">
                      <div>{{'label.so_what_makes_remote_signing_safe' | translate}}</div>
                    </a>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body p-0">
                      <p class="mb-0">{{'label.anser_1_1' | translate}}</p>
                      <p>{{'label.anser_1_2' | translate}}</p>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-2">
                  <ng-template ngbPanelTitle>
                    <a href="javascript: void(0);" class="accordion-list">
                      <div>{{'label.if_lose_mobile_device' | translate}}</div>
                    </a>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body p-0">
                      <p class="mb-0">{{'label.anser_2_1' | translate}}</p>
                      <p>{{'label.anser_2_2' | translate}}</p>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-3">
                  <ng-template ngbPanelTitle>
                    <a href="javascript: void(0);" class="accordion-list">
                      <div>{{'label.how_to_PIN_CODES_protect_your_information' | translate}}</div>
                    </a>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body p-0">
                      <div class="panel-body">
                        <p>{{'label.anser_3_1' | translate}}</p>

                        <p style="margin-left: 25px;">{{'label.anser_3_2' | translate}}</p>
                        <p style="margin-left: 25px;padding-top:20px">{{'label.anser_3_3' | translate}}</p>

                        <p>{{'label.anser_3_4' | translate}}</p>
                        <p>{{'label.anser_3_5' | translate}}</p>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-4">
                  <ng-template ngbPanelTitle>
                    <a href="javascript: void(0);" class="accordion-list">
                      <div>{{'label.how_to_upgrade_app' | translate}}</div>
                    </a>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card-body p-0">
                      <p class="mb-0"></p>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button class="btn btn-danger">
            <i class="fa-solid fa-paper-plane me-2"></i>
            <span>Gửi câu hỏi</span>
          </button>
        </div>
      </div>
    </div>
  </section> -->

  <!-- Team start -->
  <!-- <section class="section bg-white" id="team">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4>{{'label.contact_us' | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="text-muted">{{'label.MESSAGE' | translate}}</h5>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <input class="form-control" type="text" placeholder="{{'label.Full_Name' | translate}}"
                    id="example-text-input">
                </div>
                <div class="col-md-6">
                  <input class="form-control" type="text" placeholder="{{'label.email' | translate}}"
                    id="example-text-input">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <input class="form-control" type="text" placeholder="{{'label.subject' | translate}}"
                    id="example-text-input">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <textarea formControlName="textarea" placeholder="{{'label.your_message' | translate}}"
                    class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-end">
                  <a href="javascript: void(0);" class="btn-download-app btn btn-success text-white">
                    <i class="fa-solid fa-paper-plane"></i>
                    {{'label.send_message' | translate}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="text-muted">{{'label.ADDRESS' | translate}}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 d-flex">
                  <div class="me-2">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <div>
                    <h5>{{'label.our_location' | translate}}</h5>
                    <h6>{{'label.val_address' | translate}}</h6>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 d-flex">
                  <div class="me-2">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <div>
                    <h5>{{'label.call_us_on' | translate}}</h5>
                    <h6>1900 2101</h6>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 d-flex">
                  <div class="me-2">
                    <i class="fa-solid fa-envelope"></i>
                  </div>
                  <div>
                    <h5>{{'label.send_your_message' | translate}}</h5>
                    <h6>ca-support@cmc.vn</h6>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 d-flex">
                  <div class="me-2">
                    <i class="fa-regular fa-clock"></i>
                  </div>
                  <div>
                    <h5>{{'label.our_working_hours' | translate}}</h5>
                    <h6>{{'label.our_working_hours_val' | translate}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="section bg-main c-landing-footer">
    <div class="container">
      <div class="row pt-2">
        <div class="col-lg-12 col-sm-6">
          <div class="mb-4 mb-lg-0">

            <div class="blog-post">
              <div class="post mb-3">
                <strong>
                  <span class="text-white">CỔNG THÔNG TIN GIS THÀNH PHỐ CẦN THƠ</span>
                </strong>
              </div>

              <div class="mb-2 text-white line-height-1-8">
                <div class="post">
                  <span>Cơ quan chủ quản: Ủy ban nhân dân Thành phố Cần Thơ</span>
                </div>
                <div class="post">
                  <span>Cơ quan quản lý: Văn phòng Ủy ban nhân dân Thành phố Cần Thơ</span>
                </div>
                <div class="post">
                  <span>Địa chỉ: Số 02 Hòa Bình, P. Tân An, Q. Ninh Kiều, TP. Cần Thơ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <div class="blog-post">
              <div class="post mb-3">
                <strong>
                  <span class="text-white">LIÊN KẾT</span>
                </strong>
              </div>
              <div class="mb-2 text-white line-height-1-8">
                <div class="post">
                  <a href="javascript: void(0);" class="text-white">
                    <span>Cổng thông tin điện tử</span>
                  </a>
                </div>
                <div class="post">
                  <a href="javascript: void(0);" class="text-white">
                    <span>Phân tích, tổng hợp ra quyết định</span>
                  </a>
                </div>
                <div class="post">
                  <a href="javascript: void(0);" class="text-white">
                    <span>Dữ liệu mở</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</div>