import * as moment from "moment";
import { transform } from "ol/proj";
import { type_file } from "../contants";

export const convertTimeStampToDate = (time) => {
  return moment(time).format("DD/MM/YYYY")
}
export const convertTimeStampToDateTime = (time) => {
  return moment(time).format("DD/MM/YYYY HH:MM");
}

export const convertStyle = (obj: any) => {
  let typeNumber = ['integer', 'bigint', 'double'];
  return obj.source ? 'ddl' : typeNumber.includes(obj.datatype) ? 'number' : 'textbox'
}

export const convertGeom = (geom, from, to) => {
  return geom.map(item => {
    return transform(item, from, to)
  })
}

export const convertCaltoSymbol = (cal) => {
  switch (cal.toLowerCase()) {
    case "equal":
      return "=";
    case "less":
      return "<";
    case "greater":
      return ">";
    case "between":
      return "[]";
    case "like":
      return "%";
    default: return "";
  }
}

export const convertSymboltoCal = (cal) => {
  switch (cal) {
    case "=":
      return "equal";
    case "<":
      return "less";
    case ">":
      return "greater";
    case "[]":
      return "between";
    case "%":
      return "like";
    default: return "";
  }
}

export const checkTypeFile = (type) => {
  let isImage = type_file.image.includes(type);
  if (isImage) return "image";
  let isVideo = type_file.video.includes(type);
  if (isVideo) return "video";
  return "other";
}

export const convertByte = (byte, to) => {
  switch (to) {
    case "kb":
      return Number((byte / 1024).toFixed(2));
    case "mb":
      return Number((byte / (1024 * 1024)).toFixed(2));
    default: return 0;
  }
}

export const convertDatatypeToControl = (datatype) => {
  switch (datatype) {
    case "datetime":
      return "datetimepicker";
    case "date":
      return "datepicker";
    case "varchar": case "text":
      return "textbox";
    case "integer": case "bigint": case "double":
      return "number";
    case "file": case "image":
      return "file";
    case "html":
      return "html";
    case "boolean":
      return "boolean";
    case "Point": case "LineString": case "MultiPolygon":
      return "geometry"
    default:
      return;
  }
}

export const convertLocationDecimal = (location, number) => {
  return [location[0].toFixed(number), location[1].toFixed(number)];

}