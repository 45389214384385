import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: "1",
        label: 'Quản lý quy trình',
        isTitle: true
    },
    {
        id: "1.1",
        label: 'Quy trình',
        icon: 'bx-sitemap',
        link: '/etl/quy-trinh',
    },
    {
        id: "1.2",
        label: 'Lịch sử xử lý',
        icon: 'bx-history',
        link: '/etl/lich-su-xu-ly',
    },
    {
        id: "2",
        label: 'GIÁM SÁT VÀ QUẢN TRỊ',
        isTitle: true
    },
    {
        id: "2.1",
        label: 'Danh mục kết nối',
        icon: 'bx-broadcast',
        link: '/etl/ket-noi-he-thong',
    },
    {
        id: "2.2",
        label: 'Danh sách cơ sở dữ liệu',
        icon: 'bx-data',
        link: '/etl/danh-sach-co-so-du-lieu',
    },
    {
        id: "2.3",
        label: 'Danh sách API',
        icon: 'bx-world',
        link: '/etl/trinh-tao-ket-noi/danh-sach',
    },
    {
        id: "2.4",
        label: 'Cảnh báo vấn đề dữ liệu',
        icon: 'bx-bell',
        link: '/etl/canh-bao-van-de-du-lieu',
    },
    {
        id: "3",
        label: 'Cổng chia sẻ dữ liệu',
        isTitle: true
    },
    {
        id: "3.1",
        label: 'Dịch vụ chia sẻ dữ liệu',
        icon: 'bx-share-alt',
        link: '/etl/dich-vu-chia-se-du-lieu',
    },
    {
        id: "3.2",
        label: 'Lịch sử chia sẻ dữ liệu',
        icon: 'bx-history',
        link: '/etl/lich-su-chia-se-du-lieu',
        
    },
];

