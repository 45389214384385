import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from "./layouts/layout.component";
import { Page404Component } from "./extrapages/page404/page404.component";
// import { AppComponent } from './app.component';
import { TestrenderComponent } from "./testrender/testrender.component";
import { AdminComponent } from "./layouts/admin/admin.component";
import { TableComponent } from "./layouts/table/table.component";
import { EtlComponent } from "./layouts/etl/etl.component";
import { QuanTriComponent } from "./layouts/quan-tri/quan-tri.component";
import { MapboxComponent } from "./components/mapbox/mapbox.component";
import { ProfileComponent } from "./quan-tri/Pages/QuanTriNd/Users/profile/profile.component";
import { HoSoComponent } from "./layouts/hoso/ho-so/ho-so.component";
import { HoSoModule } from "./ho-so/ho-so.module";
import { PhanAnhComponent } from "./layouts/phan-anh/phan-anh.component";
const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  // // tslint:disable-next-line: max-line-length
  // { path: '', component: MapboxComponent},
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  // {
  //   path: "extrapages",
  //   loadChildren: () =>
  //     import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
  // },
  // // { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  // // { path: 'admin', component: AdminComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  // {
  //   path: "admin/table",
  //   component: TableComponent,
  //   loadChildren: () =>
  //     import("./admin/table/table.module").then((m) => m.TableModule),
  // },
  // {
  //   path: "etl",
  //   component: EtlComponent,
  //   loadChildren: () => import("./etl/etl.module").then((m) => m.ETLModule),
  // },
  {
    path: "ho-so",
    component: HoSoComponent,
    loadChildren: () =>
      import("./ho-so/ho-so.module").then((m) => m.HoSoModule),
  },
  {
    path: "phan-anh",
    component: PhanAnhComponent,
    loadChildren: () =>
      import("./phan-anh/phan-anh.module").then((m) => m.PhanAnhModule),
  },
  // { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule) },
  {
    path: "quan-tri",
    component: QuanTriComponent,
    loadChildren: () =>
      import("./quan-tri/quan-tri.module").then((m) => m.AppQuanTriModule),
  },
  { path: "profile", component: ProfileComponent },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
