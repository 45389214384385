<div class="container-fluid">

  <app-pagetitle1 title="Thông tin tài khoản"></app-pagetitle1>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">


          <div class="row mb-2">
            <div class="col-sm-12">

              <app-gen-html-form (genHtmlFormComponentEmitter)="genHtmlFormComponentEmitter($event)">
              </app-gen-html-form>

              <div class="col-sm-12">
                <button type="button" class="btn btn-primary w-md m-b-5" (click)="update()"
                  [role]="buttons.UPDATE.VALUE">
                  Lưu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>