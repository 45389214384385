import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from "../../contants/ApiContants";
import { WORKSPACE } from "../../contants";
import { User } from '../models/auth.models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class CatalogService {

    constructor(private httpRequest: ConfigService) {

    }

    getHuyenXa(){
      return this.httpRequest.get(`${API.BASE_URL}/category/category`);
    }

    getDataDDL(tbkey:string ){
      return this.httpRequest.get(`${API.BASE_URL}/category/data-on-ddl?tbkey=${tbkey}`);
    }

    searchTD(body){
      let bodyRQ = {
        "ma_huyen": body.maHuyen,
        "ma_xa": body.maXa,
        "so_thua": body.soThua,
        "so_to": body.soTo
      }
      return this.httpRequest.post(`${API.BASE_URL}/gis/find/sheets-plots`, bodyRQ);
    }

    searchLocation(body){
      return this.httpRequest.post(`${API.BASE_URL}/gis/find/coordinates`, body);
    }

    getMenu(){
      return this.httpRequest.get(`${API.BASE_URL}/gis-portal/menu`);
    }

    getMaps(type, page, pageSize){
      return this.httpRequest.get(`${API.BASE_URL}/gis-portal/map-all?maloai=${type}&page=${page}&size=${pageSize}`);
    }

    getMapsById(id, type, page, pageSize){
      return this.httpRequest.get(`${API.BASE_URL}/gis-portal/map-all/${id}?maloai=${type}&page=${page}&size=${pageSize}`);
    }

    getMapsBySearch(id, type, page, pageSize, key){
      return this.httpRequest.get(`${API.BASE_URL}/gis-portal/map-search?page=${page}&size=${pageSize}&maloai=${type}&idChuyenDe=${id}&&search=${key}`);
    }

    getAllWMS(){
      return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/wms/feature`);
    }

    getTableSource(){
      return this.httpRequest.get(`${API.BASE_URL}/private/gis-db/resource/table/all-models`);
    }
    
    getLayerFromWorkspace(){
      return this.httpRequest.get(`${API.BASE_URL}/public/geo-server/${WORKSPACE}/layer`);
    }

    
}