import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends MatPaginatorIntl {

  @Input() pageSize = 10;
  @Input() total = 0;
  @Input() length = 0;
  @Output() nextPage = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() hidePaging = false;
  currentPage = 1;
  
  constructor() {
    super();
    this.getAndInitTranslations();
  }

  getNext(event){
    this.currentPage = event.pageIndex + 1;
    this.nextPage.emit(event);
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = "Sổ bản ghi trên trang";
    this.nextPageLabel = "Trang tiếp";
    this.previousPageLabel = "Trang trước";
    this.firstPageLabel = "Trang đầu";
    this.lastPageLabel = "Trang cuối";
    this.changes.next();
  }
  
  ngOnInit() {
  }

}
