import { Component, ChangeDetectionStrategy } from '@angular/core';
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import TileWMS from 'ol/source/TileWMS';
import Select from 'ol/interaction/Select';
import { altKeyOnly, click, pointerMove } from 'ol/events/condition';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import * as _ from 'lodash';
import XYZ from 'ol/source/XYZ';
import { api_map } from "./contants";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import MultiPoint from 'ol/geom/MultiPoint';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DISTRICT, VILLAGES } from "./data";
import { MapService } from './core/services/map.service';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength, getDistance } from 'ol/sphere';
import Draw from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import { options } from 'preact';
import {
  getPointResolution,
  transform,
} from 'ol/proj';
import { ActivatedRoute, Router } from '@angular/router';
import {version} from 'src/app/contants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  // name = "Angular " + VERSION.major;

  noHeader: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private mapService: MapService, private router: Router) {
    console.log("======version=======", "v1 25/3/2024");
    // if (!sessionStorage.getItem("token")) {
    //   sessionStorage.setItem("token", "fake");
    //   this.router.navigate(['/pages/home']);
    // }
  }

  ngOnInit(): void {
    let href = (window.location.pathname).split("/");
    if (['mobile'].includes(href[href.length - 1])) {
      this.noHeader = true;
    }
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    console.log(version);
    console.log("-----screenWidth-------", screenWidth, screenHeight)
  }

  renderLog(){
    console.log("=======renderLog======");
  }
}