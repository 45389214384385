<div class="row mt-2">
  <div class="col-md-12 align-self-center">
    <label class="form-check-label">
      Điều kiện lọc:
    </label>
    <button type="button" class="btn btn-sm btn-light bg-white ms-3" (click)="addCondition()">
      <i class="fa-solid fa-plus"></i>
    </button>
  </div>
  <div class="col-md-12">
    <form>
      <div *ngFor="let row of filterConditions; let i = index" class="row mt-2">
        <div class="col-md-6 pe-1">
          <ng-select [notFoundText]="'Không có dữ liệu'" [items]="optionsFilter" [multiple]="false" bindLabel="colname"
            bindValue="colname" (change)="changeNgSelect($event, row, i)" [(ngModel)]="row.column" name="{{i}}">
            <ng-template ng-option-tmp let-item="item"> <!-- Template for option display -->
              {{ item.alias }} - ({{ item.colname }})
            </ng-template>
          </ng-select>
        </div>
        <div class="col-md-2 px-0 mx-0">
          <select class="form-select" (change)="changeOperator($event, i)">
            <option *ngFor="let option of conditions; let i = index" [value]="option.value"
              [selected]="option.value === row.operator">
              {{option.label}}
            </option>
          </select>
        </div>
        <div class="col-md-3 pe-0 me-0 ps-1">
          <input class="form-control" type="search" (change)="changeCondition($event, row, i, 'value')"
            [defaultValue]="row.value">
        </div>
        <div class="col-md-1 align-self-center px-0 ps-2">
          <i class="fa-solid fa-xmark text-danger font-size-14 cursor-pointer ms-0 "
            (click)="removeCondition(row, i)"></i>
        </div>
      </div>
    </form>
  </div>
</div>